<template>
  <DistributorContactPersonGrid
    ref="cpGrid"
    masterField="distributorId"
    cpAbbreviation="ecp"
    :model="distributor"
    :service="empoweredContactPersonService"
    :mountAccess="mountAccess"
    :checkAccess="checkAccess"
    :validationProps="validations"
    :fields="fields"
    :modalFields="modalFields"
    :modalHeader="$t('empoweredPerson')"
    :mainTxt="$t('empoweredContactPersonCreateInfo')"
    :buildModel="buildModel"
  />
</template>
<script>
import DistributorContactPersonGrid from "./DistributorContactPersonGrid";
import { EmpoweredContactPersonService } from "../../../services/EmpoweredContactPersonService.js";
import { BaseTypeService } from "../../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
const _ = require("lodash");

export default {
  components: {
    DistributorContactPersonGrid,
  },
  props: {
    distributor: {
      type: Object,
      required: true,
    },
    mountAccess: {
      type: Function,
      required: true,
    },
    checkAccess: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      empoweredContactPersonService: new EmpoweredContactPersonService(),
      baseTypeService: new BaseTypeService(),
    };
  },
  computed: {
    validations() {
      const docValid = (v) => {
        if (this.$refs["cpGrid"].modalModel.document !== "Certificate")
          return true;

        return !!v;
      };
      return {
        fio: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
        },
        position: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
        },
        document: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
        },
        series: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            docValid
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
        },
        number: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            docValid
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
        },
        registerDate: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            docValid
          ),
        },
      };
    },
    modalFields() {
      return [
        [
          {
            name: "position",
            displayName: this.$t("positionFull"),
            placeholder: 'Руководитель отдела продаж'
          },
        ],
        [
          {
            name: "fio",
            displayName: this.$t("fioFull"),
            placeholder: 'Иванов Иван Иванович'
          },
        ],
        [
          {
            name: "document",
            displayName: this.$t("document"),
            type: "dropdown",
            service: this.baseTypeService,
            serviceGetMethod: "grid",
            propValue: "name",
            propLabel: "name",
            argMethod: "$filter=systemDirectoryCode eq 'Document foundation' and disabled eq false"
          },
        ],
        [
          {
            name: "series",
            displayName: this.$t("series"),
          },
          {
            name: "number",
            displayName: this.$t("number"),
          },
        ],
        [
          {
            name: "registerDate",
            displayName: this.$t("date"),
            type: "date",
          },
        ],
      ];
    },
    fields() {
      return [
        {
          value: "position",
          name: this.$t("positionFull"),
          f: (item) => item,
        },
        {
          value: "fio",
          name: this.$t("fioFull"),
          f: (item) => item,
          class: 'text-bold'
        },
        {
          value: "document",
          name: this.$t("document"),
        },
        {
          value: "seriesNumber",
          name: this.$t("seriesNumber"),
        },
        {
          value: "registerDate",
          name: this.$t("date"),
        },
      ];
    },
  },
  methods: {
    buildModel(model) {
      let newModel = _.cloneDeep(model);

      if (model.document != "Regulation") {
        newModel = Object.assign(model, {
          seriesNumber: `${model.series ?? ""} ${model.number ?? ""}`.trim(),
        });
      } else {
        newModel.seriesNumber = null;
      }

      return newModel;
    },
  },
};
</script>
<style lang="scss">
</style>
