<template>
  <ProSpaceGeoLayout class="notification-logs" :routeName="$route.name">
    <template #top>
      <ProSpaceTopPanel :header="this.$t('notificationLogs')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <IconButtonSettingsColumns @click="showModalSettingsColumns"/>
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            :unicKeyStorage="notificationLogService.constructor.name + 'grid'"
            @apply="submitFilter"
            ref="filterPanel"
            :filter-model="['eventName', 'subject']"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
              }
            "
            :blocked-filters="needBlock"
            @block="selectedMultipleBlockFilterHandler"
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceFilterContainsChips
                v-model="filter.recipients"
                :placeholder-value="$t('notificationLogRecipients')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />

              <ProSpaceMultiSelect
                :localization="$t"
                v-model="filter.target"
                :service="targetService"
                getMethod="get"
                optionLabel="text"
                optionValue="value"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :placeholder-value="
                  $t('notificationLogNotificationType')
                "
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :searchPlaceholder="$t('searchTxt')"
                @apply="emitFilter"
              />

              <ProSpaceFilterDatesRange
                v-model="filter.sendAt"
                :placeholder-value="$t('notificationLogSendAt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />

              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="
                  notificationLogService.constructor.name + 'grid'
                "
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            :service="notificationLogService"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            @selected-change="selectedHandler"
            @db-click="toItem"
            :noMountedRequest="true"
            getMethod="grid"
            selectionMode="multiple"
            v-bind="gridPropsSettingsColumns"
          >
            <template #columns>
              <Column
                field="eventName"
                :header="this.$t('notificationLogNotificationName')"
                sortable
                headerClass="move-sort-head"
                headerStyle="min-width: 300px"
                style="min-width: 300px"
                frozen
                class="column-table-header-cell"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :status="getStatus(field.data.status)"
                    :title="field.data.eventName"
                    :description="field.data.subject"
                    @click-on-title="toItem(field.data)"
                  />
                </template>
              </Column>

              <Column
                field="recipients"
                :header="$t('notificationLogRecipients')"
                headerStyle="min-width: 300px"
                style="min-width: 300px"
                sortable
              >
                <template #body="field">
                  <ProSpaceTagsColumn
                    :tags="getFilterRecipients(field.data.recipients)"
                    :limit="2"
                  />
                </template>
              </Column>

              <Column
                field="target"
                :header="$t('notificationLogNotificationType')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ $t(distTypes[field.data.target]) }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="sendAt"
                :header="$t('notificationLogSendAt')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.sendAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>

              <Column
                field="createdAt"
                :header="$t('createDate')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>

              <Column
                field="tryCount"
                :header="$t('notificationLogTryCount')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.tryCount }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="errorCount"
                :header="$t('notificationLogErrorCount')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    <div class="notification-logs__error-count" v-if="field.data.errorCount">
                      {{ field.data.errorCount }}
                    </div>
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="actions"
                style="display: flex; justify-content: flex-end"
                frozen alignFrozen="right"
              >
                <template #body="field">
                  <ProSpaceDots
                    :options="getDotsOptions(field.data)"
                    @action="(action) => handleDots(action, field.data)"
                  ></ProSpaceDots>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
    </template>
    <template #right>
      <NotificationLogPanel
        v-if="hasSelect"
        ref="right-panel"
        :service="notificationLogService"
        :options="dotsOptions"
        :unrepeatableTypes="unrepeatableTypes"
        @clear="selectedMultipleReset"
        @repeat="repeatItems"
        @showHistory="showHistory"
        @errors="showErrors"
      />
      <ProSpaceErrorModal
        ref="errorsModal"
        :dateFormat="userinfo.dateFormat"
        :formattedErrors="formattedErrors"
        :translates="modalTranslates"
        :title="$t('errors')"
        :showDetailsLabel="$t('showErrorDetails')"
        :cancelText="$t('closeTxt')"
      >
      </ProSpaceErrorModal>
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceHistoryModal
    ref="history"
    v-model="showHistoryModal"
    modelName="NotificationLogApiModel"
    @close="closeHistory"
  />
  <ModalBlockFilters />
</template>
<script>
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceModal,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceDateTimeColumn,
  ProSpaceDefaultTextColumn,
  ProSpaceEmptyGrid,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpaceVLayout,
  ProSpaceFilterContainsChips,
  ProSpaceFilterDatesRange,
  ProSpaceDropdown,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceInputSwitch,
  ProSpaceInputText,
  ProSpaceErrorModal,
  ProSpaceTagsColumn,
} from "@prospace/prospace-components-library";
import { NotificationLogService } from "../../services/NotificationLogService.js";
import { TargetService } from "../../services/TargetService.js";
import { LogStatusService } from "../../services/LogStatusService.js";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import Column from "primevue/column";
import NotificationLogPanel from "./components/NotificationLogPanel";
const _ = require("lodash");
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import ModalBlockFilters from "@multiselect/ModalBlockFilters"
import { ref } from "vue"
import IconButtonSettingsColumns from "@components/settingsColumns/IconButtonSettingsColumns.vue";
import useSettingsColumns from "@composes/useSettingsColumns.js";
export default {
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    ProSpaceModal,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceDateTimeColumn,
    ProSpaceDefaultTextColumn,
    ProSpaceEmptyGrid,
    NotificationLogPanel,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpaceVLayout,
    ProSpaceFilterContainsChips,
    ProSpaceFilterDatesRange,
    ProSpaceDropdown,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceInputSwitch,
    ProSpaceInputText,
    ProSpaceErrorModal,
    ProSpaceTagsColumn,
    ProSpaceHistoryModal,
    ModalBlockFilters,
    IconButtonSettingsColumns
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic()
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      selectedData,
      hasSelect,
      selectedMultipleBlockFilterHandler,
      needBlock, selectedMultipleReset,
      getRequestModel
    } = useMultiSelectGrid(grid, filterPanel)
     const {
      showModalSettingsColumns,
      gridPropsSettingsColumns
    } = useSettingsColumns(grid)
    return {
      grid, filterPanel,
      setStatistic, distActions,
      selectedMultipleGridHandler, selectedData,
      hasSelect, selectedMultipleClear,
      selectedMultipleBlockFilterHandler,
      needBlock,
      getRequestModel, selectedMultipleReset,
      showModalSettingsColumns,
      gridPropsSettingsColumns
    }
  },
  data() {
    return {
      showHistoryModal: false,
      recordsRecipients: [],
      formattedErrors: [],

      // Service
      notificationLogService: new NotificationLogService(),
      targetService: new TargetService(),

      //InlinePanel
      masterId: null,
      isEdit: false,
      isCreate: false,
      modalModel: {},

      // Multi filter
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: false,
        field: "disabled",
      },

      distTypes: {
        "SignalR": 'browser',
        "Mail": 'email'
      },
      unrepeatableTypes: ["SignalR"]
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    modalTranslates() {
      const me = this
      return {
        type: me.$t('type'),
        date: me.$t('date'),
        description: me.$t('description'),
      }
    },
    customServices() {
      return [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        },
        {
          service: new LogStatusService(),
          getMethod: "get",
          optionValue: "value",
          optionLabel: "text",
          type: "checkboxes",
          model: "status",
          label: this.$t("notificationLogStatus"),
          selected: {},
        },
        {
          optionValue: "date",
          type: "dates-range-time",
          model: "sendAt",
          label: this.$t("notificationLogSendAt"),
          selected: {},
        },
        {
          optionValue: "value",
          type: "range",
          model: "tryCount",
          label: this.$t("notificationLogTryCount"),
          selected: {},
        },
        {
          optionValue: "value",
          type: "range",
          model: "errorCount",
          label: this.$t("notificationLogErrorCount"),
          selected: {},
        },
      ];
    },
    dotsOptions() {
      let res = [
        {
          name: "repeat",
          text: this.$t("notificationActionRepeat"),
          icon: "rotate-right",
          method: this.repeatItems,
        },
        {
          name: "errors",
          text: this.$t("notiticationActionErrors"),
          icon: "file-lines",
          method: this.showErrors,
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
        },
      ];
      return res;
    },
    fastFilters() {
      return [
        { label: this.$t("allTxt"), field: "disabled", value: false },
        { label: this.$t("notificationStatusCreated"), field: "status", value: 0 },
        { label: this.$t("notificationStatusSending"), field: "status", value: 1 },
        { label: this.$t("notificationStatusSent"), field: "status", value: 2 },
        { label: this.$t("notificationStatusError"), field: "status", value: 3 },
        { label: this.$t("notificationStatusSkipped"), field: "status", value: 4 },
      ];
    },
  },
  methods: {
    closeHistory() {
      this.showHistoryModal = false;
    },
    getFilterRecipients(recipients) {
      if (!recipients) {
        []
      }
      return recipients.filter(i => i.parameters).map(i => i.email)
    },
    showHistory(record) {
      if (!record) return;
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.eventName;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    getDotsOptions(item) {
      let options = this.dotsOptions
      if (!item.errorCount) {
        options = options.filter(i => i.name !== "errors")
      }
      if (this.unrepeatableTypes.includes(item.target)) {
        options = options.filter(i => i.name !== "repeat")
      }
      return options
    },
    async repeatItems(record) {
      const requestModel = this.getRequestModel(record)
      await this.notificationLogService.repeat(requestModel);
      this.actionUpdate();
    },
    submitFilterRecipient({search}) {
      const values = Object.values(search)
      const searchStr = values.length > 0 ? values[0].contains : ""
      const filteredRecipients = this.recordsRecipients.filter(i => i.email.includes(searchStr) || i.login.includes(searchStr))
      this.$refs.recipientsGrid.setRecords(filteredRecipients)
    },
    showErrors(item) {
      if (!item) return;
      this.formattedErrors = [{
        code: item.errorCode,
        type: 'error',
        sourceId: item.id,
        message: item.errorMessage,
        parameters: [
          { label: this.$t('notificationLogTryCount'), value: item.tryCount },
          { label: this.$t('notificationLogErrorCount'), value: item.errorCount }
        ]
      }]
      this.$refs.errorsModal.show();
    },
    toItem(data) {
      const url = `/notifications/notificationlog/${data.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    actionUpdate() {
      this.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    loadingHandler(val) {
      this.grid.loading = val;
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    changeItem() {
      let method;
      if (this.isCreate) {
        method = "create";
      }
      if (this.isEdit) {
        method = "update";
      }
      this.notificationLogService[method](this.modalModel).then(() => {
        this.actionUpdate();
      });
    },
    deleteItem(model) {
      if (!model) return;
      this.notificationLogService.delete(model).then(() => {
        this.actionUpdate();
        this.selectedMultipleClear();
      });
    },
    getStatus(status) {
      if (!status) return;
      const dist = {
        "Created": "blue",
        "Sending": "orange",
        "Error": "danger",
        "Sent": "success",
        "Skipped": "info",
      }
      const type = dist[status] || "default"
      return {
        text: this.$t(`notificationStatus${status}`),
        type,
      };
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
  },
};
</script>
<style lang="scss">
.notification-logs {
  &__error-count {
    display: inline-flex;
    align-items: center;
    background-color: var(--prospace-block-red-secondary);
    color: var(--prospace-ui-red);
    padding: 1px 6px 1px 6px;
    border-radius: 3px;
    justify-content: center;
    height: 18px;
  }
}
</style>
