import { ref, computed, watch, onMounted } from 'vue';

const useSelectElementType = (selectedNode, getComponentTypeOptions, model) => {

  const defaultModel = ref(null);
  const selectedContainer = ref(null);
  let oldModel = ref(null);

  const isEmptyModel = computed(() => {
    if (defaultModel.value) {
      const actualModel = _.cloneDeep(model.value);
      const filteredActualModelKeys = Object.keys(actualModel).filter(m => {
        return Object.keys(oldModel.value).includes(m);
      });
      const filteredActualModel = {};
      for (const actualModelKey in actualModel) {
        if (filteredActualModelKeys.includes(actualModelKey)) {
          filteredActualModel[actualModelKey] = actualModel[actualModelKey];
        }
      }
      delete oldModel.value.element;
      delete oldModel.value.parent;
      delete oldModel.value.blockName;
      delete filteredActualModel.element;
      delete filteredActualModel.parent;
      delete filteredActualModel.blockName;

      return JSON.stringify(oldModel.value) === JSON.stringify(filteredActualModel);
    } else {
      return false;
    }
  });

  const isEmptyContainer = computed(() => {
    return selectedNode?.element === 'container' &&
      selectedNode.children?.length === 0;
  })

  const setDefaultSelectedContainer = (type) => {
    selectedContainer.value = getComponentTypeOptions
      .find(el => {
        if (!type || !type.value) {
          return  el.value === selectedNode.element;
        } else {
          if (typeof type.value === 'string') {
            return el.value === type.value
          } else {
            return el.value === type.value.value;
          }
        }
      });
  }

  watch(selectedNode, (val)  => {
    setDefaultSelectedContainer(val);
  })

  onMounted(() => {
    defaultModel.value = selectedNode;
    oldModel.value = _.cloneDeep(defaultModel.value);
    setDefaultSelectedContainer();
  });
  return {
    isEmptyModel,
    selectedContainer,
    defaultModel,
    isEmptyContainer,
    setDefaultSelectedContainer
  }
}

export default useSelectElementType;
