<template>
  <div class="prospace-panel-block-list__row">
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
      :title="$t('source')"
    >
      <template #content>
        <ProSpaceDropdown
          ref="filterPanelSourceDropdown"
          v-model="model.source"
          :options="filterPanelSourceDropdownOptions"
          :placeholder="$t('source')"
          :invalid="validation.source.$error"
          :invalid-messages="validation.source.$errors"
          propValue="systemName"
          propLabel="name"
          @change="handleFilterPanelSourceDropdownChange"
          @blur="validation.source.$touch"
        />
      </template>
    </ProSpacePanelBlockItem>
  </div>
  <DashboardLayoutElementSettings
    isColorTextOnly
    :elementName="$t('title')"
    :elementType="model.header.type"
    :elementValue="model.header.value"
    :color="model.header.color"
    :createDefaultLayoutColorSettings="createDefaultLayoutColorSettings"
    :valueOptions="filterPanelValuesOptions"
    :validation="validation.header"
    @update:elementType="(elementType) => updateElementProp('header', 'type', elementType)"
    @update:elementValue="(elementValue) => updateElementProp('header', 'value', elementValue)"
    @update:color="(color) => updateElementProp('header', 'color', color)"
  />
  <DashboardLayoutElementSettings
    isColorTextOnly
    :elementName="$t('label')"
    :elementType="model.label.type"
    :elementValue="model.label.value"
    :color="model.label.color"
    :createDefaultLayoutColorSettings="createDefaultLayoutColorSettings"
    :valueOptions="filterPanelValuesOptions"
    :validation="validation.label"
    @update:elementType="(elementType) => updateElementProp('label', 'type', elementType)"
    @update:elementValue="(elementValue) => updateElementProp('label', 'value', elementValue)"
    @update:color="(color) => updateElementProp('label', 'color', color)"
  />
  <DashboardLayoutElementSettings
    :elementName="$t('value')"
    :elementType="model.value.type"
    :elementValue="model.value.value"
    :color="model.value.color"
    :createDefaultLayoutColorSettings="createDefaultLayoutColorSettings"
    :valueOptions="filterPanelValuesOptions"
    :validation="validation.value"
    @update:elementType="(elementType) => updateElementProp('value', 'type', elementType)"
    @update:elementValue="(elementValue) => updateElementProp('value', 'value', elementValue)"
    @update:color="(color) => updateElementProp('value', 'color', color)"
  />
  <div class="prospace-panel-block-list__row">
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
    >
      <template #header>
        <ProSpaceHLayout :gap="5">
          <div>
            {{ $t('filter') }}
          </div>
          <ProSpaceIcon
            v-hint="filterPanelFilterHintText"
            icon="question"
          />
        </ProSpaceHLayout>
      </template>
      <template #content>
        <ProSpaceInputChips
          v-model="model.filter"
          showHintItem
        />
      </template>
    </ProSpacePanelBlockItem>
  </div>
</template>

<script>
import {
  ProSpacePanelBlockItem,
  ProSpaceHLayout,
  ProSpaceIcon,
  ProSpaceInputChips,
  ProSpaceDropdown
}
from "@prospace/prospace-components-library";
import DashboardLayoutElementSettings from "./DashboardLayoutElementSettings.vue";
export default {
  name: "DashboardLayoutSettingsGridFilterPanel",
  components: {
    DashboardLayoutElementSettings,
    ProSpacePanelBlockItem,
    ProSpaceHLayout,
    ProSpaceIcon,
    ProSpaceInputChips,
    ProSpaceDropdown
  },
  emits: ['combinedEvents', 'update:selectedNode'],
  props: {
    selectedNode: {
      type: Object,
      default: () => {}
    },
    validation: {
      type: Object,
      required: true
    },
    filterPanelSourceDropdownOptions: {
      type: Array,
      default: () => []
    },
    filterPanelValuesOptions: {
      type: Array,
      default: () => []
    },
    createDefaultLayoutColorSettings: {
      type: Function,
      required: true
    },
    filterPanelFilterHintText: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      model: {
        source: '',
        header: {
          type: '' ,
          value: '',
          color: ''
        },
        label: {
          type: '' ,
          value: '',
          color: ''
        },
        value: {
          type: '' ,
          value: '',
          color: ''
        },
        filter: ''
      }
    }
  },
  watch: {
    selectedNode: {
      handler: function(val) {
        this.model = val;
      },
      immediate: true,
      deep: true
    },
    model: {
      handler: function(val, oldVal) {
        this.updateModel(val);
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    updateModel(value) {
      this.$emit(`update:selectedNode`, value);
    },
    updateElementProp(element, prop, value) {
      this.model[element][prop] = value;
    },
    handleFilterPanelSourceDropdownChange(item) {
      this.$emit('combinedEvents', 'update:source', item)
    }
  }
}
</script>

<style scoped>

</style>
