<template>
  <ProSpaceHLayout
    v-if="layout === 'horizontal'"
    :gap="10"
    isInlineH
    :style="style"
    class="dashboard-block"
    :class="[bg, { 'dashboard-block--without-padding': hasPivot }]"
  >
    <Component
      ref="comp"
      v-for="(item, index) in children.filter((i) => i)"
      :key="index"
      :is="item.component"
      :style="
        item.component.name === 'Chart' ? 'width: 100%; height: 100%;' : ''
      "
      v-bind="item?.props"
      :bg="
        bg === 'dashboard-bg-white' ? 'dashboard-bg-gray' : 'dashboard-bg-white'
      "
    />
  </ProSpaceHLayout>
  <ProSpaceVLayout
    v-else
    :gap="10"
    :style="style"
    isInlineH
    class="dashboard-block"
    :class="[bg, { 'dashboard-block--without-padding': hasPivot }]"
  >
    <Component
      ref="comp"
      v-for="(item, index) in children.filter((i) => i)"
      :key="index"
      :is="item.component"
      :style="
        item.component.name === 'Chart' ? 'width: 100%; height: 100%;' : ''
      "
      v-bind="item?.props"
      :bg="
        bg === 'dashboard-bg-white' ? 'dashboard-bg-gray' : 'dashboard-bg-white'
      "
      @ref-sent="($event) => handleRef(item.component.name, $event)"
    />
  </ProSpaceVLayout>
</template>

<script>
import {
  ProSpaceVLayout,
  ProSpaceHLayout,
} from "@prospace/prospace-components-library";
export default {
  name: "DashboardContainer",
  components: {
    ProSpaceHLayout,
    ProSpaceVLayout,
  },
  props: {
    layout: {
      type: String,
      required: true,
    },
    layoutParent: {
      type: String,
      required: true,
    },
    with: {
      type: Number,
      required: true,
    },
    bg: {
      type: String,
      required: true,
    },
    children: {
      type: Array,
      default: [],
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      let style = {};
      if (this.isRoot) {
        style = {
          width: "100%",
          height: "100%",
          padding: 0,
          "background-color": "var(--prospace-dashboard-ui-bg)",
        };
      } else {
        if (this.layoutParent && this.layoutParent === "horizontal") {
          style = {
            width: this.with + "%",
            height: "100%",
            overflow: 'hidden',
            "justify-content": this.children.length === 1
              && this.children.some(el => el.component.name === "DashboardChart" ||
              el.component.name === "DashboardPivot" ||
              el.component.name === "DashboardTable")
                ? "flex-start"
                : "center",
          };
        } else {
          style = {
            width: "100%",
            height: this.with + "px",
            "min-height": this.with + "px",
            "justify-content": this.children.length === 1
              && this.children.some(el => el.component.name === "DashboardChart" ||
              el.component.name === "DashboardPivot" ||
              el.component.name === "DashboardTable")
                ? "flex-start"
                : "center",
          };
        }
      }
      return style;
    },
    hasPivot() {
      return this.children.some(i => i.component.name === "DashboardPivot" || i.component.name === "DashboardTable");
    },
  },
  methods: {
    handleRef(component, ref) {
      this.$emit("ref-sent", { ref: ref, component: component });
    },
  },
  mounted() {
    if (
      this.$refs.comp?.find((c) => c.$options.name === "DashboardPivot") ===
      undefined
    ) {
      this.handleRef(null, null);
    }
  },
};
</script>
