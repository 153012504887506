<template>
  <ProSpaceGeoLayout :routeName="$route.name">
    <template #top>
      <ProSpaceTopPanel :header="this.$t('statisticsReports')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <IconButtonSettingsColumns @click="showModalSettingsColumns"/>
          <ProSpaceDots
            :options="dotsHeaderOptions"
            :isSquare="true"
            @action="(action) => handleHeaderDots(action)"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            ref="filter-panel"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :unicKeyStorage="statisticsReportService.constructor.name + 'grid'"
            :filter-model="['userFullName', 'userLogin', 'userEmail']"
            @apply="submitFilter"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
                selectedMultipleClear()
              }
            "
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceFilterContainsChips
                v-model="filter.userFullName"
                :placeholder-value="$t('statisticsReportUser')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterContainsChips
                v-model="filter.userDistributor"
                :placeholder-value="$t('statisticsReportUserDistributor')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterContainsChips
                v-model="filter.userRole"
                :placeholder-value="$t('statisticsReportUserRole')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterDateTimeRange
                v-model="filter.requestDate"
                :placeholder-value="$t('statisticsReportDate')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterContainsChips
                v-model="filter.entity"
                :placeholder-value="$t('statisticsReportEntity')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="statisticsReportService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            :service="statisticsReportService"
            defaultSortField="requestDate"
            :defaultSortOrder="-1"
            @selected-change="selectedHandler"
            :noMountedRequest="true"
            getMethod="grid"
            defaultColumnWidths="200"
            v-bind="gridPropsSettingsColumns"
          >
            <template #columns>
              <Column
                field="userFullName"
                :header="this.$t('statisticsReportUser')"
                sortable
                headerClass="move-sort-head"
                frozen
                class="column-table-header-cell"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :title="field.data.userFullName"
                    :description="$t(field.data.userRole || '')"
                    noHover
                  />
                </template>
              </Column>

              <Column field="userLogin" :header="$t('statisticsReportUserLogin')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.userLogin">
                    {{ field.data.userLogin }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column field="userEmail" :header="$t('statisticsReportUserEmail')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.userEmail">
                    {{ field.data.userEmail }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column field="userType" :header="$t('statisticsReportUserType')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="$t(field.data.userType || 'Unknow type')">
                    {{ $t(field.data.userType || '') }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="userPosition"
                :header="$t('statisticsReportUserPosition')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.userPosition">
                    {{ field.data.userPosition }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="userDistributor"
                :header="$t('statisticsReportUserDistributor')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.userDistributor">
                    {{ field.data.userDistributor }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="requestDate"
                :header="$t('statisticsReportDate')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="getFormatDateTime(field.data.requestDate)">
                    {{ getFormatDateTime(field.data.requestDate) }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="requestType"
                :header="$t('statisticsReportRequestType')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.requestType">
                    {{ field.data.requestType }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="requestUrl"
                :header="$t('statisticsReportRequestUrl')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.requestUrl">
                    {{ field.data.requestUrl }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column field="module" :header="$t('statisticsReportModule')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.module">
                    {{ field.data.module }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column field="entity" :header="$t('statisticsReportEntity')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.entity">
                    {{ field.data.entity }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column field="entityId" :header="$t('statisticsReportEntityId')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.entityId">
                    {{ field.data.entityId }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="operation"
                :header="$t('statisticsReportOperation')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.operation">
                    {{ field.data.operation }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
    </template>
    <template #right>
      <StatisticsReportPanel
        v-if="hasSelect"
        ref="right-panel"
        :service="statisticsReportService"
        @clear="selectedMultipleClear"
      />
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceDefaultTextColumn,
  ProSpaceEmptyGrid,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpaceVLayout,
  ProSpaceFilterContainsChips,
  ProSpaceFilterDateTimeRange,
  ProSpaceDropdown,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceInputSwitch,
  ProSpaceInputText,
  ProSpaceDots,
} from "@prospace/prospace-components-library";
import { StatisticsReportService } from "../../services/StatisticsReportService.js";
import { DistributorService } from "@masterdata/services/DistributorService.js";
import { UserService } from "../../../../SecurityAdmin/ProSpace.SecurityAdmin.Frontend/services/UserService.js";
import Column from "primevue/column";
import StatisticsReportPanel from "./components/StatisticsReportPanel";
const _ = require("lodash");
import { mapGetters } from "vuex";
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue";
import IconButtonSettingsColumns from "@components/settingsColumns/IconButtonSettingsColumns.vue";
import useSettingsColumns from "@composes/useSettingsColumns.js";
export default {
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceDefaultTextColumn,
    ProSpaceEmptyGrid,
    StatisticsReportPanel,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpaceVLayout,
    ProSpaceFilterContainsChips,
    ProSpaceFilterDateTimeRange,
    ProSpaceDropdown,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceInputSwitch,
    ProSpaceInputText,
    ProSpaceDots,
    IconButtonSettingsColumns
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null);
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel);
    const {
      showModalSettingsColumns,
      gridPropsSettingsColumns
    } = useSettingsColumns(grid);
    return {
      grid, filterPanel,
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
      showModalSettingsColumns,
      gridPropsSettingsColumns
    }
  },
  data() {
    return {
      // Service
      statisticsReportService: new StatisticsReportService(),
      distributorService: new DistributorService(),
      userService: new UserService(),
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime"
    }),
    dotsHeaderOptions() {
      return [
        { name: "export_csv", text: this.$t("export_csv"), icon: "upload" },
        { name: "export_xlsx", text: this.$t("export_xlsx"), icon: "upload" },
      ];
    },
    customServices() {
      return [
        {
          type: "chips",
          model: "userLogin",
          label: this.$t("statisticsReportUserLogin"),
          selected: {},
        },
        {
          type: "chips",
          model: "userEmail",
          label: this.$t("statisticsReportUserEmail"),
          selected: {},
        },
        {
          type: "chips",
          model: "userType",
          label: this.$t("statisticsReportUserType"),
          selected: {},
        },
        {
          type: "number",
          model: "userId",
          label: this.$t("statisticsReportUserId"),
          selected: {},
        },
        {
          type: "chips",
          model: "requestType",
          label: this.$t("statisticsReportRequestType"),
          selected: {},
        },
        {
          type: "chips",
          model: "requestUrl",
          label: this.$t("statisticsReportRequestUrl"),
          selected: {},
        },
        {
          type: "chips",
          model: "module",
          label: this.$t("statisticsReportModule"),
          selected: {},
        },
        {
          type: "number",
          model: "entityId",
          label: this.$t("statisticsReportEntityId"),
          selected: {},
        },
        {
          type: "chips",
          model: "operation",
          label: this.$t("statisticsReportOperation"),
          selected: {},
        },
      ];
    },
  },
  methods: {
    handleHeaderDots(action) {
      if (action === "export_csv") {
        this.actionExport("CSV");
      } else if (action === "export_xlsx") {
        this.actionExport("XLSX");
      }
    },
    async actionExport(format) {
      this.loadingHandler(true);
      await this.statisticsReportService.export(
        this.grid.getRecords(false, true),
        format
      );
      this.loadingHandler(false);
    },
    closeHistory() {
      this.showHistoryModal = false;
    },
    submitFilter({ filter, search }) {
      const getAddingFilterAndDeleteIndexes = (filterArray, dist) => {
        const addingFilter = []
        const deleteIndexes = []
        filterArray.forEach((i, index) => {
          const value = Object.entries(dist)
            .filter(([key, value]) => key.includes(i.contains.toLowerCase()))
            .map(([key, value]) => ({contains: value}))
          if (value.length > 0) {
            deleteIndexes.push(index)
          }
          addingFilter.push(...value)
        })
        return {addingFilter, deleteIndexes}
      }
      if (filter.userRole) {
        const distRoles = {
          "администратор": "Support administrator",
          "марс менеджер": "Mars manager",
          "марс пользователь": "Mars user",
          "дистрибьютор менеджер": "Distributor manager",
          "дистрибьютор пользователь": "Distributor user",
          "контент менеджер плюс": "Content manager plus",
          "вендор администратор": "Technical Support",
          "контент менеджер": "Content manager"
        }
        const {addingFilter, deleteIndexes} = getAddingFilterAndDeleteIndexes(filter.userRole.or, distRoles)
        filter.userRole.or = filter.userRole.or.filter((i, index) => !deleteIndexes.includes(index))
        filter.userRole.or.push(...addingFilter)
      }
      if (filter.userType) {
        const distTypes = {
          "марс": "Mars",
          "дистрибьютор": "Distributor"
        }
        const {addingFilter, deleteIndexes} = getAddingFilterAndDeleteIndexes(filter.userType.or, distTypes)
        filter.userType.or = filter.userType.or.filter((i, index) => !deleteIndexes.includes(index))
        filter.userType.or.push(...addingFilter)
      }
      this.grid.submitFilter(filter, search);
    },
    emitFilter() {
      this.$refs["filter-panel"].submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.$refs["filter-panel"].submitFilter();
    },
    actionUpdate() {
      this.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    loadingHandler(val) {
      this.grid.loading = val;
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
  },
};
</script>
<style lang="scss"></style>
