<script>
import { h, ref, computed, withDirectives } from 'vue';
import {
  ProSpaceGeoLayout,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceAction,
  ProSpaceIcon,
  ProSpaceDots,
  Hint,
} from "@prospace/prospace-components-library";
import ProSpaceGeoLayoutOld from "../GeoLayoutOld.vue";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DashboardLayoutSettingsGridElement from "./DashboardLayoutSettingsGridElement.functional.vue";
import i18n from '@common/utils/i18n.js';

export default {
  name: "DashboardLayoutSettingsGridElement",
  functional: true,
  directives: { Hint },
  emits: [
    'update:activeContainer',
    'update:hoverKey',
    'clear:hoverKey',
    'add:element',
    'delete:element',
    'copy:element',
    'paste:element',
    'edit:element',
    'moveUp:element',
    'moveDown:element',
    'moveLeft:element',
    'moveRight:element',
    'click'
  ],
  props: {
    element: {
      type: Object,
      default: null
    },
    filter: {
      type: Object,
      default: null
    },
    validation: {
      type: Object,
      default: null
    },
    validationFilter: {
      type: Object,
      default: null
    },
    activeContainer: {
      type: String,
      default: 'key_0:1'
    },
    hoverKey: {
      type: String,
      default: null
    },
    isPaste: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: null
    },
    totalChildren: {
      type: Number,
      default: null
    },
    dotOptions: {
      type: Array,
      default: () => []
    },
    invalidKeys: {
      type: Array,
      default: () => []
    },
  },
  setup(props, { emit, slots, attrs }) {
    const $t = i18n.global.t;
    const labelRef = ref(null);

    const actionButtons = computed(() => {
      const options = [
        { name: 'add', icon: 'plus', hint: $t('add') },
        { name: 'edit', icon: 'pen', hint: $t('editItemTxt') },
      ];

      if (props.element.systemName === 'mainContainer' || props.element.systemName === 'key_0:1') {
        return options.filter(o => o.name === 'add' || o.name === 'edit');
      } else if (props.element.element !== 'container') {
        return options.filter(o => o.name !== 'add');
      } else {
        return options;
      }
    });

    const dotsOptions = computed(() => {
      const options = props.dotOptions;

      const isMainContainer = props.element.systemName === 'mainContainer' || props.element.systemName === 'key_0:1';
      const isDisabledDecrease = props.index === 0;
      const isDisabledIncrease = props.index + 1 === props.totalChildren;
      const isContainer = props.element.element === 'container';
      const isElement = !isMainContainer && !isContainer;
      const typeContainer = props.element.parent && !isMainContainer ? props.element.parent.type : props.element.type;
      const canPaste = props.isPaste && !isElement;

      let availableOptions = [];

      if (!isMainContainer) {
        availableOptions.push(options.find(o => o.name === "copy"));
      }

      if (canPaste) {
        availableOptions.push(options.find(o => o.name === "paste"));
      }

      if (typeContainer === 'horizontal' && !isMainContainer) {
        availableOptions.push(options.find(o => o.name === "moveRight"));
        availableOptions.push(options.find(o => o.name === "moveLeft"));
        if (isDisabledDecrease) {
          availableOptions = availableOptions.filter(o => o.name !== "moveLeft");
        }
        if (isDisabledIncrease) {
          availableOptions = availableOptions.filter(o => o.name !== "moveRight");
        }
      } else if (typeContainer === 'vertical' && !isMainContainer) {
        availableOptions.push(options.find(o => o.name === "moveUp"));
        availableOptions.push(options.find(o => o.name === "moveDown"));
        if (isDisabledDecrease) {
          availableOptions = availableOptions.filter(o => o.name !== "moveUp");
        }
        if (isDisabledIncrease) {
          availableOptions = availableOptions.filter(o => o.name !== "moveDown");
        }
      }

      if (!isMainContainer) {
        availableOptions.push(options.find(o => o.name === "delete"));
      }

      return availableOptions.filter(Boolean);
    });

    const icons = { faExclamationTriangle };

    const getElementName = (element) => {
      return element && (element.element !== 'container' && element.systemName !== 'mainContainer' && element.systemName !== 'key_0:1')
        ? element.blockName ?? element.name
        : element.children?.length === 0 ? element.blockName ?? `${$t(element.type)} ${$t('container')}` : '';
    };

    const handleHeaderDots = (action, payload) => {
      const firstKey = payload.firstKey || payload.key;
      if (props.activeContainer !== payload.systemName) {
        setActiveContainer(payload.systemName);
        emit(`${action}Element`, { ...payload, firstKey });
      } else {
        emit(`${action}Element`, { ...payload, firstKey });
      }
    };

    const setActiveContainer = (key) => {
      emit('updateActiveContainer', key);
    };

    const setActiveHoverContainer = (key) => {
      emit('updateActiveHoverContainer', key);
    };

    const handleClearActiveHoverContainer = () => {
      emit('clear:hoverKey');
    };

    return () => {
      const renderElement = () => {
        if (props.element) {
          const elementClass = [
            'dashboard-layout-settings-grid-element',
            { 'dashboard-layout-settings-grid-element--container': props.element.systemName === 'mainContainer' || props.element.systemName === 'key_0:1' || props.element.element === 'container' },
            { 'dashboard-layout-settings-grid-element--active': props.element.systemName === props.activeContainer },
            { 'dashboard-layout-settings-grid-element--hover': props.element.systemName === props.hoverKey },
            { 'dashboard-layout-settings-grid-element--error': props.element.needWarning },
            { 'dashboard-layout-settings-grid-element--root': props.element.level === 0 },
            `dashboard-layout-settings-grid-element--${props.element.type}`,
            `dashboard-layout-settings-grid-element--${props.element.level % 2 === 1 ? 'odd' : 'even'}`
          ];

          const elementChildren = [];

          // Label
          const labelText = (props.element.systemName === 'mainContainer' || props.element.systemName === 'key_0:1') && props.element?.children && props.element.children.length === 0
            ?  $t('mainContainer')
            :  getElementName(props.element)

          let label =  h('div', {
            class: 'dashboard-layout-settings-grid-element__label element-container-label',
            ref: labelRef
          }, labelText)

          const hintValue =  getElementName(props.element);
          const labelWithDirective = withDirectives(label, [
            [Hint, hintValue]
          ]);

          if (props.element?.children && props.element.children.length > 0) {
            label = null;
          }

          elementChildren.push(labelWithDirective);

          // Recursive rendering
          if (props.element && props.element.children?.length > 0) {
            props.element.children.forEach((child, index) => {

              const handleEmitter = (event, hover) => {
                if (!hover) {
                  emit('updateActiveContainer', event)
                } else {
                  emit('updateActiveHoverContainer', event);
                }
              }

              elementChildren.push(
                h(DashboardLayoutSettingsGridElement, {
                  key: child.systemName,
                  index: index,
                  totalChildren: props.element.children?.length,
                  element: child,
                  activeContainer: props.activeContainer,
                  validation: props.validation,
                  hoverKey: props.hoverKey,
                  isPaste: props.isPaste,
                  dotOptions: props.dotOptions,
                  invalidKeys: props.invalidKeys,
                  onUpdateActiveContainer: handleEmitter,
                  onUpdateActiveHoverContainer: (event) => handleEmitter(event, true),
                  onClearHoverKey: handleClearActiveHoverContainer,
                  onAddElement: (event) => handleHeaderDots('add', event),
                  onEditElement: (event) => handleHeaderDots('edit', event),
                  onCopyElement: (event) => handleHeaderDots('copy', event),
                  onPasteElement: (event) => handleHeaderDots('paste', event),
                  onDeleteElement: (event) => handleHeaderDots('delete', event),
                  onMoveUpElement: (event) => handleHeaderDots('moveUp', event),
                  onMoveDownElement: (event) => handleHeaderDots('moveDown', event),
                  onMoveRightElement: (event) => handleHeaderDots('moveRight', event),
                  onMoveLeftElement: (event) => handleHeaderDots('moveLeft', event),
                })
              );
            });
          }

          // Error indicator
          if (props.element.needWarning) {
            elementChildren.push(
              h('div', { class: 'dashboard-layout-settings-grid-element__error' }, [
                h(FontAwesomeIcon, { icon: icons.faExclamationTriangle, style: { color: 'var(--prospace-ui-red)' }, size: "1x" })
              ])
            );
          }

          // Actions
          if (props.element.systemName === props.activeContainer || props.element.systemName === props.hoverKey) {
            const actionButtonsVNodes = actionButtons.value?.map(btn => {
              return h(ProSpaceIconButton, {
                key: btn.name,
                type: 'primary',
                icon: btn.icon,
                hint: btn.hint,
                iconWidth: btn.name === 'edit' ? '14px' : '18px',
                iconHeight: btn.name === 'edit' ? '14px' : '18px',
                containerSize: '20px',
                onClick: () => handleHeaderDots(btn.name, props.element)
              });
            });

            elementChildren.push(
              h('div', { class: 'dashboard-layout-settings-grid-element__actions' }, [
                h(ProSpaceHLayout, { gap: 5 }, [
                  ...actionButtonsVNodes,
                  h(ProSpaceDots, {
                    ref: 'dots',
                    class: 'prospace-icon-button--primary',
                    options: dotsOptions.value,
                    onAction: (action) => handleHeaderDots(action, props.element)
                  })
                ])
              ])
            );
          }

          const clickHandler = (key) => (event) => {
            event.stopPropagation();
            setActiveContainer(key);
          };

          const mouseoverHandler = (key) => (event) => {
            event.stopPropagation();
            setActiveHoverContainer(key);
          };

          return h('div', {
            class: elementClass,
            'data-key': props.element.systemName,
            onClick: clickHandler(props.element.systemName),
            onMouseover: mouseoverHandler(props.element.systemName),
            onMouseleave: handleClearActiveHoverContainer,
          }, elementChildren);
        }
        return null;
      };

      //Render Filter
      const renderFilter = () => {
        if (props.filter) {
          const filterClass = [
            'dashboard-layout-settings-grid-element',
            'dashboard-layout-settings-grid-element--top',
            { 'dashboard-layout-settings-grid-element--active': props.activeContainer === 'filterPanel' },
            { 'dashboard-layout-settings-grid-element--hover': props.hoverKey === 'filterPanel' },
            { 'dashboard-layout-settings-grid-element--hover': props.filter.systemName === props.hoverKey },
            { 'dashboard-layout-settings-grid-element--error': props.filter.needWarning },
          ];

          return h('div', {
            class: filterClass,
            dataKey: 'filterPanel',
            onClick: () => setActiveContainer('filterPanel'),
            onMouseover: () => setActiveHoverContainer('filterPanel'),
            onMouseleave: handleClearActiveHoverContainer,
          }, [
            h('div', { class: 'dashboard-layout-settings-grid-element__label' }, $t('filterPanel')),
            props.activeContainer === 'filterPanel' || props.hoverKey === 'filterPanel'
              ? h('div', { class: 'dashboard-layout-settings-grid-element__actions' }, [
                h(ProSpaceHLayout, { gap: 5 }, [
                  h(ProSpaceIconButton, {
                    type: 'primary',
                    icon: 'edit',
                    hint: $t('editItemTxt'),
                    iconWidth: '18px',
                    iconHeight: '18px',
                    containerSize: '20px',
                    onClick: () => handleHeaderDots('edit', props.filter)
                  })
                ])
              ])
              : null,
            props.filter.needWarning
              ? h('div', { class: 'dashboard-layout-settings-grid-element__error' }, [
                h(FontAwesomeIcon, { icon: icons.faExclamationTriangle, style: { color: 'var(--prospace-ui-red)' }, size: "1x" })
              ])
              : null
          ]);
        }
        return null;
      };

      return [renderFilter(), renderElement()];
    };
  }
};

</script>

<style lang="scss">
.dashboard-layout-settings-grid-element {
  min-height: 55px;
  container-type: inline-size;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 1em 1em 1em 1em;
  border-radius: 5px;
  border: 1px dashed var(--prospace-ui-border-color);
  background-color: var(--prospace-dashboard-ui-card-white);
  cursor: pointer;
  justify-content: center;

  &--container {
    padding-top: 2.5em;
  }

  &__label {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 1.2em;
    font-weight: 400;
    color: var(--prospace-text-gray);
    &:not(:empty) {
      display: block;
      width: fit-content;
      margin: 0 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &--even {
    background-color: var(--prospace-dashboard-ui-card-white);
  }

  &--odd {
    background-color: var(--prospace-ui-bg-layout);
  }

  &__actions {
    position: absolute;
    top: 3px;
    right: 3px;

    .prospace-dots-wrapper {
      border-radius: 5px;
      .prospace-icon-button {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__wrapper {
    height: auto;
    width: 100%;
    padding: 1em;
    display: flex;
    flex-flow: column nowrap;
    border: 1px solid var(--prospace-ui-border-color);
    border-radius: 5px;
    background-color: var(--prospace-ui-gray-card-bg);
    font-size: 10px;
  }

  &__error {
    position: absolute;
    left: 3px;
    top: 3px;
    display: none;
  }

  &--top {
    height: 40px;
    margin-bottom: 10px;
  }

  &--vertical {
    flex-direction: column;
  }

  &--vertical > & {
    flex: 1 1 auto;
    overflow: hidden;
  }

  &--vertical > & + & {
    margin-top: 1em;
  }

  &--horizontal {
    flex-direction: row;
  }

  &--horizontal > & {
    flex: 1 1 auto;
    overflow: hidden;
  }

  &--horizontal > & + & {
    margin-left: 1em;
  }

  &--error {
    border-color: var(--prospace-ui-red);

    .dashboard-layout-settings-grid-element__error {
      display: block;
    }
  }

  &--active {
    border-color: var(--prospace-ui-main);
  }

  &--hover {
    border-color: var(--prospace-ui-selected);
  }

  &--active > &__label {
    color: var(--prospace-ui-main);
  }

@container (max-width: 140px) {
  .dashboard-layout-settings-grid-element__label {
    font-size: 1em;
  }
}
}
</style>
