<template>
  <ProSpaceVLayout
    class="login-layout"
    align-items="center"
    justify-content="space-between"
  >
    <svg-icon class="login-logo"
      name="auth-logo"
      :width="551"
      :height="223"
    />
    <div class="login-layout__content">
      <slot />
    </div>
    <div
      class="login-layout__footer"
      :class="{ 'login-layout__footer--show': showFooter }"
    >
      We were unable to authorise your account.<br />
      Try to <ProSpaceAction href="#" text="logout" @click="onLogout" /> of all
      accounts and log in again
    </div>
  </ProSpaceVLayout>
  <ProSpaceFlashMessage v-if="error"
    v-model="showMessageError"
    type="error"
    icon="flash-error"
    :title="`Error ${error.code}`"
    :applyTxt="$t('support')"
    :cancelTxt="$t('closeTxt')"
    @apply="onClickSupport"
    @cancel="onCloseError"
  >
    {{ error.message }}
  </ProSpaceFlashMessage>
  <ProSpaceModal
    v-model="showMessageSupport"
    is-custom
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('applyTxt')"
  >
    <ProSpaceVLayout class="message-support">
      <div class="text-color-gray text-size-13">
        <strong>Скопируйте данное сообщение и отправьте в Портал Поддержки.</strong><br />
        Если вы не знаете ссылку Портала Поддержки, обратитесь за информацией к вашему Сотруднику Марс ответственному за Дистрибьютора.
      </div>
      <ProSpaceVLayout :gap="10" class="margin-top-30">
        <ProSpaceHLayout :gap="20" v-for="(item, i) in getGrid()" :key="i">
          <div class="text-color-gray text-bold text-size-13" style="flex: 1">
            {{ item.title }}
          </div>
          <div
            class="text-color-gray text-ellipsis text-size-13"
            style="flex: 3"
            :title="item.label instanceof String? item.label: JSON.stringify(item.label)"
          >
            {{ item.label }}
          </div>
        </ProSpaceHLayout>
      </ProSpaceVLayout>
      <ProSpaceHLayout class="margin-top-50">
        <ProSpaceButton
          type="tertiary"
          @click="onCloseSupport"
          :label="$t('closeTxt')"
        />
        <ProSpaceHLayout :gap="5" justify-content="end">
          <ProSpaceButton
            type="secondary"
            icon="copy"
            @click="onClickCopy"
            :label="$t('copyAction')"
          />
        </ProSpaceHLayout>
      </ProSpaceHLayout>
      <ProSpaceIconButton
        icon="close"
        class="close-btn"
        @click="onCloseSupport"
      />
    </ProSpaceVLayout>
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceVLayout,
  ProSpaceAction,
  ProSpaceHLayout,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceModal,
  ProSpaceMessage,
  ProSpaceFlashMessage
} from "@prospace/prospace-components-library";
import UserService from "../../../services/UserService";
import {
  removeToken,
  removeRefreshToken,
  removeRedirectUrl,
} from "../../../../../../Frontends/Common/utils/auth";
import moment from "moment";
export default {
  components: {
    ProSpaceVLayout,
    ProSpaceAction,
    ProSpaceHLayout,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceModal,
    ProSpaceMessage,
    ProSpaceFlashMessage
  },
  props: {
    showFooter: {
      type: Boolean,
      default: false,
    },
    modelMessageError: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      userService: new UserService(),
      showMessageSupport: false,
      moment,
      supportEmail: "support@prospace.tech",
    };
  },
  methods: {
    onLogout() {
      this.userService.logout().then((res) => {
        removeToken();
        removeRefreshToken();
        removeRedirectUrl();
        if (res.data) window.location.href = res.data;
        else location.href = "/security/login";
      });
    },
    onClickSupport() {
      this.showMessageSupport = true;
    },
    onClickCopy() {
      let str = "";
      for (let prop in this.error) {
        if (this.error[prop] === null) {
          continue;
        }
        let line = `${prop}: ${this.error[prop]}\n`;
        if (typeof this.error[prop] === "object") line = `${prop}: ${JSON.stringify(this.error[prop])}\n`
        str += line;
      }
      navigator.clipboard.writeText(str);
    },
    onClickSend() {
      window.open(
        `mailto:${this.supportEmail}?subject=Prospace error&body=Code: ${this.error.code}; Title: ${this.error.message}; SourceId: ${this.error.sourceId}`
      );
    },
    onCloseError() {
      this.$emit("update:modelMessageError", false);
    },
    onCloseSupport() {
      this.showMessageSupport = false;
    },
    getGrid() {
      const grid = [];
      for (const prop in this.error) {
        let label = this.error[prop];
        if (!label) {
          continue;
        }
        if (prop === "date") {
          label = moment(label).format("DD.MM.YYYY hh:mm");
        }
        const item = {
          title: prop.charAt(0).toUpperCase() + prop.slice(1),
          label: label,
        };
        grid.push(item);
      }
      return grid;
    },
  },
  computed: {
    showMessageError() {
      return this.modelMessageError;
    },
  },
};
</script>

<style lang="scss" scope>
.login-layout {
  background: url("../../../assets/img/side-logo.png") no-repeat,
    linear-gradient(180deg, #2c3e50 0%, #4f5c75 100%);
  background-position-y: bottom;
  padding: 130px 0 30px;
  min-height: 450px;
  min-width: 500px;
  &__content {
    padding-top: 43px;
    height: 65%;
  }
  &__footer {
    color: var(--prospace-ui-icon-gray);
    text-align: center;
    visibility: hidden;
    &--show {
      visibility: visible;
      span {
        cursor: pointer;
        color: #42a5f5;
      }
    }
    .prospace-action {
      display: inline-flex;
    }
  }
  .login-logo {
    margin: 0 auto;
  }
  @media screen and (max-height: 700px) {
    padding: 70px 0 10px;
    .login-logo {
      width: 350px !important;
      height: 290px !important;
    }
  }
  @media screen and (max-height: 450px) {
    padding: 30px 0 10px;
    .login-logo {
      width: 260px !important;
      height: 200px !important;
    }
  }
}
.message-support {
  position: relative;
  background: var(--prospace-ui-card);
  width: 400px !important;
  padding: 20px 15px 10px;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.07);
  border-radius: 5px;
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>
