<template>
  <ProSpaceGeoLayout class="distributor" v-if="model">
    <template #top>
      <ProSpaceTopPanel
        :header="$t('distributorProfile') + ' | ' + model.name"
        back
        @back="backHandler"
      >
        <template #right>
          <ProSpaceAction
            ref="action-saveAll"
            icon="double-check"
            :disabled="v$.model.$invalid"
            :text="$t('saveAll')"
            @click="saveAll"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout
        :is-templated="true"
        :template-center="true"
        :template-right="true"
        :routeName="$route.name"
      >
        <template #center>
          <ProSpaceTabs
            v-model="currentTab"
            without-margin-bottom
            :refLoading="mixinLoadingAccess"
            :tabs="overviewTabs"
            :loading="loading"
            @select-tab="handlerSelectTab"
          >
            <ProSpaceAction
              ref="action-save"
              icon="check"
              :disabled="v$.model.$invalid"
              :text="$t('save')"
              @click="savePart(saveFields.Overview)"
            />
            <template v-slot:tab-1>
              <ProSpaceVLayout :gap="10">
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('distributorMainInfo')"
                      :bottom="$t('distributorMainInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout justify-content="space-between" :gap="20">
                      <ProSpaceTextBlock
                        :top="$t('code')"
                        :bottom="model.code"
                        :gap="6"
                      />
                      <ProSpaceTextBlock
                        :top="$t('distributorNameForm')"
                        :bottom="model.name"
                        :gap="6"
                      />
                      <ProSpaceTextBlock
                        :top="$t('distributorLegalAddress')"
                        :bottom="model.legalAddress"
                        :gap="6"
                      />
                    </ProSpaceVLayout>
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('distributorMailAddress')"
                      :bottom="$t('distributorMailAddressAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="6"
                    >
                      <ProSpaceInputText
                        ref="mailAddress-ProSpaceInputText"
                        v-model="model.mailAddress"
                        :label="$t('distributorMailAddress')"
                        :placeholder="$t('distributorMailAddress')"
                        :invalid="v$.model.mailAddress.$error"
                        :invalid-messages="v$.model.mailAddress.$errors"
                        @blur="v$.model.mailAddress.$touch"
                      />
                    </ProSpaceHLayout>
                  </template>
                  <template v-if="v$.model.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('distributorImage')"
                      :bottom="$t('distributorImageAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceFile
                        v-model="logoFile"
                        ref="logoFileName-ProSpaceFile"
                        :maxFileSize="102400"
                        accept=".jpeg, .jpg, .png"
                        :invalid="v$.model.logoFileName.$error"
                        @blur="v$.model.logoFileName.$touch"
                        :txtBtnUpload="$t('uploadFile')"
                        :txtEmptyFiles="$t('uploadFileDragAndDropText')"
                        :invalidFileLimitMessage="$t('invalidFileLimitMessage')"
                        :invalidFileSizeMessage="$t('invalidFileSizeMessage')"
                        :invalidFileTypeMessage="$t('invalidFileTypeMessage')"
                      />
                    </ProSpaceHLayout>
                  </template>
                  <template v-if="v$.model.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('distributorContacts')"
                      :bottom="$t('distributorContactsAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceTextBlock
                        :top="$t('distributorPhoneNumber')"
                        :bottom="model.phoneNumber"
                        :gap="6"
                      />
                      <ProSpaceTextBlock
                        :top="$t('distributorFaxNumber')"
                        :bottom="model.faxNumber"
                        :gap="6"
                      />
                      <ProSpaceTextBlock
                        :top="$t('email')"
                        :bottom="model.emailAddress"
                        :gap="6"
                      />
                    </ProSpaceHLayout>
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout
                  class="geo-row"
                  style="height: 100% !important"
                >
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('distributorEDOInfo')"
                      :gap="10"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceCheckbox
                        v-if="edoProviderCheckboxes"
                        v-model="edoProviderCheckboxes"
                        ref="edoProvider-ProSpaceCheckbox"
                        direction="column"
                        field="label"
                        :change-obj="edoProviderNames"
                        @update:filterValue="edoChanged"
                        style="overflow: hidden"
                      />
                    </ProSpaceHLayout>
                  </template>
                  <template v-if="v$.model.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceVLayout>
            </template>
            <!-- <template v-slot:tab-2>
              <DistributorRetailOutlets :distributorId="model.id" />
            </template> -->
            <template v-slot:tab-2>
              <ProSpaceVLayout :gap="10">
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('distributorRequisites')"
                      :bottom="$t('distributorRequisitesAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout justify-content="space-between" :gap="20">
                      <ProSpaceTextBlock
                        :top="$t('bankName')"
                        :bottom="model.bankName"
                        :gap="6"
                      />
                      <ProSpaceTextBlock
                        :top="$t('bankPaymentAccount')"
                        :bottom="model.bankPaymentAccount"
                        :gap="6"
                      />
                      <ProSpaceTextBlock
                        :top="$t('bankCorrespondentAccount')"
                        :bottom="model.bankCorrespondentAccount"
                        :gap="6"
                      />
                      <ProSpaceTextBlock
                        :top="$t('bankBIC')"
                        :bottom="model.bankBIC"
                        :gap="6"
                      />
                      <ProSpaceTextBlock
                        :top="$t('bankINN')"
                        :bottom="model.bankINN"
                        :gap="6"
                      />
                      <ProSpaceTextBlock
                        :top="$t('bankKPP')"
                        :bottom="model.bankKPP"
                        :gap="6"
                      />
                    </ProSpaceVLayout>
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('distributorOGRNInfo')"
                      :bottom="$t('distributorOGRNInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout justify-content="space-between" :gap="10">
                      <ProSpaceInputText
                        ref="bankOGRN-ProSpaceInputText"
                        v-model="model.bankOGRN"
                        :label="$t('bankOGRN')"
                        :placeholder="$t('bankOGRN')"
                        :invalid="v$.model.bankOGRN.$error"
                        :invalid-messages="v$.model.bankOGRN.$errors"
                        @blur="v$.model.bankOGRN.$touch"
                      />
                    </ProSpaceVLayout>
                  </template>
                  <template v-if="v$.model.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceVLayout>
            </template>
            <template #tab-3>
              <ProSpaceGeoLayout
                  class="geo-row"
                  style="height: 100% !important"
                >
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('distributorStructureInfo')"
                      :bottom="$t('distributorStructureInfoAdditional')"
                      type="third"
                      :gap="10"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <DistributorHierarchySelectTree
                      selectionMode="single"
                      getMethod="allTree"
                      searchName="name"
                      searchable
                      expandAll
                      :service="distributorStructureService"
                    />
                  </template>
                </ProSpaceGeoLayout>
            </template>
            <template #tab-4>
              <ProSpaceGeoLayout
                  class="geo-row"
                  style="height: 100% !important"
                >
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('distributionShipToWarehouseDistrsTitle')"
                      :bottom="$t('distributionShipToWarehouseDistrsDescription')"
                      type="third"
                      :gap="10"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <DistributorHierarchySelectTree
                      selectionMode="single"
                      getMethod="allTree"
                      searchName="name"
                      searchable
                      expandAll
                      :service="distributorShipToService"
                    />
                  </template>
                </ProSpaceGeoLayout>
            </template>
            <template v-slot:tab-5>
              <DistributorAuthorizedContactPerson
                :distributor="model"
                :mountAccess="mountAccess"
                :checkAccess="checkAccess"
              />
              <DistributorEmpoweredContactPerson
                :distributor="model"
                :mountAccess="mountAccess"
                :checkAccess="checkAccess"
              />
              <DistributorAnotherContactPerson
                :distributor="model"
                :mountAccess="mountAccess"
                :checkAccess="checkAccess"
              />
            </template>
            <template v-slot:tab-6>
              <DistributorMarsContactPerson
                :distributor="model"
                :mountAccess="mountAccess"
                :checkAccess="checkAccess"
              />
            </template>
          </ProSpaceTabs>
        </template>
        <template #right>
          <ProSpaceRightPanel class="distributor-right-panel" no-border>
            <template #center>
              <ProSpacePanelBlock
                isNeedTopMarginHeader
                v-if="currentTab.systemName === 'retailOutlets'"
                :header="retailInfoBlockHeader"
              >
                <template v-slot>
                  <ProSpaceEmptyGrid
                    icon="error-message"
                    hideBg
                    :title="$t('featureInProgress')"
                    :text="$t('retailFeatureInfo')"
                  />
                </template>
              </ProSpacePanelBlock>
              <ProSpacePanelBlock
                isNeedTopMarginHeader
                v-if="currentTab.systemName === 'warehouse'"
                :header="warehouseInfoBlockHeader"
              >
                <ProSpaceVLayout :gap="10" class="text-color-gray text-size-13">
                  <div>
                    <span class="text-bold">{{ $t('distributorStructurePlantCode') }}</span>
                    <span v-html="$t('distributorStructurePlantCodeDesc')" />
                  </div>
                  <div>
                    <span class="text-bold">{{ $t('distributorStructureWarehouseShipTo') }}</span>
                    <span v-html="$t('distributorStructureWarehouseShipToDesc')" />
                  </div>
                </ProSpaceVLayout>
              </ProSpacePanelBlock>
              <ProSpacePanelBlock
                isNeedTopMarginHeader
                v-if="currentTab.systemName === 'distributor'"
                :header="proSpacePanelBlockHeader"
              >
                <ProSpaceTextBlock
                  :bottom="$t('distributorProfileInfo')"
                  :gap="10"
                  class="distr-text-pre-wrap"
                  break-word
                  type="fourth"
                />
              </ProSpacePanelBlock>
              <ProSpacePanelBlock
                isNeedTopMarginHeader
                v-if="currentTab.systemName === 'requisites'"
                :header="requisitesInfoBlockHeader"
              >
                <ProSpaceVLayout :gap="10" class="text-color-gray text-size-13">
                  <div>
                    <span v-html="$t('distributorRequisitesInfo')" />
                  </div>
                </ProSpaceVLayout>
              </ProSpacePanelBlock>
              <ProSpacePanelBlock
                isNeedTopMarginHeader
                v-if="currentTab.systemName === 'distributorContactPersons'"
                :header="distributorContactInfoBlockHeader"
              >
                <ProSpaceVLayout :gap="10" class="text-color-gray text-size-13">
                  <div>
                    <span v-html="$t('distributorContactsInfo')" />
                  </div>
                </ProSpaceVLayout>
              </ProSpacePanelBlock>
              <ProSpacePanelBlock
                isNeedTopMarginHeader
                v-if="currentTab.systemName === 'marsContactPersons'"
                :header="marsContactInfoBlockHeader"
              >
                <ProSpaceVLayout :gap="10" class="text-color-gray text-size-13">
                  <div>
                    <span v-html="$t('marsContactsInfo')" />
                  </div>
                </ProSpaceVLayout>
              </ProSpacePanelBlock>
              <ProSpacePanelBlock
                v-if="currentTab.systemName !== 'retailOutlets'
                && currentTab.systemName !== 'distributorStructure'
                && currentTab.systemName !== 'warehouse'
                && currentTab.systemName !== 'marsContactPersons'
                && currentTab.systemName !== 'distributor'"
                withPaddingTop
                header=""
              >
                <template v-slot>
                  <ProSpaceVLayout :gap="20">
                    <a
                      href="https://mars.intradesk.ru"
                      class="text-color-accent link-font-13"
                      target="_blank"
                    >
                      {{ $t('linkOnPortal') }}
                    </a>
                  </ProSpaceVLayout>
                  <ProSpaceVLayout class="margin-top-20">
                    <ProSpaceTextBlock
                      :bottom="$t('distributorProfileMassChange')"
                      :gap="10"
                      class="distr-text-pre-wrap"
                      break-word
                      type="fourth"
                    />
                    <a
                      href="https://docs.google.com/spreadsheets/d/11Ehxa0EGPR-aOIa59MXU-795MKKfMRCM/edit#gid=937766932"
                      class="text-color-accent link-font-13"
                      target="_blank"
                    >
                      {{ $t('distributorProfileTemplate') }}
                    </a>
                  </ProSpaceVLayout>
                </template>
              </ProSpacePanelBlock>
              <ProSpacePanelBlock
                isNeedTopMarginHeader
                v-if="currentTab.systemName === 'distributorStructure'"
                :header="structureInfoBlockHeader"
              >
                <ProSpaceVLayout :gap="10" class="text-color-gray text-size-13">
                  <div>
                    <span class="text-bold">{{ $t('distributorStructureInfoBillTo') }}</span>
                    <span v-html="$t('distributorStructureBillToIs')" />
                  </div>
                  <div>
                    <span class="text-bold">{{ $t('distributorStructureSoldTo') }}</span>
                    <span v-html="$t('distributorStructureSoldToIs')" />
                  </div>
                  <div>
                    <span class="text-bold">{{ $t('distributorStructureGRD') }}</span>
                    <span v-html="$t('distributorStructureGRDIs')" />
                  </div>
                  <div>
                    <span class="text-bold">{{ $t('distributorStructureShipTo') }}</span>
                    <span v-html="$t('distributorStructureShipToIs')" />
                  </div>
                  <div>
                    <span class="text-bold">{{ $t('distributorStructureVirtualGRD') }}</span>
                    <span v-html="$t('distributorStructureVirtualGRDIs')" />
                  </div>
                  <div>
                    <span class="text-bold">{{ $t('distributorStructureFilial') }}</span>
                    <span v-html="$t('distributorStructureFilialIs')" />
                  </div>
                  <div>
                    <span class="text-bold">{{ $t('distributorStructureOffice') }}</span>
                    <span v-html="$t('distributorStructureOfficeIs')" />
                  </div>
                </ProSpaceVLayout>
              </ProSpacePanelBlock>
            </template>
            <template v-if="currentTab.systemName === 'retailOutlets'" #bottom>
              <ProSpacePanelBlock :header="proSpacePanelBlockHeader">
                <template v-slot>
                  <ProSpaceVLayout :gap="10">
                    <ProSpaceTextBlock
                      :bottom="$t('distributorProfileInfo')"
                      :gap="10"
                      class="distr-text-pre-wrap"
                      break-word
                      type="fourth"
                    />
                    <a
                      href="https://mars.intradesk.ru"
                      class="text-color-accent link-font-13"
                    >
                      https://mars.intradesk.ru
                    </a>
                  </ProSpaceVLayout>
                </template>
              </ProSpacePanelBlock>
            </template>
          </ProSpaceRightPanel>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceRadioButtonSecondary,
  ProSpaceGeoLayout,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceFile,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceIcon,
  ProSpaceCheckbox,
  ProSpaceEmptyGrid,
  ProSpaceWarning,
} from "@prospace/prospace-components-library";
import { DistributorService } from "../../services/DistributorService.js";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
import { DistributorShipToService } from "@masterdata/services/DistributorShipToService.js";
import DistributorHierarchySelectTree from "../../../../../Frontends/ProSpace/components/trees/DistributorHierarchySelectTree";
import DistributorEmpoweredContactPerson from "./components/DistributorEmpoweredContactPerson";
import DistributorAuthorizedContactPerson from "./components/DistributorAuthorizedContactPerson";
import DistributorAnotherContactPerson from "./components/DistributorAnotherContactPerson";
import DistributorMarsContactPerson from "./components/DistributorMarsContactPerson";
import DistributorRetailOutlets from "./components/DistributorRetailOutlets";

import {
  maxLength,
  helpers,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes"
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceRadioButtonSecondary,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpaceTextBlock,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceFile,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceIcon,
    ProSpaceCheckbox,
    DistributorHierarchySelectTree,
    DistributorEmpoweredContactPerson,
    DistributorAuthorizedContactPerson,
    DistributorAnotherContactPerson,
    DistributorMarsContactPerson,
    DistributorRetailOutlets,
    ProSpaceEmptyGrid,
    ProSpaceWarning,
    DistributorHierarchySelectTree
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  data() {
    return {
      // Common
      model: null,
      loading: false,
      currentTab: { name: this.$t("distributor"), systemName: "distributor" },

      // Service
      distributorService: new DistributorService(),
      distributorStructureService: new DistributorStructureService(),
      distributorShipToService: new DistributorShipToService(),

      getMethod: "get",
      masterId: null,
      edoProviderNames: {},
      logoFile: null,
      edoProviderCheckboxes: null,

      // SaveFields
      saveFields: {
        Overview: [
          "name",
          "number",
          "legalAddress",
          "mailAddress",
          "phoneNumber",
          "faxNumber",
          "emailAddress",
          "edoProviderName",
          "logoFileName",
          "logoFileContent",
          "bankName",
          "bankPaymentAccount",
          "bankCorrespondentAccount",
          "bankBIC",
          "bankINN",
          "bankKPP",
          "bankOGRN",
        ],
      },
    };
  },
  validations() {
    return {
      model: {
        name: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
        },
        number: {},
        legalAddress: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
        },
        mailAddress: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
        },
        phoneNumber: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
        },
        faxNumber: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
        },
        emailAddress: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
        },
        edoProviderName: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
        },
        logoFileName: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
        },
        bankName: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
        },
        bankPaymentAccount: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '50'}),
            maxLength(50)
          ),
        },
        bankCorrespondentAccount: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '50'}),
            maxLength(50)
          ),
        },
        bankBIC: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '50'}),
            maxLength(50)
          ),
        },
        bankINN: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '50'}),
            maxLength(50)
          ),
        },
        bankKPP: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '50'}),
            maxLength(50)
          ),
        },
        bankOGRN: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '50'}),
            maxLength(50)
          ),
        },
      },
    };
  },
  created() {
    this.distributorService.getEDOs().then((response) => {
      this.edoProviderCheckboxes = response.data.records;
    });
  },
  mounted() {
    this.loadRoleAccess();
    this.refreshAccess();
    this.getData();
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.refreshAccess()
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    refreshAccess() {
      this.$nextTick(() => {
        this.mountAccess();
      })
    },
    async getData() {
      this.masterId = this.$route.params.id;
      if (this.masterId === undefined) {
        // redirect to 404
        return;
      }
      const result = await this.distributorService[this.getMethod](
        this.masterId
      );
      this.model = result.data;
      this.distributorStructureService.setBillToCodes([this.model.code])
      this.distributorShipToService.setBillToCodes([this.model.code])
      if(result.data.edoProviderName)
        result.data.edoProviderName.split("||").forEach((i) => {
          this.edoProviderNames[i] = true;
        });
      this.logoFile = this.dataURLtoFile(
          result.data.logoFileContent,
          result.data.logoFileName
        );
    },
    saveAll() {
      this.loading = true;
      this.distributorService.set().then(() => {
        this.afterSave();
      });
    },
    savePart(fields) {
      this.loading = true;
      this.distributorService.setPart(fields).then(() => {
        this.afterSave();
      });
    },
    afterSave() {
      this.getData();
      this.mountAccess();
      this.loading = false;
    },
    setLogoFileString(logoFile) {
      const me = this;
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        me.model.logoFileContent = base64String;
      };

      reader.readAsDataURL(logoFile);
    },
    submitFilter(nameRef = "grid") {
      if (!this.$refs[nameRef]) return;
      this.$refs[nameRef].getRecords(true);
    },
    backHandler() {
      this.$router.go(-1);
    },
    logoChanged(files) {
      if (Array.isArray(files) && files[0]) {
        this.model.logoFileName = files[0].name;
        this.setLogoFileString(files[0]);
      } else if (this.model) {
        this.model.logoFileName = null;
        this.model.logoFileContent = null;
      }
    },
    edoChanged(names) {
      this.model.edoProviderName = names.join("||");
    },
    dataURLtoFile(dataurl, filename) {
      if (!dataurl || !filename) return [];
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return [new File([u8arr], filename, { type: mime })];
    },
  },
  watch: {
    logoFile: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.logoChanged(newValue);
      },
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
    }),
    refsForCheckInMixin() {
      let refs = [
        "action-saveAll",
        "action-save"
      ];

      if(this.currentTab.name === this.$t("distributor"))
        refs.push(
          "mailAddress-ProSpaceInputText",
          "logoFileName-ProSpaceFile",
          "edoProvider-ProSpaceCheckbox"
        );

      if(this.currentTab.name === this.$t("requisites"))
        refs.push(
          "bankOGRN-ProSpaceInputText"
        );

      return refs;
    },
    proSpacePanelBlockHeader() {
      return {
        title: this.$t("distributor"),
      };
    },
    retailInfoBlockHeader() {
      return {
        title: this.$t("information"),
      };
    },
    requisitesInfoBlockHeader() {
      return {
        title: this.$t("requisites"),
      };
    },
    distributorContactInfoBlockHeader() {
      return {
        title: this.$t("distributorContactPersons"),
      };
    },
    marsContactInfoBlockHeader() {
      return {
        title: this.$t("marsContactPersons"),
      };
    },
    warehouseInfoBlockHeader() {
      return {
        title: this.$t("distributionShipToWarehouseDistrsTitle"),
      };
    },
    structureInfoBlockHeader() {
      return {
        title: this.$t("distributorStructure"),
      };
    },
    overviewTabs() {
      return [
        {
          name: this.$t("distributor"),
          systemName: "distributor",
          isActive: this.currentTab.systemName === "distributor"
        },
        // вкладка скрыта, саппортная правка - Bug ID - B048. "Принято решение удалить раздел " Торговые точки приоритетной Дистрибуции". Просьба скрыть эту закладку из Профиля Дистрибьютора."
        // {
        //   name: this.$t("retailOutlets"),
        //   systemName:"retailOutlets",
        //   isActive: this.currentTab.systemName === "retailOutlets"
        // },
        {
          name: this.$t("requisites"),
          systemName: "requisites",
          isActive: this.currentTab.systemName === "requisites"
        },
        {
          name: this.$t("distributorStructure"),
          systemName: "distributorStructure",
          isActive: this.currentTab.systemName === "distributorStructure"
        },
        {
          name: this.$t("distributionShipToWarehouse"),
          systemName: "warehouse",
          isActive: this.currentTab.systemName === "warehouse"
        },
        {
          name: this.$t("distributorContactPersons"),
          systemName: "distributorContactPersons",
          isActive: this.currentTab.systemName === "distributorContactPersons"
        },
        {
          name: this.$t("marsContactPersons"),
          systemName: "marsContactPersons",
          isActive: this.currentTab.systemName === "marsContactPersons"
        },
      ];
    },
  },
};
</script>
<style lang="scss">
.distr-text-pre-wrap {
  .prospace-text-block__bottom {
    white-space: pre-wrap;
  }
}

.distributor {
  &-right-panel {
    .prospace-panel-block-header {
      margin-bottom: 10px;
    }
  }
  // .prospace-scrollbar::-webkit-scrollbar {
  //   width: 3px;
  //   height: 3px;
  //   background: inherit;
  // }
  // .prospace-list-items-table {
  //   .p-datatable-thead,
  //   .p-datatable-tbody {
  //     > tr {
  //       display: inline-flex;
  //       justify-content: space-between;
  //       width: calc(100% - 28px) !important;
  //       > th, > td {
  //         display: block;
  //         flex: 1 0 auto;
  //         &:first-child {
  //           display: inline-block;
  //           flex: 0 0 120px;
  //           max-width: 120px;
  //         }
  //       }
  //     }
  //   }
  // }
}
</style>
