<template>
  <ProSpaceGeoLayout :routeName="$route.name" isParent>
    <template #top>
      <ProSpaceTopPanel :header="this.$t('distributors')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <IconButtonSettingsColumns @click="showModalSettingsColumns"/>
          <ProSpaceDots
            :options="wrapperCheckRoles(dotsHeaderOptions)"
            :isSquare="true"
            @action="(action) => handleHeaderDots(action)"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            :unicKeyStorage="distributorService.constructor.name + 'grid'"
            @apply="submitFilter"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
              }
            "
            ref="filterPanel"
            :filter-model="['code', 'name']"
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceFilterContainsChips
                v-model="filter.name"
                :placeholder-value="$t('distributor')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="distributorService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            selectionMode="single"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :service="distributorService"
            :noMountedRequest="true"
            :defaultFilter="{ disabled: { and: [false] } }"
            defaultColumnWidths="300"
            v-bind="gridPropsSettingsColumns"
            @selected-change="selectedHandler"
            @db-click="toItem"
          >
            <template #columns>
              <Column
                field="name"
                :header="$t('distributor')"
                sortable
                headerClass="move-sort-head"
                frozen
                class="column-table-header-cell"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.code"
                    :status="{ text: $t('distributor'), type: 'blue' }"
                    :title="field.data.name"
                    :description="`${field.data.mailAddress || ''}`"
                    :img-src="field.data.logoFileContent"
                    show-image
                    is-inline-w
                    @click-on-title="toItem(field.data)"
                  />
                </template>
              </Column>
              <Column
                field="phoneNumber"
                :header="$t('distributorPhoneNumber')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.phoneNumber">
                    {{ field.data.phoneNumber }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column
                field="faxNumber"
                :header="$t('distributorFaxNumber')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.faxNumber">
                    {{ field.data.faxNumber }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column
                field="emailAddress"
                :header="$t('email')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.emailAddress">
                    {{ field.data.emailAddress }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="createdAt" sortable :header="$t('createdDate')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column
                field="actions"
                class="column-end column-end-30"
                frozen alignFrozen="right"
              >
                <template #body="field">
                  <ProSpaceDots
                    :options="wrapperCheckRoles(dotsOptions, field.data)"
                    @action="(action) => handleDots(action, field.data)"
                  ></ProSpaceDots>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
      <ProSpaceHistoryModal
        ref="history"
        v-model="showHistoryModal"
        modelName="DistributorApiModel"
        @close="closeHistory"
      />
    </template>
    <template #right>
      <DistributorPanel
        v-if="hasSelect"
        ref="right-panel"
        :service="distributorService"
        :options="dotsOptions"
        :filterAccessDots="wrapperCheckRoles"
        :checkAccess="checkAccess"
        @delete="deleteItem"
        @history="showHistory"
      />
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingConfirmation')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteDistributor', { name:  deletingModel.name })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceEmptyGrid,
  ProSpaceDefaultTextColumn,
  ProSpaceFilterContainsChips,
  ProSpaceDateTimeColumn,
  ProSpaceFlashMessage
} from "@prospace/prospace-components-library";
import { DistributorService } from "../../services/DistributorService.js";

import Column from "primevue/column";
import DistributorPanel from "./components/DistributorPanel";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import { mapGetters } from "vuex";
const _ = require("lodash");
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue";
import IconButtonSettingsColumns from "@components/settingsColumns/IconButtonSettingsColumns.vue";
import useSettingsColumns from "@composes/useSettingsColumns.js";
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceEmptyGrid,
    ProSpaceDefaultTextColumn,
    DistributorPanel,
    ProSpaceHistoryModal,
    ProSpaceFilterContainsChips,
    ProSpaceDateTimeColumn,
    ProSpaceFlashMessage,
    IconButtonSettingsColumns
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic()
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    const {
      showModalSettingsColumns,
      gridPropsSettingsColumns
    } = useSettingsColumns(grid);
    return {
      grid, filterPanel,
      setStatistic, distActions,
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
      showModalSettingsColumns,
      gridPropsSettingsColumns
    }
  },
  data() {
    return {
      showHistoryModal: false,

      // Service
      distributorService: new DistributorService(),

      //InlinePanel
      masterId: null,
      isEdit: false,
      isCreate: false,
      modalModel: {},

      // Multi filter
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: false,
        field: "disabled",
      },

      deletingModel: null,
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
    }),
    customServices() {
      let filters = [
        {
          type: "chips",
          model: "Code",
          label: this.$t("distributorCode"),
          selected: {},
        },
        {
          type: "chips",
          model: "LegalAddress",
          label: this.$t("distributorLegalAddress"),
          selected: {},
        },
        {
          type: "chips",
          model: "MailAddress",
          label: this.$t("distributorMailAddress"),
          selected: {},
        },
        {
          type: "chips",
          model: "PhoneNumber",
          label: this.$t("distributorPhoneNumber"),
          selected: {},
        },
        {
          type: "chips",
          model: "FaxNumber",
          label: this.$t("distributorFaxNumber"),
          selected: {},
        },
        {
          type: "chips",
          model: "emailAddress",
          label: this.$t("email"),
          selected: {},
        },
        {
          type: "checkboxes",
          service: new DistributorService(),
          getMethod: "getEDOs",
          model: "EDOProviderName",
          label: this.$t("distributorEDO"),
          optionValue: "value",
          optionLabel: "label",
          selected: {},
        },
        {
          type: "chips",
          model: "BankName",
          label: this.$t("bankName"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankPaymentAccount",
          label: this.$t("bankPaymentAccount"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankCorrespondentAccount",
          label: this.$t("bankCorrespondentAccount"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankBIC",
          label: this.$t("bankBIC"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankINN",
          label: this.$t("bankINN"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankKPP",
          label: this.$t("bankKPP"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankOGRN",
          label: this.$t("bankOGRN"),
          selected: {},
        }
      ]

      if(this.checkAccess("metainfo-by-block"))
        filters.push(
          {
            type: "chips",
            model: "createdBy",
            label: this.$t("createdByTxt"),
            selected: {},
          },
          {
            type: "chips",
            model: "updatedBy",
            label: this.$t("updatedByTxt"),
            selected: {},
          }
        );

      filters.push(
        {
          type: "dates-range-time",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range-time",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        }
      );

      return filters;
    },
    fastFilters() {
      let filters = [
        { label: this.$t("allTxt"), field: "disabled", value: false },
      ];

      if(this.checkDeleteAccess())
        filters.push({ label: this.$t("deleted"), field: "disabled", value: true });

      return filters;
    },
    dotsOptions() {
      let res = [
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
          liteRef: "delete-option",
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
          liteRef: "history-option",
        },
      ];
      if (this.modelFastFilter.label === this.$t("deleted")) {
        res = res.filter(
          (option) => option.name !== "edit" && option.name !== "delete"
        );
      }
      return res;
    },
    dotsHeaderOptions() {
      return [
        {
          name: "export_csv",
          text: this.$t("export_csv"),
          icon: "download",
          liteRef: "export-csv-option",
        },
        {
          name: "export_xlsx",
          text: this.$t("export_xlsx"),
          icon: "download",
          liteRef: "export-xlsx-option",
        },
      ];
    },
  },
  mounted() {
    this.loadRoleAccess();
  },
  methods: {
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.name;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    toItem(data) {
      const url = `/masterdata/distributor/${data.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    actionUpdate() {
      this.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    async actionExport(format) {
      this.loadingHandler(true);
      await this.distributorService
        .export(this.grid.getRecords(false, true), format)
      this.loadingHandler(false)
    },
    deleteItem(model) {
      if (!model) return;
      this.deletingModel = model;
      this.showDeleteModal = true;
    },
    applyDelete() {
      this.distributorService.delete(this.deletingModel).then(() => {
        this.actionUpdate();
        this.selectedMultipleClear();
        this.deletingModel = null;
      });
    },
    loadingHandler(val) {
      this.grid.loading = val;
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    handleHeaderDots(action) {
      if (action === "export_csv") {
        this.actionExport("CSV");
      } else if (action === "export_xlsx") {
        this.actionExport("XLSX");
      }
    },
  },
};
</script>
<style lang="scss"></style>
