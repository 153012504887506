<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @open="openPanel"
    @clear="clearPanel"
    @edit="editPanel"
    @delete="deletePanel"
    @restrictions="editRestrictions"
    @show-history="showHistoryPanel"
  >
    <template v-if="dashboard" v-slot:center>
      <ProSpaceTabs v-model="modelTab" :tabs="tabs">
        <template #tab-1>
          <ProSpacePanelBlock :header="headerOverview">
            <template v-slot:header>
              <ProSpaceAction v-if="checkEditAccess(dashboard) && modelTab.systemName === 'restrictions'" :text="$t('editItemTxt')" @click="onClickEdit" />
            </template>
            <template v-slot>
              <ProSpacePanelBlockList :list="content" />
            </template>
          </ProSpacePanelBlock>
        </template>
        <template #tab-2>
          <DashboardPanelRestrictions
            v-if="dashboard.restrictions"
            :dashboard="dashboard"
            :checkRestrictionsAccess="checkRestrictionsAccess"
            @edit:restrictions="$emit('edit:restrictions', $event)"
            @show:modal="$emit('show:modal', $event)"
            @openModalViewMore="$emit('openModalViewMore', $event)"
          />
        </template>
      </ProSpaceTabs>

    </template>
    <template v-if="dashboard" v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
} from "@prospace/prospace-components-library";
import { getAMorPM } from "@prospace/prospace-components-library/src/lib-assets/mixins/date";
import { DashboardService } from "../../../../services/DashboardService";
import DashboardPanelRestrictions from "./DashboardRestrictions/DashboardPanelRestrictions.vue";
import { mapGetters } from "vuex";
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
export default {
  name: "DashboardPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    DashboardPanelRestrictions,
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterAccessDots: {
      type: Function,
    },
    checkEditAccess: {
      type: Function,
    },
    checkRestrictionsAccess: {
      type: Function,
    },
    checkAccess: {
      type: Function,
    }
  },
  setup() {
    const {
      selectedData
    } = useMultiSelectRightPanel()
    return {
      selectedData
    }
  },
  data() {
    return {
      dashboard: null,
      dashboardService: new DashboardService(),
      loading: false,
      hasError: false,
      modelTab: {
        name: this.$t("basicInformation"),
        isActive: "baseInfo",
        systemName: "baseInfo",
      },
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    headerOverview() {
      return {
        title: this.$t("mainInformation"),
        isExpand: false,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    header() {
      if (this.dashboard) {
        return {
          title: this.dashboard.name,
          id: this.dashboard.id,
          options: this.getAccessDots(this.options),
          showCounter: false,
        };
      }
    },
    content() {
      if (this.dashboard) {
        return [
          [
            {
              title: this.$t("nameL"),
              text: this.dashboard.name,
            },
          ],
          [
            {
              title: this.$t("description"),
              text: this.dashboard.description,
            },
          ],
        ];
      }
      return [];
    },
    contentBottom() {
      let metaInfo = [];
      if (this.dashboard) {
        if(this.checkAccess("metainfo-by-block"))
          metaInfo.push([
            {
              title: this.$t("createdByTxt"),
              text: this.dashboard.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.dashboard.updatedBy,
            },
          ]);

        metaInfo.push([
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.dashboard.createdAt) + ' ' + getAMorPM(this.dashboard.createdAt, this.userinfo.timeFormat),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.dashboard.lastUpdated) + ' ' + getAMorPM(this.dashboard.lastUpdated, this.userinfo.timeFormat),
          },
        ]);
      }
      return metaInfo;
    },
    tabs() {
      if (!this.dashboard) return [];
      const tabs = [
        {
          name: this.$t("basicInformation"),
          systemName: "baseInfo",
          isActive: this.modelTab.systemName === "baseInfo" || !this.dashboard.restrictions,
        },
        {
          name: this.$t("permissions"),
          systemName: "restrictions",
          isHidden: !(this.dashboard.restrictions && this.checkAccess("restrictions-tab")),
          isActive: this.modelTab.systemName === "restrictions",
        },
      ];
      if (!tabs.some(i => i.isActive)) {
        tabs[0].isActive = true
        this.modelTab = tabs[0]
      }
      return tabs
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    getAccessDots(options) {
      return this.filterAccessDots ? this.filterAccessDots(options, this.dashboard) : options;
    },
    update() {
      this.hasError = false;
      try {
        this.getDashboard();
      } catch (e) {
        this.hasError = true;
      }
    },
    getDashboard() {
      if (this.selectedData) {
        this.loading = true;
        this.dashboard = null;
        this.dashboardService.get(this.selectedData.id).then((res) => {
          this.dashboard = res.data;
          this.loading = false;
        });
      } else {
        this.dashboard = null;
      }
    },
    clearPanel() {
      this.$emit("clear");
    },
    editPanel() {
      this.$emit("edit", this.dashboard);
    },
    editRestrictions() {
      this.$emit("edit:restrictions", this.dashboard);
    },
    openPanel() {
      this.$emit("open", this.dashboard);
    },
    deletePanel() {
      this.$emit("delete", this.dashboard);
    },
    showHistoryPanel() {
      this.$emit("history", this.dashboard);
    },
  },
  watch: {
    selectedData: {
      handler() {
        this.update();
      },
      deep: true
    },
  },
};
</script>

<style scoped></style>
