<template>
  <ProSpaceModal
    v-model="visible"
    :title="modalTitle"
    icon="parameters"
    width="1500"
    without-padding
    :content-style="{ height: '80vh' }"
    :cancelTxt="!readOnly ? $t('cancelInf') : $t('closeTxt')"
    :applyTxt="!readOnly ? $t('selectTxt2') : null"
    @apply="apply"
  >
    <ProSpaceGeoLayout :routeName="`${$route.name}DistributorSelectModal`">
      <template #center>
        <ProSpaceGeoLayout>
          <template #top>
            <ProSpaceFilterPanel
              :closeTxt="$t('closeTxt')"
              :searchPlaceholder="$t('searchTxt')"
              :txtResetFilters="$t('txtResetFilters')"
              @apply="submitFilter"
              ref="filterPanel"
              :filter-model="filterModel"
              :blocked-filters="needBlock"
              @block="selectedMultipleBlockFilterHandler"
            >
              <template #hint>
                {{ this.$t("filterBy") }}
              </template>
              <template #filters="{ filter }">
                <ProSpaceFilterContainsChips
                  v-model="filter[filterPrefix + 'name']"
                  :placeholder-value="$t('distributor')"
                  :closeTxt="$t('closeTxt')"
                  :applyTxt="$t('applyTxt')"
                  :clearFilterTxt="$t('clearFilterTxt')"
                  @apply="emitFilter"
                />
                <ProSpaceCustomFilterPanel
                  :customTxt="$t('custom')"
                  :customFilterTxt="$t('customFilterTxt')"
                  :clearFilterTxt="$t('clearFilterTxt')"
                  :selectAllTxt="$t('selectAllTxt')"
                  :closeTxt="$t('closeTxt')"
                  :applyTxt="$t('applyTxt')"
                  :fromTxt="$t('fromTxt')"
                  :toTxt="$t('toTxt')"
                  :services="customServices"
                  @apply="(model) => customFilterApply(filter, model)"
                />
              </template>
            </ProSpaceFilterPanel>
          </template>
          <template #center>
            <ProSpaceMainGrid
              ref="grid"
              defaultSortField="createdAt"
              :getMethod="getMethod"
              :defaultSortOrder="-1"
              :selectionMode="selectionMode"
              :service="service || distributorService"
              :noMountedRequest="true"
              :defaultFilter="distributorFilter"
              @selected-change="selectedHandler"
              @loaded="setCheckboxes"
            >
              <template #columns>
                <Column
                  field="name"
                  :header="$t('distributor')"
                  sortable
                  headerClass="move-sort-head"
                  style="min-width: 450px; max-width: 450px;"
                  frozen
                  class="column-table-header-cell"
                >
                  <template #body="field">
                    <ProSpaceTableHeaderCell
                      v-if="field.data"
                      :id="field.data.code"
                      :status="{ text: $t('distributor'), type: 'blue' }"
                      :title="field.data.name"
                      :description="`${field.data.mailAddress || ''}`"
                      :img-src="field.data.logoFileContent"
                      :noHover="true"
                      show-image
                      is-inline-w
                    />
                  </template>
                </Column>
                <Column
                  field="phoneNumber"
                  :header="$t('distributorPhoneNumber')"
                  sortable
                >
                  <template #body="field">
                    <ProSpaceDefaultTextColumn>
                      {{ field.data.phoneNumber || ' - ' }}
                    </ProSpaceDefaultTextColumn>
                  </template>
                </Column>
                <Column
                  field="faxNumber"
                  :header="$t('distributorFaxNumber')"
                  sortable
                >
                  <template #body="field">
                    <ProSpaceDefaultTextColumn>
                      {{ field.data.faxNumber || ' - '  }}
                    </ProSpaceDefaultTextColumn>
                  </template>
                </Column>
                <Column
                  field="emailAddress"
                  :header="$t('email')"
                  sortable
                >
                  <template #body="field">
                    <ProSpaceDefaultTextColumn>
                      {{ field.data.emailAddress || ' - '  }}
                    </ProSpaceDefaultTextColumn>
                  </template>
                </Column>
                <Column field="createdAt" sortable :header="$t('createdDate')">
                  <template #body="field">
                    <ProSpaceDateTimeColumn
                      :date="field.data.createdAt"
                      :dateFormat="userinfo.dateFormat"
                      :timeFormat="userinfo.timeFormat"
                    />
                  </template>
                </Column>
              </template>
              <template #empty>
                <ProSpaceEmptyGrid
                  icon="error-message"
                  :title="$t('noDataTitle')"
                  :text="$t('noDataTxt')"
                />
              </template>
            </ProSpaceMainGrid>
          </template>
        </ProSpaceGeoLayout>
      </template>
      <template #right>
        <DistributorPanel
          v-if="hasSelect"
          ref="right-panel"
          :service="distributorService"
          :keyField="keyField"
          @clear="clear"
          @clear-selected="clearSelected"
        />
      </template>
    </ProSpaceGeoLayout>
  </ProSpaceModal>
  <ProSpaceFlashMessage
    v-if="showConfirmModel"
    v-model="showConfirmModel"
    type="default"
    icon="flash-success"
    :title="$t('isAllDistributorsSelectionTitle')"
    :applyTxt="$t('accept')"
    :cancelTxt="$t('reject')"
    @apply="applySelection"
    @update:modelValue="(val) => { if(!val) toAll = true }"
  >
    <ProSpaceVLayout :gap="10">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="allSelectionText"
      ></div>
      <ProSpaceRadioButton style="margin-left: 30px;" v-model="toAll" :values="selectionTypes" />
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
  <ModalBlockFilters
    @apply="() => selectedModel = []"
  />
</template>
<script>
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMainGrid,
  ProSpaceGeoLayout,
  ProSpaceTableHeaderCell,
  ProSpaceEmptyGrid,
  ProSpaceDefaultTextColumn,
  ProSpaceFilterContainsChips,
  ProSpaceModal,
  ProSpaceDateTimeColumn,
  ProSpaceFlashMessage,
  ProSpaceRadioButton,
  ProSpaceVLayout
} from "@prospace/prospace-components-library";
import { DistributorService } from "../../../../Services/MasterData/MasterData.Frontend/services/DistributorService.js";

import Column from "primevue/column";
import DistributorPanel from "../../../../Services/MasterData/MasterData.Frontend/views/Distributors/components/DistributorPanel";
import { mapGetters } from "vuex";
const _ = require("lodash");
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import ModalBlockFilters from "@multiselect/ModalBlockFilters"
import { ref } from "vue"
export default {
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMainGrid,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTableHeaderCell,
    ProSpaceEmptyGrid,
    ProSpaceDefaultTextColumn,
    DistributorPanel,
    ProSpaceFilterContainsChips,
    ProSpaceModal,
    ProSpaceDateTimeColumn,
    ProSpaceFlashMessage,
    ProSpaceRadioButton,
    ProSpaceVLayout,
    ModalBlockFilters
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    modalTitle: {
      type: String,
      default: "Distributor Select"
    },
    service: {
      type: Object,
    },
    getMethod: {
      type: String,
      default: "grid"
    },
    selectedIds: {
      type: Array,
      default: []
    },
    selectionMode: {
      type: String,
      default: "multiple",
    },
    keyField: {
      type: String,
      default: "id",
    },
    defaultFilter: {
      type: Object,
      default: { disabled: { and: [false] } },
    },
    needAllQuestion: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isAll: {
      type: Boolean,
      default: false,
    },
    allSelectionText: {
      type: String,
      default: "",
    },
    filterModel: {
      type: Array,
      default: ['code', 'name'],
    },
    filterPrefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // Service
      selectedModel: [],
      allRequestModel: {},
      distributorService: new DistributorService(),

      showConfirmModel: false,
      isAllChecked: false,
      toAll: true,
    };
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
     const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
      selectedData,
      selectedMultipleBlockFilterHandler,
      setSelected,
      needBlock,
      getRequestModel,
      selectedMultipleReset
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      grid, filterPanel, selectedMultipleGridHandler,
      hasSelect, selectedMultipleClear, selectedData,
      selectedMultipleBlockFilterHandler, setSelected,
      needBlock, getRequestModel, selectedMultipleReset
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.selectedModel = this.selectedIds
        this.needBlock = false
      }
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    visible: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    distributorFilter() {
      return this.readOnly ? this.defaultFilter : { disabled: { and: [false] } };
    },
    customServices() {
      return [
        {
          type: "chips",
          model: this.filterPrefix + "Code",
          label: this.$t("distributorCode"),
          selected: {},
        },
        {
          type: "chips",
          model: this.filterPrefix + "LegalAddress",
          label: this.$t("distributorLegalAddress"),
          selected: {},
        },
        {
          type: "chips",
          model: this.filterPrefix + "MailAddress",
          label: this.$t("distributorMailAddress"),
          selected: {},
        },
        {
          type: "chips",
          model: this.filterPrefix + "PhoneNumber",
          label: this.$t("distributorPhoneNumber"),
          selected: {},
        },
        {
          type: "chips",
          model: this.filterPrefix + "FaxNumber",
          label: this.$t("distributorFaxNumber"),
          selected: {},
        },
        {
          type: "chips",
          model: this.filterPrefix + "EmailAddress",
          label: this.$t("email"),
          selected: {},
        },
        {
          type: "checkboxes",
          service: new DistributorService(),
          getMethod: "getEDOs",
          model: this.filterPrefix + "EDOProviderName",
          label: this.$t("distributorEDO"),
          optionValue: "value",
          optionLabel: "label",
          selected: {},
        },
        {
          type: "chips",
          model: this.filterPrefix + "BankName",
          label: this.$t("bankName"),
          selected: {},
        },
        {
          type: "chips",
          model: this.filterPrefix + "BankPaymentAccount",
          label: this.$t("bankPaymentAccount"),
          selected: {},
        },
        {
          type: "chips",
          model: this.filterPrefix + "BankCorrespondentAccount",
          label: this.$t("bankCorrespondentAccount"),
          selected: {},
        },
        {
          type: "chips",
          model: this.filterPrefix + "BankBIC",
          label: this.$t("bankBIC"),
          selected: {},
        },
        {
          type: "chips",
          model: this.filterPrefix + "BankINN",
          label: this.$t("bankINN"),
          selected: {},
        },
        {
          type: "chips",
          model: this.filterPrefix + "BankKPP",
          label: this.$t("bankKPP"),
          selected: {},
        },
        {
          type: "chips",
          model: this.filterPrefix + "BankOGRN",
          label: this.$t("bankOGRN"),
          selected: {},
        },
        {
          type: "chips",
          model: this.filterPrefix + "CreatedBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "chips",
          model: this.filterPrefix + "UpdatedBy",
          label: this.$t("updatedByTxt"),
          selected: {},
        },
        {
          type: "dates-range",
          model: this.filterPrefix + "CreatedAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
    selectionTypes() {
      return [
        {
          value: true,
          label: this.$t("isAllDistributorsSelectionAllOption"),
        },
        {
          value: false,
          label: this.$t("isAllDistributorsSelectionNotAllOption"),
        },
      ];
    }
  },
  methods: {
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    clear() {
      this.clearSelectedModel()
      this.selectedMultipleReset()
    },
    clearSelected() {
      this.clearSelectedModel()
    },
    clearSelectedModel() {
      if (!this.grid?.isAllRowsSelected) {
        this.selectedModel = []
      }
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    actionUpdate() {
      this.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    selectedHandler(val) {
      if (val.type === "check") {
        const test = _.cloneDeep(this.selectedModel) // fixme: RangeError: Maximum call stack size exceeded
        test.push(val.data.id)
        this.selectedModel = test
      } else if (val.type === "uncheck") {
        this.selectedModel = this.selectedModel.filter(id => id !== val.data.id)
      }
      this.selectedMultipleGridHandler(val)
    },
    setCheckboxes() {
      if(this.readOnly)
        return;
      const records = _.cloneDeep(this.grid.records);
      if (this.isAll) {
        this.grid.isAllRowsSelected = true
      }
      const selectedModel = this.grid.getSelectedModel()
      const currentSelected = _.cloneDeep(selectedModel)
      const currentSelectedIds = currentSelected.map(i => i[this.keyField])
      const defaultSelectedIds = _.cloneDeep(this.selectedModel || []);
      const allSelectedIds = _.union(currentSelectedIds, defaultSelectedIds)
      const selectedRecords = records.filter((x) =>
        allSelectedIds.includes(x[this.keyField])
      );
      const allSelectedRecords = _.unionBy(currentSelected, selectedRecords, "id")
      const items =  this.grid.isAllRowsSelected ? this.grid.selectedModel : allSelectedRecords
      this.grid.selectedModel = items;
      this.setSelected({
        items,
        data: this.selectedData,
        isAll: this.grid.isAllRowsSelected
      })
    },
    apply() {
      this.allRequestModel = this.getRequestModel();
      // this.selectedModel = this.grid.getSelectedModel().map(i => i[this.keyField])
      if(this.needAllQuestion) {
        this.isAllChecked = this.grid.isAllRowsSelected && this.allRequestModel.records.length === 0;
        if(this.isAllChecked) {
          //  this.selectedModel = this.grid.records.map(i => i[this.keyField])
           this.showConfirmAllSelection();
        } else {
          this.acceptSelectedOnly();
        }
      }
      else {
        this.acceptSelectedOnly();
      }
    },
    showConfirmAllSelection() {
      this.showConfirmModel = true;
    },
    applySelection() {
      if(this.toAll)
        this.acceptAll();
      else
        this.acceptSelectedOnly();

      this.toAll = true;
    },
    acceptAll() {
      this.$emit("applyDistributors", {items: [], requestModel: this.allRequestModel}, this.isAllChecked);
    },
    acceptSelectedOnly() {
      if (this.selectionMode === 'single') { // тоже объекты
        this.$emit("applyDistributors", {items: [this.selectedData.id], requestModel: this.allRequestModel}, false);
      } else {
        this.$emit("applyDistributors", {items: this.selectedModel, requestModel: this.allRequestModel}, false);
      }
    }
  },
};
</script>
<style lang="scss"></style>
