<template>
  <DashboardLayoutSettingsGridSelectTypeElement
    v-if="isEmptyContainer"
    :selectedContainer="selectedContainer"
    :typeOptions="getComponentTypeOptions"
    @change:type="changeComponentType"
  />
  <div class="prospace-panel-block-list__row">
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
      :title="$t('containerType')"
    >
      <template #content>
        <ProSpaceDropdown
          v-model="model.type"
          :options="rootContainerTypeDropdownOptions"
          :placeholder="$t('containerType')"
          propValue="systemName"
          propLabel="name"
          @change="changeContainerChildrenValues"
        />
      </template>
    </ProSpacePanelBlockItem>
  </div>
  <div class="prospace-panel-block-list__row">
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
      :title="$t('elementName')"
    >
      <template #content>
        <ProSpaceInputText
          v-model="model.blockName"
          :placeholder="$t('name')"
        />
      </template>
    </ProSpacePanelBlockItem>
  </div>
  <div class="prospace-panel-block-list__row">
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
      :title="
              isParentContainerVertical
                ? $t('heightContainerPx')
                : $t('widthContainerPercent')
            "
    >
      <template #content>
        <ProSpaceInputNumber
          v-model="model.value"
          likeText
          :placeholder="
                  isParentContainerVertical
                    ? $t('heightContainerPx')
                    : $t('widthContainerPercent')
                "
          :invalid="validation?.value?.$error"
          :invalid-messages="validation?.value?.$errors"
          @blur="validation?.value?.$touch"
        />
      </template>
    </ProSpacePanelBlockItem>
  </div>
</template>

<script>
import {
  ProSpacePanelBlockItem,
  ProSpaceDropdown,
  ProSpaceInputText,
  ProSpaceInputNumber
} from "@prospace/prospace-components-library";
import DashboardLayoutSettingsGridSelectTypeElement from "./DashboardLayoutSettingsGridSelectTypeElement.vue";
import { ref } from "vue";
import useSelectElementType from "./composes/useSelectElementType";

export default {
  name: "DashboardLayoutSettingsContainer",
  components: {
    ProSpacePanelBlockItem,
    DashboardLayoutSettingsGridSelectTypeElement,
    ProSpaceDropdown,
    ProSpaceInputText,
    ProSpaceInputNumber
  },
  emits: ['update:selectedNode', 'combinedEvents'],
  props: {
    validation: {
      type: Object,
      required: true
    },
    rootContainerTypeDropdownOptions: {
      type: Array,
      default: () => []
    },
    selectedNode: {
      type: Object,
      default: null
    },
    isParentContainerVertical: {
      type: Boolean,
      default: true
    },
    getComponentTypeOptions: {
      type: Array,
      default: () => []
    },
    gridView: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const model = ref({
      model: {
        type: '',
        blockName: '',
        value: ''
      },
    });
    const {
      isEmptyModel,
      isEmptyContainer,
      selectedContainer,
      defaultModel } = useSelectElementType(
      props.selectedNode,
      props.getComponentTypeOptions,
      model
    );

    return {
      isEmptyModel,
      isEmptyContainer,
      selectedContainer,
      defaultModel,
      model
    }
  },
  data() {
    return {

    }
  },
  watch: {
    selectedNode: {
      handler: function(val, oldVal) {
        this.model = val;
      },
      immediate: true,
      deep: true
    },
    model: {
      handler: function(val, oldVal) {
        this.updateModel(val);
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    changeContainerChildrenValues(e) {
      this.$emit('combinedEvents', 'change:container', e);
    },
    updateModel(value) {
      this.$emit(`update:selectedNode`, value);
    },
    changeComponentType(e) {
      this.$emit('combinedEvents', 'change:component', this.selectedNode, e);
    }
  }
}
</script>

<style scoped>

</style>
