<template>
  <ProSpaceGeoLayout class="dashboardIntegrationMatching" v-if="model">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler">
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout
        :is-templated="true"
        :template-center="true"
        :template-right="true"
      >
        <template #center>
          <ProSpaceTabs
            v-model="modelTab"
            :tabs="overviewTabs"
            :loading="loading">
            <ProSpaceHLayout :gap="5">
              <ProSpaceAction
                :disabled="v$.model.$invalid"
                icon="check"
                :text="$t('save')"
                @click="saveHandle"
              />
            </ProSpaceHLayout>
            <template v-slot:tab-1>
              <ProSpaceVLayout :gap="10">
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('dashboardIntegrationMatchingAdditional')"
                      :bottom="$t('dashboardIntegrationMatchingAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout :gap="20">
                        <ProSpaceDropdown
                          v-model="model.integrationSource"
                          :service="baseTypeService"
                          getMethod="grid"
                          propValue="code"
                          propLabel="name"
                          argMethod="$filter=systemDirectoryCode eq 'IntegrationSource' and disabled eq false"
                          :label="$t('dashboardIntegrationMatchingIntegrationSource')"
                          :placeholder="$t('dashboardIntegrationMatchingIntegrationSource')"
                          :invalid="v$.model.integrationSource.$error"
                          :invalid-messages="v$.model.integrationSource.$errors"
                          @blur="v$.model.integrationSource.$touch"
                          @change="integrationSourceChangeHandle"
                        />
                        <ProSpaceDropdown
                          ref="integrationField-ProSpaceDropdown"
                          v-model="model.integrationField"
                          getMethod="getIntegrationSourceColumns"
                          propValue="name"
                          propLabel="name"
                          :label="$t('dashboardIntegrationMatchingIntegrationField')"
                          :placeholder="$t('dashboardIntegrationMatchingIntegrationField')"
                          :invalid="v$.model.integrationField.$error"
                          :invalid-messages="v$.model.integrationField.$errors"
                          :service="dashboardDatasetService"
                          @blur="v$.model.integrationField.$touch"
                          @change="
                            (item) =>
                              (model.integrationField =
                                item && item.name ? item.name : null)
                          "
                        />
                        <ProSpaceInputText
                          v-model="model.integrationValue"
                          :label="$t('dashboardIntegrationMatchingIntegrationValue')"
                          :placeholder="$t('dashboardIntegrationMatchingIntegrationValue')"
                          :invalid="v$.model.integrationValue.$error"
                          :invalid-messages="v$.model.integrationValue.$errors"
                          @blur="v$.model.integrationValue.$touch"
                        />
                        <ProSpaceInputText
                          v-model="model.systemValue"
                          :label="$t('dashboardIntegrationMatchingSystemValue')"
                          :placeholder="$t('dashboardIntegrationMatchingSystemValue')"
                          :invalid="v$.model.systemValue.$error"
                          :invalid-messages="v$.model.systemValue.$errors"
                          @blur="v$.model.systemValue.$touch"
                        />
                        <ProSpaceInputText
                          v-model="model.displayValue"
                          :label="$t('dashboardIntegrationMatchingDisplayValue')"
                          :placeholder="$t('dashboardIntegrationMatchingDisplayValue')"
                          :invalid="v$.model.displayValue.$error"
                          :invalid-messages="v$.model.displayValue.$errors"
                          @blur="v$.model.displayValue.$touch"
                        />
                    </ProSpaceVLayout>
                  </template>
                  <template
                    v-if="
                      v$.model.integrationSource.$error ||
                      v$.model.integrationField.$error ||
                      v$.model.integrationValue.$error ||
                      v$.model.systemValue.$error ||
                      v$.model.displayValue.$error"
                    v-slot:right
                  >
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceVLayout>
            </template>
          </ProSpaceTabs>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceFlashMessage
    v-if="showChangeModal"
    v-model="showChangeModal"
    type="default"
    icon="flash-warning"
    :title="$t('dashboardIntegrationMatchingIntegrationSourceChangeTitle')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('cancelInf')"
    @cancel="cancelChange"
    @apply="applyChange"
  >
    <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('dashboardIntegrationMatchingIntegrationSourceChageMessage')"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceRadioButtonSecondary,
  ProSpaceGeoLayout,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceFile,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceSearchfield,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceIcon,
  ProSpaceExtendedTextArea,
  ProSpaceWarning,
  ProSpaceFlashMessage
} from "@prospace/prospace-components-library";
import { DashboardIntegrationMatchingService } from "../../services/DashboardIntegrationMatchingService.js";
import { DashboardDatasetService } from "../../services/DashboardDatasetService.js";
import { required, helpers} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService.js";
export default {
  components: {
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceRadioButtonSecondary,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpaceTextBlock,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceFile,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceSearchfield,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceIcon,
    ProSpaceExtendedTextArea,
    ProSpaceWarning,
    ProSpaceFlashMessage
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      showChangeModal: false,
      prevIntegrationSource: null,

      // Tabs
      modelTab: {
        name: this.$t("commonInformation"),
        isActive: true,
        systemName: "commonInformation",
      },

      // Common
      layoutName: "",
      model: null,
      modelCopy: null,
      loading: false,

      // Service
      dashboardIntegrationMatchingService: new DashboardIntegrationMatchingService(),
      dashboardDatasetService: new DashboardDatasetService(),
      baseTypeService: new BaseTypeService(),

      getMethod: "get",
      masterId: null
    };
  },
  validations() {
    return {
      model: {
        integrationSource: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          )
        },
        integrationField: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          )
        },
        integrationValue: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          )
        },
        systemValue: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          )
        },
        displayValue: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          )
        }
      }
    };
  },
  mounted() {
    this.masterId = this.$route.params.id;
    this.getData();
  },
  methods: {
    gridLoaded() {},
    async getData() {
      if (this.masterId === undefined) {
        this.model = this.getEmptyModel();
        this.layoutName = this.$t('dashboardIntegrationMatchingCreateNew');
        return;
      }
      const result = await this.dashboardIntegrationMatchingService[this.getMethod](
        this.masterId
      );
      this.model = result.data;
      this.modelCopy = _.cloneDeep(result.data);
      this.layoutName = `${this.model.integrationValue}`;
      this.prevIntegrationSource = this.model.integrationSource;
      this.dashboardDatasetService.integrationTable = this.prevIntegrationSource;
    },
    saveHandle() {
      this.loading = true;
      const isCreate = !this.masterId;
      const saveMethod = isCreate ? "create" : "update";
      this.dashboardIntegrationMatchingService[saveMethod](this.model).then((result) => {
        this.model.id = result.data[0].id;
        this.masterId = result.data[0].id;
        this.getData();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    getEmptyModel() {
      return ({
        integrationSource: null,
        integrationField: null,
        integrationValue: null,
        systemValue: null,
        displayValue: null
    })},
    submitFilter(nameRef = "grid") {
      if (!this.$refs[nameRef]) return;
      this.$refs[nameRef].getRecords(true);
    },
    backHandler() {
      this.$router.go(-1);
    },
    cancelChange() {
      this.model.integrationSource = this.prevIntegrationSource;
    },
    applyChange() {
      this.model.integrationField = null;
      this.model.integrationValue = null;
      this.model.systemValue = null;
      this.model.displayValue = null;

      this.dashboardDatasetService.integrationTable = this.model.integrationSource;

      this.$nextTick(() => {
        this.$refs['integrationField-ProSpaceDropdown'].getData()
      });

      this.showChangeModal = false;
      this.prevIntegrationSource = this.model.integrationSource;
    },
    integrationSourceChangeHandle() {
      if(this.prevIntegrationSource != null)
        this.showChangeModal = true;
      else
        this.applyChange()
    }
  },
  computed: {
    overviewTabs() {
      return [
        {
          name: this.$t("commonInformation"),
          systemName: "commonInformation",
          isActive: true
        }];
    },

    integrationFieldFilter() {
      return `$filter=tableName eq '${this.model.integrationSource}'`
    }
  },
};
</script>
<style lang="scss"></style>