<template>
  <ProSpaceGeoLayout class="document" v-if="model">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler">
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout
        :is-templated="true"
        :template-center="true"
        :template-right="true"
      >
        <template #center>
          <ProSpaceTabs
            v-model="modelTab"
            :tabs="overviewTabs"
            :loading="loading"
            :refLoading="mixinLoadingAccess"
            without-margin-bottom
            @select-tab="handlerSelectTab"
          >
            <ProSpaceHLayout :gap="10">
              <ProSpaceAction
                v-if="!isPublished"
                ref="action-savedraft"
                :disabled="disabledSave"
                :text="$t('documentSaveDraft')"
                @click="saveDraft"
              />
              <ProSpaceAction
                v-if="!isCreation"
                ref="action-publish"
                icon="check"
                :disabled="disabledPublished"
                :text="!isPublished ? `${$t('documentPublishAction')}` : `${$t('save')}`"
                @click="publish"
              />
            </ProSpaceHLayout>
            <template v-slot:tab-1>
              <ProSpaceButton
                type="tertiary"
                class="important-button margin-top-20"
                coloredIcon
                :icon="
                  model.isImportant ? 'bookmark-solid' : 'bookmark-regular'
                "
                :label="$t('documentIsImportant')"
                :iconWidth="15"
                :iconHeight="15"
                :disabled="isCreation"
                :loading="importantLoading"
                @click="saveIsImportant"
              />
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('documentInfo')"
                      :bottom="$t('documentInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout justify-content="space-between" :gap="20">
                      <ProSpaceDropdown
                        ref="type-ProSpaceDropdown"
                        v-model="model.type"
                        :label="$t('documentTypeField')"
                        :placeholder="$t('documentTypeField')"
                        :invalid="v$.model.type.$error"
                        :invalid-messages="v$.model.type.$errors"
                        @blur="v$.model.type.$touch"
                        :service="baseTypeService"
                        argMethod="$filter=(systemDirectoryCode eq 'Types of documents' and disabled eq false)&$orderby=name asc"
                        getMethod="grid"
                        propValue="code"
                        propLabel="name"
                        @change="(item) => (model.type = item?.code)"
                        style="width: calc(50% - 5px)"
                      ></ProSpaceDropdown>
                      <ProSpaceInputText
                        ref="title-ProSpaceInputText"
                        v-model="model.title"
                        :label="$t('documentTitle')"
                        :placeholder="$t('documentTitle')"
                        :invalid="v$.model.title.$error"
                        :invalid-messages="v$.model.title.$errors"
                        @blur="v$.model.title.$touch"
                      />
                      <ProSpaceExtendedTextArea
                        ref="message-ProSpaceEditor"
                        v-model="model.message"
                        :placeholder="$t('documentMessage')"
                        :label="$t('documentMessage')"
                        :t="$t"
                        :locale="$i18n.locale"
                      >
                      </ProSpaceExtendedTextArea>

                      <ProSpaceHLayout
                        justify-content="flex-start"
                        align-items="center"
                        :gap="5"
                      >
                        <ProSpaceInputSwitch
                          ref="isPersonal-ProSpaceInputSwitch"
                          v-model="model.isPersonal"
                          :label="$t('newsItemIsPersonal')"
                        />
                        <ProSpaceIcon
                          v-hint="$t('documentsItemExtraInfoAdditionalPersonalHint')"
                          icon="question"
                        />
                      </ProSpaceHLayout>

                      <ProSpaceHLayout
                        justify-content="flex-start"
                        align-items="center"
                        :gap="5"
                      >
                        <ProSpaceInputSwitch
                          ref="isDistributorStructureRestricted-ProSpaceInputSwitch"    
                          v-model="model.isDistributorStructureRestricted"
                          :label="$t('restrictingAccessBranch')"
                          @update:modelValue="isDistributorStructureRestrictedChange"
                        />
                        <ProSpaceIcon
                          v-hint="$t('restrictingAccessBranchesHint')"
                          icon="question"
                        />
                      </ProSpaceHLayout>
                    </ProSpaceVLayout>
                  </template>
                  <template
                    v-if="v$.model.type.$error || v$.model.title.$error"
                    v-slot:right
                  >
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>

                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('documentAttachmentsInfo')"
                      :bottom="$t('documentAttachmentsInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceFile
                        ref="attachments-ProSpaceFile"
                        v-model="attachments"
                        accept=".doc, .docx, .docm, .rtf, .txt, .csv, .xml, .pdf, .png, .gif, .jpg, .jpeg, .ppt, .pptx, .potx, .potm, .pptm, .ppsx, .ppsm, .xls, .xlsx, .xlsm, .xlsb, .zip, .7z, .rar, .mp4, .avi, .mkv, .wmv"
                        :service="fileService"
                        :fileLimit="1000"
                        :maxFileSize="209715200"
                        :txtBtnUpload="$t('uploadFile')"
                        :txtEmptyFiles="$t('uploadFileDragAndDropText')"
                        :txtErrorStatus="$t('uploadStatusError')"
                        :invalidFileLimitMessage="$t('invalidFileLimitMessage')"
                        :invalidFileSizeMessage="$t('invalidFileSizeMessage')"
                        :invalidFileTypeMessage="$t('invalidFileTypeMessage')"
                      />
                    </ProSpaceHLayout>
                  </template>
                  <template v-if="v$.model.attachments.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('documentAdditionalInfo')"
                      :bottom="$t('documentAdditionalInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceInputChips
                        ref="tags-ProSpaceInputChips"
                        v-model="tags"
                        placeholder="+"
                        :label="$t('documentTags')"
                        :invalid="v$.model.tags.$error"
                        :invalid-messages="v$.model.tags.$errors"
                        @blur="v$.model.tags.$touch"
                      />
                    </ProSpaceHLayout>
                  </template>
                  <template v-if="v$.model.tags.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('documentAuthorInfo')"
                      :bottom="$t('documentAuthorInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout align-items="flex-start" :gap="110">
                      <ProSpaceTextBlock
                        ref="author-ProSpaceTextBlock"
                        :top="$t('documentAuthor')"
                        :bottom="model.author"
                        :gap="10"
                        break-word
                      />
                      <ProSpaceTextBlock
                        :top="$t('documentDate')"
                        :bottom="getDate(model.createdAt) + ' ' + getAMorPM(model.createdAt, userinfo.timeFormat)"
                        :gap="10"
                        break-word
                      />
                    </ProSpaceHLayout>
                  </template>
                </ProSpaceGeoLayout>
            </template>
            <template #tab-2 v-if="isFilial">
              <DistributorHierarchySelectForm
                ref="distributorStructures-DistributorHierarchySelectForm"
                v-model="model.distributorStructures"
                expandAll
                searchable
                readOnly
                :service="distributorStructureService"
                :titleTxt="$t('branches')"
                :descriptionTxt="$t('restrictingAccessBranchesDocs')"
              />
            </template>
            <template #tab-2 v-else-if="model.isDistributorStructureRestricted">
              <DistributorHierarchySelectForm
                ref="distributorStructures-DistributorHierarchySelectForm"
                v-model="model.distributorStructures"
                viewMethod="getDocViewTree"
                expandAll
                searchable
                :service="distributorStructureService"
                :afterEdit="afterDistributorStructureEdit"
                :titleTxt="$t('distributors')"
                :descriptionTxt="$t('restrictingAccessDistrsDocs')"
                :hint="$t('restrictingAccessHintAll')"
                @apply="applyDistributorStructure"
              />
            </template>
            <template v-slot:tab-2 v-else>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('distributors')"
                    :bottom="$t('documentsItemAllHint')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceGeoLayout>
                    <template v-slot:top>
                      <ProSpaceVLayout :gap="20">
                        <ProSpaceInputSwitch
                          ref="isAllDistributors-ProSpaceInputSwitch"
                          v-model="model.isAllDistributors"
                          :label="$t('allTxt')"
                          @update:modelValue="isAllDistributorsChanged"
                        />
                        <ProSpaceButton
                          ref="addRecipient-button"
                          type="secondary"
                          icon="edit"
                          class="margin-bottom-30"
                          :label="this.$t('editItemTxt')"
                          :disabled="model.isAllDistributors"
                          @click="showDocumentDistributor"
                        />
                      </ProSpaceVLayout>
                    </template>
                    <template v-slot:center>
                      <ProSpaceSimpleGrid
                        v-if="
                          model.distributorIds &&
                          model.distributorIds.length > 0
                        "
                        ref="distributorGrid"
                        getMethod="grid"
                        :headers="distributorGridHeaders"
                        :service="distributorService"
                        :limit="50"
                        :btnText="$t('viewMore')"
                        :deletable="checkAccess('action-delete-ProSpaceSimpleGrid', model)"
                        :deleteMethod="null"
                        :defaultFilter="distributorFilter"
                        deletingFieldName="name"
                        :afterConfirmText="$t('questionDeletingList')"
                        @delete="deleteFromDistributors"
                        :defaultModal="false"
                        :confirmDelete="true"
                        confirmIcon="flash-success"
                        :confirmTitle="$t('deletingConfirmation')"
                        :confirmText="$t('questionDeleting')"
                        :confirmApplyText="$t('applyTxt')"
                        :confirmCancelText="$t('cancelInf')"
                        @openModal="showEditDistributorsModalReadOnly"
                      />
                      <DistributorSelectModal
                        v-model="readDistributorModal"
                        :modalTitle="$t('listDistributors')"
                        :selectedIds="model.distributorIds"
                        :needAllQuestion="false"
                        :readOnly="true"
                        :selectionMode="'single'"
                        :isAll="model.isAllDistributors"
                        :defaultFilter="distributorFilter"
                      />
                    </template>
                  </ProSpaceGeoLayout>
                </template>
              </ProSpaceGeoLayout>
              <DistributorSelectModal
                v-model="showDistributorSelectModal"
                :modalTitle="$t('selectDistributors')"
                needAllQuestion
                :selectedIds="model.distributorIds"
                :allSelectionText="$t('isAllDistributorsSelectionDocs')"
                @applyDistributors="applyDistributorSelectModal"
              />
              <ProSpaceFlashMessage
                v-if="isAllChangedMessage"
                v-model="isAllChangedMessage"
                type="default"
                icon="flash-success"
                :title="$t('isAllDistributorsTitle')"
                :applyTxt="$t('accept')"
                :cancelTxt="$t('reject')"
                @apply="applyIsAllChangedMessage"
                @update:modelValue="cancelIsAllChangedMessage"
              >
                {{ $t('isAllDistributorsText') }}
              </ProSpaceFlashMessage>
            </template>
            <template v-slot:tab-3>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('positions')"
                    :bottom="$t('documentPositionsInfo')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceGeoLayout>
                    <template v-slot:center>
                      <PositionSelectTree
                        ref="tree"
                        expandAll
                        :positionService="positionOptions.positionService"
                        :positionGetMethod="positionOptions.positionGetMethod"
                        :positionSelectionMode="positionOptions.positionSelectionMode"
                        :positionFilter="positionOptions.positionFilter"
                        :propLabel="positionOptions.propLabel"
                        :checkedPositions="positions"
                        @changed="(newPositions) => { this.positions = [...newPositions] }"
                      />
                    </template>
                  </ProSpaceGeoLayout>
                </template>
              </ProSpaceGeoLayout>
            </template>
            <template v-slot:tab-4>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('documentRecipientsMars')"
                    :bottom="$t('documentRecipientsInfo')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceGeoLayout>
                    <template v-slot:top>
                      <ProSpaceButton
                        ref="addRecipient-button"
                        type="secondary"
                        icon="edit"
                        class="margin-bottom-30"
                        :label="this.$t('editItemTxt')"
                        @click="showOrgStructureSelect"
                      />
                    </template>
                    <template v-slot:center>
                      <ProSpaceSimpleGrid
                        ref="recipientGrid"
                        getMethod="simple"
                        :headers="recipientGridHeaders"
                        :service="recipientService"
                        :limit="50"
                        :btnText="$t('viewMore')"
                        :deletable="
                          checkAccess(
                            'action-delete-ProSpaceSimpleGrid',
                            model
                          )
                        "
                        deletingFieldName="email"
                        :confirmTitle="$t('deleting')"
                        :confirmText="$t('questionDeleting')"
                        :confirmApplyText="$t('applyTxt')"
                        :confirmCancelText="$t('cancelInf')"
                        :afterConfirmText="$t('questionDeletingList')"
                        @delete="deleteFromFilter"
                      />
                    </template>
                  </ProSpaceGeoLayout>
                </template>
              </ProSpaceGeoLayout>
              <OrgStructureSelectModal
                ref="filterModal"
                v-model="showOrgStructureSelectModal"
                node-method="onlyMars"
                @close="closeOrgStructureSelectModal"
              />
            </template>
          </ProSpaceTabs>
          <ProSpaceFlashMessage
            v-if="isNonExistsUsers"
            v-model="isNonExistsUsers"
            type="error"
            icon="flash-error"
            :title="$t('nonExistsUsersTitle')"
            :applyTxt="null"
            :cancelTxt="null"
          >
            {{ $t('nonExistsUsersText') }}
          </ProSpaceFlashMessage>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceGeoLayout,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceFile,
  ProSpaceInputCalendar,
  ProSpaceIcon,
  ProSpaceInputChips,
  ProSpaceButton,
  ProSpaceSimpleGrid,
  ProSpaceExtendedTextArea,
  ProSpaceWarning,
  ProSpaceInputSwitch,
  ProSpaceTree,
  ProSpaceFlashMessage,
  Hint
} from "@prospace/prospace-components-library";
import { getAMorPM } from "@prospace/prospace-components-library/src/lib-assets/mixins/date";
import TimezoneConverter from "@prospace/prospace-components-library/src/lib-components/none-visual-components/timezoneConverter";
import moment from "moment";
import OrgStructureSelectModal from "@modals/OrgStructureSelectModal";
import DistributorSelectModal from "@modals/DistributorSelectModal";
import PositionSelectTree from "@trees/PositionSelectTree";
import { DocumentService } from "../../services/DocumentService.js";
import { DocumentAttachmentService } from "../../services/DocumentAttachmentService.js";
import { DistributorService } from "@masterdata/services/DistributorService.js";
import { DistributorOrgService } from "@masterdata/services/DistributorOrgService";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
import { DocumentViewService } from "../../services/DocumentViewService.js";
import { DocumentRecipientService } from "../../services/DocumentRecipientService";
import { ImportantDocumentUserService } from "../../services/ImportantDocumentUserService.js";
import { FileService } from "../../../../../Frontends/ProSpace/services/FileService.js";
import { BaseTypeService } from "../../../../../Services/BaseType/BaseTypeService.Frontend/services/BaseTypeService.js";
import DistributorHierarchySelectForm from '@forms/DistributorHierarchySelectForm.vue'
import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";
const _ = require("lodash");
import {
  useStatistic
} from "@composes"
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceTabs,
    ProSpaceTextBlock,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceFile,
    ProSpaceInputCalendar,
    ProSpaceIcon,
    ProSpaceInputChips,
    ProSpaceButton,
    OrgStructureSelectModal,
    ProSpaceSimpleGrid,
    ProSpaceExtendedTextArea,
    ProSpaceWarning,
    DistributorSelectModal,
    ProSpaceInputSwitch,
    ProSpaceTree,
    PositionSelectTree,
    ProSpaceFlashMessage,
    DistributorHierarchySelectForm
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  directives: {
    hint: Hint
  },
  data() {
    return {
      // Tabs
      modelTab: { name: this.$t("parameters"), isActive: true, systemName: "parameters" },

      // Common
      model: null,
      loading: false,
      importantLoading: false,
      attachments: [],
      attachmentsToCreate: [],
      tags: [],
      positions: [],

      // Service
      documentService: new DocumentService(),
      documentAttachmentService: new DocumentAttachmentService(),
      distributorService: new DistributorService(),
      distributorStructureService: new DistributorStructureService(),
      documentViewService: new DocumentViewService(),
      baseTypeService: new BaseTypeService(),
      recipientService: new DocumentRecipientService(),
      fileService: new FileService(),
      importantDocumentUserService: new ImportantDocumentUserService(),

      getMethod: "get",
      masterId: null,

      toolbarOptions: {
        toolbar: [
          "bold",
          "italic",
          "underline",
          { color: [] },
          { list: "ordered" },
          { list: "bullet" },
          { align: [] },
        ],
      },

      //Recipients
      showOrgStructureSelectModal: false,
      showDistributorSelectModal: false,
      isAllChangedMessage: false,
      isNonExistsUsers: false,
      needCancelIsAll: true,
      readDistributorModal: false,
      afterDistributorStructureEdit: false,
      nodeFilter: {
        add: {},
        remove: {},
      },
      gridFilter: {
        add: {},
        remove: {},
      },
      oDataStringFilter: "",
      selectedTreeNodes: {},
      recipientGridHeaders: [
        { name: this.$t("type"), value: "recordTypeLocale" },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
        {
          name: this.$t("email"),
          value: "email",
          f: (item) => item,
        },
      ],
      distributorGridHeaders: [
        {
          name: this.$t("code"),
          value: "code",
          f: (item) => item,
        },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
      ],

      // to switch between view data and persist
      persistRecipients: true,
    };
  },
  validations() {
    return {
      model: {
        type: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        status: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
        },
        title: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
        },
        message: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        publisher: {},
        author: {},
        tags: {},
        isHighPriority: {},
        attachments: {},
      },
    };
  },
  mounted() {
    this.loadRoleAccess();
    this.mountAccess();
    this.masterId = this.$route.params.id;
    this.getData();
    this.recipientService.filter = this.recipientGridFilter;
    if (this.masterId)
      this.$store.dispatch("notifications/updateReadedRecord", {
        title: "Documents",
        id: Number(this.masterId),
      });
  },
  methods: {
    getAMorPM,
    handlerSelectTab({ selectedTab }) {
      this.mountAccess()
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    async getData() {
      if (this.masterId === undefined) {
        this.model = {
          type:
            this.$route.query &&
            this.$route.query.code &&
            this.$route.query.code != "all"
              ? this.$route.query.code
              : null,
          isHighPriority: false,
          isImportant: false,
          publisher: "",
          author: "",
          attachments: [],
          distributorIds: [],
          distributorRequestModel: {
            filter: "",
            records: []
          },
          distributorStructures: [],
          isDistributorStructureRestricted: false,
          createdBy: this.userinfo.code,
        };
        return;
      }
      const result = await this.documentViewService[this.getMethod](
        this.masterId
      );
      this.model = result.data;
      this.positions = [...this.model.positions || []];
      this.distributorStructureService.setDocId(this.model.id);
      this.distributorStructureService.setNodeViewIds(this.model.distributorStructures.map(x => x.distributorStructureViewId));
      this.$store.dispatch("notifications/updateReadedRecord", {
        title: "Documents",
        id: "documentPublishStaticId",
        realId: Number(this.model.id),
        type: this.model.type,
        groupUpdate: true
      });
      this.$store.dispatch("notifications/updateReadedRecord", {
        title: "Documents",
        id: "documentNewStaticId",
        realId: Number(this.model.id),
        type: this.model.type,
        groupUpdate: true
      });

      this.model.needFilter = false;
      try {
        let filter = JSON.parse(this.model.filter);
        this.nodeFilter = filter.nodeFilter;
        this.gridFilter = filter.gridFilter;
        this.feelNeedFilter();
      } catch {}
      try {
        this.selectedTreeNodes = JSON.parse(this.model.nodeSelection);
      } catch {}

      this.tags = result.data.tags.split("#").filter((t) => !!t);

      if (this.model.attachments)
        this.attachments = this.model.attachments.map((f) => {
          let r = new File([], f.fileName);
          r.gfid = f.gfid;
          r.fileLength = f.fileLength;
          r.statusFlag = f.statusFlag;
          return r;
        });
    },
    saveDraft() {
      this.setModelAndSave("Черновик", "Draft");
    },
    publish() {
      if (this.model.isPersonal && this.model.isDistributorStructureRestricted) {
        this.loading = true;
        this.positions.forEach(x => {
          x["documentId"] = this.model.id;
          x["leaf"] = !this.positions.some(p => p.parentId === x.distributorOrgId);
        });
        this.model.positions = this.positions;

        this.documentService.validateUsersExists(this.model).then(res => {
          if(!res || res.data === false) {
            this.isNonExistsUsers = true;
            this.loading = false;
          } else if (!res || res.errors) {
            this.loading = false;
          } else {
            this.setModelAndSave("Публикация", "Publication");
          }
        });
      } else {
        this.setModelAndSave("Публикация", "Publication");
      }
    },
    showEditDistributorsModalReadOnly() {
      this.readDistributorModal = true;
    },
    setModelAndSave(status, statusCode) {
      this.loading = true;
      this.setModel(status, statusCode).then((response) => {
        let newFiles = this.setNewAttachments(response.data.data);
        this.save(newFiles);
      });
    },
    save(newFiles) {
      const isCreate = !this.masterId;
      let saveMethod = isCreate ? "create" : "update";
      this.model.filter = JSON.stringify({
        nodeFilter: this.nodeFilter,
        gridFilter: this.gridFilter,
      });
      this.model.nodeSelection = JSON.stringify(this.selectedTreeNodes);
      this.model.distributorIds = !this.model.isAllDistributors ? this.model.distributorIds : [];
      this.documentService[saveMethod](this.model).then((result) => {
        this.model.id = result.data[0].id;
        if (newFiles.length > 0) {
          this.upload(newFiles).then(() => {
            this.afterSave(result, isCreate);
          })
          .catch(() => {
            this.afterSave(result, isCreate);
          });
        } else {
          this.afterSave(result, isCreate);
        }
      })
      .catch(() => {
        this.loading = false;
      });
    },
    afterSave(result, isCreate) {
      if (isCreate) {
        this.masterId = result.data[0].id;
      }
      this.getData();
      this.mountAccess();
      this.persistRecipients = true;
      this.afterDistributorStructureEdit = false;
      this.recipientService.fromView = false;
      this.recipientService.filter = this.recipientGridFilter;
      this.loading = false;
    },
    async upload(newFiles) {
      await this.fileService.uploadChunks(newFiles, this.attachmentsToCreate, this.uploadSuccess, this.uploadError);
      this.attachmentsToCreate = [];
    },
    async uploadSuccess(gfids) {
      await this.documentAttachmentService.acceptFiles(gfids, this.model.id);
    },
    async uploadError(gfids) {
      await this.documentAttachmentService.errorFiles(gfids, this.model.id);
    },
    setModel(status, statusCode) {
      this.model.tags = this.tags.join("#");
      this.model.status = status;
      this.model.statusCode = statusCode;

      if(this.model.isPersonal) {
        this.positions.forEach(x => {
          x["documentId"] = this.model.id;
          x["leaf"] = !this.positions.some(p => p.parentId === x.distributorOrgId);
        })
        this.model.positions = this.positions;
      } else {
        this.model.positions = [];
      }

      this.attachmentsToCreate = this.attachments.filter((f) => !f.gfid);
      const actualFiles = this.attachments
        .filter((f) => f.gfid)
        .map((f) => f.gfid);
      if (this.model.attachments)
        this.model.attachments = this.model.attachments.filter((f) =>
          actualFiles.includes(f.gfid)
        );
      if (this.attachmentsToCreate.length > 0) {
        let fileInfos = this.attachmentsToCreate
          .filter((f) => !f.id)
          .map((f) => {
            return {
              fileName: f.name,
              fileVisibleFlag: 0,
            };
          });
        return this.fileService.createFilesRoot(fileInfos);
      }
      return new Promise((resolve, reject) => {
        resolve({ data: { data: [] } });
      });
    },
    saveIsImportant() {
      this.importantLoading = true;
      let isSet = !this.model.isImportant;
      let method = isSet ? "create" : "delete";

      let importantModel = {
        documentId: this.model.id,
        securityUserCode: this.userinfo.code,
      };

      this.importantDocumentUserService[method](importantModel)
        .then((response) => {
          this.model.isImportant = isSet;
        })
        .finally(() => {
          this.importantLoading = false;
        });
    },
    setNewAttachments(attachments) {
      let newFiles = attachments.map((f, i) => {
        return {
          fileName: f.fileName,
          gfid: f.gfid,
          fileLength: this.attachmentsToCreate[i].size,
        };
      });
      this.model.attachments = this.model.attachments.concat(newFiles);
      return newFiles;
    },
    backHandler() {
      this.$router.go(-1);
    },
    getDate(val) {
      const userTimeZone = JSON.parse(localStorage.getItem('timeZoneId'));
      if (userTimeZone) {
        const dateWithOffset = this.getDateWithOffsetAccordingToUserTimeZoneId(val, userTimeZone); 
        return moment.parseZone(dateWithOffset).format(
          `${this.userinfo.dateFormat} ${this.userinfo.timeFormat}`
        );
      } 
      else {
        return moment(val).format(
          `${this.userinfo.dateFormat} ${this.userinfo.timeFormat}`
        );
      }
    },
    getDateWithOffsetAccordingToUserTimeZoneId(val, userTimeZone) {
      const currentUserTimezone = TimezoneConverter.parseTimeZoneOffset(userTimeZone);
      const minCurrentUserTimeZoneOffset = TimezoneConverter.convertH2M(currentUserTimezone, ':');
      const result = TimezoneConverter.tzCorrector(moment(val), minCurrentUserTimeZoneOffset, false);
      return result;
    },
    getRecipientName(record) {
      return (
        record.mailingGroupName ||
        "" +
          `${record.securityUserFirstName || ""} ${
            record.securityUserLastName || ""
          }`
      );
    },
    getRecipientEmail(record) {
      return record.mailingGroupEmail || "" + record.securityUserEmail || "";
    },
    showOrgStructureSelect() {
      this.updateSelectedTreeNodes();
      this.$refs.filterModal.nodeFilter = { ...this.nodeFilter };
      this.$refs.filterModal.gridFilter = { ...this.gridFilter };
      this.$refs.filterModal.selectedTreeNodes = { ...this.selectedTreeNodes };
      this.$nextTick(() => {
        this.showOrgStructureSelectModal = true;
      });
    },
    showDocumentDistributor() {
      this.showDistributorSelectModal = true;
    },
    showIsAllChangedMessage() {
      this.isAllChangedMessage = true;
    },
    applyIsAllChangedMessage() {
      this.needCancelIsAll = false;
      this.model.distributorIds = [];
      this.updateDistributorGrid(true);
    },
    cancelIsAllChangedMessage(val) {
      if(!(val || this.needCancelIsAll)) {
        this.needCancelIsAll = true;
        return;
      }

      this.model.isAllDistributors = false;
    },
    closeOrgStructureSelectModal() {
      // баг с применением
      this.nodeFilter = { ...this.$refs.filterModal.nodeFilter };
      this.gridFilter = { ...this.$refs.filterModal.gridFilter };
      this.selectedTreeNodes = {
        ...this.$refs.filterModal.$refs.tree.checkedNodes,
      };
      this.oDataStringFilter = this.convertFilterToOdata();
      Object.values(this.nodeFilter).forEach((o) => delete o.children);
      this.model.filter = JSON.stringify({
        nodeFilter: this.nodeFilter,
        gridFilter: this.gridFilter,
      });
      this.model.nodeSelection = JSON.stringify(this.selectedTreeNodes);
      this.model.oDataFilter = this.oDataStringFilter;
      this.persistRecipients = false;
      this.recipientService.fromView = true;
      this.recipientService.filter = this.recipientGridFilter;
      this.$refs.recipientGrid.records = [];
      this.feelNeedFilter();
      if (this.model.needFilter) this.$refs.recipientGrid.getData();
      this.showOrgStructureSelectModal = false;
    },
    async applyDistributorSelectModal({items, requestModel}, isAll) {
      this.model.isAllDistributors = isAll;
      if (!isAll) {
        this.model.distributorRequestModel = requestModel;
        if (requestModel && requestModel.filter) {
          let distributors = await this.distributorService.getByMultiple(requestModel);
          this.model.distributorIds = distributors.data.records.map(x => x.id);
        } else {
          this.model.distributorIds = items;
        }
      } else {
        this.model.distributorIds = [];
      }
      this.$nextTick(() => {
        this.updateDistributorGrid(isAll);
      });
    },
    applyDistributorStructure() {
      this.afterDistributorStructureEdit = true;
    },
    isAllDistributorsChanged(val) {
      if(!val)
        this.updateDistributorGrid(val);
      else
        this.showIsAllChangedMessage();
    },
    updateDistributorGrid(isAll) {
      if(!this.$refs.distributorGrid)
        return;

      this.$refs.distributorGrid.records = [];
      if(!isAll)
        this.$refs.distributorGrid.getData();
    },
    convertFilterToOdata() {
      // ( ((orgId in included nodes) && (orgId not in excluded nodes)) || (userid in included users) ) && (userId not in excluded users)
      const nodeAdd = Object.values(this.nodeFilter.add);
      const usersAdd = Object.values(this.gridFilter.add);
      const nodeRemove = Object.values(this.nodeFilter.remove);
      const usersRemove = Object.values(this.gridFilter.remove);
      let nodeAddStrings = "";
      let usersAddStrings = usersAdd
        .map((e) => `(recordTypeId eq '${e.recordType + "-" + e.id}')`)
        .join(" or ");
      let usersRemoveStrings = usersRemove
        .map((e) => `(recordTypeId ne '${e.recordType + "-" + e.id}')`)
        .join(" and ");
      let getIdFilter = (n, o) => {
        if (n.type === "RealDistributor")
          return `and distributorId ${o} ${n.id}`;
        if ([9223372036854775000, -9223372036854775000].includes(n.id))
          return `and orgId ${o} null`;
        return `and orgId ${o} ${n.id}`;
      };
      let nodeRemoveStrings = nodeRemove
        .map((e) => `(type eq '${e.type}' ${getIdFilter(e, "ne")})`)
        .join(" and ");
      if (!nodeAdd.find((e) => e.key === "all"))
        nodeAddStrings = nodeAdd
          .map((e) => `(type eq '${e.realType}' ${getIdFilter(e, "eq")})`)
          .join(" or ");

      let filter = "";
      if (nodeAddStrings || nodeRemoveStrings)
        filter =
          "(" +
          [nodeAddStrings, nodeRemoveStrings]
            .filter((e) => e)
            .map((e) => `(${e})`)
            .join(" and ") +
          ")";

      if (usersAddStrings) {
        if (filter) filter = `${filter} or `;
        filter = `${filter} (${usersAddStrings})`;
      }

      if (usersRemoveStrings) {
        if (filter) filter = `(${filter}) and `;
        filter = `${filter} (${usersRemoveStrings})`;
      }
      if (filter) filter = "$filter=" + filter;
      return filter;
    },
    deleteFromFilter(item) {
      this.$refs.recipientGrid.records =
        this.$refs.recipientGrid.records.filter(
          (r) => !(r.id === item.id && r.recordType === item.recordType)
        );
      if (this.$refs.recipientGrid.records.length === 0) {
        this.nodeFilter = {
          add: {},
          remove: {},
        };
        this.gridFilter = {
          add: {},
          remove: {},
        };
      } else {
        let key = `${item.id}|${item.recordType}`;
        if (Object.keys(this.gridFilter.add).includes(key)) {
          delete this.gridFilter.add[key];
        } else {
          this.gridFilter.remove[key] = item;
        }
      }
      this.oDataStringFilter = this.convertFilterToOdata();
      this.model.oDataFilter = this.oDataStringFilter;
      this.persistRecipients = false;
      this.recipientService.fromView = true;
      this.recipientService.filter = this.recipientGridFilter;
      this.feelNeedFilter();
    },
    deleteFromDistributors(item) {
      this.$refs.distributorGrid.records =
        this.$refs.distributorGrid.records.filter((r) => r.id !== item.id);

      this.model.distributorIds = this.model.distributorIds.filter(
        (r) => r !== item.id
      );
    },
    updateSelectedTreeNodes() {
      if (
        Object.keys(this.nodeFilter.add).length === 0 &&
        Object.keys(this.gridFilter.add).length === 0
      ) {
        if (this.selectedTreeNodes) {
          delete this.selectedTreeNodes["all"];
        }
        return;
      }
      const fullRecords = this.$refs.recipientGrid.records;
      const listTreeNodes = {
        all: {
          checked: false,
          partialChecked: true,
        },
      };

      fullRecords.forEach((r) => {
        const keys = r.fullPath.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      });
      Object.values(this.nodeFilter.add).forEach((n) => {
        const keys = n.path.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      });
      // check=true all nodeFilter.add and children
      // баг если дочерние check, parent uncheck, см свой чат
      // listTreeNodes добавлять из через this.nodeFilter.add (нет некоторых узлов)
      const checkChildren = (parentKey) => {
        const children = Object.entries(listTreeNodes)
          .filter(([k, v]) => v.parentKey === parentKey)
          .map(([k]) => k);
        if (children.length === 0) {
          return;
        }
        children.forEach((k) => {
          checkChildren(k);
          listTreeNodes[k] = {
            checked: true,
            partialChecked: false,
            parentKey: parentKey,
          };
        });
        return;
      };
      Object.entries(this.nodeFilter.add).forEach(([key, value]) => {
        listTreeNodes[key] = {
          checked: true,
          partialChecked: false,
          parentKey: value.parentId,
        };
        checkChildren(key);
      });

      // partialChecked=true if parent has removed child
      const removesParentKeys = [];
      Object.values(this.gridFilter.remove).forEach((val) => {
        const arr = val.fullPath.split("|");
        const parentKey = arr[arr.length - 2];
        if (!removesParentKeys.includes(parentKey)) {
          removesParentKeys.push(parentKey);
        }
      });
      const uncheckParent = (childKey) => {
        const child = listTreeNodes[childKey];
        if (!child) {
          return;
        }
        if (child.checked) {
          child.checked = false;
          child.partialChecked = true;
        }
        const parent = listTreeNodes[child.parentKey];
        if (!parent) {
          return;
        }
        if (parent.checked) {
          parent.checked = false;
          parent.partialChecked = true;
          uncheckParent(parent.parentKey);
          return;
        }
        return;
      };
      removesParentKeys.forEach(uncheckParent);

      this.selectedTreeNodes = listTreeNodes;
    },
    feelNeedFilter() {
      this.model.needFilter =
        Object.keys(this.nodeFilter.add).length > 0 ||
        Object.keys(this.nodeFilter.remove).length > 0 ||
        Object.keys(this.gridFilter.add).length > 0 ||
        Object.keys(this.gridFilter.remove).length > 0;
      this.recipientService.needFilter = this.model.needFilter;
    },
    isDistributorStructureRestrictedChange(val) {
      if(val) {
        this.model.isAllDistributors = false;
        this.model.distributorIds = [];
      } else {
        this.model.distributorStructures = [];
      }
    }
  }, 
  computed: {
    isFilial() {
      return this.model.isDistributorStructureRestricted && !this.checkAccess("isFilial-Expression", this.model);
    },
    disabledSave() {
      return this.v$.model.$invalid;// || _.isEqual(this.model, this.startModel);
    },
    disabledPublished() {
      let distributorsNotChosen = true;
      if(this.model.isDistributorStructureRestricted)
        distributorsNotChosen = this.model.distributorStructures.length === 0;
      else
        distributorsNotChosen = !this.model.isAllDistributors && this.model.distributorIds.length === 0;
      const positionsNotChosen = this.model.isPersonal && !(this.positions && this.positions.length > 0);
      return this.v$.model.$invalid || distributorsNotChosen || positionsNotChosen;
    },
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
    }),
    refsForCheckInMixin() {
      let refs = [];

      if(this.modelTab.systemName === "parameters")
        refs.push(
          "type-ProSpaceDropdown",
          "title-ProSpaceInputText",
          "message-ProSpaceEditor",
          "attachments-ProSpaceFile",
          "tags-ProSpaceInputChips",
          "author-ProSpaceTextBlock",
          "isPersonal-ProSpaceInputSwitch"
        );
        
      if (this.modelTab.systemName === "distributors") {
        if(this.model && this.model.isDistributorStructureRestricted)
          refs.push("distributorStructures-DistributorHierarchySelectForm");
        else
          refs.push(
            "addRecipient-button",
            "isAllDistributors-ProSpaceInputSwitch"
          );
      }

      if (this.modelTab.systemName === "notifications")
        refs.push("addRecipient-button");

      if(!this.isPublished)
        refs.push("action-savedraft");

      if(!this.isCreation)
        refs.push("action-publish");

      return refs;
    },
    recipientGridFilter() {
      if (this.persistRecipients)
        return `$filter=DocumentId eq ${Number(
          this.masterId
        )} and disabled eq false`;
      return this.oDataStringFilter;
    },
    distributorFilter() {
      return { id: { or: this.model.distributorIds } };
    },
    overviewTabs() {
      return [
        {
          name: this.$t("parameters"),
          systemName: "parameters",
          isActive: this.modelTab.systemName === "parameters",
        },
        {
          name: this.isFilial || this.model.isDistributorStructureRestricted ? this.$t('branches') : this.$t("distributors"),
          systemName: "distributors",
          isDisabled: this.isCreation,
          isHidden: !this.isFilial && !this.checkAccess("tab-distributors-ProSpaceTabs", this.model),
          isActive: this.modelTab.systemName === "distributors"
        },
        {
          name: this.$t("positions"),
          systemName: "positions",
          isDisabled: this.isCreation,
          isHidden: !this.model.isPersonal,
          isActive: this.modelTab.systemName === "positions"
        },
        {
          name: this.$t("documentNotificationsMars"),
          systemName: "notifications",
          isDisabled: this.isCreation,
          isHidden: !this.checkAccess("tab-notifications-ProSpaceTabs", this.model),
          isActive: this.modelTab.systemName === "notifications",
        },
      ];
    },
    layoutName() {
      return this.masterId
        ? this.model.title
        : this.$t("newTxt") + " " + this.$t("document");
    },
    isPublished() {
      return this.model && this.model.statusCode === "Publication";
    },
    isCreation() {
      return !(this.model && this.model.id);
    },
    positionOptions() {
      let options = {
        positionService: null,
        positionGetMethod: null,
        positionSelectionMode: null,
        positionFilter: null,
        propLabel: null
      }

      if(this.checkAccess("position-PositionSelectTree", this.model)) {
        options.positionService = new DistributorOrgService();
        options.positionGetMethod = "allTree";
        options.positionSelectionMode = "checkbox";
        options.propLabel = "name";
      } else {
        let positionService = new DocumentViewService();
        positionService.documentId = this.model.id;
        options.positionService = positionService;
        options.positionGetMethod = "getPositions";
        options.positionSelectionMode = "single";
        options.propLabel = "distributorOrgName";
        options.positionFilter = { disabled: { and: [false] }, documentId: this.model.id };
      }

      return options;
    }
  },
};
</script>
<style lang="scss">
.document {
  .important-button {
    font-size: 13px;
    line-height: inherit;
  }
}
</style>
