<template>
  <ProSpaceVLayout :gap="20">
    <ProSpaceInputText
      v-model="model.name"
      :label="$t('dashboardName')"
      :placeholder="$t('dashboardName')"
      :invalid="v$.model.name.$error"
      :invalid-messages="v$.model.name.$errors"
      @blur="v$.model.name.$touch"
    />
    <ProSpaceDropdown
      v-model="model.section"
      :label="$t('dashboardSection')"
      :placeholder="$t('dashboardSection')"
      :invalid="v$.model.section.$error"
      :invalid-messages="v$.model.section.$errors"
      @blur="v$.model.section.$touch"
      :service="baseTypeService"
      getMethod="grid"
      propValue="code"
      propLabel="name"
      argMethod="$filter=systemDirectoryCode eq 'DashboardSection' and disabled eq false"
      @change="
                        (item) =>
                          (model.section =
                            item && item.code ? item.code : null)
                      "
    />
    <ProSpaceInputText
      isTextarea
      v-model="model.description"
      :label="$t('dashboardDescription')"
      :placeholder="$t('dashboardDescription')"
      :invalid="v$.model.description.$error"
      :invalid-messages="v$.model.description.$errors"
      @blur="v$.model.description.$touch"
    />
  </ProSpaceVLayout>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers, minValue, maxValue } from "@vuelidate/validators";
import { BaseTypeService } from "../../../../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService.js";

export default {
  name: "DashboardBaseInfoForm",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      model: {
        name: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", { maxLength: 100 }),
            maxLength(100)
          ),
        },
        section: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", { maxLength: 255 }),
            maxLength(255)
          ),
        },
        description: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", { maxLength: 3000 }),
            maxLength(3000)
          ),
        }
      },
    };
  },
  data() {
    return {
      model: null,
      baseTypeService: new BaseTypeService(),
    };
  }
}
</script>

<style scoped>

</style>
