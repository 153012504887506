import { createI18n } from "vue-i18n";
const _ = require("lodash");

const req = require.context("./i18n-services", true, /.json$/);
const messages = {
  en: {
    actions: "Actions",
    fio: "First, last and second names",
    yes: "Yes",
    no: "No",
    id:"ID",
    rolesEmpty: "You have no one role",
    selectRoleForEnter: "Select role for enter",
    topics: "Topics",
    language: "Language",
    save: "Save",
    saveAll: "Save all",
    export_csv: "Export CSV",
    export_xlsx: "Export XLSX",
    up: "Up",
    down: "Down",
    changes: "Changes",
    fields: "Fields",
    field: "Field",
    fieldValue: "Field value",
    fieldName: "Field name",
    systemName: "System name",
    changeDateTime: "Change Date/Time",
    operation: "Operation",
    operations: "Операции",
    changeHistory: "Change History",
    history: "History",
    userProfile: "User profile",
    currentPassword: "Current password",
    currentRole: "Current Role",
    change: "Change",
    newPassword: "New password",
    activityPeriod: "Activity Period",
    activityPeriodF: "Activity period",
    addItemTxt: "Add",
    description: "Description",
    steps: "Steps",
    version: "Version",
    title: "Title",
    group: "Group",
    searchTxt: "Search",
    selectTxt: "Select",
    selectTxt2: "Choose",
    filterBy: "Filter by",
    editItemTxt: "Edit",
    createItemTxt: "Create",
    closeTxt: "Close",
    cancelTxt: "Cancel",
    cancelInf: "Cancel",
    selectAllTxt: "Select All",
    applyTxt: "Apply",
    confirmTxt: "Confirm",
    resetTxt: "Reset",
    choose: "Choose",
    clearFilterTxt: "Clear",
    clearTxt: "Clear",
    customFilterTxt: "Custom Filter",
    newTxt: "New",
    newTxtF: "New",
    newTxtT: "New",
    next: "Next",
    metaInfTxt: "Meta information",
    detailTxt: "Detail Text",
    allTxt: "All",
    allTxtT: "ALL",
    recentlyTxt: "Recently added",
    recentlyViewed: "Recently viewed",
    deleted: "Deleted",
    unacted: "Unacted",
    overdue: "Overdue",
    delete: "Delete",
    deleting: "Deleting",
    record: "Record",
    deletingPosition: "Deleting a position",
    questionDeletingPosition: "Are you sure you want to delete the item",
    deletingConfirmation: "Confirmation of deletion",
    deletingContactUser: "Deleting a contact person",
    deletingRole: "Deleting a role",
    deletingClaim: "Deleting a claim",
    questionDeletingContactUser: "Are you sure you want to delete a contact person",
    questionDeleting: "Are you sure you want to delete a ",
    questionDeletingList: " from the list of recipients",
    open: "Open",
    created: "Created",
    createdByTxt: "Created by",
    updatedByTxt: "Updated by",
    createdAtTxt: "Created at",
    createdDate: "Creation date",
    lastUpdatedTxt: "Last updated",
    fromTxt: "from",
    toTxt: "to",
    settingsTxt: "Settings",
    systemSettings: "System settings",
    overview: "Overview",
    roles: "Roles",
    role: "Role",
    roleLower: "role",
    localization: "Localization",
    firstName: "First name",
    secondName: "Second name",
    lastName: "Last name",
    email: "E-mail",
    browser: "Browser",
    node: "Node",
    nodes: "Nodes",
    holderName: "Holder name",
    holderEmail: "User e-mail",
    loginType: "Login type",
    loginWith: "Login with",
    recipients: "Recipients",
    recipient: "Recipient",
    users: "Users",
    user: "User",
    userLower: "user",
    userCreateLower: "user",
    useCases: "Use cases",
    useCase: "Use case",
    useCaseLower: "use case",
    status: "Status",
    custom: "Custom",
    other: "Other",
    customContacts: "Custom contacts",
    name: "Name",
    nameL: "Name",
    notifications: "Notifications",
    notification: "Notification",
    notificationLower: "notification",
    events: "Events",
    event: "Event",
    workflow: "Workflow",
    workflowLower: "workflow",
    mainInformation: "Main information",
    emptyFilter: "No results found",
    mainTab: "Main",
    userInformation: "User name and other <br>contact information",
    organization: "Organization",
    organizationalInformation:
      "Organizational structure<br>that user belongs to",
    additionalInformation: "Additional information",
    additionalInformationInformation:
      "Any other information that may<br>help to identify user",
    date: "Date",
    dateInformation: "Any other information that may<br>help to identify user",
    lastLoginDate: "Last login date",
    lastLoginDateInformation:
      "Any other information that may<br>help to identify user",
    lastLoginDateF: "Last login date",
    jobTitle: "Job Title",
    comments: "Comments",
    dateFormat: "Date format",
    dateTimeFormat: "Date time format",
    timeZone: "Time zone",
    timeFormat: "Time format",
    changePasswordB: "Change password",
    resetPasswordB: "Reset password",
    profile: "Profile",
    permissions: "Permissions",
    menuItems: "Menu items",
    menuItemL: "menu item",
    restrictions: "Restrictions",
    apiModels: "Api models",
    domainModels: "Domain models",
    itemsSelected: "Total selected",
    total: "Total",
    roleProfile: "Role profile",
    identification: "Identification",
    identificationInformation: "Role name and other<br> contact information",
    usecaseName: "Use case name",
    usecaseTitle: "Use case title",
    usecaseDescription: "Usecase description",
    usecaseSampleDescription: "Usecase sample description",
    value: "Value",
    addValue: "Add value",
    added: "Added",
    excluded: "Excluded",
    noDataTitle: "Nothing to show yet",
    noDataTxt: "There are no records here yet",
    noData: "No data",
    claims: "Claims",
    claim: "Claim",
    useCaseInformation: "Use case name and description",
    usecaseActivityPeriodInformation: "Pediod activity of use case",
    notificationType: "Notification type",
    ruleOptions: "Rule options",
    searchIn: "Search In",
    body: "Body",
    hasAttach: "Has attach",
    notificationMainInformationInformation:
      "Sample text block form here may<br>be different text block description",
    notificationActivityPeriodInformation: "Sample",
    notificationEventsInformation: "Sample",
    notificationNotificationTypeInformation: "Sample",
    notificationRuleOptionsInformation: "Sample",
    notificationRecipientsInformation: "Sample",
    expand: "Expand",
    collapse: "Collapse",
    step: "Step",
    workflowMainInformationInformation: "Workflow main information",
    workflowName: "Workflow name",
    workflowDescription: "Workflow description",
    activateWorkflow: "Activate",
    stepOptions: "Step options",
    stepNumber: "Step number",
    stepType: "Step type",
    stepName: "Step name",
    stepStatus: "Step status",
    condition: "Condition",
    onSuccess: "On Success",
    onSuccessF: "On success",
    onFailure: "On Failure",
    onFailureF: "On failure",
    parameters: "Parameters",
    parametersStep: "Parameters step",
    parameter: "Parameter",
    selectStepType: "Select step type",
    filter: "Filter",
    selectStep: "Select step",
    repeatPassword: "Repeat password",
    password: "Password",
    enterPassword: "Enter password",
    login: "Login",
    enterLogin: "Enter login",
    active: "Active",
    blocked: "Blocked",
    new: "New",
    update: "Update",
    statusActive: "Active",
    statusBlocked: "Blocked",
    statusNew: "New",
    statusInactive: "Inactive",
    activate: "Active",
    paied: "Paied",
    draft: "Draft",
    inactive: "Inactive",
    code: "Code",
    workflowUniqueCode: "Workflow unique code",
    taskType: "Type",
    taskName: "Name",
    execution: "Execution",
    taskResult: "Task result",
    runResult: "Run result",
    taskLog: "Task log",
    downloadLog: "Download log",
    runAgain: "Run again",
    complete: "Complete",
    completeS: "Complete",
    error: "Error",
    errors: "Errors",
    warning: "Warning",
    warnings: "Warnings",
    fatal: "Fatal error",
    infos: "Infos",
    systemTasks: "System tasks",
    backgroundTasks: "Background tasks",
    systemTaskLog: "Task log",
    import: "Import",
    startedImport: "Started",
    finishedImport: "Finished",
    nonActive: "Not active",
    task: "Task",
    taskY: "Task",
    tasks: "Tasks",
    taskCreate: "Create task",
    taskEdit: "Edit task",
    nextExecution: "Next execution",
    lastExecution: "Last execution",
    startExecution: "Start execution",
    endExecution: "End execution",
    executionTime: "Execution time",
    trigger: "Trigger",
    triggers: "Triggers",
    addTrigger: "Add triggers",
    editTrigger: "Edit triggers",
    systemLog: "System log",
    pause: "Pause",
    run: "Run",
    repeat: "Repeat",
    every: "Every",
    everyDay: "Every day",
    everyWeek: "Every week",
    everyMonth: "Every month",
    time: "Time",
    onTheDays: "On the days",
    startRepeat: "Start repeat",
    endRepeat: "End repeat",
    taskDetails: "Task details",
    taskDetailsInfo: "Choose type task and write description.",
    repeatOptions: "Repeat options",
    scheduling: "Scheduling",
    lastStart: "Last start",
    nextStart: "Next start",
    once: "Once",
    second: "Second",
    minute: "Minute",
    hour: "Hour",
    day: "Day",
    week: "Week",
    month: "Month",
    sun: "Sun",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    path: "Path",
    icon: "Icon",
    order: "Order",
    parent: "Parent",
    parentField: "Parent field",
    add: "Add",
    uploadFileDragAndDropText: "Drag and drop files to here to upload",
    uploadStatusError: "File upload error",
    uploadFile: "Upload file",
    upload: "Upload",
    sourceFile: "Source file",
    importTemplate: "Import template",
    jobResult_ExportedCount: "rows exported",
    jobResult_ExportResultFile: "Export results",
    jobResult_SourceCount: "rows in source file",
    jobResult_ErrorsCount: "errors",
    jobResult_ImportedCount: "rows imported",
    jobResult_ErrorsCSV: "Download errors",
    jobResult_UnknownFile: "Download file",
    jobResult_UnknownText: "",
    jobParameters_ImportFile: "Download import file",
    jobResult_ShowErrors: "show errors",
    jobParameters_showModalParameters: "Show parameters",
    importSourceCount: "rows in source file",
    importErrorsCount: "import errors",
    importedCount: "rows imported",
    dashboard: "Dashboard",
    promo: "Promo",
    marketing: "Marketing",
    lock: "Lock",
    boxes: "Boxes",
    copy: "Copy",
    copyAction: "Copy",
    to: "To",
    BCC: "BCC",
    viewMore: "View more",
    showAll: "View All",
    authorIncludes: "Author (includes)",
    createDate: "Create date",
    editDate: "Edit date",
    startedAt: "Started at",
    finishedAt: "Finished at",
    userType: "User type",
    mainInfo: "User info",
    parentPosition: "Supervisor",
    mars: "Mars",
    Mars: "Mars",
    legalAddress: "Legal address",
    mailAddress: "Mail address",
    phoneNumber: "Phone number",
    faxNumber: "Fax number",
    emailAddress: "E-mail address",
    Distributor: "Distributor",
    MailingGroup: "Mailing group",
    User: "User",
    link: "Link",
    orgLinkInfo: "User type",
    orgLinkInfoSub: "User type and position",
    orgStructure: "Organizational structure",
    orgStructureFilial: "Organizational structure by branches",
    support: "Support",
    supportFrame: "Support online",
    maxLengthErrorMessage: "Maximum length exceeded: {maxLength} symbols",
    minLengthErrorMessage: "Enter the phone completely",
    maxValueErrorMessage: "Max value: ",
    minValueErrorMessage: "Min value: ",
    requiredFieldErrorMessage: "Required field",
    featureInProgress: "The functionality will be added later",
    notValidPassword: "Not a valid password",
    information: "Information",
    type: "Type",
    info: "Info",
    key: "Key",
    pictogram: "Pictogram",
    checkeds: "Checked",
    listDistributors: "List distributors",
    selectDistributors: "Select distributors",
    selectDistributor: "Select distributor",
    selectRecipients: "Select recipients",
    repeatTask: "Task repetition",
    repeatTaskTxt: "Task recurrence settings",
    duration: "Duration",
    notValidPasswordLength: "Password shall be more or equal than 12 characters",
    notValidPasswordAlpth: "Password must contain an English letter",
    notValidPasswordAlpthUpper: "Password must contain an English capital letter",
    validatePasswordNumbers: "Password must contain a number",
    validatePasswordSymbols: "Password must contain a special character",
    validatePasswordUserId: "Password cannot contain an user Id",
    validatePasswordUserLogin: "Password cannot contain an user Login",
    validatePasswordUserEmail: "Password cannot contain an user E-mail",
    validatePasswordUserFirstName: "Password cannot contain an user First name",
    validatePasswordUserLastName: "Password cannot contain an user Last name",
    validatePasswordUserSecondName: "Password cannot contain an user Second name",
    validatePasswordSpace: "Password cannot contain a space character",
    validatePasswordMDP: "Password cannot contain a MDP keywords",
    validatePasswordEnglish: "Password can contain only English characters",
    notValidLogin: "Login can contain English letters, numbers, dot, underscore",
    notValidEmail: "Value is not a valid e-mail address",
    company: "Company",
    nameOfBank: "Name of the bank",
    tags: "Tags",
    requestAccess: 'Request access',
    validations: {
      localRequired: "Required field",
      localMaxLength: "Maximum length exceeded",
      localEmail: "Value is not a valid e-mail address",
      localMarsValidate: "Required field",
      localDistrValidate: "Required field",
      localdistrStructureValidate: "Required field"
    },
    passwordChanged: "Password changed",
    publish: "Publish",
    publishTask: "Publish task",
    publishTaskTxt: "Do you want to publish a task?",
    publishedNews: "News published",
    publishedNewsTxt: "The news is displayed in the «News» section",
    publishNews: "News publication",
    publishNewsTxt: "Do you want to post news? Once a post has been published, it cannot be deleted.",
    needModerationNewsTxt: "The news is created without using a template, so it will be sent for moderation. <br>Moderation and Publication will be further performed by <i>Content Manager plus</i>.",
    saveDraft: "Save draft",
    taskDraft: "Draft",
    taskModer: "On moderation",
    taskPublished: "Published",
    taskApproving: "On approving",
    taskCanceled: "Canceled",
    taskComplete: "Complete",
    taskStatus: "Task status",
    moderation: "Moderation",
    moderationTxt: "Created without using a template",
    acceptPublish: "Accept publish",
    reject: "Reject",
    Reject: "Reject",
    rejectTask: "Reject the task",
    rejectModeration: "Reject publication",
    taskCompleteTxt: "Task completed",
    accept: "Accept",
    comment: "Comment",
    needModeration: "Moderation required",
    needModerationTxt: "The task is created without using a template, so it will be sent for moderation. <br>Moderation and publication will be further performed by<br><span class='text-color-accent'>Content Manager plus</span>.",
    send: "Send",
    performTask: "Complete the task",
    taskModerStep: "Отправил на модерацию",
    taskModerStepReject: "Отклонил публикацию",
    taskPublishedStep: "Опубликовал",
    taskPublishedStepReject: "Canceled publication",
    taskApprovingStep: "Подтвердил выполнение",
    taskApprovingStepReject: "Отклонил выполнение",
    taskCompleteStep: "Принял выполнение",
    addComment: "Add comment",
    rejectPublish: "Reject post",
    rejectTaskFlashTitle: "Task rejected",
    rejectTaskFlashTxt: "The task was returned to the executor for revision",
    distrCountWarning: "More than one distributor selected",
    distrCountWarningTxt: "Only one distributor must be selected to validate a selection",
    needRepeatTxt: "Task recurrence period selected",
    taskInProgress: "Task in progress",
    taskPublishedTxt: "Unperformed",
    taskApprovingTxt: "On approving",
    taskModerTxt: "Moderation",
    newsModerTxt: "Moderation",
    isExpiredTxt: "Overdue",
    taskDraftTxt: "Drafts",
    taskCanceledTxt: "Canceled",
    newTaskTxt: "New task",
    highImportants: "Important",
    drafts: "Drafts",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    download: "Download",
    newsModerStep: "Sent for moderation",
    rotateRight: "Rotate Right",
    rotateLeft: "Rotate Left",
    newsWaitingStep: "Confirmed the publication",
    newsPublishedStep: "Published",
    dateFormatDDMMYYYY: "DD.MM.YYYY",
    dateFormatMMDDYYYY: "MM.DD.YYYY",
    dateFormatYYYYMMDD: "YYYY.MM.DD",
    timeFormat12: "12 hour",
    timeFormat24: "24 hour",
    sideBarThemeTxt: "Theme",
    sideBarLanguageTxt: "Language",
    sideBarLogoutTxt: "Log out",
    sideBarStorageTxt: "Clear cache",
    select: "Select",
    seriesTask: "Series task",
    taskCancellationApprove: "Cancellation approve",
    editSeriesTask: "This is a recurring task.<br>Edit only this task or the entire range of tasks for the Distributor?",
    deleteSeriesTask: "This is a recurring task.<br>Delete only this task or the entire range of tasks for the Distributor?",
    cancelTask: "Are you shure want to cancel <br/>task <span style=\"color:#2196f3\">{name}</span>?",
    deleteTask: "Are you sure want to delete<br>the task <span style=\"color:#2196f3\">{name}</span>?",
    deleteTaskTemplate: "Are you sure want to delete<br>the template <span style=\"color:#2196f3\">{name}</span>?",
    deleteWorkflow: "Are you sure want to delete<br>the workflow <span style=\"color:#2196f3\">{name}</span>?",
    deleteWorkflowItem: "Are you sure want to delete<br>the step <span style=\"color:#2196f3\">{name}</span>?",
    deleteUser: "Are you sure want to delete:<br><span style=\"color:#2196f3\">{name}</span>?",
    deleteUsers: "Are you sure want to delete the selected users?",
    deleteUseCase: "Are you sure want to delete<br>the use case <span style=\"color:#2196f3\">{name}</span>?",
    deleteRole: "Are you sure want to delete<br>the role <span style=\"color:#2196f3\">{name}</span>?",
    deleteMenuItem: "Are you sure want to delete<br>the position <span style=\"color:#2196f3\">{name}</span>?",
    deleteNotification: "Are you sure want to delete<br>the notification <span style=\"color:#2196f3\">{name}</span>?",
    deleteNewsItemTemplate: "Are you sure want to delete<br>the template <span style=\"color:#2196f3\">{name}</span>?",
    deleteNewsItem: "Are you sure want to delete<br>the news item <span style=\"color:#2196f3\">{name}</span>?",
    deleteMailingGroup: "Are you sure want to delete<br>the mailing group <span style=\"color:#2196f3\">{name}</span>?",
    deleteDistributor: "Are you sure want to delete<br>the distributor <span style=\"color:#2196f3\">{name}</span>?",
    deleteUsefulLink: "Are you sure want to delete<br>the link <span style=\"color:#2196f3\">{name}</span>?",
    deleteFaq: "Are you sure want to delete<br>the faq <span style=\"color:#2196f3\">{name}</span>?",
    deleteDocument: "Are you sure want to delete<br>the document <span style=\"color:#2196f3\">{name}</span>?",
    deleteCompetenceDirectory: "Are you sure want to delete<br>the competence directory <span style=\"color:#2196f3\">{name}</span>?",
    deleteSystemDirectory: "Are you sure want to delete<br>the system directory <span style=\"color:#2196f3\">{name}</span>?",
    deleteBaseType: "Are you sure want to delete<br>the base type <span style=\"color:#2196f3\">{name}</span>?",
    deleteSystemTask: "Are you sure want to delete<br>the system task <span style=\"color:#2196f3\">{name}</span>?",
    deleteTrigger: "Are you sure want to delete<br>the trigger <span style=\"color:#2196f3\">{name}</span>?",
    deleteTaskRecipient: "Are you sure want to delete<br> <span style=\"color:#2196f3\">{name}</span> from recipients?",
    deleteReport: "Are you sure want to delete<br>the report <span style=\"color:#2196f3\">{name}</span>?",
    deleteDashboardIntegrationMatching: "Are you sure want to delete<br>the dashboard integration matching <span style=\"color:#2196f3\">{name}</span>?",
    singleTaskEdit: "Edit only this task",
    seriesTaskEdit: "Edit entire series of tasks",
    singleTaskDelete: "Delete only this task",
    seriesTaskDelete: "Delete entire series of tasks",
    portalDistr: "Distributor Portal",
    unreaded: "Unreaded",
    or: "or",
    loading: "Loading",
    returnToLoginPage: "Return to login page",
    main: "Main",
    historyMain: "Main",
    createdOperation: "Created",
    updatedOperation: "Updated",
    deletedOperation: "Deleted",
    theme: "Theme",
    logout: "Log out",
    taskStatus_new: 'New',
    taskStatus_created: 'Created',
    taskStatus_complete: 'Complete',
    taskStatus_error: 'Error',
    taskStatus_accepted: 'In progress',
    taskStatus_partialcomplete: 'Partial complete',
    taskStatus_is: 'is',
    loadFileProgress: "File download",
    loadFileDone: "Download completed",
    loadFileError: "Download error",
    txtResetFilters: "Reset all filters",
    dateMustBeLessThanEnd: "The start date must be less than the end date",
    dateMustBeGreaterThanStart: "The end date must be greater than the start date",
    correctTaskDates: "Correct task dates",
    showErrorDetails: "Show details",
    businessErrorsMessage: "Business errors message",
    branch: "Branch",
    branches: "Branches",
    listBranches: "List of branches",
    distrsAndBranches: "Distributors and branches",
    distributorStructure: "Distributor structure",
    distributorStructureBinding: "Binding to the structure",
    distributorStructureBindingFull: "Binding to the Distributor structure",
    itemsSelectAll: "All selected",
    clear: "Clear",
    exclamationHint: "Important",
    overdueHint: "Overdue",
    bookmarkHint: "Checked",
    bookmarkDeleteHint: "Checked off",
    clipHint: "Attachment",
    repeatHint: "Repeat task",
    unfulfilledHint: "Unfulfilled",
    unreadHint: "Unread",
    overdueDayHint: "Number of days overdue",
    updateSubscribeHint: "Subscribe to update",
    hintFilterBranchUsers: "Select a branch/office/division to see users associated with them",
    hintFilterDistrUsers: "Select a distributor to see users associated with it",
    hintFilterCompetence: "Select a distributor to see competences associated with it",
    hintFilterDistrResesrved: "Select a Distributor to see a list of its reserves",
    hintFilterBranchDocs: "Select a branch/office/division to see documents for its employees",
    hintFilterDistrDocs: "Select a distributor to see their documents. For documents by branch, use the 'Branch' filter",
    hintFilterBranchNews: "Select a branch/office/division to see news for its employees",
    hintFilterBranchCompetence: "Select a branch/office/division to see competence for its employees",
    hintFilterDistrNews: "Select a distributor to see their news. For branch news, use the Branch filter",
    hintFilterDistrTasks: "Select a distributor to see their tasks",
    restrictingAccessBranch: "Restricting access by distributor branches",
    restrictingAccessBranchesHint: "Branch restrictions allow you to select recipients based on GRDs, branches and Distributor offices",
    restrictingAccessDistrsDocs: "Selecting levels in the distributor structure allows you to limit the list of employees for whom your document will be published. Employees associated with the selected level and higher in the tree will see it.<br><br>To send a document to all employees of the distributor, you must disable the \"Access Restriction\" function by distributor's branches on the \"Parameters\" tab.<br><br>When this parameter is enabled, there is no \"All\" switch. A new distributor (which will be added to the system in the future) can have absolutely any divisional structure and determine which node to send the document to for this distributor is impossible.",
     restrictingAccessBranchesDocs: "The document is available on the portal both to the selected Distributor division (branch, office, etc.) and to the top Distributor nodes of this branch (according to subordination).",
     restrictingAccessDistrsNews: "Selecting levels in the distributor structure allows you to limit the list of employees for whom your news will be published. Employees associated with the selected level and higher in the tree will see it.<br><br>To send a document to all employees of the distributor, you must disable the \"Access Restriction\" function by distributor branches on the \"Parameters\" tab.",
     restrictingAccessBranchesNews: "The news is available on the portal, both to the selected division of the Distributor (branch, office, etc.) and to the top nodes of the Distributor of this branch (according to subordination).",
     restrictingAccessBranchesTask: "The task is available on the portal, both to the selected division of the Distributor (branch, office, etc.) and to the top nodes of the Distributor of this branch (according to subordination).",
     restrictingAccessHintAll: ` 1. Bill-To is the legal entity of the distributor. One legal entity person = one Bill-To;
     2. Sold-To – an intermediate link between Bill-To and Ship-To. Created to suit business needs and typically coincides with Mars regions. If a distributor operates in three regions, then he will have three Sold-Tos;
     3. GRD code – completely identical to the Sold-To code;
     4. Ship-To – code of the delivery point where the product is delivered;
     5. Virtual GRD – necessary for granularity in setting goals and paying bonuses;
     6. Branch – branch level;
     7. Office – the lowest granularity in the hierarchy, corresponds to the sales team.`,

    errorFileDisplay: "Error: File cannot be displayed",
    itemsIncluded: "Selected",
    itemsExcluded: "Excluded",
    listIncluded: "Selected list",
    listExcluded: "Excluded list",
    questClearFilters: "Selected entries will be reset",
    bodyClearFilters: "When this option is applied, the previous selected entries will be reset",
    rejectedByDistributor: "Rejected by Distributor",
    planningActions: "Developing an action plan",
    selectDate: "Select a date",
    openList: "Open list",
    alertChangeTimezone: "To apply the parameters, you will log out of the system",
    signOut: "Sign Out",
    assign: "Assign to",
    printTxt: "Print",
    columnSettings: 'Column Settings'
  },
  ru: {
    actions: "Действия",
    fio: "ФИО",
    yes: "Да",
    no: "Нет",
    id: "ID",
    rolesEmpty: "У вас нет ни одной роли",
    selectRoleForEnter: "Выбрать роль для входа",
    topics: "Разделы",
    editDate: "Дата редактирования",
    version: "Версия",
    createDate: "Дата создания",
    authorIncludes: "Автор (вхождения)",
    viewMore: "Показать еще",
    showAll: "Показать всё",
    copy: "Копия",
    copyAction: "Копировать",
    to: "Кому",
    BCC: "Скрытые",
    boxes: "Блоки",
    lock: "Закрыто",
    marketing: "Маркетинг",
    promo: "Промо",
    dashboard: "Дашборд",
    language: "Язык",
    save: "Сохранить",
    saveAll: "Сохранить всё",
    export_csv: "Экспорт CSV",
    export_xlsx: "Экспорт XLSX",
    up: "Вверх",
    down: "Вниз",
    changes: "Изменения",
    fields: "Поля",
    field: "Поле",
    fieldValue: "Значение поля",
    fieldName: "Название поля",
    systemName: "Системное название",
    changeDateTime: "Дата и время изменения",
    operation: "Операция",
    operations: "Операции",
    changeHistory: "История изменений",
    history: "История",
    userProfile: "Профиль пользователя",
    currentPassword: "Текущий пароль",
    currentRole: "Текущая роль",
    change: "Изменить",
    newPassword: "Новый пароль",
    activityPeriod: "Период Активности",
    activityPeriodF: "Период активности",
    addItemTxt: "Добавить",
    description: "Описание",
    steps: "Шаги",
    title: "Заголовок",
    group: "Группа",
    searchTxt: "Поиск",
    selectTxt: "Выбор",
    filterBy: "Фильтры:",
    editItemTxt: "Редактировать",
    createItemTxt: "Создать",
    closeTxt: "Закрыть",
    cancelTxt: "Отмена",
    cancelInf: "Отменить",
    selectAllTxt: "Выбрать все",
    selectTxt2: "Выбрать",
    applyTxt: "Применить",
    clearTxt: "Сбросить",
    confirmTxt: "Подтвердить",
    resetTxt: "Сбросить",
    choose: "Выбрать",
    clearFilterTxt: "Очистить",
    customFilterTxt: "Общий фильтр",
    newTxt: "Новый",
    newTxtF: "Новая",
    newTxtT: "Новое",
    next: "Далее",
    metaInfTxt: "Мета информация",
    detailTxt: "Подробно",
    allTxt: "Все",
    allTxtT: "ВСЕ",
    active: "Активные",
    blocked: "Заблокированые",
    statusActive: "Активный",
    statusBlocked: "Заблокированный",
    statusNew: "Новый",
    statusInactive: "Неактивный",
    activate: "Активировать",
    deactivate: "Деактивированные",
    paied: "Оплаченные",
    draft: "Черновики",
    new: "Новый",
    changed: "Изменено",
    update: "Обновить",
    updated: "Обновлено",
    inactive: "Неактивные",
    recentlyTxt: "Недавние",
    recentlyViewed: "Недавно просмотренные",
    deleted: "Удалённые",
    unacted: "Невыполненные",
    overdue: "Просроченные",
    delete: "Удалить",
    deleting: "Удаление",
    record: "Запись",
    deletingPosition: "Удаление позиции",
    questionDeletingPosition: "Вы действительно хотите удалить позицию",
    deletingConfirmation: "Подтверждение удаления",
    deletingContactUser: "Удаление контактного лица",
    deletingRole: "Удаление роли",
    deletingClaim: "Удаление права",
    questionDeletingContactUser: "Вы действительно хотите удалить контактное лицо",
    questionDeleting: "Вы действительно хотите удалить ",
    questionDeletingList: " из списка получателей",
    open: "Открыть",
    created: "Создано",
    createdByTxt: "Кем создано",
    updatedByTxt: "Кем обновлено",
    createdAtTxt: "Когда создано",
    createdDate: "Дата создания",
    lastUpdatedTxt: "Обновлено",
    fromTxt: "От",
    toTxt: "До",
    settingsTxt: "Настройки",
    systemSettings: "Системные настройки",
    overview: "Обзор",
    roles: "Роли",
    role: "Роль",
    localization: "Локализация",
    roleLower: "роль",
    firstName: "Имя",
    secondName: "Отчество",
    lastName: "Фамилия",
    email: "Электронный адрес",
    browser: "Браузер",
    node: "Узел",
    nodes: "Узлы",
    holderName: "Имя пользователя",
    holderEmail: "E-mail пользователя",
    loginType: "Тип логина",
    loginWith: "Войти с помощью",
    recipients: "Получатели",
    recipient: "Получатель",
    users: "Пользователи",
    user: "Пользователь",
    userLower: "пользователь",
    userCreateLower: "пользователя",
    useCases: "Сценарии использования",
    useCase: "Сценарий использования",
    useCaseLower: "cценарий использования",
    status: "Статус",
    custom: "Общий",
    other: "Другие",
    customContacts: "Другие контакты",
    name: "Имя",
    nameL: "Название",
    notifications: "Уведомления",
    notification: "Уведомление",
    notificationLower: "уведомление",
    events: "События",
    event: "Событие",
    workflow: "Рабочий процесс",
    workflowLower: "рабочий процесс",
    mainInformation: "Основная информация",
    emptyFilter: "Результатов не найдено",
    mainTab: "Основное",
    userInformation: "Имя пользователя и другая <br>контактная информация",
    organization: "Организация",
    organizationalInformation:
      "Организационная структура, <br>к которой принадлежит пользователь",
    additionalInformation: "Дополнительная информация",
    additionalInformationInformation:
      "Любая другая информация, <br>которая может помочь идентифицировать пользователя",
    date: "Дата",
    dateInformation:
      "Любая другая информация, <br>которая может помочь идентифицировать пользователя",
    lastLoginDate: "Дата последнего входа в систему",
    lastLoginDateInformation:
      "Любая другая информация, <br>которая может помочь идентифицировать пользователя",
    lastLoginDateF: "Дата последнего входа в систему",
    jobTitle: "Должность",
    comments: "Комментарии",
    dateFormat: "Формат даты",
    dateTimeFormat: "Формат даты и времени",
    timeZone: "Часовой пояс",
    timeFormat: "Формат времени",
    backToLogin: "Вернуться к выбору",
    signIn: "Войти",
    changePasswordB: "Сменить пароль",
    resetPasswordB: "Сбросить пароль",
    profile: "Профиль",
    permissions: "Разрешения",
    menuItems: "Пункты меню",
    menuItemL: "пункт меню",
    restrictions: "Ограничения",
    apiModels: "Модели Api",
    domainModels: "Доменные модели",
    itemsSelected: "Выбрано всего",
    total: "Всего",
    roleProfile: "Профиль роли",
    identification: "Идентификация",
    identificationInformation: "Имя роли и другая контактная информация",
    usecaseName: "Название cценария использования",
    usecaseTitle: "Заголовок cценария использования",
    usecaseDescription: "Описание cценария использования",
    usecaseSampleDescription: "Простое описание cценария использования",
    value: "Значение",
    addValue: "Добавить значение",
    added: "Добавлено",
    excluded: "Исключены",
    noDataTitle: "Пока нечего показывать",
    noDataTxt: "Здесь пока нет никаких записей",
    noData: "Нет данных",
    claims: "Права",
    claim: "Права",
    useCaseInformation: "Название и описание сценария использования",
    usecaseActivityPeriodInformation: "Период действия сценария использования",
    notificationType: "Тип уведомления",
    ruleOptions: "Параметры правил",
    searchIn: "Искать В",
    body: "Тело",
    hasAttach: "Имеет прикрепление",
    notificationMainInformationInformation:
      "Пример формы текстового блока здесь может отличаться описанием текстового блока",
    notificationActivityPeriodInformation:
      "Пример формы текстового блока здесь может отличаться описанием текстового блока",
    notificationEventsInformation:
      "Пример формы текстового блока здесь может отличаться описанием текстового блока",
    notificationNotificationTypeInformation:
      "Пример формы текстового блока здесь может отличаться описанием текстового блока",
    notificationRuleOptionsInformation:
      "Пример формы текстового блока здесь может отличаться описанием текстового блока",
    notificationRecipientsInformation:
      "Пример формы текстового блока здесь может отличаться описанием текстового блока",
    expand: "Развернуть",
    collapse: "Свернуть",
    step: "Шаг",
    workflowMainInformationInformation:
      "Основная информация о рабочем процессе",
    workflowName: "Имя рабочего процесса",
    workflowDescription: "Описание рабочего процесса",
    activateWorkflow: "Активировать",
    stepOptions: "Параметры шага",
    stepNumber: "Номер шага",
    stepType: "Тип шага",
    stepName: "Имя шага",
    stepStatus: "Статус шага",
    condition: "Состояние",
    onSuccess: "При Успехе",
    onSuccessF: "При успехе",
    onFailure: "При Неудаче",
    onFailureF: "При неудаче",
    parameters: "Параметры",
    parametersStep: "Параметры шага",
    parameter: "Параметр",
    selectStepType: "Выберите тип шага",
    filter: "Фильтр",
    selectStep: "Выберите шаг",
    repeatPassword: "Повторите Пароль",
    password: "Пароль",
    enterPassword: "Введите пароль",
    login: "Логин",
    enterLogin: "Введите логин",
    code: "Код",
    workflowUniqueCode: "Уникальный код рабочего процесса",
    taskType: "Тип",
    taskName: "Название",
    execution: "Исполнение",
    taskResult: "Результат задачи",
    runResult: "Результат выполнения",
    taskLog: "Журнал задач",
    downloadLog: "Скачать лог",
    runAgain: "Повтор",
    complete: "Завершенный",
    completeS: "Завершенныe",
    error: "Ошибка",
    errors: "Ошибки",
    warning: "Предупреждение",
    warnings: "Предупреждения",
    fatal: "Фатальная ошибка",
    infos: "Информация",
    systemTasks: "Системные задачи",
    backgroundTasks: "Фоновые задачи",
    systemTaskLog: "Лог задач",
    import: "Импорт",
    startedImport: "Запущена",
    finishedImport: "Завершена",
    nonActive: "Не Активные",
    task: "Задача",
    taskY: "Задачу",
    tasks: "Задачи",
    taskCreate: "Создать задачу",
    taskEdit: "Редактировать задачу",
    nextExecution: "Следующий запуск",
    lastExecution: "Последний запуск",
    startExecution: "Начало выполнения",
    endExecution: "Конец выполнения",
    executionTime: "Время выполнения",
    trigger: "Триггер",
    triggers: "Триггеры",
    addTrigger: "Создать триггер",
    editTrigger: "Редактировать триггер",
    systemLog: "Системные задачи",
    pause: "Пауза",
    run: "Запуск",
    repeat: "Повторять",
    every: "Каждый",
    everyDay: "Каждый день",
    everyWeek: "Каждую неделю",
    everyMonth: "Каждый месяц",
    time: "Время",
    onTheDays: "В дни",
    startRepeat: "Начало повторения",
    endRepeat: "Окончание повторений",
    taskDetails: "Детали задачи",
    taskDetailsInfo: "Выберите тип задачи и напишите описание.",
    repeatOptions: "Настройки повторения",
    scheduling: "Планирование",
    lastStart: "Последний старт",
    nextStart: "Следующий старт",
    once: "Одиночное",
    second: "Секунда",
    minute: "Минута",
    hour: "Час",
    day: "День",
    week: "Неделя",
    month: "Месяц",
    sun: "Вск",
    mon: "Пн",
    tue: "Вт",
    wed: "Ср",
    thu: "Чт",
    fri: "Пт",
    sat: "Сб",
    path: "Путь",
    icon: "Изображение",
    order: "Порядок",
    parent: "Родитель",
    parentField: "Родительское поле",
    add: "Добавить",
    uploadFileDragAndDropText: "Перетащите файл в данную область для загрузки",
    uploadStatusError: "Ошибка загрузки файла",
    uploadFile: "Загрузить файл",
    upload: "Загрузить",
    sourceFile: "Исходный файл",
    importTemplate: "Шаблон импорта",
    jobResult_ExportedCount: "строк успешно экспортированно",
    jobResult_ExportResultFile: "Результаты экспорта",
    jobResult_SourceCount: "строк в исходном файле",
    jobResult_ErrorsCount: "ошибок",
    jobResult_ImportedCount: "строк импортированно",
    jobResult_ErrorsCSV: "Cкачать файл с ошибками",
    jobResult_UnknownFile: "Скачать файл",
    jobResult_UnknownText: "",
    jobResult_ShowErrors: "Открыть ошибки",
    jobParameters_ImportFile: "Cкачать файл импорта",
    jobParameters_showModalParameters: "Открыть параметры",
    importSourceCount: "строк в загружаемом файле",
    importErrorsCount: "ошибок импорта",
    importedCount: "строк успешно импортированно",
    startedAt: "Начало",
    finishedAt: "Окончание",
    userType: "Тип пользователя",
    mainInfo: "Основная информация",
    distributor: "Дистрибьютор",
    parentPosition: "Руководитель",
    mars: "Марс",
    Mars: "Марс",
    legalAddress: "Юридический адрес",
    mailAddress: "Почтовый адрес",
    phoneNumber: "Телефон",
    faxNumber: "Факс",
    emailAddress: "Электронный адрес",
    Distributor: "Дистрибьютор",
    MailingGroup: "Группа рассылки",
    User: "Пользователь",
    link: "Ссылка",
    orgLinkInfo: "Тип пользователя",
    orgLinkInfoSub: "Тип пользователя и должность",
    orgStructure: "Оргструктура",
    orgStructureFilial: "Структура по филиалам",
    support: "Поддержка",
    supportFrame: "Поддержка онлайн",
    maxLengthErrorMessage: "Превышена максимальная длина: {maxLength} символов",
    minLengthErrorMessage: "Введите телефон полностью",
    maxValueErrorMessage: "Максимальное значение: ",
    minValueErrorMessage: "Минимальное значение: ",
    requiredFieldErrorMessage: "Обязательное поле",
    featureInProgress: "Функционал добавится позднее",
    notValidEmail: "Некорректный e-mail",
    notValidPassword: "Некорректный пароль",
    information: "Информация",
    type: "Тип",
    info: "Информация",
    key: "Ключ",
    pictogram: "Пиктограмма",
    checkeds: "Помеченные",
    listDistributors: "Список Дистрибьюторов",
    selectDistributors: "Выбор Дистрибьюторов",
    selectDistributor: "Выбор Дистрибьютора",
    selectRecipients: "Выбор получателей",
    repeatTask: "Повторение задачи",
    repeatTaskTxt: "Настройки параметров повторения задачи",
    duration: "Длительность, дней",
    notValidPasswordLength: "Пароль должен быть больше 12 символов",
    notValidPasswordAlpth: "Пароль должен содержать английскую прописную букву",
    notValidPasswordAlpthUpper: "Пароль должен содержать английскую заглавную букву",
    validatePasswordNumbers: "Пароль должен содержать цифру",
    validatePasswordSymbols: "Пароль должен содержать специальный символ",
    validatePasswordUserId: "Пароль не должен содержать Id пользователя",
    validatePasswordUserLogin: "Пароль не должен содержать логин пользователя",
    validatePasswordUserEmail: "Пароль не должен содержать почту пользователя",
    validatePasswordUserFirstName: "Пароль не должен содержать имя пользователя",
    validatePasswordUserLastName: "Пароль не должен содержать фамилию пользователя",
    validatePasswordUserSecondName: "Пароль не должен содержать отчество пользователя",
    validatePasswordSpace: "Пароль не должен содержать пробел",
    validatePasswordMDP: "Пароль не должен содержать ключевые слова проекта",
    validatePasswordEnglish: "Пароль должен содержать только английские буквы",
    notValidLogin: "Логин может содержать английские буквы, цифры, точку и нижнее подчеркивание",
    passwordChanged: "Пароль изменен",
    company: "Компания",
    nameOfBank: "Наименование Банка",
    tags: "Теги",
    requestAccess: 'Запрос доступа',
    validations: {
      localRequired: "Обязательное поле",
      localMaxLength: "Превышена максимальная длина",
      localEmail: "Некорректный электронный адрес",
      localMarsValidate: "Обязательное поле",
      localDistrValidate: "Обязательное поле",
      localdistrStructureValidate: "Обязательное поле"
    },
    publish: "Опубликовать",
    publishTask: "Публикация задачи",
    publishTaskTxt: "Вы хотите опубликовать задачу?",
    saveDraft: "Сохранить черновик",
    taskDraft: "Черновик",
    taskModer: "На модерации",
    taskPublished: "Опубликовано",
    taskApproving: "На подтверждении",
    taskCanceled: "Отменено",
    taskComplete: "Выполнено",
    taskStatus: "Статус задачи",
    moderation: "Модерация",
    moderationTxt: "Создано без использования шаблона",
    acceptPublish: "Подтвердить публикацию",
    reject: "Отклонить",
    Reject: "Отклонил публикацию",
    rejectTask: "Отклонить выполнение задачи",
    taskCompleteTxt: "Задача выполнена",
    accept: "Подтвердить",
    comment: "Комментарий",
    needModeration: "Требуется модерация",
    needModerationTxt: "Задача создаётся без использования шаблона, поэтому будет отправлена на модерацию. <br>Модерацию и публикацию далее выполнит<br><span class='text-color-accent'>Контент менеджер плюс</span>.",
    send: "Отправить",
    performTask: "Выполнить задачу",
    taskModerStep: "Отправил на модерацию",
    taskModerStepReject: "Отклонил публикацию",
    taskPublishedStep: "Опубликовал",
    taskPublishedStepReject: "Отменил публикацию",
    taskApprovingStep: "Подтвердил выполнение",
    taskApprovingStepReject: "Отклонил выполнение",
    taskCompleteStep: "Принял выполнение",
    addComment: "Добавить комментарий",
    rejectPublish: "Отклонить публикацию",
    rejectTaskFlashTitle: "Выполнение задачи отклонено",
    rejectTaskFlashTxt: "Задача возвращена исполнителю на доработку",
    distrCountWarning: "Выбрано больше одного Дистрибьютора",
    distrCountWarningTxt: "Для подтверждения выбора должен быть выбран только один Дистрибьютор",
    needRepeatTxt: "Выбран период повторения задачи",
    taskInProgress: "Выполняется задача",
    taskPublishedTxt: "Невыполненные",
    taskApprovingTxt: "На подтверждении у Марс",
    taskModerTxt: "На модерации",
    newsModerTxt: "На модерации",
    isExpiredTxt: "Просроченные",
    taskDraftTxt: "Черновики",
    taskCanceledTxt: "Отмененные",
    newTaskTxt: "Создать задачу",
    publishedNews: "Новость опубликована",
    publishedNewsTxt: "Новость отображена в разделе «Новости»",
    publishNews: "Публикация новости",
    publishNewsTxt: "Вы хотите опубликовать новость? После публикации новости её нельзя будет удалить",
    needModerationNewsTxt: "Новость создаётся без использования шаблона, поэтому будет отправлена на модерацию. <br>Модерацию и Публикацию далее выполнит <i>Контент менеджер плюс</i>.",
    highImportants: "Важные",
    drafts: "Черновики",
    daily: "Ежедневно",
    weekly: "Еженедельно",
    monthly: "Ежемесячно",
    taskStatus_new: 'Новая',
    taskStatus_created: 'Создана',
    taskStatus_complete: 'Завершена',
    taskStatus_error: 'Ошибка',
    taskStatus_accepted: 'В процессе',
    taskStatus_partialcomplete: 'Частично завершена',
    taskStatus_is: '-',
    download: "Скачать",
    rotateRight: "Повернуть вправо",
    rotateLeft: "Повернуть влево",
    newsModerStep: "Отправил на модерацию",
    newsWaitingStep: "Подтвердил публикацию",
    newsPublishedStep: "Опубликовано",
    dateFormatDDMMYYYY: "ДД.ММ.ГГГГ",
    dateFormatMMDDYYYY: "ММ.ДД.ГГГГ",
    dateFormatYYYYMMDD: "ГГГГ.ММ.ДД",
    timeFormat12: "12-часовой",
    timeFormat24: "24-часовой",
    sideBarThemeTxt: "Тема",
    sideBarLanguageTxt: "Язык",
    sideBarLogoutTxt: "Выйти",
    sideBarStorageTxt: "Очистить кеш",
    select: "Выбрать",
    seriesTask: "Повторяющаяся задача",
    taskCancellationApprove: "Подтверждение отмены",
    editSeriesTask: "Это повторяющаяся задача.<br>Редактировать только эту задачу или весь ряд задач для Дистрибьютора?",
    deleteSeriesTask: "Это повторяющаяся задача.<br>Удалить только эту задачу или весь ряд задач для Дистрибьютора?",
    cancelTask: "Вы действительно хотите отменить<br/>задачу <span style=\"color:#2196f3\">{name}</span>?",
    deleteTask: "Вы действительно хотите удалить<br>задачу <span style=\"color:#2196f3\">{name}</span>?",
    deleteTaskTemplate: "Вы действительно хотите удалить<br>шаблон <span style=\"color:#2196f3\">{name}</span>?",
    deleteWorkflow: "Вы действительно хотите удалить<br><span style=\"color:#2196f3\">{name}</span>?",
    deleteWorkflowItem: "Вы действительно хотите удалить<br>шаг <span style=\"color:#2196f3\">{name}</span>?",
    deleteUseCase: "Вы действительно хотите удалить<br>сценарий использования <span style=\"color:#2196f3\">{name}</span>?",
    deleteUser: "Вы действительно хотите удалить:<br><span style=\"color:#2196f3\">{name}</span>?",
    deleteUsers: "Вы действительно хотите удалить выбранных пользователей?",
    deleteRole: "Вы действительно хотите удалить<br>роль <span style=\"color:#2196f3\">{name}</span>?",
    deleteMenuItem: "Вы действительно хотите удалить<br>пункт <span style=\"color:#2196f3\">{name}</span>?",
    deleteNotification: "Вы действительно хотите удалить<br>уведомление <span style=\"color:#2196f3\">{name}</span>?",
    deleteNewsItemTemplate: "Вы действительно хотите удалить<br>шаблон <span style=\"color:#2196f3\">{name}</span>?",
    deleteNewsItem: "Вы действительно хотите удалить<br>новость <span style=\"color:#2196f3\">{name}</span>?",
    deleteMailingGroup: "Вы действительно хотите удалить<br>группу рассылки <span style=\"color:#2196f3\">{name}</span>?",
    deleteDistributor: "Вы действительно хотите удалить<br>дистрибьютора <span style=\"color:#2196f3\">{name}</span>?",
    deleteUsefulLink: "Вы действительно хотите удалить<br>ссылку <span style=\"color:#2196f3\">{name}</span>?",
    deleteFaq: "Вы действительно хотите удалить<br>инструкцию <span style=\"color:#2196f3\">{name}</span>?",
    deleteDocument: "Вы действительно хотите удалить<br>документ <span style=\"color:#2196f3\">{name}</span>?",
    deleteCompetenceDirectory: "Вы действительно хотите удалить<br>компетенцию <span style=\"color:#2196f3\">{name}</span>?",
    deleteSystemDirectory: "Вы действительно хотите удалить<br>справочник <span style=\"color:#2196f3\">{name}</span>?",
    deleteBaseType: "Вы действительно хотите удалить<br>тип <span style=\"color:#2196f3\">{name}</span>?",
    deleteSystemTask: "Вы действительно хотите удалить<br>задачу <span style=\"color:#2196f3\">{name}</span>?",
    deleteTrigger: "Вы действительно хотите удалить<br>триггер <span style=\"color:#2196f3\">{name}</span>?",
    deleteTaskRecipient: "Вы действительно хотите удалить<br><span style=\"color:#2196f3\">{name}</span> из списка<br>получателей?",
    deleteReport: "Вы действительно хотите удалить<br>отчет <span style=\"color:#2196f3\">{name}</span>?",
    deleteDashboardIntegrationMatching: "Вы действительно хотите удалить<br>справочник соответствий источников данных <span style=\"color:#2196f3\">{name}</span>?",
    singleTaskEdit: "Редактировать только эту задачу",
    seriesTaskEdit: "Редактировать весь ряд задач",
    singleTaskDelete: "Удалить только эту задачу",
    seriesTaskDelete: "Удалить весь ряд задач",
    portalDistr: "Портал Дистрибьютора",
    unreaded: "Непрочитанные",
    or: "или",
    loading: "Загрузка",
    returnToLoginPage: "Вернуться на страницу входа",
    main: "Главная",
    historyMain: "Главное",
    createdOperation: "Создание",
    updatedOperation: "Редактирование",
    deletedOperation: "Удаление",
    theme: "Тема",
    logout: "Выйти",
    loadFileProgress: "Скачивание файла",
    loadFileDone: "Скачивание завершено",
    loadFileError: "Ошибка скачивания",
    dateMustBeLessThanEnd: "Дата начала должна быть меньше даты окончания",
    dateMustBeGreaterThanStart: "Дата окончания должна быть больше даты начала",
    correctTaskDates: "Скорректируйте даты выполнения задачи",
    txtResetFilters: "Сброс всех фильтров",
    showErrorDetails: "Показать ошибки",
    businessErrorsMessage: "Сообщение об ошибках",
    branch: "Филиал",
    branches: "Филиалы",
    listBranches: "Список филиалов",
    distrsAndBranches: "Дистрибьюторы и филиалы",
    distributorStructure: "Структура Дистрибьютора",
    distributorStructureBinding: "Привязка к структуре",
    distributorStructureBindingFull: "Привязка к структуре Дистрибьютора",
    itemsSelectAll: "Выбрано все",
    clear: "Очистить",
    exclamationHint: "Важно",
    overdueHint: "Просрочено",
    bookmarkHint: "Пометить",
    bookmarkDeleteHint: "Снять метку",
    clipHint: "Вложение",
    repeatHint: "Повторяющаяся задача",
    unfulfilledHint: "Не выполнено",
    unreadHint: "Не прочитано",
    overdueDayHint: "Количество просроченных дней",
    updateSubscribeHint: "Подписаться на обновления",
    hintFilterBranchUsers: "Выберите филиал/офис/подразделение, чтобы видеть привязанных к ним пользователей",
    hintFilterBranchDocs: "Выберите филиал/офис/подразделение, чтобы видеть документы для его сотрудников",
    hintFilterBranchNews: "Выберите филиал/офис/подразделение, чтобы видеть новости для его сотрудников",
    hintFilterBranchCompetence: "Выберите филиал, чтобы увидеть назначенные на него компетенции",
    hintFilterDistrUsers: "Выберите дистрибьютора, чтобы видеть привязанных к нему пользователей",
    hintFilterCompetence: "Выберите Дистрибьютора, чтобы увидеть назначенные на него компетенции",
    hintFilterDistrResesrved: "Выберите Дистрибьютора, чтобы увидеть список его резервов",
    hintFilterDistrDocs: "Выберите дистрибьютора, чтобы видеть его документы. Для документов по филиалам используйте фильтр «Филиал»",
    hintFilterDistrNews: "Выберите дистрибьютора, чтобы видеть его новости. Для новостей по филиалам используйте фильтр «Филиал»",
    hintFilterDistrTasks: "Выберите дистрибьютора, чтобы видеть его задачи",
    restrictingAccessBranch: "Ограничение доступа по филиалам дистрибьютора",
    restrictingAccessBranchesHint: "Ограничения по филиалам позволяют выбрать получателей, учитывая GRD, филиалы и офисы Дистрибьюторов",
    restrictingAccessDistrsDocs: "Выбор уровней в структуре дистрибьюторов позволяет ограничить список сотрудников, для которых будет опубликован ваш документ. Сотрудники, привязанные к выбранному уровню и выше по дереву, увидят его.<br><br>Для отправки документа на всех сотрудников дистрибьютора необходимо отключить функцию «Ограничение доступа по филиалам дистрибьютора» на вкладке «Параметры».",
    restrictingAccessBranchesDocs: "Документ доступен на портале, как выбранному подразделению Дистрибьютора (филиал, офис и т.д), так и верхним узлам Дистрибьютора данного ветвления (согласно подчиненности).",
    restrictingAccessDistrsNews: "Выбор уровней в структуре дистрибьюторов позволяет ограничить список сотрудников, для которых будет опубликована ваша новость. Сотрудники, привязанные к выбранному уровню и выше по дереву, увидят ее.<br><br>Для отправки новости на всех сотрудников дистрибьютора необходимо отключить функцию «Ограничение доступа по филиалам дистрибьютора» на вкладке «Параметры».",
    restrictingAccessBranchesNews: "Новость доступна на портале, как выбранному подразделению Дистрибьютора (филиал, офис и т.д), так и верхним узлам Дистрибьютора данного ветвления (согласно подчиненности).",
    restrictingAccessBranchesTask: "Задача доступна на портале, как выбранному подразделению Дистрибьютора (филиал, офис и т.д), так и верхним узлам Дистрибьютора данного ветвления (согласно подчиненности).",
    restrictingAccessHintAll: `    1. Bill-To – это юридическое лицо дистрибьютора. Одно юр. лицо = один Bill-To;
    2. Sold-To – промежуточное звено между Bill-To и Ship-To. Создано под потребности бизнеса и как правило совпадает с регионами Mars. Если дистрибьютор оперирует на трех регионах, то у него будет три Sold-To;
    3. GRD код – полностью идентичен Sold-To коду;
    4. Ship-To – код точки доставки, куда доставляется продукт;
    5. Virtual GRD – необходим для гранулярности постановки целей и выплаты премий;
    6. Filial – уровень филиала;
    7. Office – самая нижняя гранулярность в иерархии, соответствует команде продаж.`,
    errorFileDisplay: "Ошибка: файл не может быть отображен.",
    itemsIncluded: "Выбрано",
    itemsExcluded: "Исключено",
    listIncluded: "Список выбранных",
    listExcluded: "Список исключенных",
    questClearFilters: "Выбранные записи будут сброшены",
    bodyClearFilters: "При применении данной опции предыдущие выбранные записи будут сброшены",
    rejectedByDistributor: "Отклонено Дистрибьютором",
    planningActions: "Разработка плана действий",
    selectDate: "Выберите дату",
    openList: "Открыть список",
    alertChangeTimezone: "Для применения параметров будет выполнен выход из системы",
    signOut: "Выход из системы",
    assign: "Назначено",
    printTxt: "Печать",
    columnSettings: 'Настройки столбцов'
  },
};

req
  .keys()
  .map(req)
  .forEach((module) => {
    _.merge(messages, module);
  });

const i18n = createI18n({
  locale: "ru",
  legacy: false,
  globalInjection: true,
  messages,
});
export default i18n;
