<template>
  <div class="dashboard-panel-restrictions">
    <ProSpacePanelBlock :header="headerOverview">
      <template v-slot:header>
        <ProSpaceAction v-if="checkRestrictionsAccess(dashboard)" :text="$t('editItemTxt')" @click="onClickEdit" />
      </template>
    </ProSpacePanelBlock>
    <ProSpaceVLayout :gap="10">
      <ProSpacePanelBlock v-for="block in restrictionsBlocks" :header="block.header">
        <template v-slot v-if="loading">
          <ProSpaceVLayout :gap="5">
            <Skeleton height="30px" v-for="s in 3" />
          </ProSpaceVLayout>
        </template>
        <template v-slot v-else >
          <ProSpaceSimpleGrid
            v-if="restrictions[block.name]?.length > 0"
            :ref="`simpleGrid-${block.name}`"
            :getMethod="block.method"
            :service="block.service"
            :defaultFilter="block.filter"
            :headers="block.fields"
            :limit="5"
            :deletable="false"
            :btnText="$t('viewMore')"
            :modalTitle="$t(block.name)"
            :cancelTxt="$t('closeTxt')"
            :applyTxt="null"
            :defaultModal="block.defaultModal"
            @openModal="$emit('openModalAllDistributors')"
            @loaded="simpleGridDataLoaded(block.name)"
          />
          <ProSpaceAction
            v-else-if="restrictions[`isAll${capitalize(block.name)}`]"
            :text="block.action"
            @click="onClickAll(block.name)"
          />
        </template>
      </ProSpacePanelBlock>
    </ProSpaceVLayout>
  </div>
</template>

<script>
import {
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  ProSpaceSimpleGrid,
  ProSpaceVLayout,
} from "@prospace/prospace-components-library";

import { DistributorService } from "@masterdata/services/DistributorService.js";
import { RoleService } from "../../../../../../../Notification/ProSpace.Notification.Frontend/services/RoleService";
import { DistributorOrgService } from "@masterdata/services/DistributorOrgService";
import Skeleton from 'primevue/skeleton';

export default {
  name: "DashboardPanelRestrictions",
  components: {
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpaceSimpleGrid,
    ProSpaceVLayout,
    Skeleton,
  },
  props: {
    dashboard: {
      type: Object,
      default: null
    },
    checkRestrictionsAccess: {
      type: Function
    }
  },
  data() {
    return {
      // services
      distributorsService: new DistributorService(),
      rolesService: new RoleService(),
      positionsService: new DistributorOrgService(),

      // restrictions ids: distributors, positions, roles
      restrictions: null,
      loaded: false,
      dataLoaded: {
        roles: false,
        positions: false,
        distributors: false,
      },
      loading: false,
    }
  },
  watch: {
    dashboard: {
      handler: async function(val, oldVal) {
        const types = ['distributors', 'roles', 'positions'];
        if (val?.id !== oldVal?.id) {
          if (this.loading) {
            for (const type in types) {
              await this.clearServices(types[type]);
            }
            this.setDefaultRestrictions();
            this.clearData();
            this.loading = false;
          }
          this.loading = true;
          await this.getRestrictions();

          for (const type in types) {
            await this.setServices(types[type]);
          }
          this.loaded = true;
          this.clearData();
          this.$nextTick(() => {
            this.getData();
          });
          this.loading = false;
        }
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    headerOverview() {
      return {
        title: this.$t("permissions"),
        isExpand: false,
      };
    },
    restrictionsBlocks() {
      return [
        {
          name: "distributors",
          header: {
            title: this.$t("distributors"),
            isExpand: true,
          },
          fields: [
            {
              name: this.$t("code"),
              value: "code",
              f: (item) => item,
            },
            {
              name: this.$t("distributor"),
              value: "name",
              f: (item) => item,
            },
          ],
          method: null,
          service: null,
          filter: null,
          action: this.$t("allDistributors"),
          defaultModal: false,
        },
        {
          name: "roles",
          header: {
            title: this.$t("roles"),
            isExpand: true,
          },
          fields: [
            {
              name: this.$t("role"),
              value: "displayName",
              f: (value, data) => data.displayName || data.systemName,
            },
          ],
          method: null,
          service: null,
          filter: null,
          action: this.$t("allRoles"),
          defaultModal: true,
        },
        {
          name: "positions",
          header: {
            title: this.$t("positions"),
            isExpand: true,
          },
          fields: [
            {
              name: this.$t("position"),
              value: "name",
              f: (item) => item,
            },
          ],
          method: null,
          service: null,
          filter: null,
          action: this.$t("allPositions"),
          defaultModal: true,
        }
      ]
    },
  },
  methods: {
    getData() {
      this.restrictionsBlocks.forEach(block => {
        const { name } = block;
        if (name && !this.restrictions[`isAll${this.capitalize(name)}`]) {
          const grid = this.$refs[`simpleGrid-${name}`];
          grid ? grid[0].getServerData() : null;
        }
      });
    },
    clearData() {
      this.restrictionsBlocks.forEach(block => {
        const { name } = block;
        if (name) {
          const grid = this.$refs[`simpleGrid-${name}`];
          grid && grid[0] ? grid[0].records = [] : null;
          this.dataLoaded[name] = false;
        }
      })
    },
    onClickEdit() {
      this.$emit('edit:restrictions', this.dashboard);
    },
    onClickAll(name) {
      this.$emit('show:modal', name)
    },
    async getRestrictions() {
      const { restrictions } = this.dashboard;
      if (restrictions) {
        this.restrictions = restrictions;
      } else {
        this.setDefaultRestrictions();
      }
    },
    setServices(name) {
      const type = this.restrictionsBlocks.find(block => block.name === name);
      if (type) {
        type.service = this[`${name}Service`];
        type.filter = { id: { or: this.restrictions[name] } };
        type.method = 'grid'
      }
    },
    clearServices(name) {
      const type = this.restrictionsBlocks.find(block => block.name === name);
      if (type) {
        type.service = this[`${name}Service`];
        type.filter = { id: { or: this.restrictions[name] } };
        type.method = 'grid'
      }
    },
    simpleGridDataLoaded(e) {
      this.dataLoaded[e] = true;
    },
    setDefaultRestrictions() {
      this.restrictions = {
          distributors: [],
          positions: [],
          roles: [],
          isAllRoles: false,
          isAllDistributors: false,
          isAllPositions: false,
      };
    },
    capitalize: (text) => text[0].toUpperCase() + text.toLowerCase().slice(1)
  },
}
</script>

<style lang="scss">
.dashboard-panel-restrictions {
  .prospace-panel-block-header {
    margin-bottom: 0;
    & + .prospace-panel-block__body {
      margin-top: 10px;
    }
  }
  .p-datatable {
    .prospace-list-items-table {
      .p-datatable-tbody {
        &  > tr {
          height: 34px;
        }
      }
    }
  }
  .prospace-panel-block {
    &:last-child {
      padding-bottom: 10px;
    }
  }
}
</style>
