<template>
  <ProSpaceGeoLayout :routeName="$route.name" isParent>
    <template #top>
      <ProSpaceTopPanel :header="this.$t('taskAssignments')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <ProSpaceDots
            style="margin-left: 10px"
            :options="wrapperCheckRoles(dotsHeaderOptions)"
            :isSquare="true"
            @action="(action) => handleHeaderDots(action)"
          ></ProSpaceDots>
          <ProSpaceButton
            v-if="checkAccess('add-button')"
            type="primary"
            icon="plus"
            :label="this.$t('newTaskTxt')"
            :disabled="!selectedNode.code"
            @click="
              $router.push({
                path: `/taskservice/taskassignment`,
                query: {
                  code: this.selectedNode ? this.selectedNode.code : null,
                },
              })
            "
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #left>
      <ProSpaceLeftPanel
        :panelName="$t('topics')"
        searchable
        @search="handlerSearch"
        :searchPlaceholder="$t('searchTxt')"
        :disabled="disabledLeftPanel"
      >
        <template #center>
          <ProSpaceTree
            ref="tree"
            searchName="name"
            getNodeMethod="treeWithAll"
            propKey="id"
            propLabel="name"
            counterField="unreadCounter"
            defaultSortField="name"
            :service="baseTypeService"
            :prop-parent-id="null"
            :defaultFilter="{
              systemDirectoryCode: { and: ['Task type'] },
              disabled: { and: [false] },
            }"
            @select="nodeSelect"
            @loaded="updateUnreaded"
          />
        </template>
      </ProSpaceLeftPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
                clear();
              }
            "
            :fastFilters="fastFilters"
            :unicKeyStorage="taskAssignmentService.constructor.name + 'grid'"
            @apply="submitFilter"
            ref="filter-panel"
            filter-model="name"
            :disabled="disabledFilterPanel"
            :ignore-clear-keys="['type']"
            :noMountedRequest="true"
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceMultiSelect
                v-if="userinfo.orgLinkType !== 'Mars' && checkAccess('assign-ProSpaceMultiSelect')"
                :localization="$t"
                v-model="filter.assign"
                :service="taskAssignmentService"
                getMethod="getAssignOptions"
                optionLabel="label"
                optionValue="value"
                :placeholderValue="$t('assign')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterDistributor
                v-if="checkAccess('distributors-ProSpaceFilterSearchfield')"
                v-model="filter.taskAssignmentDistributorId"
                :hint="$t('hintFilterDistrTasks')"
                @apply="emitFilter"
              />
              <ProSpaceFilterDatesRange
                v-model="filter.startAt"
                :placeholder-value="$t('startedAt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterDatesRange
                v-model="filter.endAt"
                :placeholder-value="$t('finishedAt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterSearchfield
                v-if="checkAccess('distributorStructureId-ProSpaceFilterSearchfield')"
                v-model="filter.distributorStructureId"
                :closeTxt="$t('closeTxt')"
                :placeholderValue="$t('branch')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :service="distributorStructureService"
                :txtTitleModal="$t('distrsAndBranches')"
                :txtNameColumn="$t('distributor')"
                get-method="getStructure"
                optionLabel="name"
                optionValue="id"
                mode="tree"
                :hint="$t('hintFilterBranchNews')"
                @apply="emitFilter"
              >
                <template #modal="{model, setModel, applyFn, selectedArray, isWatchMode}">
                  <DistributorHierarchySelectModal
                    :modelValue="model"
                    :service="distributorStructureService"
                    :checkedRows="selectedArray"
                    :title-modal="$t('distrsAndBranches')"
                    get-method="allTree"
                    expandAll
                    :read-only="isWatchMode"
                    @update:modelValue="setModel"
                    @click.stop
                    @apply="applyFn"
                  />
                </template>
              </ProSpaceFilterSearchfield>
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="
                  taskAssignmentService.constructor.name + 'grid'
                "
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceCalendar
            :key="'prospace-calendar' + userinfo.dateFormat"
            ref="calendar"
            v-model="selectedModel"
            :service="taskAssignmentService"
            :getMethod="gridGetMethod"
            :locale="$i18n.locale"
            :t="$t"
            :date-format="userinfo.dateFormat"
            selectionMode="single"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            @loaded-grid="updateGridUnreaded"
            @loading="handlerLoaderCalendar"
            @dbclick="toItem"
            @selected-change="checkSelect"
          >
            <template #switch="{ view }">
              <ProSpaceButton
                @click="toggleMars"
                type="tertiary"
                v-if="view !== 'prospaceGridList'"
              >
                <template #label>
                  <ProSpaceHLayout :gap="5">
                    {{ $t("mars") }}
                    <div class="p-checkbox p-component" aria-hidden="true">
                      <div
                        :class="['p-checkbox-box', { 'p-highlight': isMars }]"
                        role="checkbox"
                      >
                        <span
                          :class="[
                            'p-checkbox-icon',
                            { 'pi pi-check': isMars },
                          ]"
                        ></span>
                      </div>
                    </div>
                  </ProSpaceHLayout>
                </template>
              </ProSpaceButton>
            </template>
            <template #day-cell="{ date }">
              <ProSpaceHLayout :gap="5" v-if="isMars">
                <ProSpaceTag :text="getMarsPeriod(date)" type="light-blue" />
                <ProSpaceLabel
                  :text="getMarsWeekDay(date)"
                  :size="11"
                  :line="20"
                  color="gray"
                />
              </ProSpaceHLayout>
            </template>
            <template #columns>
              <Column
                field="name"
                :header="this.$t('taskAssignment')"
                sortable
                headerClass="move-sort-head"
                style="min-width: 300px;"
                frozen
                class="column-table-header-cell"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.typeName"
                    :status="getStatus(field.data)"
                    :title="field.data.name"
                    :description="`${field.data.description || ''}`"
                    @click-on-title="toItem(field.data)"
                    :isRemoveHTMLtags="false"
                    :isHtml="true"
                    :calcWidthStatusId="false"
                    :width-status="100"
                  />
                </template>
              </Column>

              <Column
                field="startAt"
                :header="$t('taskAssignmentStartAt')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDateColumn
                    :date="field.data.startAt"
                    :format="userinfo.dateFormat"
                  />
                </template>
              </Column>

              <Column
                field="endAt"
                :header="$t('taskAssignmentEndAt')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDateColumn
                    :date="field.data.endAt"
                    :format="userinfo.dateFormat"
                  />
                </template>
              </Column>

              <Column
                v-if="checkAccess('distributors-Column')"
                field="distributorName"
                sortable
                :header="$t('distributors')"
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn
                    :title="!isDistrList(field.data) ? field.data.distributorName : $t('distrList')"
                  >
                    <template v-if="!(field.data.isAllDistributors || isDistrList(field.data))">
                      {{ field.data.distributorName }}
                    </template>
                    <template v-else>
                      <ProSpaceAction
                        :text="field.data.isAllDistributors ? `${$t('allTxt')} ${$t('distributors')}` : $t('distrList')"
                        @click="distrListShow(field.data)"
                      />
                    </template>
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="distributorStructureName"
                sortable
                :header="$t('branches')"
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn
                    :title="!isDistrStructureList(field.data) ? field.data.distributorStructureName : $t('listBranches')
                    "
                  >
                    <template v-if="!(field.data.IsPositionsRestricted || isDistrStructureList(field.data))">
                      {{ field.data.distributorStructureName }}
                    </template>
                    <template v-else>
                      <ProSpaceAction
                        :text="$t('listBranches')"
                        @click="distrStructureListShow(field.data)"
                      />
                    </template>
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column field="tags" :header="$t('taskAssignmentTags')" sortable>
                <template #body="field">
                  <ProSpaceTagsColumn
                    :tags="
                      field.data.tags
                        ? field.data.tags.split('#').filter((t) => !!t)
                        : []
                    "
                    :limit="2"
                  />
                </template>
              </Column>

              <Column field="actions" class="column-end column-end-120" frozen alignFrozen="right">
                <template #body="field">
                  <ProSpaceHLayout justify-content="flex-end" :gap="10">
                    <ProSpaceIcon
                      v-if="field.data.masterId && checkAccess('allIcon-ProSpaceIconsGrid')"
                      icon="boxes"
                      :hint="$t('taskAssignmentMasterHint')"
                    />
                    <ProSpaceIconsGrid
                      :repeatHint="$t('repeatHint')"
                      :clipHint="$t('clipHint')"
                      :exclamationHint="$t('exclamationHint')"
                      :bookmarkHint="$t('bookmarkHint')"
                      :isClipExist="field.data.isAnyAttachment"
                      :isRepeatExist="field.data.needRepeat"
                    />
                    <ProSpaceIcon
                      v-if="field.data.isExpired"
                      icon="due"
                      :hint="$t('overdueHint')"
                      style="background: var(--prospace-ui-red)"
                    />
                    <ProSpaceDots
                      :options="localWrapperCheckRoles(dotsOptions, field.data)"
                      @action="(action) => handleDots(action, field.data)"
                    />
                  </ProSpaceHLayout>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceCalendar>
          <DistributorHierarchySelectModal
            v-model="visibleDistrStructureModal"
            expandAll
            :getMethod="'getTaskAssignmentViewTree'"
            :readOnly="true"
            :titleModal="$t('distributorStructure')"
            :service="distributorStructureService"
            :checkedRows="distributorStructures"
          />
          <DistributorSelectModal
            v-model="showDistributorModal"
            selectionMode="single"
            readOnly
            :modalTitle="$t('listDistributors')"
            :service="distributorGridService"
            :getMethod="distributorGetMethod"
            :defaultFilter="distributorGridFilter"
            :filterModel="distributorFilterModel"
            :filterPrefix="distributorFilterPrefix"
          />
        </template>
      </ProSpaceGeoLayout>
      <ProSpaceHistoryModal
        ref="history"
        v-model="showHistoryModal"
        modelName="TaskAssignmentApiModel"
        @close="closeHistory"
      />
    </template>
    <template #right>
      <TaskAssignmentPanel
        v-if="showRightPanel"
        ref="right-panel"
        :service="taskAssignmentService"
        :options="dotsOptions"
        :filterAccessDots="localWrapperCheckRoles"
        :checkAccess="checkAccess"
        :ids="getIds"
        @edit="editItem"
        @delete="deleteItem"
        @history="showHistory"
        @cancel="cancelTask"
        @refresh="actionUpdate"
      />
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceFlashMessage
    v-if="showModal"
    v-model="showModal"
    type="default"
    icon="flash-warning"
    :title="$t('seriesTask')"
    :applyTxt="$t('select')"
    :cancelTxt="$t('reject')"
    @apply="openEdit"
    @cancel="editType='single'"
  >
    <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('editSeriesTask')"
      ></div>
      <ProSpaceRadioButton v-model="editType" :values="editTypes" direction="column" />
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
  <ProSpaceFlashMessage
    v-if="showDeleteSeriesModal"
    v-model="showDeleteSeriesModal"
    type="default"
    icon="flash-warning"
    :title="$t('seriesTask')"
    :applyTxt="$t('select')"
    :cancelTxt="$t('reject')"
    @apply="deleteItemAction"
    @cancel="editType='single'"
  >
    <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteSeriesTask')"
      ></div>
      <ProSpaceRadioButton v-model="editType" :values="editTypes" />
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingConfirmation')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="deleteItemAction"
  >
    <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteTask', { name: modalModel.name })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
  <ProSpaceFlashMessage
    v-if="showCancelModal"
    v-model="showCancelModal"
    type="default"
    icon="flash-warning"
    :title="$t('taskCancellationApprove')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyCancelTask"
  >
    <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('cancelTask', { name: cancelTaskModel.name})"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceFilterSearchfield,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceModal,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceDateColumn,
  ProSpaceTagsColumn,
  ProSpaceDefaultTextColumn,
  ProSpaceEmptyGrid,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpaceVLayout,
  ProSpaceFilterContainsChips,
  ProSpaceFilterDatesRange,
  ProSpaceInputText,
  ProSpaceDropdown,
  ProSpaceCalendar,
  ProSpaceTag,
  ProSpaceHLayout,
  ProSpaceLabel,
  ProSpaceIconsGrid,
  ProSpaceIcon,
  ProSpaceDateTimeColumn,
  ProSpaceAction,
  ProSpaceSimpleGrid,
  ProSpaceFlashMessage,
  ProSpaceRadioButton
} from "@prospace/prospace-components-library";
import { TaskAssignmentService } from "../../services/TaskAssignmentService.js";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import { DistributorService } from "@masterdata/services/DistributorService.js";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";

import Column from "primevue/column";
import TaskAssignmentPanel from "./components/TaskAssignmentPanel";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import DistributorSelectModal from "@modals/DistributorSelectModal";
import DistributorHierarchySelectModal from "@modals/DistributorHierarchySelectModal.vue";
const _ = require("lodash");
import { mapGetters } from "vuex";
import MarsDate from "../../../../../Frontends/Common/utils/MarsDate.js";
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import SvgIcon from "../../../../../Frontends/Common/components/SvgIcon.vue";
import statusAccessMixin from "../../../../../Frontends/Common/utils/elementAccess/statusAccessMixin";
import {
  useStatistic
} from "@composes"
import ProSpaceFilterDistributor from "@filters/ProSpaceFilterDistributor.vue";
import { YesNoService } from "@base/services/YesNoService.js";
export default {
  mixins: [accessMixin, statusAccessMixin],
  components: {
    SvgIcon,
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceFilterSearchfield,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    ProSpaceModal,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceDateColumn,
    ProSpaceTagsColumn,
    ProSpaceDefaultTextColumn,
    ProSpaceEmptyGrid,
    TaskAssignmentPanel,
    ProSpaceHistoryModal,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpaceVLayout,
    ProSpaceFilterContainsChips,
    ProSpaceFilterDatesRange,
    ProSpaceInputText,
    ProSpaceDropdown,
    ProSpaceCalendar,
    ProSpaceTag,
    ProSpaceHLayout,
    ProSpaceLabel,
    ProSpaceIconsGrid,
    ProSpaceIcon,
    ProSpaceDateTimeColumn,
    ProSpaceAction,
    ProSpaceSimpleGrid,
    ProSpaceFlashMessage,
    ProSpaceRadioButton,
    DistributorSelectModal,
    DistributorHierarchySelectModal,
    ProSpaceFilterDistributor
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return {
      setStatistic, distActions
    }
  },
  data() {
    let statusAccessConfig = {
      "Distributor user": {
        taskDraft: "ALIVE",
        taskModer: "ALIVE",
        other: "NONE",
      },
      "Distributor manager": {
        taskDraft: "ALIVE",
        taskModer: "ALIVE",
        other: "NONE",
      },
      "Mars user": { taskDraft: "ALIVE", taskModer: "ALIVE", other: "NONE" },
      "Mars manager": { taskDraft: "ALIVE", taskModer: "ALIVE", other: "NONE" },
      "Content manager": {
        taskDraft: "ALIVE",
        taskModer: "ALIVE",
        other: "NONE",
      },
      "Support administrator": {
        taskDraft: "ALIVE",
        taskModer: "ALIVE",
        taskPublished: "ALIVE",
        other: "NONE",
      },
      "Technical Support": {
        taskDraft: "ALIVE",
        taskModer: "ALIVE",
        taskPublished: "ALIVE",
        other: "NONE",
      },
      "Content manager plus": {
        taskDraft: "ALIVE",
        taskModer: "ALIVE",
        taskPublished: "ALIVE",
        other: "NONE",
      },
    };

    const cancelStatusConfig = {
      "Content manager": {
        taskPublished: "ALIVE",
        other: "NONE"
      },
      "Support administrator": {
        taskPublished: "ALIVE",
        other: "NONE"
      },
      "Technical Support": {
        taskPublished: "ALIVE",
        other: "NONE"
      },
      "Content manager plus": {
        taskPublished: "ALIVE",
        other: "NONE"
      },
    };

    return {
      editType: "single",
      selectedNode: {},

      showModal: false,
      showHistoryModal: false,
      // Grid Panel
      selectedModel: null,
      spKey: 0,
      spHide: false,

      gridGetMethod: "grid",
      gridDefaultFilter: {},

      // Service
      taskAssignmentService: new TaskAssignmentService(),
      baseTypeService: new BaseTypeService(),
      distributorService: new DistributorService(),
      distributorStructureService: new DistributorStructureService(),

      //InlinePanel
      masterId: null,
      isEdit: false,
      isCreate: false,
      modalModel: {},
      isMars: false,

      // Multi filter
      modelFastFilter: {
        label: this.$t("allTxt"),
        field: "disabled",
        value: false,
      },

      //Distr Structure modal
      visibleDistrStructureModal: false,
      distributorStructures: null,

      //Distr modal
      showDistributorModal: false,
      distributorGridFilter: null,
      modalContentStyle: {
        overflow: "hidden",
        height: "calc(95vh - 180px)",
        width: "95vw",
      },
      distributorGridModalHeaders: [
        {
          name: this.$t("code"),
          value: "code",
          f: (item) => item,
        },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
      ],
      statusAccessConfig: {
        "delete-option": statusAccessConfig,
        "edit-option": statusAccessConfig,
        "cancel-option": cancelStatusConfig
      },
      showDeleteSeriesModal: false,
      showDeleteModal: false,
      showCancelModal: false,
      disabledLeftPanel: false,
      disabledFilterPanel: false,
      cancelTaskModel: null,
      showRightPanel: null
    };
  },
  watch: {
    lastChangedNotification: {
      handler(val) {
        if (val && val.messageData && val.messageData.Title !== "Tasks") return;
        this.updateUnreaded();
      },
    },
  },
  mounted() {
    this.trySetRouteFastFilter();
    this.trySetRouteLeftTree();
    this.loadRoleAccess();
    this.getStorageStateMarsCalendar();
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
      lastChangedNotification: "notifications/lastChanged",
      notificationMessages: "notifications/notificationMessages",
      getCounter: "notifications/getCounter",
    }),
    getIds() {
      if (!this.selectedModel)
        return [];

      return Array.isArray(this.selectedModel) ? this.selectedModel.map((i) => i.id) : [Number(this.selectedModel.id)];
    },
    editTypes() {
      return [
        {
          value: "single",
          label: !this.showDeleteSeriesModal
            ? this.$t("singleTaskEdit")
            : this.$t("singleTaskDelete"),
        },
        {
          value: "series",
          label: !this.showDeleteSeriesModal
            ? this.$t("seriesTaskEdit")
            : this.$t("seriesTaskDelete"),
        },
      ];
    },
    taskNotifications() {
      return this.notificationMessages.filter(
        (n) => n.messageData.Title === "Tasks"
      );
    },
    unreadTaskNotifications() {
      return this.taskNotifications.filter((n) => n.readed === false);
    },
    deletedTaskNotifications() {
      return this.taskNotifications.filter(
        (n) =>
          n.messageData.NotificationType === "taskPublishDeleted" &&
          n.readed === false
      );
    },
    customServices() {
      let filters = [
        {
          service: new TaskAssignmentService(),
          getMethod: "getStatuses",
          optionValue: "value",
          optionLabel: "text",
          type: "checkboxes",
          model: "status",
          label: this.$t("status"),
          selected: {},
          needSelectAll: true,
        },
        {
          type: "chips",
          model: "tags",
          label: this.$t("taskAssignmentTags"),
          selected: {},
        },
        {
          service: new YesNoService(),
          getMethod: "get",
          optionValue: "value",
          optionLabel: "label",
          type: "checkboxes",
          model: "isAnyAttachment",
          label: this.$t("documentIsAnyAttachment"),
          selected: {},
          needSelectAll: true,
        },
        {
          service: new YesNoService(),
          getMethod: "get",
          optionValue: "value",
          optionLabel: "label",
          type: "checkboxes",
          model: "IsPositionsRestricted",
          label: this.$t("taskAssignmentTaskByPosition"),
          selected: {},
          needSelectAll: true,
        },
        {
          service: new YesNoService(),
          getMethod: "get",
          optionValue: "value",
          optionLabel: "label",
          type: "checkboxes",
          model: "ConfirmationByAuthorizedPerson",
          label: this.$t("taskAssignmentConfirmationOfCompletion"),
          selected: {},
          needSelectAll: true,
        },
        {
          type: "chips",
          model: "author",
          label: this.$t("documentAuthor"),
          selected: {},
        },
      ];

      if (this.checkAccess("metainfo-by-block"))
        filters.push(
          {
            type: "chips",
            model: "createdBy",
            label: this.$t("createdByTxt"),
            selected: {},
          },
          {
            type: "chips",
            model: "updatedBy",
            label: this.$t("updatedByTxt"),
            selected: {},
          }
        );

      filters.push(
        {
          type: "dates-range-time",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range-time",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        }
      );

      return filters;
    },
    dotsHeaderOptions() {
      return [
        {
          name: "export_csv",
          text: this.$t("export_csv"),
          icon: "upload",
          liteRef: "export-csv-option",
        },
        {
          name: "export_xlsx",
          text: this.$t("export_xlsx"),
          icon: "upload",
          liteRef: "export-xlsx-option",
        },
      ];
    },
    dotsOptions() {
      let res = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
          liteRef: "edit-option",
        },
        {
          name: "cancelTask",
          text: this.$t("cancelInf"),
          icon: "ban",
          method: this.cancelTask,
          liteRef: "cancel-option",
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
          liteRef: "delete-option",
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
          liteRef: "history-option",
        },

      ];
      if (this.modelFastFilter.label === this.$t("deleted")) {
        return res.filter(
          (option) => option.name !== "edit" && option.name !== "delete"
        );
      }
      return res;
    },
    isDeletedTab() {
      return this.modelFastFilter.label === this.$t("deleted");
    },
    fastFilters() {
      return [
        { label: this.$t("allTxt"), field: "disabled", value: false },
        {
          label: this.$t("taskApprovingTxt"),
          value: [
            { field: "status", value: "taskApproving" },
            { field: "disabled", value: false },
          ],
        },
        {
          label: this.$t("taskPublishedTxt"),
          value: [
            { field: "status", value: "taskPublished" },
            { field: "disabled", value: false },
          ],
        },
        {
          label: this.$t("taskModerTxt"),
          value: [
            { field: "status", value: "taskModer" },
            { field: "disabled", value: false },
          ],
          isHidden: !this.checkAccess("taskModer-FastFilters")
        },
        {
          label: this.$t("isExpiredTxt"),
          value: [
            { field: "isExpired", value: true },
            { field: "disabled", value: false },
          ],
        },
        {
          label: this.$t("taskDraftTxt"),
          value: [
            { field: "status", value: "taskDraft" },
            { field: "disabled", value: false },
          ],
          isHidden: !this.checkAccess("taskDraft-FastFilters")
        },
        {
          label: this.$t("deleted"),
          value: [
            { field: "isMaster", value: false },
            { field: "disabled", value: true },
          ],
          isHidden: !this.checkDeleteAccess()
        },
        {
          label: this.$t("taskCanceledTxt"),
          value: [
            { field: "status", value: "taskCanceled" },
            { field: "disabled", value: false },
          ],
          //isHidden: !this.checkDeleteAccess()
        }
      ];
    },
    stepItem() {
      return {
        workflowEntityId: "",
        stepNo: 0,
        status: "",
        comment: "",
        stepType: "",
        stepTypeMessage:  "",
        attachments: [],
        taskAssignmentId: 0,
        securityUserId: this.userinfo.id,
        securityUserFirstName: this.userinfo.firstName,
        securityUserLastName: this.userinfo.lastName,
        securityUserType: this.userinfo.orgLinkType,
        confirmDate: null,
      };
    },
  },
  methods: {
    checkSelect(val) {
      if (!this.showRightPanel || val[0] && val[0].id !== this.showRightPanel[0].id) this.showRightPanel = val;
      else if (val[0] && val[0].id === this.showRightPanel[0].id) {
        this.showRightPanel = null
      }
    },
    clear() {
      this.selectedModel = null;
    },
    handlerLoaderCalendar(loading) {
      this.disabledLeftPanel = loading
      this.disabledFilterPanel = loading
    },
    trySetRouteFastFilter() {
      let params = this.$route.params;
      if (params.fastFilter) {
        let fastFilter = this.fastFilters.find(
          (x) => x.label === params.fastFilter
        );
        if (fastFilter) {
          const filterPanel = this.$refs["filter-panel"];
          this.modelFastFilter = fastFilter;
          filterPanel.fastFilter = fastFilter;
          this.$refs["filter-panel"].submitFilter(true);
        }
      }
    },
    trySetRouteLeftTree() {
      let params = this.$route.params;
      if (params.leftTree) {
        const node = JSON.parse(params.leftTree);
        this.$refs.tree.setLocalStorage(node);
      }
    },
    handleHeaderDots(action) {
      if (action === "export_csv") {
        this.actionExport("CSV");
      } else if (action === "export_xlsx") {
        this.actionExport("XLSX");
      }
    },
    async actionExport(format) {
      await this.taskAssignmentService.export(
        this.$refs.calendar.getFilter(),
        format,
        this.taskAssignmentService.assignOptions
      );
    },
    localWrapperCheckRoles(dotsOptions, data) {
      return this.wrapperStatusCheckRoles(
        this.wrapperCheckRoles(dotsOptions, data),
        data
      );
    },
    toggleMars() {
      this.isMars = !this.isMars;
      this.saveStorageStateMarsCalendar();
    },
    getMarsPeriod(date) {
      return `P${new MarsDate(date).getPeriod().toString().padStart(2, "0")}`;
    },
    getMarsWeekDay(date) {
      const marsDate = new MarsDate(date);
      const week = marsDate.getWeek().toString();
      const day = marsDate.getDay().toString();
      return `W${week}D${day}`;
    },
    getStorageStateMarsCalendar() {
      const key = this.getStorageKeyMarsCalendar();
      const state = localStorage.getItem(key);
      if (!state) {
        this.saveStorageStateMarsCalendar();
      } else {
        const value = JSON.parse(state);
        this.isMars = value;
      }
    },
    saveStorageStateMarsCalendar() {
      const key = this.getStorageKeyMarsCalendar();
      localStorage.setItem(key, JSON.stringify(this.isMars));
    },
    getStorageKeyMarsCalendar() {
      return btoa(window.location.href + "MarsCalendar");
    },
    handlerSearch(value) {
      this.$refs.tree.handlerSearch(value);
    },
    updateDeleted() {
      let deletedIds = this.deletedTaskNotifications
        .filter((un) => !un.messageData.DeletedInfo)
        .map((un) => un.messageData.Record.Id);
      let extraIds = this.unreadTaskNotifications
        .filter((un) => un.messageData.DeletedInfo)
        .flatMap((un) => un.messageData.DeletedInfo.flatMap((x) => x.Ids));
      deletedIds = deletedIds.concat(extraIds);
      let toRead = this.unreadTaskNotifications.map((un) => {
        if (deletedIds.includes(un.messageData.Record.Id))
          return un.messageData.Record;
        else if (un.messageData.UnreadInfo) {
          let r = un.messageData.UnreadInfo.map((x) => {
            let ids = x.Ids.filter((x) => deletedIds.includes(x));
            if (ids.length > 0) {
              return {
                Type: x.Type,
                Ids: ids,
              };
            }
            return null;
          }).filter((x) => x);
          if (r.length > 0) return r;
        }
        return null;
      });
      toRead.forEach((n) => {
        if (Array.isArray(n))
          n.forEach((x) => x.Ids.forEach((id) => this.read(id, x.Type)));
        else if (n) this.read(n.Id, n.Type);
      });
    },
    read(id, type) {
      this.$store.dispatch("notifications/updateReadedRecord", {
        title: "Tasks",
        id: Number(id),
      });
      this.$store.dispatch("notifications/updateReadedRecord", {
        title: "Tasks",
        id: "taskPublishStaticId",
        realId: Number(id),
        type: type,
        groupUpdate: true,
      });
      this.$store.dispatch("notifications/updateReadedRecord", {
        title: "Tasks",
        id: "taskNewStaticId",
        realId: Number(id),
        type: type,
        groupUpdate: true,
      });
    },
    updateUnreaded(updateGrid = true) {
      this.updateDeleted();
      let allNode = this.getAllNode();
      if (allNode)
        allNode.unreadCounter = this.getCounter(this.unreadTaskNotifications);
      let nodes = this.getRealNodes();
      if (nodes)
        nodes.forEach((n) => {
          var c = this.unreadTaskNotifications.filter(
            (un) => un.messageData.Record.Type === n.code
          ).length;
          this.unreadTaskNotifications
            .filter((un) => un.messageData.UnreadInfo)
            .forEach((un) => {
              let nui = un.messageData.UnreadInfo.find(
                (ui) => ui.Type === n.code
              );
              if (nui) c += nui.Ids.length;
            });
          n.unreadCounter = c;
        });
      if (updateGrid) this.updateGridUnreaded(false);
    },
    updateGridUnreaded(updateTree = true) {
      if (updateTree) this.updateUnreaded(false);
      let unreaded =
        !this.selectedNode.id || this.selectedNode.id === 0
          ? this.unreadTaskNotifications.filter(
              (x) => !x.messageData.UnreadCount
            )
          : this.unreadTaskNotifications.filter(
              (u) => u.messageData.Record.Type === this.selectedNode.code
            );
      let ids = unreaded.map((u) => u.messageData.Record.Id);
      if (!Array.isArray(ids)) ids = [];

      this.unreadTaskNotifications
        .filter((un) => un.messageData.UnreadInfo)
        .forEach((un) => {
          if (this.selectedNode.code !== "all") {
            let nui = un.messageData.UnreadInfo.find(
              (ui) => ui.Type === this.selectedNode.code
            );
            if (nui) ids = ids.concat(nui.Ids);
          } else {
            un.messageData.UnreadInfo.forEach((x) => (ids = ids.concat(x.Ids)));
          }
        });
      if (
        this.$refs.calendar &&
        this.$refs.calendar.grid &&
        this.$refs.calendar.grid.$refs.grid &&
        this.$refs.calendar.grid.$refs.grid.records
      ) {
        this.$refs.calendar.grid.$refs.grid.records
          .filter((r) => r && ids.includes(r.id))
          .forEach((r) => (r.unread = true));
        this.$refs.calendar.grid.$refs.grid.records
          .filter((r) => r && !ids.includes(r.id))
          .forEach((r) => (r.unread = false));
      }
    },
    getAllNode() {
      if (this.$refs.tree && this.$refs.tree.nodes)
        return this.$refs.tree.nodes.find((n) => n.id === 0);
    },
    getRealNodes() {
      if (this.$refs.tree && this.$refs.tree.nodes)
        return this.$refs.tree.nodes.filter((n) => n.id !== 0);
    },
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.name;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    cancelTask(record) {
      this.showCancelModal = true;
      this.cancelTaskModel = record;
      return;
    },
    applyCancelTask() {
      this.handlerLoaderCalendar(true);
      let stepItem = this.stepItem;
      stepItem.afterPublish = !["taskModer", "taskDraft"].includes(
        this.cancelTaskModel.status
      );
      stepItem.workflowEntityId = this.cancelTaskModel.workflowEntityId;
      stepItem.stepNo = this.cancelTaskModel.currentStepNo;
      stepItem.status = this.cancelTaskModel.status;
      stepItem.comment = "";
      stepItem.stepType = "reject";
      stepItem.stepTypeMessage = this.cancelTaskModel.status ? this.cancelTaskModel.status + "Step" : "";
      stepItem.taskAssignmentId = this.cancelTaskModel.id;
      stepItem.confirmDate = new Date();

      return this.taskAssignmentService.rejectStep(stepItem).then(() => {
        this.selectedModel = null;
        this.actionUpdate();
        this.cancelTaskModel = null;
        this.handlerLoaderCalendar(false);
      })
      .catch(() => {
        this.handlerLoaderCalendar(false);
      });
    },
    toItem(data) {
      const url = `/taskservice/taskassignment/${data.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    submitFilter({ filter, search }) {

      const distributorFilterExists = filter &&
          filter.taskAssignmentDistributorId &&
          filter.taskAssignmentDistributorId.or &&
          !!filter.taskAssignmentDistributorId.or.length

        const distributorStructureFilterExists = filter &&
          filter.distributorStructureCode &&
          filter.distributorStructureCode.or &&
          !!filter.distributorStructureCode.or.length;

        const distributorStructureIdFilterExists = filter &&
          filter.distributorStructureId &&
          filter.distributorStructureId.or &&
          !!filter.distributorStructureId.or.length;

        if (distributorFilterExists && !distributorStructureFilterExists && !distributorStructureIdFilterExists) {
          filter.or = {
            or: [
              {
                and: [
                  { or: filter.taskAssignmentDistributorId.or.map(d => { return { taskAssignmentDistributorId: d }}) },
                  { or: [{ taskAssignmentDistributorDisabled: false }] },
                ]
              },
              { isAllDistributors: true }
            ]
          };

          delete filter.taskAssignmentDistributorId;
        }
        else if (!distributorFilterExists && distributorStructureFilterExists && !distributorStructureIdFilterExists) {
          filter.or = {
            or: [
              {
                and: [
                  { or: filter.distributorStructureCode.or.map(ds => { return { distributorStructureCode: ds }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              }
            ]
          };

          delete filter.distributorStructureCode;
        }
        else if (distributorFilterExists && distributorStructureFilterExists && !distributorStructureIdFilterExists) {
          filter.and = {
            or: [
              {
                and: [
                  { or: filter.taskAssignmentDistributorId.or.map(d => { return { taskAssignmentDistributorId: d }}) },
                  { or: [{ taskAssignmentDistributorDisabled: false }] },
                ]
              },
              { isAllDistributors: true }
            ],
            and: [
              {
                and: [
                  { or: filter.distributorStructureCode.or.map(ds => { return { distributorStructureCode: ds }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              }
            ]
          };

          delete filter.taskAssignmentDistributorId;
          delete filter.distributorStructureCode;
        }
        else if (distributorFilterExists && !distributorStructureFilterExists && distributorStructureIdFilterExists) {
          filter.and = {
            or: [
              {
                and: [
                  { or: filter.taskAssignmentDistributorId.or.map(d => { return { taskAssignmentDistributorId: d }}) },
                  { or: [{ taskAssignmentDistributorDisabled: false }] },
                ]
              },
              { isAllDistributors: true }
            ],
            and: [
              {
                and: [
                  { or: filter.distributorStructureId.or.map(ds => { return { distributorStructureId: ds }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              }
            ]
          };

          delete filter.taskAssignmentDistributorId;
          delete filter.distributorStructureId;
        }
        else if (!distributorFilterExists && distributorStructureFilterExists && distributorStructureIdFilterExists) {
          filter.and = {
            or: [
              {
                and: [
                  { or: filter.distributorStructureId.or.map(d => { return { distributorStructureId: d }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              },
              { isAllDistributors: true }
            ],
            and: [
              {
                and: [
                  { or: filter.distributorStructureCode.or.map(ds => { return { distributorStructureCode: ds }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              }
            ]
          };

          delete filter.distributorStructureId;
          delete filter.distributorStructureCode;
        }
        else if (distributorFilterExists && distributorStructureFilterExists && distributorStructureIdFilterExists) {
          filter.and = {
            or: [
              {
                and: [
                  { or: filter.taskAssignmentDistributorId.or.map(d => { return { taskAssignmentDistributorId: d }}) },
                  { or: [{ taskAssignmentDistributorDisabled: false }] },
                ]
              },
              { isAllDistributors: true }
            ],
            and: [
              {
                and: [
                  { or: filter.distributorStructureCode.or.map(ds => { return { distributorStructureCode: ds }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              }
            ],
            and: [
              {
                and: [
                  { or: filter.distributorStructureId.or.map(ds => { return { distributorStructureId: ds }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              }
            ]
          };

          delete filter.taskAssignmentDistributorId;
          delete filter.distributorStructureCode;
          delete filter.distributorStructureId;
        }
        else if (!distributorFilterExists && !distributorStructureFilterExists && distributorStructureIdFilterExists) {
          filter.or = {
            or: [
              {
                and: [
                  { or: filter.distributorStructureId.or.map(ds => { return { distributorStructureId: ds }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              }
            ]
          };

          delete filter.distributorStructureId;
        }
        const assignFilterExists = filter &&
          filter.assign &&
          filter.assign.or &&
          !!filter.assign.or.length;

        if (assignFilterExists) {
          let assignOptions = filter.assign.or;
          this.taskAssignmentService.setAssignOptions(assignOptions)

          delete filter.assign;
        } else {
          this.taskAssignmentService.clearAssignOption();
        }

        this.$refs.calendar.submitFilter(filter, search);
    },
    emitFilter() {
      this.$refs["filter-panel"].submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.$refs["filter-panel"].submitFilter();
    },
    actionUpdate() {
      this.$refs.calendar.staticUpdateFilter();
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    actionUpload() {
      this.taskAssignmentService.export(
        this.$refs.calendar.staticUpdateFilter()
      );
      this.$refs.grid.loading = false;
    },
    spToggle() {
      this.spHide = !this.spHide;
    },
    editItem(model) {
      if (!model) return;
      this.modalModel = _.cloneDeep(model);
      if (model.status === "taskPublished" && model.needRepeat) {
        this.showModal = true;
      } else this.openEdit(model);
    },
    openEdit(data) {
      let query = null;
      if (this.editType) query = { editType: this.editType };
      this.$router.push({
        path: `/taskservice/taskassignment/${this.modalModel.id || data.id}`,
        query,
      });
    },
    deleteItem(model) {
      if (!model) return;
      this.modalModel = _.cloneDeep(model);
      if (model.status === "taskPublished" && model.needRepeat) {
        this.showDeleteSeriesModal = true;
      } else {
        this.showDeleteModal = true;
      }
    },
    deleteItemAction() {
      let method = this.editType === "series" ? "deleteSeries" : "delete";
      this.taskAssignmentService[method](this.modalModel).then(() => {
        this.selectedModel = null;
        this.modalModel = null;
        this.actionUpdate();
      });
      this.editType = "single";
    },
    getStatus(data) {
      if (!data) {
        return { type: "default", text: "none" };
      }
      return this.taskAssignmentService.getStatusByValue(data.status);
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    nodeSelect(value) {
      if (this.selectedNode && this.selectedNode.key === value.key) return;
      this.gridGetMethod = "grid";
      this.selectedNode = value;
      let filter = { and: [value.code] };
      if (value.id == 0) filter = { and: [] };
      this.$refs["filter-panel"].filter["type"] = filter;
      this.$refs["filter-panel"].submitFilter();
    },
    isDistrList(item) {
      return item.distributorName === "-1";
    },
    distrListShow(item) {
      if(item.isAllDistributors) {
        this.distributorGridFilter = { disabled: { and: [false] } };
        this.distributorGridService = this.distributorService;
        this.distributorGetMethod = "grid";
        this.distributorFilterModel = ['code', 'name'];
        this.distributorFilterPrefix = "";
      } else {
        this.distributorGridFilter = {
          taskAssignmentId: { and: [item.id] },
          disabled: { and: [false] },
        };
        this.distributorGridService = this.taskAssignmentService;
        this.distributorGetMethod = "getDistributors";
        this.distributorFilterModel = ['distributorCode', 'distributorName'];
        this.distributorFilterPrefix = "distributor";
      }

      this.$nextTick(() => {
        this.showDistributorModal = true;
      });
    },
    distrListClose() {
      this.distributorGridFilter = null;
    },
    isDistrStructureList(item) {
      return item.distributorStructureName === "-1";
    },
    distrStructureListShow(item) {
      this.distributorStructures = item.distributorStructures;
      this.distributorStructureService.setTaskAssignmentId(item.id);
      this.$nextTick(() => {
        this.visibleDistrStructureModal = true;
      });
    }
  },
};
</script>
<style lang="scss">
.-tree {
  .radio-tabs {
    margin-top: 1px;
    .radio-tab:nth-child(2) {
      display: none !important;
    }
    &-body {
      margin: 10px;
    }
  }

  &__left-panel {
    border: 1px solid var(--prospace-ui-border-color);
  }
}
</style>
