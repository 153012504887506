<template>
  <ProSpaceGeoLayout :routeName="$route.name" isParent>
    <template #top>
      <ProSpaceTopPanel :header="this.$t('competences')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <IconButtonSettingsColumns @click="showModalSettingsColumns"/>
          <ProSpaceDots
            :options="dotsHeaderOptions"
            :isSquare="true"
            @action="(action) => handleHeaderDots(action)"
          />
          <ProSpaceButton
            style="padding-right: 5px"
            icon="chevron-down"
            type="secondary"
            :label="filterYearValue"
            :options="buttonOptions"
            iconLocation="right"
            @action="handlerActionOptionsButton"
          />
          <ProSpaceButton
            v-if="checkAccess('add-button')"
            type="primary"
            icon="plus"
            :label="this.$t('competenceNew')"
            @click="
              $router.push({
                path: `/competence/competence`,
                query: {
                  id: this.selectedNode ? this.selectedNode.id : null,
                },
              })
            "
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            ref="filter-panel"
            :filter-model="['competenceDirectoryName', 'code']"
            :txtResetFilters="$t('txtResetFilters')"
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            :unicKeyStorage="competenceService.constructor.name + 'grid'"
            :noMountedRequest="true"
            @apply="submitFilter"
            @update:modelFastFilter="updateFilter"
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceFilterDistributor
                v-if="checkAccess('distributors-ProSpaceFilterSearchfield')"
                v-model="filter.distributorId"
                :hint="$t('hintFilterCompetence')"
                @apply="emitFilter"
              />
              <ProSpaceFilterSearchfield
                v-model="filter.distributorStructureViewId"
                :closeTxt="$t('closeTxt')"
                :placeholderValue="$t('branch')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :service="distributorStructureService"
                :txtTitleModal="$t('distrsAndBranches')"
                :txtNameColumn="$t('distributor')"
                get-method="getStructure"
                optionLabel="name"
                optionValue="id"
                mode="tree"
                :hint="$t('hintFilterBranchCompetence')"
                @apply="emitFilter"
              >
                <template
                  #modal="{ model, setModel, applyFn, selectedArray, isWatchMode }"
                >
                  <DistributorHierarchySelectModal
                    :modelValue="model"
                    :service="distributorStructureService"
                    :checkedRows="selectedArray"
                    :title-modal="$t('distrsAndBranches')"
                    get-method="allFilial"
                    expandAll
                    isFilial
                    :read-only="isWatchMode"
                    @update:modelValue="setModel"
                    @click.stop
                    @apply="applyFn"
                  />
                </template>
              </ProSpaceFilterSearchfield>

              <ProSpaceFilterDatesRange
                v-model="filter.competenceTaskStartAt"
                :placeholder-value="$t('competenceTaskStartAtTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />

              <ProSpaceFilterDatesRange
                v-model="filter.competenceTaskEndAt"
                :placeholder-value="$t('competenceTaskEndAtTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />

              <ProSpaceMultiSelect
                :localization="$t"
                v-model="filter.competenceTaskStatus"
                :service="baseTypeService"
                getMethod="grid"
                optionLabel="name"
                optionValue="code"
                :default-filter="{
                  disabled: false,
                  systemDirectoryCode: 'CompetenceTaskStatus',
                }"
                :placeholderValue="$t('competenceTaskStatusTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :searchPlaceholder="$t('searchTxt')"
                @apply="emitFilter"
              />

              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="competenceService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            type="expand"
            getMethod="grid"
            selectionMode="single"
            defaultSortField="createdAt"
            childFilterField="competenceId"
            :defaultSortOrder="-1"
            :expandSortOrder="-1"
            :noMountedRequest="true"
            :service="competenceService"
            :expandService="competenceTaskService"
            :defaultTreeFilter="defaultChildFilter"
            :viewMoreTxt="$t('viewMore')"
            v-bind="gridPropsSettingsColumns"
            @selected-change="selectedHandler"
            @expand-change="expandHandler"
            @db-click="toItem"
          >
            <template #columns>
              <Column
                field="competenceDirectoryName"
                :header="$t('competenceDirectoryName')"
                sortable
                frozen
                class="column-table-header-cell"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :status="getStatus(field.data.status)"
                    :title="field.data.competenceDirectoryName"
                    :description="`${field.data.competenceDirectoryLevelName || ''}`"
                    @click-on-title="toItem(field.data)"
                  />
                </template>
              </Column>
              <Column
                v-if="checkAccess('distributors-ProSpaceFilterSearchfield')"
                field="distributorName"
                :header="$t('distributorNameTxt')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn
                    :title="field.data.distributorName"
                    class="padding-left-10"
                  >
                    {{ field.data.distributorName }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column
                field="distributorStructureViewName"
                :header="$t('distributorStructureViewNameTxt')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn
                    :title="field.data.distributorStructureViewName"
                    class="padding-left-10"
                  >
                    {{ field.data.distributorStructureViewName }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="startAt" :header="$t('competenceStartAt')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn
                    :title="formatMomentDate(field.data.startAt)"
                    class="padding-left-10"
                  >
                    {{ formatMomentDate(field.data.startAt) }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="endAt" :header="$t('competenceEndAt')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn
                    :title="formatMomentDate(field.data.endAt)"
                    class="padding-left-10"
                  >
                    {{ formatMomentDate(field.data.endAt) }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column
                field="developmentPercentage"
                :header="$t('competenceDevelopmentPercentage')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn
                    :title="field.data.developmentPercentage"
                    class="padding-left-10"
                  >
                    <strong style="font-size: 14px"
                      >{{ field.data.developmentPercentage }}%</strong
                    >
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column
                field="taskCompletedCount"
                :header="$t('competenceTaskCompletedCount')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn
                    :title="`${field.data.taskCompletedCount} / ${field.data.taskTotalCount}`"
                    class="padding-left-10"
                  >
                    <ProSpaceTag
                      :text="`${field.data.taskCompletedCount} / ${field.data.taskTotalCount}`"
                      type="secondary"
                      style="font-size: 12px"
                    />
                    <!-- <ProSpaceTag
                      :text="field.data.taskCompletedCount"
                      type="red-secondary"
                    /> -->
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column
                field="actions"
                class="column-end column-end-60"
                frozen
                alignFrozen="right"
              >
                <template #body="field">
                  <ProSpaceHLayout :gap="10" justify-content="flex-end">
                    <ProSpaceIconButton
                      v-if="
                        checkAccess('bell-action') &&
                        userinfo.orgLinkType === 'Mars' &&
                        field.data.hasSubscription
                      "
                      :hint="$t('updateSubscribeHint')"
                      icon="bell-fill"
                      coloredIcon
                      @click.stop="changeCompetenceSubscription(field.data)"
                    />
                    <ProSpaceIconButton
                      v-else-if="
                        checkAccess('bell-action') &&
                        userinfo.orgLinkType === 'Mars' &&
                        !field.data.hasSubscription
                      "
                      :hint="$t('updateSubscribeHint')"
                      icon="bell"
                      @click.stop="changeCompetenceSubscription(field.data)"
                    />
                    <ProSpaceDots
                      :options="localWrapperCheckRoles(competenceOptions, field.data)"
                      @action="(action) => handleDots(action, field.data)"
                    />
                  </ProSpaceHLayout>
                </template>
              </Column>
            </template>

            <template #expansion>
              <Column>
                <template #body="field">
                  <ProSpaceVLayout :gap="5">
                    <div
                      class="text-color-main text-bold text-ellipsis w-100"
                      style="line-height: 14px"
                    >
                      {{ field.data.name }}
                    </div>
                    <ProSpaceTag
                      style="max-width: 100%"
                      is-border-style
                      v-bind="
                        mapExpandStatuses.get(field.data.status) || {
                          type: 'draft',
                          text: field.data.status,
                        }
                      "
                    />
                  </ProSpaceVLayout>
                </template>
              </Column>
              <Column>
                <template #body="field">
                  <ProSpaceTextBlock
                    type="five"
                    :top="$t('competenceTaskAssignmentTo')"
                    :bottom="$t(field.data.assignmentTo)"
                    unboldTop
                  />
                </template>
              </Column>
              <Column>
                <template #body="field">
                  <ProSpaceTextBlock
                    type="five"
                    :top="$t('competenceStartAt')"
                    :bottom="formatMomentDate(field.data.startAt)"
                    unboldTop
                  />
                </template>
              </Column>
              <Column>
                <template #body="field">
                  <ProSpaceTextBlock
                    type="five"
                    :top="$t('competenceEndAt')"
                    :bottom="formatMomentDate(field.data.endAt)"
                    unboldTop
                  />
                </template>
              </Column>
              <Column>
                <template #body="field">
                  <ProSpaceTextBlock
                    type="five"
                    :top="$t('competenceTaskPriority')"
                    :bottom="
                      field.data.priority !== null && field.data.priority !== undefined
                        ? String(field.data.priority)
                        : null
                    "
                    unboldTop
                  />
                </template>
              </Column>
              <Column field="actions" class="column-end column-end-90">
                <template #body="field">
                  <ProSpaceHLayout :gap="10" justify-content="flex-end">
                    <ProSpaceIcon v-if="field.data.isAnyAttachment" icon="paperclip" />
                    <ProSpaceTag
                      v-if="field.data.daysExpired"
                      :text="field.data.daysExpired"
                      :description="$t('overdueDayHint')"
                      type="red-secondary"
                    />
                    <ProSpaceDots
                      :options="localWrapperCheckRoles(competenceTaskOptions, field.data)"
                      @action="(action) => handleCompetenceTaskDots(action, field.data)"
                    />
                  </ProSpaceHLayout>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="empty-grid"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
      <ProSpaceFlashMessage
        v-if="showSubscriptionMessage"
        v-model="showSubscriptionMessage"
        :title="$t('competenceSubscribe')"
        :applyTxt="$t('confirmTxt')"
        :cancelTxt="$t('cancelInf')"
        @apply="applySubscriptionMessage"
        @cancel="cancelSubscriptionMessage"
      >
        <div v-html="$t('competenceSubscribeMsgNew')" />
        <ul class="competence-flash-list">
          <li v-for="item in subscribeMsgList" :key="item">{{ item }}</li>
        </ul>
      </ProSpaceFlashMessage>
      <ProSpaceFlashMessage
        v-if="showSubscriptionSuccessMessage"
        v-model="showSubscriptionSuccessMessage"
        icon="flash-success"
        :title="$t('competenceSubscribe')"
        :applyTxt="null"
        :cancelTxt="$t('closeTxt')"
        @cancel="clearSubscriptionMessageData"
      >
        <div v-html="subscriptionSuccessMessage" />
      </ProSpaceFlashMessage>

      <CompetenceModalImport
        v-model="showImportModal"
        @loading="loadingHandler"
        accept=".csv, .xlsx"
      />
      <ProSpaceHistoryModal
        ref="competenceHistory"
        v-model="showCompetenceHistoryModal"
        modelName="CompetenceApiModel"
        :optionsPanel="optionsPanel"
        @close="closeHistory"
      />
      <ProSpaceHistoryModal
        ref="competenceTaskHistory"
        v-model="showCompetenceTaskHistoryModal"
        modelName="CompetenceTaskApiModel"
        :optionsPanel="optionsPanel"
        @close="closeHistory"
      />
      <CompetenceTaskModalForm
        v-model="showTaskModal"
        :id="taskModalId"
        :competence="taskModalCompetence"
        :minStartAt="taskModalCompetenceStartAt"
        :maxEndAt="taskModalCompetenceEndAt"
        :afterSaveHandler="actionUpdateExpanded"
      />
    </template>
    <template #right>
      <CompetencePanel
        v-if="hasSelect && isCompetenceSelected"
        ref="right-panel"
        :service="competenceService"
        :options="competenceOptions"
        :checkAccess="checkAccess"
        :filterAccessDots="localWrapperCheckRoles"
        @action="(action, competence) => handleDots(action, competence)"
      />
      <CompetenceTaskPanel
        v-if="hasSelect && isCompetenceTaskSelected"
        ref="right-panel"
        :service="competenceTaskService"
        :options="competenceTaskOptions"
        :checkAccess="checkAccess"
        :checkStatusAccess="checkStatusAccess"
        :filterAccessDots="localWrapperCheckRoles"
        @edit="showEditTaskModal"
      />
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import statusAccessMixin from "../../../../../Frontends/Common/utils/elementAccess/statusAccessMixin";
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceFilterSearchfield,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceModal,
  ProSpaceInputText,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceEmptyGrid,
  ProSpaceVLayout,
  ProSpaceHLayout,
  ProSpaceFilterDatesRange,
  ProSpaceTextBlock,
  ProSpaceTag,
  ProSpaceIcon,
  ProSpaceFlashMessage,
  ProSpaceCheckbox,
  ProSpaceDefaultTextColumn,
  queryBuilder,
} from "@prospace/prospace-components-library";
import Column from "primevue/column";
import moment from "moment";

import { CompetenceService } from "../../services/CompetenceService.js";
import { CompetenceTaskService } from "../../services/CompetenceTaskService.js";
import { DistributorService } from "@masterdata/services/DistributorService.js";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import { MarsDistributorOrgUsersViewService } from "@masterdata/services/MarsDistributorOrgUsersViewService.js";
import DistributorHierarchySelectModal from "@modals/DistributorHierarchySelectModal.vue";

import CompetencePanel from "./components/CompetencePanel";
import CompetenceTaskPanel from "./components/CompetenceTaskPanel";
import CompetenceModalImport from "./components/CompetenceModalImport";
import CompetenceTaskModalForm from "./../CompetenceTasks/components/CompetenceTaskModalForm";

import ProSpaceHistoryModal from "../../../../../Frontends/ProSpace/components/modals/ProSpaceHistoryModal";

import { mapGetters } from "vuex";
import { useMultiSelectGrid } from "@composes/multiselect";
import { ref } from "vue";
import ProSpaceFilterDistributor from "@filters/ProSpaceFilterDistributor.vue";
const _ = require("lodash");
import { formatBytes, downloadFile } from "@common/utils/fileHelpers.js";
import IconButtonSettingsColumns from "@components/settingsColumns/IconButtonSettingsColumns.vue";
import useSettingsColumns from "@composes/useSettingsColumns.js";
export default {
  mixins: [accessMixin, statusAccessMixin],
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceFilterSearchfield,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    ProSpaceModal,
    ProSpaceInputText,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceEmptyGrid,
    ProSpaceTextBlock,
    ProSpaceHistoryModal,
    ProSpaceVLayout,
    ProSpaceHLayout,
    ProSpaceFilterDatesRange,
    DistributorHierarchySelectModal,
    CompetencePanel,
    CompetenceTaskPanel,
    CompetenceModalImport,
    ProSpaceTag,
    ProSpaceIcon,
    CompetenceTaskModalForm,
    ProSpaceFlashMessage,
    ProSpaceCheckbox,
    ProSpaceDefaultTextColumn,
    ProSpaceFilterDistributor,
    IconButtonSettingsColumns
  },
  setup() {
    const grid = ref(null);
    const filterPanel = ref(null);
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      selectedData,
      hasSelect,
      selectedMultipleBlockFilterHandler,
    } = useMultiSelectGrid(grid, filterPanel);
     const {
      showModalSettingsColumns,
      gridPropsSettingsColumns
    } = useSettingsColumns(grid)
    return {
      grid,
      filterPanel,
      selectedMultipleGridHandler,
      selectedData,
      hasSelect,
      selectedMultipleClear,
      selectedMultipleBlockFilterHandler,
      showModalSettingsColumns,
      gridPropsSettingsColumns
    };
  },
  data() {
    let statusConfig = {
      "Distributor user": "NONE",
      "Distributor manager": "NONE",
      "Mars user": {
        Completed: "NONE",
        Canceled: "NONE",
        other: "ALIVE",
      },
      "Mars manager": {
        Completed: "NONE",
        Canceled: "NONE",
        other: "ALIVE",
      },
      "Content manager": {
        Completed: "READONLY",
        Canceled: "READONLY",
        other: "ALIVE",
      },
      "Support administrator": {
        Completed: "NONE",
        Canceled: "NONE",
        other: "ALIVE",
      },
      "Technical Support": {
        Completed: "NONE",
        Canceled: "NONE",
        other: "ALIVE",
      },
      "Content manager plus": {
        Completed: "NONE",
        Canceled: "NONE",
        other: "ALIVE",
      },
    };

    let statusTaskConfig = {
      "Distributor user": {
        Draft: "NONE",
        other: "ALIVE",
      },
      "Distributor manager": {
        Draft: "NONE",
        other: "ALIVE",
      },
      "Mars user": {
        Draft: "NONE",
        other: "ALIVE",
      },
      "Mars manager": {
        Draft: "NONE",
        other: "ALIVE",
      },
      "Content manager": {
        Draft: "NONE",
        other: "ALIVE",
      },
      "Support administrator": {
        Draft: "NONE",
        other: "ALIVE",
      },
      "Technical Support": {
        Draft: "NONE",
        other: "ALIVE",
      },
      "Content manager plus": {
        Draft: "NONE",
        other: "ALIVE",
      },
    };

    return {
      showCompetenceHistoryModal: false,
      showCompetenceTaskHistoryModal: false,
      showImportModal: false,

      //task modal
      showTaskModal: false,
      taskModalId: null,
      taskModalCompetence: null,
      taskModalCompetenceStartAt: null,
      taskModalCompetenceEndAt: null,
      competenceForTask: null,

      // Grid Panel
      spKey: 0,
      spHide: false,

      isCompetenceSelected: false,
      isCompetenceTaskSelected: false,
      defaultChildFilter: { disabled: { and: [false] } },

      // Service
      competenceService: new CompetenceService(),
      competenceTaskService: new CompetenceTaskService(),
      distributorService: new DistributorService(),
      distributorStructureService: new DistributorStructureService(),
      baseTypeService: new BaseTypeService(),
      marsdistributororgusersviewService: new MarsDistributorOrgUsersViewService(),

      //InlinePanel
      masterId: null,
      isEdit: false,
      isCreate: false,
      modalModel: {},

      // Multi filter
      modelFastFilter: { label: this.$t("allTxt"), field: "disabled", value: false },
      filterYearValue: new Date().getFullYear(),
      buttonOptions: Array.from({ length: 8 }, (x, i) => {
        return {
          name: new Date().getFullYear() + 2 - i,
          text: new Date().getFullYear() + 2 - i,
        };
      }),

      // Subsciptions
      showSubscriptionMessage: false,
      showSubscriptionSuccessMessage: false,
      subscriptionKey: null,
      subscriptionModel: null,
      subscriptionGridFilter: null,
      subscriptionNodeFilter: null,
      subscribeModelName: "",
      subscribeMsgList: [
        this.$t("competenceSubscribeMsgList1"),
        this.$t("competenceSubscribeMsgList2"),
        this.$t("competenceSubscribeMsgList3"),
        this.$t("competenceSubscribeMsgList4"),
        this.$t("competenceSubscribeMsgList5"),
      ],

      //status access
      statusAccessConfig: {
        "edit-option": statusConfig,
        "editTask-option": statusTaskConfig,
        "taskCreate-option": statusTaskConfig,
        "updateSubscribe-button": statusConfig,
        "subtaskCreate-action": statusTaskConfig,
      },

      //competence copy
      showCopyCompetenceMessage: false,
      copyTaskFlashMessageCheckBoxModel: [
        { label: this.$t("createTaskCopies"), field: "checked" },
      ],
      copyTaskFlashMessageCheckBoxChangeObj: { checked: false },
      copySourceId: null,
      observer: null,
    };
  },
  mounted() {
    const k = btoa(
      window.location.href +
        "component_filter_panel" +
        this.competenceService.constructor.name +
        "year"
    );
    let yearObj = localStorage.getItem(k);
    if (yearObj && yearObj != "undefined" && yearObj != "false" && yearObj != "null") {
      let year = Number.parseInt(yearObj);
      if (year) this.filterYearValue = year;
    }
    this.emitFilter();
    this.loadRoleAccess();
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
      getFormatDate: "userinfoStore/getFormatDate",
    }),
    optionsPanel() {
      return {
        fields: {
          Subtasks: {
            type: "modal",
            modalType: "checkbox",
            modalTitle: this.$t("competenceTaskSubtasks"),
            modalEmptyTitle: this.$t("competenceEmptySubtasksTitleTxt"),
            modalEmptyText: this.$t("competenceEmptySubtasksTextTxt"),
            value: (value) => (value ? this.$t("openList") : ""),
          },
          Attachments: {
            type: "tab-actual",
            value: this.$t("openList"),
            modelName: "CompetenceTaskAttachmentApiModel",
            filterField: "CompetenceTaskId",
            filterValueField: "Id",
          },
          FileLength: {
            value: formatBytes,
          },
          FileName: {
            type: "action",
            fnAction: (value) => {
              const file = {
                name: value.FileName,
                gfid: value.GFID,
              };
              downloadFile(file);
            },
          },
          StartAt: {
            type: "date",
          },
          EndAt: {
            type: "date",
          },
          CompetenceTaskAttachmentApiModel: {
            type: "tab",
            value: this.$t("openList"),
          },
          CompetenceTaskApiModel: {
            type: "tab",
            value: this.$t("CompetenceTaskApiModelChanges"),
          },
          CompetenceRecipientApiModel: {
            type: "tab",
            value: this.$t("openList"),
          },
          GFID: {
            hidden: true,
          },
        },
      };
    },
    customServices() {
      return [
        {
          type: "dates-range",
          model: "startAt",
          label: this.$t("competenceStartAtTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "endAt",
          label: this.$t("competenceEndAtTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "checkboxes",
          service: this.competenceService,
          getMethod: "getHasExpiredFilterTypes",
          model: "isExpired",
          label: this.$t("competenceIsExpiredTxt"),
          optionValue: "value",
          optionLabel: "text",
          selected: {},
          needSelectAll: true,
        },
        {
          type: "checkboxes",
          service: new BaseTypeService(),
          getMethod: "grid",
          model: "levelDevelopment",
          label: this.$t("competenceLevelDevelopment"),
          optionValue: "code",
          optionLabel: "name",
          defaultFilter: [
            { disabled: false },
            { systemDirectoryCode: "AdvancementLevelType" },
          ],
          selected: {},
          needSelectAll: true,
        },
        {
          type: "checkboxes",
          service: new BaseTypeService(),
          getMethod: "grid",
          model: "competenceDirectoryType",
          label: this.$t("competenceDirectoryType"),
          optionValue: "code",
          optionLabel: "name",
          defaultFilter: [{ disabled: false }, { systemDirectoryCode: "CompetenceType" }],
          selected: {},
          needSelectAll: true,
        },
        {
          type: "checkboxes",
          service: new BaseTypeService(),
          getMethod: "grid",
          model: "competenceDirectoryLevel",
          label: this.$t("competenceDirectoryLevel"),
          optionValue: "code",
          optionLabel: "name",
          defaultFilter: [{ disabled: false }, { systemDirectoryCode: "LevelType" }],
          selected: {},
          needSelectAll: true,
        },
        {
          type: "checkboxes",
          service: new BaseTypeService(),
          getMethod: "grid",
          model: "competenceDirectoryBlock",
          label: this.$t("competenceDirectoryBlock"),
          optionValue: "code",
          optionLabel: "name",
          defaultFilter: [{ disabled: false }, { systemDirectoryCode: "BlockType" }],
          selected: {},
          needSelectAll: true,
        },
        {
          type: "chips",
          model: "competenceDirectoryDescription",
          label: this.$t("competenceDirectoryDescription"),
          selected: {},
        },
        {
          type: "range",
          model: "developmentPercentage",
          label: this.$t("competenceDevelopmentPercentage"),
          selected: {},
          min: 0,
          max: 100,
        },
        {
          type: "chips",
          model: "businessGoal",
          label: this.$t("competenceBusinessGoal"),
          selected: {},
        },
        {
          type: "chips",
          model: "expectedResult",
          label: this.$t("competenceExpectedResult"),
          selected: {},
        },
        {
          type: "chips",
          model: "metrics",
          label: this.$t("competenceMetrics"),
          selected: {},
        },
        {
          type: "range",
          model: "taskCompletedCount",
          label: this.$t("competenceTaskCompletedCount"),
          selected: {},
          min: 0,
          max: 1000,
        },
      ];
    },
    dotsHeaderOptions() {
      return [
        {
          name: "export_csv",
          text: this.$t("export_csv"),
          icon: "download",
          liteRef: "export-csv-option",
        },
        {
          name: "export_xlsx",
          text: this.$t("export_xlsx"),
          icon: "download",
          liteRef: "export-xlsx-option",
        },
      ];
    },
    competenceOptions() {
      return [
        {
          name: "taskCreate",
          text: this.$t("taskCreate"),
          icon: "plus",
          method: this.showCreateTaskModal,
          liteRef: "taskCreate-option",
        },
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
          liteRef: "edit-option",
        },
        {
          name: "copy",
          text: this.$t("competenceTaskCopy"),
          icon: "document-copy",
          method: this.copyCompetence,
          liteRef: "copy-option",
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showCompetenceHistory,
        },
      ];
    },
    competenceTaskOptions() {
      return [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.showEditTaskModal,
          liteRef: "editTask-option",
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showCompetenceTaskHistory,
        },
      ];
    },
    fastFilters() {
      return [
        { label: this.$t("allTxt"), field: "disabled", value: false },
        { label: this.$t("competencestatusnew"), field: "status", value: "New" },
        {
          label: this.$t("competencestatusinprogress"),
          field: "status",
          value: "InProgress",
        },
        {
          label: this.$t("competencestatuscompleted"),
          field: "status",
          value: "Completed",
        },
        {
          label: this.$t("competencestatuscanceled"),
          field: "status",
          value: "Canceled",
        },
        { label: this.$t("competencestatusdraft"), field: "status", value: "Draft" },
      ];
    },
    mapExpandStatuses() {
      return new Map([
        ["New", { type: "blue", text: this.$t("competencetaskstatusnew") }],
        ["Approving", { type: "teal", text: this.$t("competencetaskstatusapproving") }],
        [
          "RejectedByDistributor",
          { type: "error", text: this.$t("competencetaskstatusrejectedbydistributor") },
        ],
        ["Planning", { type: "purple", text: this.$t("competencetaskstatusplanning") }],
        [
          "InProgress",
          { type: "warning", text: this.$t("competencetaskstatusinprogress") },
        ],
        [
          "CompleteApproving",
          { type: "teal", text: this.$t("competencetaskstatuscompleteapproving") },
        ],
        [
          "RejectedByMars",
          { type: "error", text: this.$t("competencetaskstatusrejectedbymars") },
        ],
        [
          "Completed",
          { type: "success", text: this.$t("competencetaskstatuscompleted") },
        ],
        ["Canceled", { type: "draft", text: this.$t("competencetaskstatuscanceled") }],
      ]);
    },
    subscriptionSuccessMessage() {
      return this.$t("competenceSubscribeSuccessMsg").replace(
        "###name###",
        this.subscribeModelName
      );
    },
  },
  methods: {
    localWrapperCheckRoles(dotsOptions, data) {
      return this.wrapperStatusCheckRoles(
        this.wrapperCheckRoles(dotsOptions, data),
        data
      );
    },
    updateFilter(val) {
      this.modelFastFilter = val;
      this.selectedMultipleClear();
    },
    handlerActionOptionsButton(value) {
      this.filterYearValue = Number(value);

      const k = btoa(
        window.location.href +
          "component_filter_panel" +
          this.competenceService.constructor.name +
          "year"
      );
      localStorage.setItem(k, this.filterYearValue);

      this.emitFilter();
    },
    closeHistory() {
      this.showCompetenceHistoryModal = false;
      this.showCompetenceTaskHistoryModal = false;
    },
    showCompetenceHistory(record) {
      this.$refs.competenceHistory.tabs[0].masterId = record.id;
      this.$refs.competenceHistory.masterName = record.competenceDirectoryName;
      this.$nextTick(() => {
        this.showCompetenceHistoryModal = true;
      });
    },
    showCompetenceTaskHistory(record) {
      this.$refs.competenceTaskHistory.tabs[0].masterId = record.id;
      this.$refs.competenceTaskHistory.masterName = record.name;
      this.$nextTick(() => {
        this.showCompetenceTaskHistoryModal = true;
      });
    },
    toItem(data) {
      this.$router.push(`/competence/competence/${data.id}`);
    },
    submitFilter({ filter, search }) {
      filter.startAtYear = {
        and: [{ le: this.filterYearValue }],
      };
      filter.endAtYear = {
        and: [{ ge: this.filterYearValue }],
      };

      if (filter.competenceTaskStatus) {
        this.defaultChildFilter["status"] = {
          or: filter.competenceTaskStatus.or,
        };
      } else {
        this.defaultChildFilter["status"] = null;
      }
      if (filter.isExpired) {
        this.defaultChildFilter["isExpired"] = {
          or: filter.isExpired.or,
        };
      } else {
        this.defaultChildFilter["isExpired"] = null;
      }
      if (filter.competenceTaskStartAt) {
        this.defaultChildFilter["startAt"] = {
          and: filter.competenceTaskStartAt.and,
        };
      } else {
        this.defaultChildFilter["startAt"] = null;
      }
      if (filter.competenceTaskEndAt) {
        this.defaultChildFilter["endAt"] = {
          and: filter.competenceTaskEndAt.and,
        };
      } else {
        this.defaultChildFilter["endAt"] = null;
      }
      this.$refs.grid.submitFilter(filter, search);
    },
    emitFilter() {
      this.$refs["filter-panel"].submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.$refs["filter-panel"].submitFilter();
    },
    actionUpdate() {
      this.$refs.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    async actionUpdateExpanded(model) {
      this.loadingHandler(true);
      if (
        this.competenceForTask.children &&
        this.competenceForTask.children.some((x) => x.id === model.id)
      ) {
        let toUpdate = this.competenceForTask.children.filter(
          (x) => x.id === model.id
        )[0];
        toUpdate = Object.assign(toUpdate, model);
        if (
          this.$refs["right-panel"] &&
          this.isCompetenceTaskSelected &&
          this.$refs["right-panel"].competenceTask.id === model.id
        ) {
          this.$refs["right-panel"].update();
        }
      } else if (this.competenceForTask.children) {
        this.competenceForTask.children.push(model);
        await this.$refs.grid.getRecords(true);
      } else if (this.competenceForTask.leaf) {
        this.competenceForTask.leaf = false;
      }

      const notUpdateStatuses = ["New", "Canceled"];
      const notUpdateCompetenceStatuses = ["InProgress", "Completed", "Canceled"];
      if (
        !notUpdateCompetenceStatuses.includes(this.competenceForTask.status) &&
        !notUpdateStatuses.includes(model.status)
      )
        this.competenceForTask.status = "InProgress";

      this.loadingHandler(false);
    },
    actionExport(format) {
      this.loadingHandler(true);
      this.competenceService
        .export(this.$refs.grid.getRecords(false, true), this.getChildFilter(), format)
        .then(() => this.loadingHandler(false));
      //TODO: Catch error?
      //TODO: Show message(export task created)
    },
    getChildFilter() {
      let localFilter = [];
      Object.keys(this.defaultChildFilter).forEach((fK) => {
        var res = {};
        var fValue = this.defaultChildFilter[fK];
        if (!fValue) return;
        Object.keys(fValue).forEach((fCond) => {
          res[fCond] = fValue[fCond].map((filterOption) => {
            return { [fK]: filterOption };
          });
        });
        localFilter.push(res);
      });

      let order = `createdAt desc`;
      let query = queryBuilder({
        filter: { and: localFilter },
        orderBy: order,
      });

      return query;
    },
    actionImport() {
      this.showImportModal = true;
    },
    loadingHandler(val) {
      this.$refs.grid.loading = val;
    },
    selectedHandler(val) {
      this.selectionChanged(val, false);
    },
    expandHandler(val) {
      this.selectionChanged(val, true);
    },
    selectionChanged(val, isTaskSelected) {
      this.selectedMultipleGridHandler(val);
      if (!val.hasSelect) {
        this.isCompetenceSelected = false;
        this.isCompetenceTaskSelected = false;
      } else {
        this.isCompetenceSelected = !isTaskSelected;
        this.isCompetenceTaskSelected = isTaskSelected;
      }
    },
    spToggle() {
      this.spHide = !this.spHide;
    },
    showCreateTaskModal(competence) {
      if (!competence) return;
      this.competenceForTask = this.$refs.grid.records.filter(
        (r) => r.id === competence.id
      )[0];
      this.taskModalId = null;
      this.taskModalCompetence = {
        Id: competence.id,
        CompetenceDirectoryName: competence.competenceDirectoryName,
        Status: competence.status,
      };
      this.taskModalCompetenceStartAt = new Date(competence.startAt);
      this.taskModalCompetenceEndAt = new Date(competence.endAt);
      this.showTaskModal = true;
    },
    showEditTaskModal(competenceTask) {
      this.competenceForTask = this.$refs.grid.records.filter(
        (r) => r.id === competenceTask.competenceId
      )[0];
      this.taskModalId = competenceTask.id;
      this.taskModalCompetence = null;
      const competence = this.$refs.grid.records.filter(
        (r) => r.id === competenceTask.competenceId
      )[0];
      this.taskModalCompetenceStartAt = new Date(competence.startAt);
      this.taskModalCompetenceEndAt = new Date(competence.endAt);
      this.showTaskModal = true;
    },
    copyCompetence(model) {
      if (!model) return;

      this.$router.push({
        path: `/competence/competence`,
        query: {
          copySourceId: model.id,
        },
      });
    },
    editItem(model) {
      if (!model) return;
      this.toItem(model);
    },
    changeItem() {
      let method;
      if (this.isCreate) {
        method = "create";
      }
      if (this.isEdit) {
        method = "update";
      }
      this.competenceService[method](this.modalModel).then(() => {
        this.actionUpdate();
      });
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "draft":
          type = "default";
          break;
        case "new":
          type = "light-blue";
          break;
        case "inprogress":
          type = "warning";
          break;
        case "completed":
          type = "success";
          break;
        case "canceled":
          type = "default";
          break;
        default:
          type = "default";
      }
      return {
        text: this.$t(("competenceStatus" + status).toLowerCase()),
        type,
      };
    },
    handleDots(action, item) {
      var option = this.competenceOptions.find((o) => o.name === action);
      option.method(item);
    },
    handleCompetenceTaskDots(action, item) {
      var option = this.competenceTaskOptions.find((o) => o.name === action);
      option.method(item);
    },
    handleHeaderDots(action) {
      if (action === "export_csv") {
        this.actionExport("CSV");
      } else if (action === "export_xlsx") {
        this.actionExport("XLSX");
      }
    },
    showSubscriptionMessageFlash(key, model, gridFilter, nodeFilter) {
      this.subscriptionKey = key;
      this.subscriptionModel = model;
      this.subscriptionGridFilter = gridFilter;
      this.subscriptionNodeFilter = nodeFilter;
      this.$nextTick(() => {
        this.showSubscriptionMessage = true;
      });
    },
    async applySubscriptionMessage() {
      await this.addSubscription(
        this.subscriptionKey,
        this.subscriptionModel,
        this.subscriptionGridFilter,
        this.subscriptionNodeFilter
      );
      this.subscribeModelName = this.subscriptionModel.competenceDirectoryName;
      this.$nextTick(() => {
        this.showSubscriptionSuccessMessage = true;
      });
    },
    cancelSubscriptionMessage() {
      this.subscriptionModel.hasSubscription = false;
      this.clearSubscriptionMessageData();
    },
    clearSubscriptionMessageData() {
      this.subscriptionKey = null;
      this.subscriptionModel = null;
      this.subscriptionGridFilter = null;
      this.subscriptionNodeFilter = null;
      this.subscribeModelName = "";
      this.loadingHandler(false);
    },
    changeCompetenceSubscription(model) {
      if (!this.checkStatusAccess("updateSubscribe-button", model)) return;

      model.hasSubscription = !model.hasSubscription;
      let filter = model.filter
        ? JSON.parse(model.filter)
        : {
            nodeFilter: { add: {}, remove: {} },
            gridFilter: { add: {}, remove: {} },
          };
      let nodeFilter = filter.nodeFilter;
      let gridFilter = filter.gridFilter;

      let key = `${this.userinfo.id}|User`;

      if (model.hasSubscription) {
        this.showSubscriptionMessageFlash(key, model, gridFilter, nodeFilter);
      } else {
        this.deleteSubscription(key, model, gridFilter, nodeFilter);
      }
    },
    async addSubscription(key, model, gridFilter, nodeFilter) {
      this.loadingHandler(true);
      if (Object.keys(gridFilter.remove).includes(key)) {
        delete gridFilter.remove[key];
      } else {
        gridFilter.add[key] = await this.getUserOgrItem();
      }

      this.updateSubscription(model, gridFilter, nodeFilter);
    },
    async deleteSubscription(key, model, gridFilter, nodeFilter) {
      this.loadingHandler(true);
      if (Object.keys(gridFilter.add).includes(key)) {
        delete gridFilter.add[key];
      } else {
        gridFilter.remove[key] = await this.getUserOgrItem();
      }

      this.updateSubscription(model, gridFilter, nodeFilter);
    },
    updateSubscription(model, gridFilter, nodeFilter) {
      let nodeSelection = JSON.parse(model.nodeSelection);
      nodeSelection = this.updateSelectedTreeNodes(nodeSelection, gridFilter, nodeFilter);
      model.oDataFilter = this.convertFilterToOdata(gridFilter, nodeFilter);
      model.nodeSelection = JSON.stringify(nodeSelection);
      model.filter = JSON.stringify({
        nodeFilter: nodeFilter,
        gridFilter: gridFilter,
      });
      model.needFilter = this.feelNeedFilter(gridFilter, nodeFilter);

      this.competenceService.update(model).then(() => {
        this.actionUpdate();
      });
    },
    convertFilterToOdata(gridFilter, nodeFilter) {
      const nodeAdd = Object.values(nodeFilter.add);
      const usersAdd = Object.values(gridFilter.add);
      const nodeRemove = Object.values(nodeFilter.remove);
      const usersRemove = Object.values(gridFilter.remove);
      let nodeAddStrings = "";
      let usersAddStrings = usersAdd
        .map((e) => `(recordTypeId eq '${e.recordType + "-" + e.id}')`)
        .join(" or ");
      let usersRemoveStrings = usersRemove
        .map((e) => `(recordTypeId ne '${e.recordType + "-" + e.id}')`)
        .join(" and ");
      let getIdFilter = (n, o) => {
        if (n.type === "RealDistributor") return `and distributorId ${o} ${n.id}`;
        if ([9223372036854775000, -9223372036854775000].includes(n.id))
          return `and orgId ${o} null`;
        return `and orgId ${o} ${n.id}`;
      };
      let nodeRemoveStrings = nodeRemove
        .map((e) => `(type eq '${e.type}' ${getIdFilter(e, "ne")})`)
        .join(" and ");
      if (!nodeAdd.find((e) => e.key === "all"))
        nodeAddStrings = nodeAdd
          .map((e) => `(type eq '${e.realType}' ${getIdFilter(e, "eq")})`)
          .join(" or ");

      let filter = "";
      if (nodeAddStrings || nodeRemoveStrings)
        filter =
          "(" +
          [nodeAddStrings, nodeRemoveStrings]
            .filter((e) => e)
            .map((e) => `(${e})`)
            .join(" and ") +
          ")";

      if (usersAddStrings) {
        if (filter) filter = `${filter} or `;
        filter = `${filter} (${usersAddStrings})`;
      }

      if (usersRemoveStrings) {
        if (filter) filter = `(${filter}) and `;
        filter = `${filter} (${usersRemoveStrings})`;
      }
      if (filter) filter = "$filter=" + filter;
      return filter;
    },
    updateSelectedTreeNodes(selectedTreeNodes, gridFilter, nodeFilter) {
      if (
        Object.keys(nodeFilter.add).length === 0 &&
        Object.keys(gridFilter.add).length === 0
      ) {
        if (selectedTreeNodes) {
          selectedTreeNodes = {};
        }
        return selectedTreeNodes;
      }
      const fullRecords = Object.values(gridFilter.add);
      const listTreeNodes = {
        all: {
          checked: false,
          partialChecked: true,
        },
      };

      fullRecords.forEach((r) => {
        const keys = r.fullPath.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      });
      Object.values(nodeFilter.add).forEach((n) => {
        const keys = n.path.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      });
      // check=true all nodeFilter.add and children
      // баг если дочерние check, parent uncheck, см свой чат
      // listTreeNodes добавлять из через this.nodeFilter.add (нет некоторых узлов)
      const checkChildren = (parentKey) => {
        const children = Object.entries(listTreeNodes)
          .filter(([k, v]) => v.parentKey === parentKey)
          .map(([k]) => k);
        if (children.length === 0) {
          return;
        }
        children.forEach((k) => {
          checkChildren(k);
          listTreeNodes[k] = {
            checked: true,
            partialChecked: false,
            parentKey: parentKey,
          };
        });
        return;
      };
      Object.entries(nodeFilter.add).forEach(([key, value]) => {
        listTreeNodes[key] = {
          checked: true,
          partialChecked: false,
          parentKey: value.parentId,
        };
        checkChildren(key);
      });

      // partialChecked=true if parent has removed child
      const removesParentKeys = [];
      Object.values(gridFilter.remove).forEach((val) => {
        const arr = val.fullPath.split("|");
        const parentKey = arr[arr.length - 2];
        if (!removesParentKeys.includes(parentKey)) {
          removesParentKeys.push(parentKey);
        }
      });
      const uncheckParent = (childKey) => {
        const child = listTreeNodes[childKey];
        if (!child) {
          return;
        }
        if (child.checked) {
          child.checked = false;
          child.partialChecked = true;
        }
        const parent = listTreeNodes[child.parentKey];
        if (!parent) {
          return;
        }
        if (parent.checked) {
          parent.checked = false;
          parent.partialChecked = true;
          uncheckParent(parent.parentKey);
          return;
        }
        return;
      };
      removesParentKeys.forEach(uncheckParent);

      return listTreeNodes;
    },
    feelNeedFilter(gridFilter, nodeFilter) {
      return (
        Object.keys(nodeFilter.add).length > 0 ||
        Object.keys(nodeFilter.remove).length > 0 ||
        Object.keys(gridFilter.add).length > 0 ||
        Object.keys(gridFilter.remove).length > 0
      );
    },
    async getUserOgrItem() {
      let result = await this.marsdistributororgusersviewService.grid(
        `$filter=id eq ${this.userinfo.id} and recordType eq 'User' and orgId eq null`
      );
      return result.data.records[0];
    },
    applyCopyCompetenceMessage() {
      this.$router.push({
        path: `/competence/competence`,
        query: {
          copySourceId: this.copySourceId,
          copyTasks: this.copyTaskFlashMessageCheckBoxChangeObj.checked,
        },
      });
    },
    formatMomentDate(dateString) {
      return moment(dateString).format(`${this.userinfo.dateFormat}`);
    },
  },
};
</script>
<style lang="scss">
.competence-flash-list {
  padding: 5px 25px;
  align-items: start;
  list-style-type: disc;
  text-align: left;

  li {
    font-weight: 400;

    &::marker {
      color: var(--prospace-ui-main);
    }
  }
}
</style>
