import {computed, reactive} from "vue";
import { useI18n } from "vue-i18n";

const useSettingsColumns = (grid) => {
    const { t } = useI18n({ useScope: 'global' })
    const showModalSettingsColumns = () => {
        grid.value.showModalVisisbleColumns();
    };
    const titleModal = computed(() => t('columnSettings'));
    const applyTxt = computed(() => t('applyTxt'));
    const cancelTxt = computed(() => t('cancelTxt'));
    const gridPropsSettingsColumns = reactive({
        titleModalSettingsColumns: titleModal,
        applyTxtModalSettingsColumns: applyTxt,
        cancelTxtModalSettingsColumns: cancelTxt
    });
    return {
        showModalSettingsColumns,
        gridPropsSettingsColumns
    }
};

export default useSettingsColumns;