<template>
    <DashboardContainer
        bg="dashboard-bg-grey"
        isRoot
        :layout="type"
        :children="children"
        @ref-sent="handleRef"
    />
</template>

<script>
import DashboardContainer from "@dashboard/views/Dashboards/components/DashboardViews/DashboardContainer";
export default {
    name: "DashboardRootContainer",
    components: {
        DashboardContainer
    },
    props: {
        type: {
            type: String,
            required: true
        },
        children: {
            type: Array,
            default: []
        }
    },
    methods: {
      handleRef(ref) {
        this.$emit('ref-sent', ref);
      }
    }
}
</script>
