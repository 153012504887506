<template>
  <div class="prospace-panel-block-list__row">
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
      :title="$t('containerType')"
    >
      <template #content>
        <ProSpaceDropdown
          ref="rootContainerTypeDropdown"
          v-model="model.type"
          :options="rootContainerTypeDropdownOptions"
          :placeholder="$t('containerType')"
          propValue="systemName"
          propLabel="name"
          @change="changeContainerChildrenValues"
        />
      </template>
    </ProSpacePanelBlockItem>
  </div>
</template>

<script>
import {
  ProSpacePanelBlockItem,
  ProSpaceDropdown
}
  from "@prospace/prospace-components-library";
export default {
  name: "DashboardLayoutSettingsGridRootContainer",
  components: {
    ProSpacePanelBlockItem,
    ProSpaceDropdown
  },
  emits: ['update:selectedNode', 'combinedEvents'],
  props: {
    selectedNode: {
      type: Object,
      default: () => {}
    },
    rootContainerTypeDropdownOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      model: {
        type: 'horizontal'
      }
    }
  },
  watch: {
    selectedNode: {
      handler: function(val) {
        this.model = val;
      },
      immediate: true,
      deep: true
    },
    model: {
      handler: function(val, oldVal) {
        this.updateModel(val);
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    updateModel(value) {
      this.$emit(`update:selectedNode`, value);
    },
    changeContainerChildrenValues(val) {
      this.$emit('combinedEvents', 'update:type', val)
    }
  }
}
</script>

<style scoped>

</style>
