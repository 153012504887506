<template>
  <ProSpaceGeoLayout :routeName="$route.name" isParent>
    <template #top>
      <ProSpaceTopPanel :header="topPanelText" :title="topPanelText">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <IconButtonSettingsColumns @click="showModalSettingsColumns"/>
          <ProSpaceDots
            :options="wrapperCheckRoles(dotsHeaderOptions)"
            :isSquare="true"
            @action="(action) => handleHeaderDots(action)"
          />
          <ProSpaceButton
            v-if="checkAccess('add-button')"
            type="primary"
            icon="plus"
            :label="this.$t('newTxt')"
            ref="add-button"
            @click="
              $router.push({
                path: `/information/faq`,
                query: {
                  code: this.selectedNode ? this.selectedNode.code : null,
                },
              })
            "
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #left>
      <ProSpaceLeftPanel
        :panelName="$t('topics')"
        searchable
        :searchPlaceholder="$t('searchTxt')"
        @search="handlerSearch"
      >
        <template #center>
          <ProSpaceTree
            ref="tree"
            :searchPlaceholder="$t('searchTxt')"
            :service="faqService"
            searchName="name"
            getNodeMethod="tree"
            propKey="id"
            propLabel="name"
            counterField="faqCount"
            :prop-parent-id="null"
            @select="nodeSelect"
            :defaultFilter="{ disabled: { and: [false] } }"
            defaultSortField="name"
          />
        </template>
      </ProSpaceLeftPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :searchPlaceholder="$t('searchTxt')"
            :closeTxt="$t('closeTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :noMountedRequest="true"
            :modelFastFilter="modelFastFilter"
            @update:modelFastFilter="(value) => updateFilterValue(value)"
            :fastFilters="fastFilters"
            :unicKeyStorage="faqService.constructor.name + 'grid'"
            @apply="submitFilter"
            ref="filterPanel"
            :filter-model="['question', 'shortAnswer']"
            :ignore-clear-keys="['faqTypeCode']"
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceFilterContainsChips
                v-model="filter.question"
                :placeholder-value="$t('faqQuestion')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="faqService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            selectionMode="single"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :default-filter="defaultFilters.disabled"
            :service="faqViewService"
            :noMountedRequest="true"
            v-bind="gridPropsSettingsColumns"
            @selected-change="selectedHandler"
            @db-click="editItem"
          >
            <template #columns>
              <Column
                field="question"
                :header="this.$t('faqQuestion')"
                sortable
                headerClass="move-sort-head"
                frozen
                class="column-table-header-cell"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :status="getStatus(field.data.status)"
                    :title="field.data.question"
                    :description="field.data.shortAnswer"
                    :noHover="!checkFormAccess(field.data)"
                    icon="question"
                    align-items="center"
                    is-image-inline-h
                    is-html
                    @click-on-title="editItem(field.data)"
                  />
                </template>
              </Column>
              <Column field="createdAt" sortable :header="$t('createdDate')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column field="actions" class="column-end column-end-60" frozen alignFrozen="right">
                <template #body="field">
                  <ProSpaceHLayout justify-content="flex-end" :gap="10">
                    <ProSpaceHaveFileColumn
                      v-if="field.data.isAnyAttachment"
                    ></ProSpaceHaveFileColumn>
                    <ProSpaceDots
                      :options="wrapperCheckRoles(dotsOptions, field.data)"
                      @action="(action) => handleDots(action, field.data)"
                    ></ProSpaceDots>
                  </ProSpaceHLayout>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
      <ProSpaceHistoryModal
        ref="history"
        v-model="showHistoryModal"
        modelName="FaqApiModel"
        @close="closeHistory"
      />
    </template>
    <template #right>
      <FaqPanel
        v-if="hasSelect"
        ref="right-panel"
        :service="faqService"
        :options="dotsOptions"
        :filterAccessDots="wrapperCheckRoles"
        :checkEditAccess="checkFormAccess"
        :checkAccess="checkAccess"
        @clear="selectedMultipleClear"
        @edit="editItem"
        @delete="deleteItem"
        @history="showHistory"
      />
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingConfirmation')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteFaq', { name: deletingModel.question })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import {
  ProSpaceFilterPanel,
  ProSpaceHLayout,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceDefaultTextColumn,
  ProSpaceEmptyGrid,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpaceVLayout,
  ProSpaceFilterContainsChips,
  ProSpaceFilterDatesRange,
  ProSpaceDropdown,
  ProSpaceInputText,
  ProSpaceHaveFileColumn,
  Filters,
  ProSpaceDateTimeColumn,
  ProSpaceFlashMessage
} from "@prospace/prospace-components-library";
import { FaqService } from "../../services/FaqService.js";
import { FaqViewService } from "../../services/FaqViewService.js";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService.js";
import Column from "primevue/column";
import FaqPanel from "./components/FaqPanel";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
const _ = require("lodash");
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue";
import IconButtonSettingsColumns from "@components/settingsColumns/IconButtonSettingsColumns.vue";
import useSettingsColumns from "@composes/useSettingsColumns.js";
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceFilterPanel,
    ProSpaceHLayout,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceDefaultTextColumn,
    ProSpaceEmptyGrid,
    FaqPanel,
    ProSpaceHistoryModal,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpaceVLayout,
    ProSpaceFilterContainsChips,
    ProSpaceFilterDatesRange,
    ProSpaceDropdown,
    ProSpaceInputText,
    ProSpaceHaveFileColumn,
    ProSpaceDateTimeColumn,
    ProSpaceFlashMessage,
    IconButtonSettingsColumns
  },
  data() {
    return {
      showModal: false,
      showHistoryModal: false,
      // Grid Panel
      selectedModel: [],
      spKey: 0,
      spHide: false,

      gridDefaultFilter: {},

      // Service
      faqService: new FaqService(),
      faqViewService: new FaqViewService(),
      baseTypeService: new BaseTypeService(),

      //InlinePanel
      masterId: null,
      selectedNode: null,

      defaultFilters: Filters.getDefaultsFilters(),

      // Multi filter
      modelFastFilter: {
        label: this.$t("allTxt"),
        field: "disabled",
        value: false,
      },

      deletingModel: null,
      showDeleteModal: false,
    };
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic()
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    const {
      showModalSettingsColumns,
      gridPropsSettingsColumns
    } = useSettingsColumns(grid)
    return {
      grid, filterPanel,
      setStatistic, distActions, selectedMultipleGridHandler,
      hasSelect, selectedMultipleClear,
      showModalSettingsColumns,
      gridPropsSettingsColumns
    }
  },
  mounted() {
    this.loadRoleAccess();
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
    }),
    customServices() {
      let filters = [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        },
        {
          type: "chips",
          model: "shortAnswer",
          label: this.$t("faqShortAnswer"),
          selected: {},
        },
        {
          type: "switch",
          model: "isAnyAttachment",
          label: this.$t("faqFileInfo"),
          switchLabel: this.$t("faqIsAnyAttachment"),
          selected: {},
        }
      ]

      if(this.checkAccess("metainfo-by-block"))
        filters.push(
          {
            type: "chips",
            model: "createdBy",
            label: this.$t("createdByTxt"),
            selected: {},
          },
          {
            type: "chips",
            model: "updatedBy",
            label: this.$t("updatedByTxt"),
            selected: {},
          }
        );

      filters.push(
        {
          type: "dates-range-time",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range-time",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        }
      );

      return filters;
    },
    dotsOptions() {
      let res = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
          liteRef: "edit-option",
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
          liteRef: "delete-option",
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
          liteRef: "history-option",
        },
      ];
      if (this.modelFastFilter.label === this.$t("deleted")) {
        const wrapperRes = res.filter(
          (option) => option.name !== "edit" && option.name !== "delete"
        );
        return this.wrapperCheckRoles(wrapperRes, this.access);
      }
      return this.wrapperCheckRoles(res, this.access);
    },
    dotsHeaderOptions() {
      return [
        {
          name: "export_csv",
          text: this.$t("export_csv"),
          icon: "download",
          liteRef: "export-csv-option",
        },
        {
          name: "export_xlsx",
          text: this.$t("export_xlsx"),
          icon: "download",
          liteRef: "export-xlsx-option",
        },
      ];
    },
    fastFilters() {
      let filters = [
        { label: this.$t("allTxt"), field: "disabled", value: false },
      ];

      if(this.checkDeleteAccess())
        filters.push({ label: this.$t("deleted"), field: "disabled", value: true });

      return filters;
    },
    topPanelText() {
      return this.$t("instructions");
    },
  },
  methods: {
    handlerSearch(value) {
      this.$refs.tree.handlerSearch(value);
    },
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.question;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    editItem(data) {
      if (!data || !this.checkFormAccess(data)) return;
      const url = `/information/faq/${data.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    actionUpdate() {
      this.$refs.tree.getData();
      this.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    async actionExport(format) {
      this.loadingHandler(true);
      await this.faqService.export(
        this.grid.getRecords(false, true),
        format
      );
      this.loadingHandler(false);
    },
    loadingHandler(val) {
      this.grid.loading = val;
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    deleteItem(model) {
      if (!model) return;
      this.deletingModel = model;
      this.showDeleteModal = true;
    },
    applyDelete() {
      this.faqService.delete(this.deletingModel).then(() => {
        this.actionUpdate();
        this.selectedMultipleClear();
        this.deletingModel = null;
      });
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    handleHeaderDots(action) {
      if (action === "export_csv") {
        this.actionExport("CSV");
      } else if (action === "export_xlsx") {
        this.actionExport("XLSX");
      }
    },
    nodeSelect(value) {
      if (this.selectedNode && this.selectedNode.key === value.key) return;
      var filter = value.id === 0 ? { and: [] } : { and: [value.code] };
      this.selectedModel = {};
      this.filterPanel.filter["faqTypeCode"] = filter;
      this.filterPanel.submitFilter();
      this.selectedNode = value.id !== 0 ? _.cloneDeep(value) : {};
    },
    updateFilterValue(value) {
      if (this.modelFastFilter !== value) {
        this.modelFastFilter = value;
        this.$refs.tree.defaultFilter.disabled = {
          and: [this.modelFastFilter.label === this.$t("deleted")],
        };
        this.$refs.tree.getData();
      }
      this.selectedMultipleClear();
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/main.scss";
</style>
