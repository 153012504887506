<template>
  <DashboardLayoutSettingsGridSelectTypeElement
    :selectedContainer="selectedContainer"
    :typeOptions="getComponentTypeOptions"
    @change:type="changeComponentType"
  />
  <div class="prospace-panel-block-list__row">
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
      :title="$t('elementName')"
    >
      <template #content>
        <ProSpaceInputText
          v-model="model.blockName"
          :placeholder="$t('name')"
        />
      </template>
    </ProSpacePanelBlockItem>
  </div>
  <div class="prospace-panel-block-list__row">
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
      :title="$t('value')"
    >
      <template #content>
        <ProSpaceDropdown
          ref="childIteratorPanelValueDropdown"
          v-model="model.value"
          :options="childPanelValuesOptions"
          propValue="systemName"
          propLabel="name"
          :placeholder="$t('value')"
          :invalid="validation.value.$error"
          :invalid-messages="validation.value.$errors"
          @blur="validation.value.$touch"
        />
      </template>
    </ProSpacePanelBlockItem>
  </div>
  <div class="prospace-panel-block-list__row">
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
      :title="$t('label')"
    >
      <template #content>
        <ProSpaceDropdown
          ref="childIteratorPanelLabelDropdown"
          v-model="model.label"
          :options="childPanelValuesOptions"
          propValue="systemName"
          propLabel="name"
          :placeholder="$t('label')"
          :invalid="validation.label.$error"
          :invalid-messages="validation.label.$errors"
          @blur="validation.label.$touch"
        />
      </template>
    </ProSpacePanelBlockItem>
  </div>
  <div class="prospace-panel-block-list__row">
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
    >
      <template #header>
        <ProSpaceHLayout :gap="5">
          <div>
            {{ $t('filter') }}
          </div>
          <ProSpaceIcon
            v-hint="filterHintText"
            icon="question"
          />
        </ProSpaceHLayout>

      </template>
      <template #content>
        <ProSpaceInputChips
          ref="childIteratorPanelFilterChip"
          v-model="model.filter"
          showHintItem
        />
      </template>
    </ProSpacePanelBlockItem>
  </div>
  <ProSpaceAction
    :text="$t('addColorSelection')"
    @click="handleAddColorSelection"
  />
</template>

<script>
import {
  ProSpacePanelBlockItem,
  ProSpaceInputText,
  ProSpaceDropdown,
  ProSpaceHLayout,
  ProSpaceIcon,
  ProSpaceInputChips,
  ProSpaceAction
} from "@prospace/prospace-components-library";
import DashboardLayoutElementSettings from "./DashboardLayoutElementSettings.vue";
import DashboardLayoutSettingsGridSelectTypeElement from "./DashboardLayoutSettingsGridSelectTypeElement.vue";
import { ref } from "vue";
import useSelectElementType from "./composes/useSelectElementType";
export default {
  name: "DashboardLayoutSettingsGridIterPanel",
  components: {
    DashboardLayoutElementSettings,
    DashboardLayoutSettingsGridSelectTypeElement,
    ProSpacePanelBlockItem,
    ProSpaceInputText,
    ProSpaceDropdown,
    ProSpaceHLayout,
    ProSpaceIcon,
    ProSpaceInputChips,
    ProSpaceAction
  },
  props: {
    selectedNode: {
      type: Object,
      default: () => {}
    },
    validation: {
      type: Object,
      required: true
    },
    childPanelValuesOptions: {
      type: Array,
      default: () => []
    },
    filterHintText: {
      type: String,
      default: ''
    },
    getComponentTypeOptions: {
      type: Array,
      default: () => []
    },
    gridView: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    selectedNode: {
      handler: function(val) {
        this.model = val;
      },
      immediate: true,
      deep: true
    },
    model: {
      handler: function(val, oldVal) {
        this.updateModel(val);
      },
      immediate: true,
      deep: true
    },
  },
  setup(props) {
    const model = ref({
      blockName: '',
      label: '',
      value: '',
      filter: ''
    });
    const {
      isEmptyModel,
      selectedContainer,
      defaultModel } = useSelectElementType(
      props.selectedNode,
      props.getComponentTypeOptions,
      model
    );

    return {
      isEmptyModel,
      selectedContainer,
      defaultModel,
      model
    }
  },
  data() {
    return {
    }
  },
  methods: {
    handleAddColorSelection() {
      this.$emit('combinedEvents', 'add:colorSelection');
    },
    updateModel(value) {
      this.$emit(`update:selectedNode`, value);
    },
    changeComponentType(e) {
      this.$emit('combinedEvents', 'change:component', this.selectedNode, e);
    }
  }
}
</script>

<style scoped>

</style>
