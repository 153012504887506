<template>
  <ProSpaceTabs
    v-model="localModelTabChart"
    :tabs="chartTabs"
    class="dashboard-layout-settings__tabs"
  >
    <template
      v-for="(tab, index) in chartTabs"
      v-slot:[getTabSlotName(index)]
    >
      <DashboardLayoutSettingsGridSelectTypeElement
        v-if="index === 0"
        :selectedContainer="selectedContainer"
        :typeOptions="getComponentTypeOptions"
        @change:type="changeComponentType"
      />
      <div
        v-if="index === 0 || (index > 0 && getChartType(0) !== 'radar')"
        class="prospace-panel-block-list__row"
      >
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
          :title="$t('chartType')"
        >
          <template #content>
            <ProSpaceDropdown
              ref="childChartTypeDropdown"
              v-model="model.datasets[index].graphType"
              :options="chartTypeDropdownOptions"
              :placeholder="$t('chartType')"
              propValue="systemName"
              propLabel="name"
              @change="resetGraphSettings($event, index)"
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
      <div v-if="index === 0" class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
          :title="$t('elementName')"
        >
          <template #content>
            <ProSpaceInputText
              v-model="model.blockName"
              :placeholder="$t('name')"
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
          :title="$t('value')"
        >
          <template #content>
            <ProSpaceDropdown
              ref="childChartValueDropdown"
              v-model="model.datasets[index].valueField"
              propValue="systemName"
              propLabel="name"
              :placeholder="$t('value')"
              :options="childGraphValuesOptions"
              :invalid="validation.datasets[index].valueField.$error"
              :invalid-messages="validation.datasets[index].valueField.$errors"
              @blur="validation.datasets[index].valueField.$touch"
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
      <div v-if="index === 0" class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
          :title="getAxisLabel"
        >
          <template #content>
            <ProSpaceDropdown
              ref="childChartXAxisDropdown"
              v-model="model.xAxis"
              propValue="systemName"
              propLabel="name"
              :placeholder="getAxisLabel"
              :options="childGraphParametersOptions"
              :invalid="validation.xAxis.$error"
              :invalid-messages="validation.xAxis.$errors"
              @blur="validation.xAxis.$touch"
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
      <div v-if="index === 0" class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
        >
          <template #header>
            <ProSpaceHLayout :gap="5">
              <div>
                {{ $t('filter') }}
              </div>
              <ProSpaceIcon
                v-hint="filterHintText"
                icon="question"
              />
            </ProSpaceHLayout>
          </template>
          <template #content>
            <ProSpaceInputChips
              ref="childIteratorPanelFilterChip"
              v-model="model.filter"
              showHintItem
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
        >
          <template #header>
            <ProSpaceHLayout :gap="5">
              <div>
                {{ $t('color') }}
              </div>
              <ProSpaceIcon
                v-hint="$t('graphColor')"
                icon="question"
              />
            </ProSpaceHLayout>
          </template>
          <template #content>
            <template
              v-if="getChartType(index) === 'pie' ||
                   getChartType(index) === 'doughnut' ||
                   getChartType(index) === 'polarArea'
                  ">
              <ProSpaceAction
                :text="$t('addColorSelection')"
                @click="handlePickListModal(true)"
              />
            </template>
            <template v-else>
              <DashboardColorPicker
                v-if="model.datasets[index].color.background"
                type="graph"
                :color="model.datasets[index].color.background"
                @update:color="(val) => setDatasetColor(val, index)"
              />
            </template>
          </template>
        </ProSpacePanelBlockItem>
      </div>
      <div v-if="index === 0" class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
        >
          <template #content>
            <ProSpaceCheckbox
              style="margin-bottom: 20px;"
              v-if="index === 0"
              v-model="chartShowLegendCheckbox"
              :changeObj="model"
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
      <ProSpaceAction
        v-if="index > 0"
        :text="$t('deleteDataset')"
        @click="handleDeleteGraphDatasetClick(index)"
      />
    </template>
    <template v-if="showAddDataset" v-slot>
      <ProSpaceAction
        :text="this.$t('addDataset')"
        @click="handleAddDataset"
      />
    </template>
  </ProSpaceTabs>
</template>

<script>
import {
  ProSpaceTabs,
  ProSpacePanelBlockItem,
  ProSpaceAction,
  ProSpaceHLayout,
  ProSpaceIcon,
  ProSpaceDropdown,
  ProSpaceInputText,
  ProSpaceInputChips,
  ProSpaceCheckbox
} from "@prospace/prospace-components-library";
import DashboardColorPicker from "./DashboardColorPicker.vue";
import DashboardLayoutSettingsGridSelectTypeElement from "./DashboardLayoutSettingsGridSelectTypeElement.vue";
import { ref } from "vue";
import useSelectElementType from "./composes/useSelectElementType";
export default {
  name: "DashboardLayoutSettingsChart",
  components: {
    DashboardLayoutSettingsGridSelectTypeElement,
    DashboardColorPicker,
    ProSpaceTabs,
    ProSpacePanelBlockItem,
    ProSpaceAction,
    ProSpaceHLayout,
    ProSpaceIcon,
    ProSpaceDropdown,
    ProSpaceInputText,
    ProSpaceInputChips,
    ProSpaceCheckbox
  },
  emits: ['update:selectedNode', 'combinedEvents', 'update:modelTabChart'],
  props: {
    filterHintText: {
      type: String,
      default: ''
    },
    chartTabs: {
      type: Array,
      default: () => []
    },
    modelTabChart: {
      type: Object,
      default: () => {}
    },
    chartType: {
      type: String,
      default: ''
    },
    selectedNode: {
      type: Object,
      default: () => {}
    },
    validation: {
      type: Object,
      required: true
    },
    chartTypeDropdownOptions: {
      type: Array,
      default: () => []
    },
    childGraphValuesOptions: {
      type: Array,
      default: () => []
    },
    childGraphParametersOptions: {
      type: Array,
      default: () => []
    },
    showAddDataset: {
      type: Boolean,
      default: false
    },
    getComponentTypeOptions: {
      type: Array,
      default: () => []
    },
    gridView: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const model = ref({
      type: '',
      blockName: '',
      value: ''
    });
    const {
      isEmptyModel,
      selectedContainer,
      defaultModel } = useSelectElementType(
      props.selectedNode,
      props.getComponentTypeOptions,
      model
    );

    return {
      isEmptyModel,
      selectedContainer,
      defaultModel,
      model
    }
  },
  data() {
    return {
      localModelTabChart: {
        name: this.$t("dataset") + " 1",
        isActive: true,
        systemName: "dataset1",
        index: 1
      }
    }
  },
  watch: {
    selectedNode: {
      handler: function(val, oldVal) {
        this.model = val;
      },
      immediate: true,
      deep: true
    },
    model: {
      handler: function(val, oldVal) {
        this.updateModel(val);
      },
      immediate: true,
      deep: true
    },
    localModelTabChart: {
      handler: function(val, oldVal) {
        this.updateModelTabChart(val);
      },
      immediate: true,
      deep: true
    },
    modelTabChart: {
      handler: function(val, oldVal) {
        this.localModelTabChart = val;
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    getAxisLabel() {
      return this.getChartType() === 'pie' ||
        this.getChartType() === 'doughnut' ||
        this.getChartType() === 'polarArea' ||
        this.getChartType() === 'radar'
          ? this.$t('section')
          : this.$t('xAxis');
    },
    chartShowLegendCheckbox() {
      return [{ label: this.$t("addLegend"), field: "showLegend" }];
    },
  },
  methods: {
    updateModelTabChart(val) {
      this.$emit('update:modelTabChart', val);
    },
    getTabSlotName(index) {
      return "tab-" + (index + 1);
    },
    getChartType(index = 0) {
      return this.selectedNode?.datasets[index]?.graphType;
    },
    updateModel(value) {
      this.$emit(`update:selectedNode`, value);
    },
    handlePickListModal(e) {
      this.$emit('combinedEvents', 'update:pickListModal', e)
    },
    resetGraphSettings(e, index) {
      this.$emit('combinedEvents', 'reset:graphSettings', e, index)
    },
    setDatasetColor(value, index) {
      this.$emit('combinedEvents', 'set:datasetColor', value, index)
    },
    handleDeleteGraphDatasetClick(index) {
      this.$emit('combinedEvents', 'delete:dataset', index)
    },
    handleAddDataset() {
      this.$emit('combinedEvents', 'add:dataset');
    },
    changeComponentType(e) {
      this.$emit('combinedEvents', 'change:component', this.selectedNode, e);
    }
  }
}
</script>

<style scoped>

</style>
