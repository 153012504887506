<template>
  <ProSpaceModal
    v-if="filterColumns && filterColumns.left && filterColumns.left.length > 0"
    v-model="show"
    :width="isTwoColumns ? '840' : '420'"
    :disabledApply="isDisabledApply"
    :needHideApply="false"
    :title="$t('dashboardModalFilter')"
    :icon="'list-check'"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('select')"
    @apply="handleApply"
    @cancel="closeModal"
  >
    <ProSpaceGeoLayout class="dashboard-filter-modal-body" :routeName="`${$route.name}DashboardModalFilter`">
      <template #center>
        <ProSpaceVLayout :gap="20">
          <DashboardModalFilterList
            :filterColumns="filterColumns"
          >
            <template v-slot:default="slotProps">
              <template :key="i" v-for="(serviceItem, i) in slotProps.filterList">
                <ProSpaceInputLayout
                  v-if="serviceItem.type == 'select'"
                  :label="serviceItem.label"
                  :value="filterModel[serviceItem.model]"
                >
                  <ProSpaceSearchfield
                    v-model="filterModel[serviceItem.model]"
                    :ref="serviceItem.model"
                    icon="list-check"
                    :dataKey="serviceItem.optionValue"
                    :getMethod="serviceItem.getMethod"
                    :optionLabel="serviceItem.optionField"
                    :optionValue="serviceItem.optionValue"
                    :inputPlaceholder="serviceItem.label"
                    :searchPlaceholder="$t('searchTxt')"
                    :txtResetFilters="$t('txtResetFilters')"
                    :txtTitleModal="serviceItem.label"
                    :service="serviceItem.service"
                    :columns="[
                      {
                        field: serviceItem.optionField,
                        header: serviceItem.label,
                      },
                    ]"
                    :filterModel="serviceItem.optionField"
                    :fastFilters="[]"
                    :txtFilterHint="$t('filterBy')"
                    :cancelTxt="$t('cancelInf')"
                    :applyTxt="$t('select')"
                    :invalid="v$.filterModel[serviceItem.model].$error"
                    :invalid-messages="v$.filterModel[serviceItem.model].$errors"
                    @blur="v$.filterModel[serviceItem.model].$touch"
                    @update:modelValue="updateModelField($event, serviceItem.model, serviceItem.optionValue)"
                  >
                    <template #filters="{ filter }">
                      <ProSpaceFilterContainsChips
                        v-model="filter[serviceItem.optionField]"
                        :placeholder-value="serviceItem.label"
                        :closeTxt="$t('closeTxt')"
                        :applyTxt="$t('applyTxt')"
                        :clearFilterTxt="$t('clearFilterTxt')"
                        @apply="() => emitFilter(serviceItem.model)"
                      />
                    </template>
                  </ProSpaceSearchfield>
                </ProSpaceInputLayout>

                <ProSpaceInputLayout
                  v-if="serviceItem.type == 'multiselect'"
                  :label="serviceItem.label"
                  :value="filterModel[serviceItem.model]"
                >
                  <ProSpaceVLayout :gap="5">
                    <ProSpaceSearchfield
                      v-model="filterModel[serviceItem.model]"
                      :ref="serviceItem.model"
                      icon="list-check"
                      selectionMode="multiple"
                      inputType="chips"
                      :dataKey="serviceItem.optionValue"
                      :getMethod="serviceItem.getMethod"
                      :optionLabel="serviceItem.optionField"
                      :optionValue="serviceItem.optionValue"
                      :inputPlaceholder="serviceItem.label"
                      :searchPlaceholder="$t('searchTxt')"
                      :txtResetFilters="$t('txtResetFilters')"
                      :txtTitleModal="serviceItem.label"
                      :service="serviceItem.service"
                      :columns="[
                        {
                          field: serviceItem.optionField,
                          header: serviceItem.label,
                        },
                      ]"
                      :filterModel="serviceItem.optionField"
                      :fastFilters="[]"
                      :txtFilterHint="$t('filterBy')"
                      :cancelTxt="$t('cancelInf')"
                      :closeTxt="$t('closeTxt')"
                      :applyTxt="$t('select')"
                      :invalid="v$.filterModel[serviceItem.model].$error"
                      :invalid-messages="v$.filterModel[serviceItem.model].$errors"
                      @blur="v$.filterModel[serviceItem.model].$touch"
                      @update:modelValue="updateModelField($event, serviceItem.model, serviceItem.optionValue)"
                    >
                      <template #filters="{ filter }">
                        <ProSpaceFilterContainsChips
                          v-model="filter[serviceItem.optionField]"
                          :placeholder-value="serviceItem.label"
                          :closeTxt="$t('closeTxt')"
                          :applyTxt="$t('applyTxt')"
                          :clearFilterTxt="$t('clearFilterTxt')"
                          @apply="() => emitFilter(serviceItem.model)"
                        />
                      </template>
                    </ProSpaceSearchfield>
                  </ProSpaceVLayout>
                </ProSpaceInputLayout>

                <ProSpaceCombobox
                  v-if="serviceItem.type == 'yesno'"
                  v-model="filterModel[serviceItem.model]"
                  optionLabel="label"
                  optionValue="value"
                  getMethod="get"
                  display="chip"
                  noHeader
                  :label="serviceItem.label"
                  :service="yesNoService"
                  :invalid="v$.filterModel[serviceItem.model].$error"
                  :invalid-messages="v$.filterModel[serviceItem.model].$errors"
                  @blur="v$.filterModel[serviceItem.model].$touch"
                />

                <ProSpaceCombobox
                  v-if="serviceItem.type == 'checkbox'"
                  v-model="filterModel[serviceItem.model]"
                  display="chip"
                  :optionLabel="serviceItem.optionField"
                  :optionValue="serviceItem.optionValue"
                  :label="serviceItem.label"
                  :service="serviceItem.service"
                  :getMethod="serviceItem.getMethod"
                  :invalid="v$.filterModel[serviceItem.model].$error"
                  :invalid-messages="v$.filterModel[serviceItem.model].$errors"
                  @blur="v$.filterModel[serviceItem.model].$touch"
                  @change="(res) => filterModel[serviceItem.model] = res.items.map(i => i[serviceItem.optionValue])"
                />

                <ProSpaceInputNumber
                  v-if="serviceItem.type == 'number'"
                  v-model="filterModel[serviceItem.model]"
                  likeText
                  :label="serviceItem.label"
                  :maxFractionDigits="2"
                  :invalid="v$.filterModel[serviceItem.model].$error"
                  :invalid-messages="v$.filterModel[serviceItem.model].$errors"
                  @blur="v$.filterModel[serviceItem.model].$touch"
                />

                <ProSpaceInputLayout
                  v-if="serviceItem.type == 'range'"
                  class="prospace-input-number__wrapper"
                  :label="serviceItem.label"
                  :value="filterModel[serviceItem.model]"
                  :errors="v$.filterModel[serviceItem.model].$errors"
                >
                  <ProSpaceInputSlider
                    v-model="filterModel[serviceItem.model]"
                    :label="serviceItem.label"
                    :min="Number.MIN_SAFE_INTEGER"
                    :max="Number.MAX_SAFE_INTEGER"
                    :fromTxt="$t('fromTxt')"
                    :toTxt="$t('toTxt')"
                    :invalidFrom="
                      v$.filterModel[serviceItem.model].$error && (filterModel[serviceItem.model][0] == null || filterModel[serviceItem.model][0] == undefined)
                    "
                    :invalidTo="
                      v$.filterModel[serviceItem.model].$error && (filterModel[serviceItem.model][1] == null || filterModel[serviceItem.model][1] == undefined)
                    "
                    @blur="v$.filterModel[serviceItem.model].$touch"
                  />
                </ProSpaceInputLayout>

                <ProSpaceInputChips
                  v-if="serviceItem.type == 'chips'"
                  :label="serviceItem.label"
                  :ref="`${i}_chips`"
                  v-model="filterModel[serviceItem.model]"
                  :onlyNumbers="false"
                  :invalid="v$.filterModel[serviceItem.model].$error"
                  :invalid-messages="v$.filterModel[serviceItem.model].$errors"
                  @blur="v$.filterModel[serviceItem.model].$touch"
                />

                <ProSpaceInputCalendar
                  v-if="serviceItem.type == 'dateselect'"
                  v-model="filterModel[serviceItem.model]"
                  :label="serviceItem.label"
                  :closeTxt="$t('closeTxt')"
                  :applyTxt="$t('applyTxt')"
                  :dateFormat="userinfo.dateFormat"
                  :invalid="v$.filterModel[serviceItem.model].$error"
                  :invalid-messages="v$.filterModel[serviceItem.model].$errors"
                  @blur="v$.filterModel[serviceItem.model].$touch"
                />

                <ProSpaceInputCalendar
                  v-if="serviceItem.type == 'daterangeselect'"
                  v-model="filterModel[serviceItem.model]"
                  selectionMode="range"
                  :label="serviceItem.label"
                  :closeTxt="$t('closeTxt')"
                  :applyTxt="$t('applyTxt')"
                  :dateFormat="userinfo.dateFormat"
                  :invalid="v$.filterModel[serviceItem.model].$error"
                  :invalid-messages="v$.filterModel[serviceItem.model].$errors"
                  @blur="v$.filterModel[serviceItem.model].$touch"
                />

                <ProSpaceInputCalendar
                  v-if="serviceItem.type == 'datetimeselect'"
                  v-model="filterModel[serviceItem.model]"
                  :label="serviceItem.label"
                  :placeholder="serviceItem.labelFrom"
                  :showTime="true"
                  :showSeconds="true"
                  :closeTxt="$t('closeTxt')"
                  :applyTxt="$t('applyTxt')"
                  :dateFormat="userinfo.dateFormat"
                  :invalid="v$.filterModel[serviceItem.model].$error"
                  :invalid-messages="v$.filterModel[serviceItem.model].$errors"
                  @blur="v$.filterModel[serviceItem.model].$touch"
                />

                <ProSpaceInputLayout
                  v-if="serviceItem.type == 'datetimerangeselect'"
                  :label="serviceItem.label"
                  :value="filterModel[serviceItem.model]"
                  :errors="v$.filterModel[serviceItem.model].$errors"
                >
                  <ProSpaceHLayout :gap="5">
                    <ProSpaceInputCalendar
                      v-model="filterModel[serviceItem.model][0]"
                      :date-format="userinfo.dateFormat"
                      showTime="true"
                      showSeconds="true"
                      :placeholder="$t('from')"
                      :applyTxt="$t('applyTxt')"
                      :closeTxt="$t('closeTxt')"
                      :invalid="v$.filterModel[serviceItem.model].$error && (filterModel[serviceItem.model][0] == null || filterModel[serviceItem.model][0] == undefined)"
                      @blur="v$.filterModel[serviceItem.model].$touch"
                      style="width: calc(50% - 5px)"
                    />
                    <ProSpaceInputCalendar
                      v-model="filterModel[serviceItem.model][1]"
                      :date-format="userinfo.dateFormat"
                      showTime="true"
                      showSeconds="true"
                      :placeholder="$t('before')"
                      :applyTxt="$t('applyTxt')"
                      :closeTxt="$t('closeTxt')"
                      :invalid="v$.filterModel[serviceItem.model].$error && (filterModel[serviceItem.model][1] == null || filterModel[serviceItem.model][1] == undefined)"
                      @blur="v$.filterModel[serviceItem.model].$touch"
                      style="width: calc(50% - 5px)"
                    />
                  </ProSpaceHLayout>
                </ProSpaceInputLayout>
              </template>
            </template>
          </DashboardModalFilterList>
        </ProSpaceVLayout>
      </template>
    </ProSpaceGeoLayout>
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceModal,
  ProSpaceFile,
  ProSpaceGeoLayout,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceLabel,
  ProSpaceAction,
  ProSpaceIcon,
  ProSpaceDropdown,
  ProSpaceTabs,
  ProSpaceInputText,
  ProSpaceInputNumber,
  ProSpaceInputCalendar,
  ProSpaceCheckbox,
  ProSpaceEmptyGrid,
  ProSpacePanelBlock,
  ProSpaceRightPanel,
  ProSpaceTag,
  ProSpaceInputLayout,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpacePanelBlockItem,
  ProSpaceTagsColumn,
  ProSpaceInputSwitch,
  ProSpaceInputChips,
  ProSpaceInputSlider,
  ProSpaceSearchfield,
  ProSpaceDefaultTextColumn,
  ProSpaceCombobox,
  ProSpaceFilterContainsChips
} from "@prospace/prospace-components-library";
import DashboardModalFilterList from "./DashboardModalFilterList.vue";
import TimezoneConverter from "@prospace/prospace-components-library/src/lib-components/none-visual-components/timezoneConverter";
import { mapGetters } from "vuex";
import { DashboardDatasetService } from "../../../../services/DashboardDatasetService.js";
import { YesNoService } from "@base/services/YesNoService.js";
import { useVuelidate } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import moment from "moment";

export default {
  name: "DashboardModalFilter",
  components: {
    ProSpaceModal,
    ProSpaceFile,
    ProSpaceGeoLayout,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceLabel,
    ProSpaceAction,
    ProSpaceIcon,
    ProSpaceDropdown,
    ProSpaceTabs,
    ProSpaceInputText,
    ProSpaceInputNumber,
    ProSpaceInputCalendar,
    ProSpaceCheckbox,
    ProSpaceEmptyGrid,
    ProSpacePanelBlock,
    ProSpaceRightPanel,
    ProSpaceTag,
    ProSpaceInputLayout,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpacePanelBlockItem,
    ProSpaceTagsColumn,
    ProSpaceInputSwitch,
    ProSpaceInputChips,
    ProSpaceInputSlider,
    ProSpaceSearchfield,
    ProSpaceDefaultTextColumn,
    ProSpaceCombobox,
    ProSpaceFilterContainsChips,
    DashboardModalFilterList
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    dashboard: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      show: this.visible,
      isAllSelected: false,

      moment,
      model: null,
      filteredItems: null,
      filterModel: {},
      serviceItems: [],
      yesNoService: new YesNoService(),
    };
  },
  mounted() {
    this.model = _.cloneDeep(this.dashboard);
    this.serviceItems = this.model.filters
      .filter((elem) => elem.isRequired)
      .map((elem) => {
        const localization = JSON.parse(elem.localization);
        let service = new DashboardDatasetService();
        service.datasetName = elem.sourceDatasetName;
        return {
          type: elem.type,
          model: elem.name,
          sourceField: elem.sourceField,
          label: service.getLocalizedName(localization),
          selected: {},
          service: service,
          getMethod: "getDatasetDataFilter",
          // filter: { disabled: { and: [false] } },
          optionValue: !elem.sourceField ? elem.sourceDatasetField : "Id",
          optionField: elem.sourceDatasetField,
        };
      });

    this.serviceItems.forEach((source) => {
      if (source.type == "range" || source.type === 'datetimerangeselect') {
        this.filterModel[source.model] = [null, null];
      } else if (source.type === 'daterangeselect') {
        this.filterModel[source.model] = [];
      } else if (source.type == "yesno" || source.type == "checkbox") {
        this.filterModel[source.model] = [];
      } else {
        this.filterModel[source.model] = null;
      }
    });

    this.searchItems();
  },
  validations() {
    let fm = {};
    const checkCorrect = (type) => helpers.withParams(
      { type: 'checkCorrect', value: type },
      (value) => {
        let correct = false;

        if (type === "range" || type === "daterangeselect" || type === "datetimerangeselect") {
          correct = value && value[0] != null && value[1] != null && value[0] != undefined && value[1] != undefined;
        } else if (type === "select" || type === "checkbox" || type === "multiselect" || type === "chips" || type === "yesno") {
          correct = value && Array.isArray(value) && value.length > 0 && !value.some(x => !x);
        } else {
          correct = value != null && value != undefined;
        }

        return correct;
      }
    )

    this.serviceItems.forEach((item) => {
      fm[item.model] = {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), checkCorrect(item.type))
      }
    });

    return {
      filterModel: fm
    };
  },
  methods: {
    emptyModel() {
      return {
        name: "",
        section: "",
        description: "",
        datasets: null,
        filters: [],
      };
    },
    handleApply() {
      let filterObj = this.createFilterObj();
      const filterName = btoa("dashbord-filter_" + this.model.id + this.model.lastUpdated);
      localStorage.setItem(
        filterName,
        JSON.stringify(filterObj)
      );
      this.$emit("apply", filterObj);
    },
    createFilterObj() {
      let filterObj = {};

      this.serviceItems.forEach((item) => {
        let filterValue = null;
        let elem = this.filterModel[item.model];

        if (item.type === "dateselect") {
          filterValue = [{
            ge: TimezoneConverter.getDateForDatesRange(
              moment(TimezoneConverter.getUtcDate(elem)).startOf("day").toDate()
            ),
            le: TimezoneConverter.getDateForDatesRange(
              moment(TimezoneConverter.getUtcDate(elem)).endOf("day").toDate()
            ),
          }];
        } else if (item.type === "datetimeselect") {
          filterValue = [{
            ge: TimezoneConverter.getDateForDatesRange(
              moment(TimezoneConverter.getUtcDate(elem)).toDate()
            ),
            le: TimezoneConverter.getDateForDatesRange(
              moment(TimezoneConverter.getUtcDate(elem)).toDate()
            ),
          }];
        } else if (item.type === "daterangeselect") {
          filterValue = [{
            ge: TimezoneConverter.getDateForDatesRange(
              moment(TimezoneConverter.getUtcDate(elem[0])).startOf("day").toDate()
            ),
            le: TimezoneConverter.getDateForDatesRange(
              moment(TimezoneConverter.getUtcDate(elem[1])).endOf("day").toDate()
            ),
          }];
        } else if ((item.type === "datetimerangeselect")) {
          filterValue = [{
            ge: TimezoneConverter.getDateForDatesRange(
              moment(TimezoneConverter.getUtcDate(elem[0])).toDate()
            ),
            le: TimezoneConverter.getDateForDatesRange(
              moment(TimezoneConverter.getUtcDate(elem[1])).toDate()
            ),
          }];
        } else if (item.type === "range") {
          filterValue = [{
            ge: elem[0],
            le: elem[1],
          }];
        } else if (item.type === "chips" ){
          filterValue = elem.map(x => { return { contains: x } });
        } else if (item.type === "yesno" ){
          filterValue = elem.map(x => x.value);
        }else if (item.type === "select" || item.type === "multiselect" || item.type === "checkbox") {
          filterValue = elem;
        } else {
          filterValue = [elem];
        }

        const filterField = item.sourceField ?? item.model;
        filterObj[filterField] = item.type === "multiselect" || item.type === "checkbox" || item.type === "chips" || item.type === "yesno"
          ? { or: filterValue }
          : { and: filterValue };
      });

      return Object.keys(filterObj).length > 0 ? filterObj : null;
    },
    closeModal() {
      this.clearModel();
      this.$emit("close");
    },
    clearModel() {
      this.model = this.emptyModel();
      this.filterModel = {};
      this.serviceItems.length = 0;
    },
    searchItems() {
      this.yesNoService.get().then((res) => {
        this.filteredItems = [...res.data.records];
      });
    },

    selectAll(model) {
      if (this.isAllSelected) {
        this.filterModel[model] = this.filteredItems.map((elem) => elem[this.optionValue]);
      } else {
        this.filterModel[model] = [];
      }
    },
    updateModelField(data, field, value) {
      this.filterModel[field] = this.$refs[field][0].gridSelectedItems.map((x) => x[value]);
    },
    emitFilter(ref) {
      this.$refs[ref][0].$refs['filter-panel'].submitFilter();
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    isDisabledApply() {
      return this.v$.filterModel.$invalid;
    },
    filterColumns() {
      if (this.serviceItems?.length <= 6)
        return { left: this.serviceItems ?? [] };

      const filterCount = this.serviceItems.length;
      const mod = filterCount % 2;
      const leftColumnFilterCount = ((filterCount - mod) / 2) + mod;

      const result = {
        left: [],
        right: []
      }

      for (let index = 0; index < filterCount; index++) {
        if (index < leftColumnFilterCount)
          result.left.push(this.serviceItems[index])
        else result.right.push(this.serviceItems[index])
      }

      return result;
    },
    isTwoColumns() {
      return this.filterColumns && this.filterColumns.right && this.filterColumns.right.length > 0;
    }
  },
};
</script>

<style lang="scss">
.dashboard-filter-modal-body {
  .prospace-geo-layout__center {
    height: fit-content!important;
  }
  &__columns {
    align-items: flex-start!important;
  }
}
</style>
