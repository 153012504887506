<template>
  <PickList
    class="prospace-picklist"
    v-if="localModelValue"
    ref="pickList"
    v-model="localModelValue"
    :selection="selection"
    :dataKey="propKey"
    :showSourceControls="false"
    :showTargetControls="false"
    :source-list-props="{ class: 'prospace-scrollbar' }"
    :target-list-props="{ class: 'prospace-scrollbar' }"
    :moveToTargetProps="{ icon: 'pi pi-chevron-right', class: 'prospace-picklist__button' }"
    :moveAllToTargetProps="{ icon: 'pi pi-angle-double-left', class: 'prospace-picklist__button'}"
    :moveToSourceProps="{ icon: 'pi pi-chevron-left', class: 'prospace-picklist__button' }"
    :moveAllToSourceProps="{ icon: 'pi pi-angle-double-right', class: 'prospace-picklist__button'}"
    @selection-change="handleSelectionChange"
    @move-to-target="handleMoveToTarget"
    @move-to-source="handleMoveToSource"
    @move-all-to-target="handleMoveAllToTarget"
    @move-all-to-source="handleMoveAllToSource"
  >
  <template #sourceheader>
    <ProSpaceHLayout :gap="10" justify-content="flex-start">
      <ProSpaceCheckbox
        v-model="sourceAllCheckBox.model"
        :changeObj="sourceAllCheckBox.changeObj"
        @update:modelValue="handleSourceAllCheckboxUpdated"
      />
      <ProSpaceTag
        type="light-blue"
        :text="localModelValue[0]?.length?.toString()"
        big-text
      />
  </ProSpaceHLayout>
  </template>
  <template #targetheader>
    <ProSpaceHLayout :gap="10" justify-content="flex-start">
      <ProSpaceCheckbox
        v-model="targetAllCheckBox.model"
        :changeObj="targetAllCheckBox.changeObj"
        @update:modelValue="handleTargetAllCheckboxUpdated"
      />
      <ProSpaceTag
        type="light-blue"
        :text="localModelValue[1]?.length?.toString()"
        big-text
      />
      <ProSpaceHLayout :gap="5" class="prospace-picklist__sorting">
        <ProSpaceAction
          icon="arrow-up"
          iconWidth="16px"
          iconHeight="16px"
          typeIcon="default"
          @click="handleTargetListMoveUpClick"
        />
        <ProSpaceAction
          icon="arrow-down"
          iconWidth="16px"
          iconHeight="16px"
          typeIcon="default"
          @click="handleTargetListMoveDownClick"
        />
      </ProSpaceHLayout>
    </ProSpaceHLayout>
  </template>
  <template #item="{ item }">
    <ProSpaceHLayout :gap="10">
      <ProSpaceCheckbox
        v-model="itemCheckboxes[item[this.propKey]].model"
        :changeObj="itemCheckboxes[item[this.propKey]].changeObj"
        :label="mode !== 'color' ? item[this.propLabel] : getOptionText(item[this.propLabel])"
        @update:modelValue="() => handleItemCheckboxUpdated(item[this.propKey])"
      >
        <template #before-label>
          <div v-if="mode === 'color'" :class="['picklist-square-color', `picklist-square-color--${getOptionText(item[this.propLabel], false)}`]"></div>
        </template>
      </ProSpaceCheckbox>
    </ProSpaceHLayout>
  </template>
  </PickList>
</template>
<script>
  import {
    ProSpaceCheckbox,
    ProSpaceAction,
    ProSpaceHLayout,
    ProSpaceTag,
    ProSpaceButton,
  } from "@prospace/prospace-components-library";
  import PickList from 'primevue/picklist';
  export default {
    name: 'ProSpacePickList',
    components: {
      ProSpaceButton,
      PickList,
      ProSpaceCheckbox,
      ProSpaceAction,
      ProSpaceHLayout,
      ProSpaceTag
    },
    props: {
      modelValue: {
        type: Array,
        required: true
      },
      options: {
        type: Array,
        required: true
      },
      propKey: {
        type: String,
        default: "code"
      },
      propLabel: {
        type: String,
        required: true
      },
      mode: {
        type: String,
        default: "tag",
        validator: (value) => {
          return ["tag", "color"].includes(value);
        },
      }
    },
    data() {
      return {
        localOptions: null,
        selection: [[],[]],
        sourceAllCheckBox: {
          changeObj: {
            all: false
          },
          model: [
            { label: this.$t('allFields'), field: 'all' }
          ]
        },
        targetAllCheckBox: {
          changeObj: {
            all: false
          },
          model: [
            { label: this.$t('selectedFields'), field: 'all' }
          ]
        },
        itemCheckboxes: null,
        itemCheckHandled: false
      }
    },
    watch:{
      options: {
        handler(val) {
          this.localOptions = val;
        }
      }
    },
    computed: {
      localModelValue: {
        get() {
          if (this.localOptions && this.modelValue)
            return [
              this.localOptions,
              this.modelValue
            ]
          else return null
        },
        set(newValue) {
          this.$emit("update:modelValue", newValue[1]);
          this.localOptions = newValue[0];
        }
      },
    },
    created() {
      this.setLocalOptions();
      this.itemCheckboxes = {};
      for (const option of this.options) {
        const keyValue = option[this.propKey];

        this.itemCheckboxes[keyValue] = {
          //отрисовка текста просходит отдельно вне чекбокса, а не в лейбле,
          //так как с лейблом происходит некорректное поведение
          model: [{label: '', field: 'checked'}],
          changeObj: { checked: false }
        }
      }
    },
    methods: {
      setLocalOptions() {
        if (this.modelValue &&
          Array.isArray(this.modelValue) &&
          this.propKey && this.options && this.options.length > 0)
        {
          this.localOptions = this.options
            .filter(o => this.modelValue
              .findIndex(mv => mv[this.propKey] === o[this.propKey]) === -1)
        } else {
          this.localOptions = this.options;
        }
      },
      handleTargetListMoveUpClick(event) {
        this.$refs.pickList.moveUp(event, 1);
      },
      handleTargetListMoveDownClick(event) {
        this.$refs.pickList.moveDown(event, 1);
      },
      handleSourceAllCheckboxUpdated() {
        this.handleAllCheckboxUpdated(this.sourceAllCheckBox, 0)
      },
      handleTargetAllCheckboxUpdated() {
        this.handleAllCheckboxUpdated(this.targetAllCheckBox, 1)
      },
      handleAllCheckboxUpdated(allCheckbox, listIndex) {
        if (allCheckbox.changeObj.all) {
          if (!this.itemCheckHandled) {
            this.selection[listIndex] = [...this.localModelValue[listIndex]]

            for (const item of this.localModelValue[listIndex]) {
              this.itemCheckboxes[item[this.propKey]].changeObj.checked = true;
            }
          } else this.itemCheckHandled = false;
        } else {
          if (!this.itemCheckHandled) {
            this.selection[listIndex] = []

            for (const item of this.localModelValue[listIndex]) {
              this.itemCheckboxes[item[this.propKey]].changeObj.checked = false;
            }
          } else this.itemCheckHandled = false;
        }
      },
      handleItemCheckboxUpdated(id) {
        const listIndex = this.localModelValue[0].findIndex(o => o[this.propKey] === id) !== -1
          ? 0 : 1;
        const allCheckboxChangeObj = listIndex === 0
          ? this.sourceAllCheckBox.changeObj
          : this.targetAllCheckBox.changeObj;

        const item = this.localModelValue[listIndex].filter(o => o[this.propKey] === id)[0]

        if (this.itemCheckboxes[id].changeObj.checked) {
          const newSelection = [...this.selection[listIndex]]

          if (newSelection.findIndex(o => o[this.propKey] === id) === -1)
            newSelection.push(item)
          this.selection[listIndex] = newSelection

          if (this.everyItemInListIsChecked(listIndex)) {
            allCheckboxChangeObj.all = true;
            this.itemCheckHandled = true;
          }

        } else if (allCheckboxChangeObj.all) {
          allCheckboxChangeObj.all = false;
          this.itemCheckHandled = true;
        }
      },
      handleSelectionChange(event) {
        const newSelection = [[],[]];

        for (const item of this.localModelValue[0]) {
          const checkboxChangeObj = this.itemCheckboxes[item[this.propKey]].changeObj;
          if (checkboxChangeObj.checked) {
            newSelection[0].push(item);
          }
        }

        for (const item of this.localModelValue[1]) {
          const checkboxChangeObj = this.itemCheckboxes[item[this.propKey]].changeObj;
          if (checkboxChangeObj.checked) {
            newSelection[1].push(item);
          }
        }

        this.selection = newSelection
        this.$refs.pickList.focusedOptionIndex = -1;
      },
      handleMoveToTarget() {
        if (this.sourceAllCheckBox.changeObj.all)
          this.sourceAllCheckBox.changeObj.all = false
        this.$nextTick(() =>{
          this.clearSelection(1);
        });
      },
      handleMoveToSource() {
        if (this.targetAllCheckBox.changeObj.all)
          this.targetAllCheckBox.changeObj.all = false
        this.$nextTick(() =>{
          this.clearSelection();
        });
      },
      clearSelection(target = 0) {
        if (target === 0) {
          for (const item of this.localModelValue[0]) {
            const checkboxChangeObj = this.itemCheckboxes[item[this.propKey]].changeObj;
            checkboxChangeObj.checked = false;
          }
        } else if (target === 1) {
          for (const item of this.localModelValue[1]) {
            const checkboxChangeObj = this.itemCheckboxes[item[this.propKey]].changeObj;
            checkboxChangeObj.checked = false;
          }
        }
        this.selection.forEach(arr => arr.length = 0);
      },
      handleMoveAllToTarget() {
        if (this.sourceAllCheckBox.changeObj.all)
          this.sourceAllCheckBox.changeObj.all = false
      },
      handleMoveAllToSource() {
        if (this.targetAllCheckBox.changeObj.all)
          this.targetAllCheckBox.changeObj.all = false
      },
      everyItemInListIsChecked(listIndex) {
        return this.localModelValue[listIndex].every(item => this.itemCheckboxes[item[this.propKey]].changeObj.checked)
      },
      getOptionText(color, translate = true) {
        let prop = 'graphColor'
        if (color && color.startsWith("--prospace-")) {
          let theme = localStorage.getItem("theme") ?? "light";
          prop = translate
            ? theme + "-" + color.replace("--prospace-", "")
            : color.replace("--prospace-", "");
        }
        return translate ? this.$t(prop) : prop
      }
    }
  }
</script>
<style lang="scss">
$colors: (
  dashboard-graph-black: --prospace-dashboard-graph-black,
  dashboard-graph-light-blue: --prospace-dashboard-graph-light-blue,
  dashboard-graph-green: --prospace-dashboard-graph-green,
  dashboard-graph-turquoise: --prospace-dashboard-graph-turquoise,
  dashboard-graph-blue: --prospace-dashboard-graph-blue,
  dashboard-graph-orange: --prospace-dashboard-graph-orange,
  dashboard-graph-red: --prospace-dashboard-graph-red,
  dashboard-graph-pink: --prospace-dashboard-graph-pink,
  dashboard-graph-grey: --prospace-dashboard-graph-grey,
  dashboard-graph-violet: --prospace-dashboard-graph-violet
);

.modal-pick-list {
  background-color: var(--prospace-ui-bg-layout);
  .p-dialog-content {
    padding: 8px;
    background-color: var(--prospace-ui-bg-layout);
  }
  .prospace-modal-wrapper {
    overflow: hidden;
  }
}
.prospace-picklist {
  height: 100%;
  .p-picklist-list-wrapper {
    background-color: var(--prospace-ui-bg);
  }
  .p-picklist-header {
    padding: 8px 10px;
    .prospace-input-layout {
      width: auto !important;
    }
    .prospace-checkbox__label {
      font-size: 12px;
      font-weight: 400;
      color: var(--prospace-text-gray);
    }
    .prospace-tag {
      border-radius: 5px;
      background-color: var(--prospace-block-blue-secondary);
      color: var(--prospace-ui-main);
    }
  }
  &__sorting {
    width: auto !important;
    margin-left: auto;
    .prospace-icon {
      mask-size: 200%;
    }
    .prospace-action {
      &:last-child {
        .prospace-icon {
          top: -2px;
        }
      }
    }
  }
  .p-picklist-transfer-buttons {
    .p-button {
      &:nth-child(even) {
        display: none;
        //.p-button-icon {
        //  font-size: 15px;
        //}
      }
    }
  }
  &__button {
    width: 30px !important;
    height: 30px !important;
    border: 1px solid var(--prospace-ui-border-color);
    padding: 5px !important;
    background-color: var(--prospace-ui-bg);
    border-radius: 5px;
    cursor: pointer;
    .p-button-icon {
      font-size: 12px;
      color: var(--prospace-ui-icon-gray);
    }
  }
  .p-picklist-list {
    min-height: calc(100% - 35px);
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .p-picklist-item {
    .prospace-input-layout {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-flow: row-reverse;
      &__label {
        font-size: 12px;
        max-width: 365px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &__label-container {
        gap: 8px !important;
        margin-bottom: 0;
      }
      .prospace-checkbox-container {
        width: 14px;
        margin-right: 10px;
      }
    }
  }
  .picklist-square-color {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    overflow: hidden;
    border: 1px solid transparent;
    line-height: 14px;
    @each $name, $color in $colors {
      &--#{$name} {
        border-color: var($color);
        background-color: var($color);
      }
    }
  }
}
</style>
