<template>
  <ProSpaceVLayout class="prospace-panel-block-list__row" style="height: 50px;" :gap="5">
    <ProSpaceLabel :text="$t('elementType')" bold color="gray" />
    <ProSpaceDropdown
      ref="filterTypeDropDown"
      v-model="localSelectedContainer"
      :options="typeOptions"
      propValue="value"
      propLabel="label"
      :placeholder="$t('elementType')"
      @change="changeComponentType"
    />
  </ProSpaceVLayout>
</template>

<script>
import {
  ProSpaceVLayout,
  ProSpaceLabel,
  ProSpaceDropdown,
} from "@prospace/prospace-components-library";
export default {
  name: "DashboardLayoutSettingsGridSelectTypeElement",
  components: {
    ProSpaceVLayout,
    ProSpaceLabel,
    ProSpaceDropdown,
  },
  inject: ['changingComponentType'],
  props: {
    selectedContainer: {
      type: Object,
      default: null
    },
    typeOptions: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      localSelectedContainer: null,
      oldSelectedContainer: null,
    }
  },
  watch: {
    selectedContainer: {
      handler: function(val) {
        if (!val) return;
        const value = val.value ? val.value : this.typeOptions[0].value
        this.localSelectedContainer = value;
        this.oldSelectedContainer = value;
      },
      immediate: true,
      deep: true
    },
    changingComponentType: {
      handler: function(val, oldVal) {
        if (val && val.value) {
          const { oldType, newType, cancel } = val.value;
          if (!oldType && !newType) return;
          if (cancel && oldType !== newType.value) {
            this.localSelectedContainer = oldType;
          } else {
            this.localSelectedContainer = newType.value;
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    changeComponentType(type) {
      this.$emit('change:type', type);
    }
  },
}
</script>

<style scoped>

</style>
