import moment from 'moment';

function compareFloats(a, b, precision = 6 ) {
  return Math.abs(a - b) < Math.pow(10, -precision);
}

function compareValues(a, b , key, naturalCompare, isValidDate) {
  const valueA = a[key];
  const valueB = b[key];

  // numbers
  if (typeof valueA === 'number' && typeof valueB === 'number') {
    if (Number.isInteger(valueA) && Number.isInteger(valueB)) {
      return valueA - valueB;
    } else {
      if (compareFloats(valueA, valueB)) {
        return 0;
      } else {
        return valueA - valueB;
      }
    }
  }

  // dates
  if (isValidDate(valueA) && isValidDate(valueB)) {
    return moment(valueA).diff(moment(valueB));
  }

  // strings
  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return naturalCompare(valueA, valueB);
  }

  return 0;
}

function sortData(data, key, direction, naturalCompare, isValidDate) {
  return data.sort((a, b) => {
    const result = compareValues(a, b, key, naturalCompare, isValidDate);
    return direction === 'asc' ? result : -result;
  })
}

export function useCompareData(naturalCompare, isValidDate) {
  return {
    sortData: (data, key, direction = 'asc') => sortData(data, key, direction, naturalCompare, isValidDate)
  }
}
