<template>
  <ProSpaceModal
    ref="modal"
    v-if="model"
    v-model="localModelValue"
    class="dashboard-modal-color-selection"
    :width="500"
    :height="height"
    :title="$t('settingColorSelection')"
    icon="gears"
    :iconWidth="20"
    :iconHeight="20"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('save')"
    @apply="saveColorSetting"
    @cancel="closeModal"
  >
    <ProSpaceGeoLayout v-if="model" isHideBottom isHideRight isHideLeft isHideTop>
      <template #center>
        <ProSpaceVLayout :gap="20" style="height: auto;">
          <ProSpaceVLayout v-if="fieldType === 'field'" :gap="5">
            <ProSpaceLabel :text="$t('typeColorSelection')" bold color="gray" />
            <ProSpaceDropdown
              ref="filterTypeDropDown"
              v-model="model.type"
              :options="getTypeColorSelection"
              propValue="value"
              propLabel="label"
              @change="updateSize"
            />
          </ProSpaceVLayout>
          <template v-if="model?.type === 'default'">
            <ProSpaceVLayout :gap="5">
              <ProSpaceLabel :text="$t('colorSelection')" bold color="gray" />
              <ProSpaceHLayout :gap="10" justify-content="space-between">
                <DashboardColorPicker
                  v-if="!textOnly"
                  type="background"
                  :color="model.background"
                  :label="$t('cellColor')"
                  :hint="$t('cellColor')"
                  @update:color="setColor($event, 'default', 'background')"
                />
                <DashboardColorPicker
                  type="text"
                  :color="model.text"
                  :label="$t('textColor')"
                  @update:color="setColor($event, 'default', 'text')"
                />
              </ProSpaceHLayout>
            </ProSpaceVLayout>
          </template>
          <template v-if="model?.type === 'lights'">
            <ProSpaceVLayout :gap="15">
              <ProSpaceVLayout :gap="5">
                <ProSpaceLabel :text="$t('colorSelection')" bold color="gray" />
                <ProSpaceHLayout :gap="10">
                  <ProSpaceInputNumber
                    like-text
                    v-model="model.range.left"
                    :label="$t('bottomBorderTxt')"
                  />
                  <ProSpaceInputNumber
                    like-text
                    v-model="model.range.right"
                    :label="$t('topBorderTxt')"
                  />
                </ProSpaceHLayout>
              </ProSpaceVLayout>
              <ProSpaceHLayout :gap="10">
                <DashboardColorPicker
                  v-if="!textOnly"
                  type="background"
                  :color="model.before.background"
                  :label="$t('cellColor')"
                  :hint="$t('cellColorHintTextBefore')"
                  @update:color="setColor($event, 'lights', 'background', 'before')"
                />
                <DashboardColorPicker
                  type="text"
                  :color="model.before.text"
                  :label="$t('textColor')"
                  @update:color="setColor($event, 'lights', 'text', 'before')"
                />
              </ProSpaceHLayout>
              <ProSpaceHLayout :gap="10">
                <DashboardColorPicker
                  v-if="!textOnly"
                  type="background"
                  :color="model.center.background"
                  :label="$t('cellColor')"
                  :hint="$t('cellColorHintTextCenter')"
                  @update:color="setColor($event, 'lights', 'background', 'center')"
                />
                <DashboardColorPicker
                  type="text"
                  :color="model.center.text"
                  :label="$t('textColor')"
                  @update:color="setColor($event, 'lights', 'text', 'center')"
                />
              </ProSpaceHLayout>
              <ProSpaceHLayout :gap="10">
                <DashboardColorPicker
                  v-if="!textOnly"
                  type="background"
                  :color="model.after.background"
                  :label="$t('cellColor')"
                  :hint="$t('cellColorHintTextAfter')"
                  @update:color="setColor($event, 'lights', 'background', 'after')"
                />
                <DashboardColorPicker
                  type="text"
                  :color="model.after.text"
                  :label="$t('textColor')"
                  @update:color="setColor($event, 'lights', 'text', 'after')"
                />
              </ProSpaceHLayout>
            </ProSpaceVLayout>
          </template>
        </ProSpaceVLayout>

      </template>
    </ProSpaceGeoLayout>

  </ProSpaceModal>

</template>

<script>
import {
  ProSpaceGeoLayout,
  ProSpaceModal,
  ProSpacePanelBlock,
  ProSpaceTag,
  ProSpaceTree,
  ProSpacePanelBlockItem,
  ProSpaceInputSwitch,
  ProSpaceTagsColumn,
  ProSpaceIcon,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceLabel,
  ProSpaceInputNumber,
  Hint,
} from "@prospace/prospace-components-library";
import DashboardColorPicker from "./DashboardColorPicker.vue";
import _ from "lodash";
export default {
  name: "DashboardModalColorSelection",
  components: {
    ProSpaceGeoLayout,
    ProSpaceModal,
    ProSpacePanelBlock,
    ProSpaceTag,
    ProSpaceTree,
    ProSpacePanelBlockItem,
    ProSpaceInputSwitch,
    ProSpaceInputNumber,
    ProSpaceTagsColumn,
    ProSpaceIcon,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceLabel,
    Hint,
    DashboardColorPicker,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    color: {
      type: Object,
      required: true,
    },
    textOnly: {
      type: Boolean,
      default: false,
    },
    fieldType: {
      type: String,
      default: "",
    }
  },
  emits: ['update:color', 'update:modelValue'],
  data() {
    return {
      model: null,
      height: 310
    };
  },
  watch: {
    modelValue: {
        handler: function(val) {
        if (val) {
          //this.model = { ...this.color }
        }
      }
    }
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    getTypeColorSelection() {
      return [
        { label: this.$t('typeDefaultColorSelection'), value: 'default' },
        { label: this.$t('typeLightsColorSelection'), value: 'lights' },
      ]
    }
  },
  methods: {
    closeModal() {
      // this.clearModel();
      this.$emit("update:modelValue", false);
    },
    clearModel() {
      //.model = null;
    },
    saveColorSetting() {
      this.$emit("update:color", this.model);
    },
    setColor(color, type, model, submodel) {
      if (type === 'lights') {
        this.model[submodel][model] = color;
      } else if (type === 'default') {
        this.model[model] = color;
      }
    },
    setHeigth() {
      let height = 0;
      height = this.model?.type === 'default' ? 320 : 520;
      if (this.fieldType !== 'field')
        height -= 65;

      this.height = height;
    },
    updateSize() {
      this.setHeigth()
      this.$nextTick(() => {
        this.$refs.modal.setSize();
      })
    }
  },
  mounted() {
    this.model = _.cloneDeep(this.color);
    this.setHeigth()
  }
}
</script>

<style lang="scss">
.dashboard-modal-color-selection {
  .prospace-input-layout__label {
    font-size: 12px;
    font-weight: 400;
    color: var(--prospace-text-gray);
  }
}
</style>
