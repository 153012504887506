<template>
  <ProSpaceGeoLayout :routeName="$route.name" isParent>
    <template #top>
      <ProSpaceTopPanel :header="this.$t('orgStructure')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            @click="actionUpdate(false)"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
          />
          <IconButtonSettingsColumns @click="showModalSettingsColumns"/>
          <ProSpaceButton
            v-if="checkAccess('add-button')"
            ref="add-button"
            type="primary"
            icon="plus"
            :label="this.$t('MailingGroup')"
            :disabled="canCreateMailingGroup"
            @click="showChangeModal"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #left>
      <ProSpaceLeftPanel
        class="marsdistributororgusersview-tree__left-panel"
        :searchPlaceholder="$t('searchTxt')"
        panelName=""
        searchable
        @search="handlerSearch"
      >
        <template #center>
          <ProSpaceTree
            ref="tree"
            :service="marsDistributorOrgViewService"
            searchName="name"
            getNodeMethod="tree"
            propKey="treeId"
            propLabel="name"
            :searchFilterModel="['name']"
            :needAdd="needAdd"
            :needEdit="needEdit"
            :needDelete="needDelete"
            :needLocalSearch="true"
            :isAll="true"
            :isNested="true"
            :labelOverflow="true"
            @select="nodeSelect"
            @node-expand="nodeExpand"
            @addEvent="openAddMarsStructure"
            @deleteEvent="showFlashMessageDelete"
            @editEvent="openEdit"
          >
            <template #empty>
              <ProSpaceEmptyGrid icon="error-message" :title="$t('noDataTitle')" />
            </template>
          </ProSpaceTree>
        </template>
      </ProSpaceLeftPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :noMountedRequest="true"
            :unicKeyStorage="marsdistributororgusersviewService.constructor.name + 'grid'"
            @apply="submitFilter"
            @clear="onClearFilter"
            ref="filterPanel"
            :filter-model="['firstName', 'lastName', 'secondName', 'email', 'position']"
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceMultiSelect
                v-model="filter.recordType"
                :service="recordTypeService"
                get-method="get"
                option-label="text"
                option-value="recordType"
                :placeholder-value="$t('type')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :searchPlaceholder="$t('searchTxt')"
                @apply="(model) => customFilterApply(filter, model)"
              />
              <ProSpaceMultiSelect
                :key="positionFilterKey"
                v-model="filter.position"
                :service="getPositionService"
                get-method="grid"
                option-label="name"
                option-value="name"
                :isAddSearch="true"
                :panelStyle="{
                  maxWidth: '240px',
                }"
                :placeholder-value="$t('position')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :searchPlaceholder="$t('searchTxt')"
                @apply="(model) => customFilterApply(filter, model)"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="
                  marsdistributororgusersviewService.constructor.name + 'grid'
                "
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            :isHaveFilter="true"
            :noMountedRequest="true"
            :service="marsdistributororgusersviewService"
            @selected-change="selectedHandler"
            getMethod="grid"
            selectionMode="single"
            dataKey="unicId"
            :defaultFilter="crutchFilter"
            v-bind="gridPropsSettingsColumns"
          >
            <template #columns>
              <Column
                field="fullName"
                :header="$t('user')"
                sortable
                headerClass="move-sort-head"
                frozen
                class="column-table-header-cell"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    isTitleInline
                    :no-hover="true"
                    :status="getStatus(field.data)"
                    :title="
                      field.data.secondName
                        ? `${field.data.lastName} ${field.data.firstName} ${field.data.secondName}`
                        : `${field.data.lastName} ${field.data.firstName}`
                    "
                  />
                </template>
              </Column>
              <Column field="position" :header="$t('position')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.position">
                    {{ field.data.position }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="companyName" :header="$t('company')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.companyName">
                    {{ field.data.companyName }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="email" :header="$t('email')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.email">
                    {{ field.data.email }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="comments" :header="$t('comments')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.comments">
                    {{ field.data.comments }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column
                field="actions"
                class="column-end column-end-30"
                frozen
                alignFrozen="right"
              >
                <template #body="field">
                  <ProSpaceDots
                    v-if="field.data.recordType === 'MailingGroup'"
                    :options="wrapperCheckRoles(dotsOptions, field.data)"
                    @action="(action) => handleDots(action, field.data)"
                  ></ProSpaceDots>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
      <ProSpaceModal
        :key="`validate-modal-${showModal}`"
        v-model="showModal"
        width="410"
        :disabledApply="false"
        :title="isEdit ? $t('editItemTxt') : $t('createItemTxt')"
        :icon="isEdit ? 'edit' : 'plus'"
        @apply="changeItem"
        :cancelTxt="$t('cancelInf')"
        :applyTxt="$t('applyTxt')"
        :validate-local="$t"
        :validate-model="modalModel"
        :validate-config="{
          name: { required },
          email: {
            required,
            email: {
              fn: Validations.isEmail,
              message: $t('notValidEmail'),
            },
          },
        }"
      >
        <template #default="{ v }">
          <ProSpaceVLayout :gap="20">
            <ProSpaceInputText
              :label="$t('name')"
              v-model="modalModel.name"
              :placeholder="$t('name')"
              :invalid="v.name.$error"
              :invalid-messages="v.name.$errors"
              @blur="v.name.$touch"
            />
            <ProSpaceInputText
              :label="$t('email')"
              v-model="modalModel.email"
              :placeholder="$t('email')"
              :invalid="v.email.$error"
              :invalid-messages="v.email.$errors"
              @blur="v.email.$touch"
            />
          </ProSpaceVLayout>
        </template>
      </ProSpaceModal>
    </template>
    <template #right>
      <MarsDistributorOrgUsersViewPanel
        v-if="hasSelect"
        ref="right-panel"
        :options="dotsOptions"
        :filterAccessDots="wrapperCheckRoles"
        :checkAccess="checkAccess"
        @edit-item="editMailingGroup"
        @delete-item="deleteMailingGroup"
      />
    </template>
  </ProSpaceGeoLayout>
  <DistributorOrgModal
    ref="distrOrgModal"
    :service="distributorOrgService"
    @apply="updateDistrOrg"
  ></DistributorOrgModal>
  <ProSpaceModal
    :key="`validate-mars-modal-${showChangeMarsModal}`"
    v-model="showChangeMarsModal"
    width="410"
    :disabledApply="false"
    :title="$t('createItemTxt')"
    icon="plus"
    @apply="addMarsStructureUnit"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('applyTxt')"
    :validate-local="$t"
    :validate-model="modalModelMars"
    :validate-config="{
      name: { required },
    }"
  >
    <template #default="{ v }">
      <ProSpaceInputText
        :label="$t('name')"
        v-model="modalModelMars.name"
        :placeholder="$t('name')"
        :invalid="v.name.$error"
        :invalid-messages="v.name.$errors"
        @blur="v.name.$touch"
      />
    </template>
  </ProSpaceModal>
  <OrgDeleteFlashMessage
    v-model="flashMessageDelete"
    v-model:node="deletingNode"
    @apply="deleteMarsStructure"
  />
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingConfirmation')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="
          $t('deleteMailingGroup', {
            name: deletingModel.firstName || deletingModel.name,
          })
        "
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceModal,
  ProSpaceInputText,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceEmptyGrid,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpacePanelBlockList,
  ProSpacePanelBlock,
  ProSpaceSearchfield,
  ProSpaceVLayout,
  Validations,
  ProSpaceFlashMessage,
  ProSpaceHLayout,
  ProSpaceDefaultTextColumn,
} from "@prospace/prospace-components-library";
import Column from "primevue/column";
import { MarsDistributorOrgUsersViewService } from "../../services/MarsDistributorOrgUsersViewService.js";
import { MarsDistributorOrgViewService } from "../../services/MarsDistributorOrgViewService.js";
import MarsDistributorOrgUsersViewPanel from "./components/MarsDistributorOrgUsersViewPanel";
import DistributorOrgModal from "./components/DistributorOrgModal";
import { DistributorOrgService } from "../../services/DistributorOrgService";
import { MarsOrgService } from "../../services/MarsOrgService";
import { MailingGroupService } from "../../services/MailingGroupService";
import { RecordTypeService } from "../../services/RecordTypeService";
import OrgDeleteFlashMessage from "./components/OrgDeleteFlashMessage.vue";
import { mapGetters } from "vuex";
const _ = require("lodash");
import { useMultiSelectGrid } from "@composes/multiselect";
import { ref } from "vue";
import IconButtonSettingsColumns from "@components/settingsColumns/IconButtonSettingsColumns.vue";
import useSettingsColumns from "@composes/useSettingsColumns.js";
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    ProSpaceModal,
    ProSpaceInputText,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceEmptyGrid,
    MarsDistributorOrgUsersViewPanel,
    ProSpaceLeftPanel,
    ProSpaceTree,
    DistributorOrgModal,
    ProSpacePanelBlockList,
    ProSpacePanelBlock,
    ProSpaceSearchfield,
    ProSpaceVLayout,
    ProSpaceFlashMessage,
    ProSpaceHLayout,
    OrgDeleteFlashMessage,
    ProSpaceDefaultTextColumn,
    IconButtonSettingsColumns
  },
  data() {
    return {
      crutchFilter: {},

      Validations,

      positionFilterKey: 0,
      selectedNode: {},

      currentNode: {},
      modalModelMars: { name: null },
      showChangeMarsModal: false,
      isMarsEdit: true,

      flashMessageDelete: false,
      deletingNode: null,

      userFilter: null,
      showModal: false,
      showHistoryModal: false,

      // Service
      marsdistributororgusersviewService: new MarsDistributorOrgUsersViewService(),
      marsDistributorOrgViewService: new MarsDistributorOrgViewService(),
      distributorOrgService: new DistributorOrgService(),
      marsOrgService: new MarsOrgService(),
      mailingGroupService: new MailingGroupService(),
      recordTypeService: new RecordTypeService(),

      //InlinePanel
      masterId: null,
      isEdit: false,
      isCreate: false,
      modalModel: {},

      // Multi filter
      customServices: [
        {
          type: "chips",
          model: "firstName",
          label: this.$t("firstName"),
          selected: {},
        },
        {
          type: "chips",
          model: "email",
          label: this.$t("email"),
          selected: {},
        },
      ],

      deletingModel: null,
      showDeleteModal: false,
    };
  },
  setup() {
    const grid = ref(null);
    const filterPanel = ref(null);
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel);
    const {
      showModalSettingsColumns,
      gridPropsSettingsColumns
    } = useSettingsColumns(grid)
    return {
      grid,
      filterPanel,
      selectedMultipleGridHandler,
      hasSelect,
      selectedMultipleClear,
      showModalSettingsColumns,
      gridPropsSettingsColumns
    };
  },
  mounted() {
    this.loadRoleAccess();
  },
  computed: {
    ...mapGetters({
      access: "securityRoleAccess/access",
    }),
    dotsOptions() {
      let res = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editMailingGroup,
          liteRef: "edit-option",
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteMailingGroup,
          liteRef: "delete-option",
        },
      ];
      return res;
    },
    canCreateMailingGroup() {
      return !(this.selectedNode.parentId && this.selectedNode.type != "RealDistributor");
    },
    getPositionService() {
      if (Object.keys(this.selectedNode).length === 0) return this.marsOrgService;

      return this.selectedNode.realType === "Mars"
        ? this.marsOrgService
        : this.distributorOrgService;
    },
  },
  methods: {
    onClearFilter() {
      this.crutchFilter = {};
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    deleteMailingGroup(record) {
      if (!record) return;
      this.deletingModel = record;
      this.showDeleteModal = true;
    },
    applyDelete() {
      const grid = this.grid;
      grid.setLoading(true);
      let toDelete = {
        id: this.deletingModel.id,
        name: this.deletingModel.firstName || this.deletingModel.name,
        email: this.deletingModel.email,
      };
      this.mailingGroupService.delete(toDelete).then(() => {
        this.actionUpdate();
        this.selectedModel = {};
        this.deletingModel = null;
      });
    },
    editMailingGroup(record) {
      this.isEdit = true;
      this.isCreate = false;
      this.modalModel = {
        id: record.id,
        name: record.firstName,
        email: record.email,
      };
      this.showModal = true;
    },
    openEdit(node) {
      if (node.type === "Distributor") {
        this.openDistStructure();
        return;
      }
      this.currentNode = node;
      this.modalModelMars = {
        id: node.id,
        name: node.name,
      };
      this.isMarsEdit = true;
      this.showChangeMarsModal = true;
    },
    handlerSearch(value) {
      this.$refs.tree.handlerSearch(value);
    },
    updateModelField(data, field) {
      this.modalModel[
        `orgLink${field[0].toUpperCase() + field.toLowerCase().slice(1)}Name`
      ] = this.$refs[field].selectedItem.name;
    },
    getDistrOrgPanelInfo(record) {
      const pos = [
        {
          title: this.$t("position"),
          text: record.name,
        },
      ];
      const parentPos = [
        {
          title: this.$t("parentPosition"),
          text: record.parentName,
        },
      ];
      return record.parentName ? [parentPos, pos] : [pos];
    },
    addMarsStructureUnit() {
      let method = this.isMarsEdit ? "update" : "create";
      this.marsOrgService[method](this.modalModelMars).then((res) => {
        if (!this.isMarsEdit) this.$refs.tree.getData(this.currentNode);
        else this.currentNode.name = this.modalModelMars.name;
        this.modalModelMars = { name: null };
        this.currentNode = {};
        this.showChangeMarsModal = false;
      });
    },
    updateDistrOrg() {
      this.$refs.tree.getData(
        this.$refs.tree.findNode(this.$refs.tree.nodes, "-9223372036854775000-D")
      );
    },
    openDistStructure() {
      this.$refs.distrOrgModal.changed = false;
      this.$refs.distrOrgModal.show();
    },
    showFlashMessageDelete(node) {
      this.deletingNode = node;
      this.flashMessageDelete = true;
    },
    // handlerCancelFlashMessageDelete() {
    //   this.deletingNode = null;
    //   this.flashMessageDelete = false;
    // },
    deleteMarsStructure() {
      // this.flashMessageDelete = false;
      const node = this.deletingNode;
      const me = this;
      const toDelete = {
        id: node.id,
        name: node.name,
        code: node.code,
        stamp: node.stamp,
        leaf: node.leaf,
      };
      this.marsOrgService.delete(toDelete).then((res) => {
        if (res && res.success) {
          let parent = me.$refs.tree.findNode(me.$refs.tree.nodes, node.parentId);
          this.$refs.tree.getData(parent);
        }
      });
    },
    openAddMarsStructure(node) {
      this.currentNode = node;
      const parentId = node.id === 9223372036854775000 ? null : node.id;
      this.modalModelMars = {
        parentId,
        name: null,
      };
      this.isMarsEdit = false;
      this.showChangeMarsModal = true;
    },
    submitFilter({ filter, search }) {
      this.$nextTick(() => {
        this.grid.submitFilter(filter, search);
        this.crutchFilter = this.grid.filter;
      });
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    actionUpdate(partUpdate = false) {
      if (partUpdate === false) {
        this.$refs.tree.getData();
      }
      this.grid.submitDefaultFilter();
      this.crutchFilter = this.grid.filter;
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val);
    },
    showChangeModal() {
      this.isEdit = false;
      this.isCreate = true;
      this.modalModel = {
        type: this.selectedNode.type,
        distributorId: this.selectedNode.distributorId,
      };
      if (this.selectedNode.type === "Mars") {
        this.modalModel.marsOrgId = this.selectedNode.id;
        this.modalModel.marsOrgName = this.selectedNode.name;
      } else {
        this.modalModel.distributorOrgId = this.selectedNode.id;
        this.modalModel.distributorOrgName = this.selectedNode.name;
      }
      this.showModal = true;
    },
    changeItem() {
      let method;
      if (this.isCreate) {
        method = "create";
      }
      if (this.isEdit) {
        method = "update";
      }
      this.mailingGroupService[method](this.modalModel).then(() => {
        this.actionUpdate(true);
      });
    },
    getStatus(record) {
      let type = "default";
      return {
        text: this.$t(record.recordType),
        type,
      };
    },
    nodeSelect(value) {
      if (this.selectedNode && this.selectedNode.key === value.key) return;
      this.selectedMultipleClear();

      const type = value.type === "RealDistributor" ? "Distributor" : value.type;
      let filter = {};
      const orgId = value.parentId && value.type !== "RealDistributor" ? value.id : null;
      filter.orgId = { and: [orgId] };
      filter.type = { and: [type] };
      if (type === "Distributor" && value.parentId)
        filter.distributorId = { and: [value.distributorId] };
      else filter.distributorId = { and: [] };
      if (
        this.selectedNode.realType !== type &&
        this.selectedNode.realType !== undefined
      ) {
        // Reset positon filter
        delete this.filterPanel.filter.position;
        delete this.grid.filter.position;
        this.positionFilterKey++;
      }
      this.selectedNode = value;
      this.selectedNode.realType = type;
      this.$nextTick(() => {
        this.grid.sumbitMergeFilter(filter);
        this.crutchFilter = this.grid.filter;
      });
    },
    needAdd(node) {
      return this.checkAccess("action-addNode", node) && node.type === "Mars";
    },
    needEdit(node) {
      return (
        this.checkAccess("action-editNode", node) &&
        ((node.type === "Mars" && node.parentId !== null) ||
          (node.type === "Distributor" && node.parentId === null))
      );
    },
    needDelete(node) {
      return (
        this.checkAccess("action-deleteNode", node) &&
        node.type === "Mars" &&
        node.parentId
      );
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/main.scss";
.marsdistributororgusersview-tree {
  .radio-tabs {
    margin-top: 1px;
    .radio-tab:nth-child(2) {
      display: none !important;
    }
    &-body {
      margin: 10px;
    }
  }

  &__left-panel {
    border: var(--prospace-ui-border);
  }
}
</style>
