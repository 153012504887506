<template>
  <ProSpaceModal
    v-model="localModelValue"
    :title="$t('selectRecipients')"
    icon="history"
    without-padding
    :content-style="{ height: '80vh', width: '1500px' }"
    @apply="$emit('close', false)"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('selectTxt2')"
    :needHideApply="false"
  >
    <template v-slot:default>
      <ProSpaceGeoLayout :routeName="`${$route.name}OrgStructureSelectModal`" isParent>
        <template #left>
          <ProSpaceLeftPanel searchable panelName="" @search="handlerSearch" :searchPlaceholder="$t('searchTxt')">
            <template #center>
              <ProSpaceTree
                selectionMode="checkbox"
                ref="tree"
                :service="marsDistributorOrgViewService"
                searchName="name"
                :getNodeMethod="nodeMethod"
                propKey="treeId"
                propLabel="name"
                :fn-disabled-checkbox="(node) => !node.count"
                :needLocalSearch="true"
                @select="handlerNodeSelect"
                @loaded="loadFilter"
                @check="handlerCheck"
                @uncheck="handlerUncheck"
              />
            </template>
          </ProSpaceLeftPanel>
        </template>
        <template #center>
          <ProSpaceGeoLayout>
            <template #top>
              <ProSpaceFilterPanel
                :closeTxt="$t('closeTxt')"
                :searchPlaceholder="$t('searchTxt')"
                :txtResetFilters="$t('txtResetFilters')"
                :noMountedRequest="true"
                @apply="submitFilter"
                :unicKeyStorage="
                  marsdistributororgusersviewService.constructor.name + 'grid'
                "
                ref="filter-panel"
                :filter-model="[
                  'firstName',
                  'lastName',
                  'secondName',
                  'email',
                  'position',
                ]"
              >
                <template #hint>
                  {{ this.$t("filterBy") }}
                </template>
                <template #filters="{ filter }">
                  <ProSpaceFilterContainsChips
                    v-if="!shortGrid"
                    v-model="filter.companyName"
                    :placeholder-value="$t('company')"
                    :closeTxt="$t('closeTxt')"
                    :applyTxt="$t('applyTxt')"
                    :clearFilterTxt="$t('clearFilterTxt')"
                    @apply="(model) => customFilterApply(filter, model)"
                  />
                  <ProSpaceMultiSelect
                    v-model="filter.recordType"
                    :service="recordTypeService"
                    :get-method="!shortGrid ? 'get' : 'getShort'"
                    option-label="text"
                    option-value="recordType"
                    :placeholder-value="$t('type')"
                    :searchPlaceholder="$t('searchTxt')"
                    :selectAllTxt="$t('selectAllTxt')"
                    :closeTxt="$t('closeTxt')"
                    :applyTxt="$t('applyTxt')"
                    :clearFilterTxt="$t('clearFilterTxt')"
                    @apply="(model) => customFilterApply(filter, model)"
                  />
                  <ProSpaceMultiSelect
                    :key="positionFilterKey"
                    v-model="filter.position"
                    :service="getPositionService"
                    get-method="grid"
                    option-label="name"
                    option-value="name"
                    :placeholder-value="$t('position')"
                    :searchPlaceholder="$t('searchTxt')"
                    :selectAllTxt="$t('selectAllTxt')"
                    :closeTxt="$t('closeTxt')"
                    :applyTxt="$t('applyTxt')"
                    :clearFilterTxt="$t('clearFilterTxt')"
                    @apply="(model) => customFilterApply(filter, model)"
                  />
                  <ProSpaceCustomFilterPanel
                    :customTxt="$t('custom')"
                    :customFilterTxt="$t('customFilterTxt')"
                    :selectAllTxt="$t('selectAllTxt')"
                    :closeTxt="$t('closeTxt')"
                    :applyTxt="$t('applyTxt')"
                    :clearFilterTxt="$t('clearFilterTxt')"
                    :fromTxt="$t('fromTxt')"
                    :toTxt="$t('toTxt')"
                    :services="customServices"
                    :unicKeyStorage="
                      marsdistributororgusersviewService.constructor.name +
                      'grid'
                    "
                    @apply="(model) => customFilterApply(filter, model)"
                  />
                </template>
              </ProSpaceFilterPanel>
            </template>
            <template #center>
              <ProSpaceMainGrid
                ref="grid"
                selectionMode="multiple"
                :isHaveFilter="true"
                :noMountedRequest="true"
                :service="marsdistributororgusersviewService"
                @loaded="setCheckedRows"
                @selected-change="handlerSelected"
                getMethod="grid"
                dataKey="unicId"
                :defaultFilter="{
                  orgId: { and: [null] },
                  type: { and: ['Mars'] },
                }"
              >
                <template #columns>
                  <Column
                    field="fullName"
                    :header="$t('user')"
                    sortable
                    headerClass="move-sort-head"
                    frozen
                    class="column-table-header-cell"
                  >
                    <template #body="field">
                      <ProSpaceTableHeaderCell
                        v-if="field.data"
                        :no-hover="true"
                        :status="getStatus(field.data)"
                        :title="
                          field.data.secondName
                            ? `${field.data.lastName} ${field.data.firstName} ${field.data.secondName}`
                            : `${field.data.lastName} ${field.data.firstName}`
                        "
                      />
                    </template>
                  </Column>
                  <Column field="position" :header="$t('position')" sortable />
                  <Column
                    v-if="!shortGrid && nodeMethod !== 'onlyMars'"
                    field="companyName"
                    :header="$t('company')"
                    sortable
                  />
                  <Column field="email" :header="$t('email')" sortable />
                  <Column v-if="!shortGrid" field="comments" :header="$t('comments')" sortable />
                </template>
                <template #empty>
                  <ProSpaceEmptyGrid
                    icon="error-message"
                    :title="$t('noDataTitle')"
                    :text="$t('noDataTxt')"
                  />
                </template>
              </ProSpaceMainGrid>
            </template>
          </ProSpaceGeoLayout>
        </template>
        <template #right>
          <ProSpaceRightPanel
            :header="{ title: $t('recipients') }"
            v-if="addItems.length > 0 || removeItems.length > 0"
          >
            <template #center>
              <ProSpaceVLayout :gap="20">
                <ProSpacePanelBlock
                  :header="{ title: $t('added'), counter: addItems.length }"
                  v-if="addItems.length > 0"
                >
                  <ProSpaceSimpleGrid
                    :items="addItems"
                    :key="addItems.map((i) => i.id).toString()"
                    :modalTitle="$t('recipients')"
                    :cancelTxt="$t('cancelInf')"
                    :applyTxt="$t('applyTxt')"
                    :btnText="$t('viewMore')"
                    :confirmTitle="$t('deleting')"
                    :confirmText="$t('questionDeleting')"
                    :confirmApplyText="$t('applyTxt')"
                    :confirmCancelText="$t('cancelInf')"
                    :headers="[
                      { name: $t('type'), value: 'type' },
                      {
                        name: $t('recipient'),
                        value: 'recipient',
                        f: (item) => item,
                      },
                    ]"
                    deletable
                    @delete="handlerDeleteItem"
                  />
                </ProSpacePanelBlock>
                <ProSpacePanelBlock
                  :header="{
                    title: $t('excluded'),
                    counter: removeItems.length,
                  }"
                  v-if="removeItems.length > 0"
                >
                  <ProSpaceSimpleGrid
                    :items="removeItems"
                    :key="removeItems.map((i) => i.id).toString()"
                    :modalTitle="$t('recipients')"
                    :cancelTxt="$t('cancelInf')"
                    :applyTxt="$t('applyTxt')"
                    :btnText="$t('viewMore')"
                    :confirmTitle="$t('deleting')"
                    :confirmText="$t('questionDeleting')"
                    :confirmApplyText="$t('applyTxt')"
                    :confirmCancelText="$t('cancelInf')"
                    :headers="[
                      { name: $t('type'), value: 'type' },
                      {
                        name: $t('recipient'),
                        value: 'recipient',
                        f: (item) => item,
                      },
                    ]"
                    deletable
                    @delete="handlerDeleteItem"
                  />
                </ProSpacePanelBlock>
              </ProSpaceVLayout>
            </template>
          </ProSpaceRightPanel>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceModal>
</template>
<script>
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceModal,
  ProSpaceGeoLayout,
  ProSpaceTableHeaderCell,
  ProSpaceEmptyGrid,
  ProSpaceTree,
  ProSpaceRightPanel,
  ProSpacePanelBlock,
  ProSpaceSimpleGrid,
  ProSpaceVLayout,
  ProSpaceLeftPanel,
  ProSpaceFilterContainsChips,
} from "@prospace/prospace-components-library";
import Column from "primevue/column";
import { DistributorOrgService } from "../../../../Services/MasterData/MasterData.Frontend/services/DistributorOrgService";
import { MarsOrgService } from "../../../../Services/MasterData/MasterData.Frontend/services/MarsOrgService";
import { MailingGroupService } from "../../../../Services/MasterData/MasterData.Frontend/services/MailingGroupService";
import { RecordTypeService } from "../../../../Services/MasterData/MasterData.Frontend/services/RecordTypeService";
import { MarsDistributorOrgUsersViewService } from "../../../../Services/MasterData/MasterData.Frontend/services/MarsDistributorOrgUsersViewService.js";
import { MarsDistributorOrgViewService } from "../../../../Services/MasterData/MasterData.Frontend/services/MarsDistributorOrgViewService.js";
export default {
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceModal,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTableHeaderCell,
    ProSpaceEmptyGrid,
    ProSpaceTree,
    ProSpaceRightPanel,
    ProSpacePanelBlock,
    ProSpaceSimpleGrid,
    ProSpaceVLayout,
    ProSpaceLeftPanel,
    ProSpaceFilterContainsChips,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    nodeMethod: {
      type: String,
      default: "allTree",
    },
    shortGrid: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    modelValue(val) {
      this.$emit("update:modelValue", val);
      if (!val) {
        this.nodeFilter = {
          add: {},
          remove: {},
        };
        this.gridFilter = {
          add: {},
          remove: {},
        };
        this.selectedNode = {};
        const k = btoa(
          window.location.href +
            "component_filter_panel" +
            this.$refs["filter-panel"].unicKeyStorage
        );
        localStorage.removeItem(k);
      }
    },
  },
  data() {
    return {
      positionFilterKey: 0,
      selectedNode: {},
      // Grid Panel
      spHide: false,
      panelModel: {},
      panelModels: [],

      marsdistributororgusersviewService:
        new MarsDistributorOrgUsersViewService(),
      marsDistributorOrgViewService: new MarsDistributorOrgViewService(),
      distributorOrgService: new DistributorOrgService(),
      marsOrgService: new MarsOrgService(),
      mailingGroupService: new MailingGroupService(),
      recordTypeService: new RecordTypeService(),

      masterId: null,

      nodeFilter: {
        add: {},
        remove: {},
      },
      gridFilter: {
        add: {},
        remove: {},
      },
      selectedTreeNodes: {},
    };
  },
  methods: {
    handlerSearch(value) {
      this.$refs.tree.handlerSearch(value);
    },
    getDistrOrgPanelInfo(record) {
      const pos = [
        {
          title: this.$t("position"),
          text: record.name,
        },
      ];
      const parentPos = [
        {
          title: this.$t("parentPosition"),
          text: record.parentName,
        },
      ];
      return record.parentName ? [parentPos, pos] : [pos];
    },
    submitFilter({ filter, search }) {
      this.$refs.grid.submitFilter(filter, search);
    },
    customFilterApply(filter, model) {
      // if (filter.companyName) {
      //   filter[`tolower(companyName)`] = filter.companyName;
      //   delete filter.companyName;
      // }
      filter = Object.assign(filter, model);
      this.$refs["filter-panel"].submitFilter();
    },
    actionUpdate(partUpdate = false) {
      if (partUpdate === false) {
        this.$refs.tree.getData();
      }
      this.$refs.grid.submitDefaultFilter();
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    loadFilter() {
      if (!this.$refs.tree.checkedNodes) {
        this.$refs.tree.checkedNodes = this.selectedTreeNodes || {};
      } else {
        this.updateFilterStateAndCheckedNodes();
      }
    },
    handlerSelected() {
      // check selectedModel -> gridFilter(add/remove)
      const selectedModel = this.$refs.grid.getSelectedModel()
      const selectedModelUnicIds = selectedModel.map((r) => r.unicId);
      this.$refs.grid.records.forEach((r) => {
        const hasInSelectedModel = selectedModelUnicIds.includes(r.unicId);
        const mode = hasInSelectedModel ? "add" : "remove";
        const antiMode = mode === "add" ? "remove" : "add";
        const key = this.getKeyRecord(r);

        if (this.gridFilter[antiMode][key]) {
          delete this.gridFilter[antiMode][key];
        }

        if (!this.gridFilter[mode][key]) {
          this.gridFilter[mode][key] = r;
        }
      });

      this.updateFilterStateAndCheckedNodes(false);
    },
    updateFilterStateAndCheckedNodes(needUpdateRows = true) {
      if (
        Object.keys(this.nodeFilter.add).length === 0 &&
        Object.keys(this.gridFilter.add).length === 0
      ) {
        this.$refs.tree.checkedNodes = {};
        this.nodeFilter.remove = {};
        this.gridFilter.remove = {};
        return;
      }
      this.updateFilterState();
      this.updateCheckedNodes();
      if (needUpdateRows) {
        this.setCheckedRows();
      }
    },
    updateFilterState() {
      // обновляет значения nodeFilter и gridFilter

      // common fn
      const getAntiMode = (mode) => {
        return mode === "add" ? "remove" : "add";
      };

      // init fns for gridFilter
      const getSelectNode = (record, mode = "add") => {
        return Object.values(this.nodeFilter[mode]).find((n) =>
          this.filterGridRecord(record, n)
        );
      };
      const deleteRecordsFromFilter = (records, mode = "add") => {
        if (!Array.isArray(records)) {
          delete this.gridFilter[mode][this.getKeyRecord(records)];
          return;
        }
        records.forEach(
          (r) => delete this.gridFilter[mode][this.getKeyRecord(r)]
        );
      };

      // init fns for nodeFilter
      const getNodeRecords = (node, mode = "add") => {
        return Object.values(this.gridFilter[mode]).filter((r) =>
          this.filterGridRecord(r, node)
        );
      };
      const getSelectParent = (child, mode) => {
        return Object.values(this.nodeFilter[mode]).find((n) =>
          this.filterParentNode(n, child)
        );
      };
      // const getSelectChildren = (parent, mode) => {
      //   return Object.values(this.nodeFilter[mode]).filter((n) =>
      //     this.filterParentNode(parent, n)
      //   );
      // };
      const deleteNodeFromFilter = (node, mode = "add") => {
        delete this.nodeFilter[mode][node.key];
      };
      const addNodeInFilter = (node, mode = "add") => {
        const antiMode = getAntiMode(mode);
        if (this.nodeFilter[antiMode][node.key]) {
          deleteNodeFromFilter(node, antiMode);
        }
        this.nodeFilter[mode][node.key] = node;
      };

      // gridFilter all records / children nodeFilter -> add or remove nodeFilter node
      const checkRecordsForAddingNodeInFilter = (node, mode) => {
        const records = getNodeRecords(node, mode);
        if (node.count > 0 && node.count === records.length) {
          addNodeInFilter(node, mode);
        }
      };
      const checkChildrenNodesForAddingParentInFilter = (node, mode) => {
        // const children = getSelectChildren(node, mode);
        if (!node.children) {
          return;
        }
        const childrenKeys = node.children.map((n) => n.key);
        const children = Object.values(this.nodeFilter[mode]).filter((n) =>
          childrenKeys.includes(n.key)
        );
        const childrenCount = children.reduce((prev, curr) => {
          return (prev += curr.count);
        }, 0);
        if (node.count > 0 && node.count === childrenCount) {
          addNodeInFilter(node, mode);
        }
      };
      Object.values(this.$refs.tree.list).forEach((node) => {
        checkRecordsForAddingNodeInFilter(node, "add");
        checkRecordsForAddingNodeInFilter(node, "remove");
      });
      Object.values(this.$refs.tree.list).forEach((node) => {
        checkChildrenNodesForAddingParentInFilter(node, "add");
        checkChildrenNodesForAddingParentInFilter(node, "remove");
      });
      // check hierarchy
      // delete nodes check parent node
      Object.values(this.nodeFilter.add).forEach((n) => {
        const mode = "add";
        const antiMode = getAntiMode(mode);
        const node = getSelectParent(n, mode);
        const antiNode = getSelectParent(n, antiMode);
        if ((node && !antiNode) || n.count === 0) {
          deleteNodeFromFilter(n, mode);
        }
      });
      Object.values(this.nodeFilter.remove).forEach((n) => {
        const mode = "remove";
        const antiMode = getAntiMode(mode);
        const node = getSelectParent(n, mode);
        const antiNode = getSelectParent(n, antiMode);
        if (node || !antiNode) {
          deleteNodeFromFilter(n, mode);
        }
      });

      // delete records has parent node in nodeFilter
      Object.values(this.gridFilter.add).forEach((r) => {
        const mode = "add";
        const antiMode = getAntiMode(mode);
        const node = getSelectNode(r, mode);
        const antiNode = getSelectNode(r, antiMode);
        if (node && !antiNode) {
          deleteRecordsFromFilter(r, mode);
        }
      });
      Object.values(this.gridFilter.remove).forEach((r) => {
        const mode = "remove";
        const antiMode = getAntiMode(mode);
        const node = getSelectNode(r, mode);
        const antiNode = getSelectNode(r, antiMode);
        if (node || !antiNode) {
          deleteRecordsFromFilter(r, mode);
        }
      });

      // has filter(add) -> delete parent nodeFilter(add/remove), add children nodeFilter(add)
      Object.values(this.gridFilter.add).forEach((r) => {
        const parentAdd = getSelectNode(r, "add");
        const parentRemove = getSelectNode(r, "remove");
        if (!parentAdd || !parentRemove) {
          return;
        }
        deleteNodeFromFilter(parentAdd, "add");
        deleteNodeFromFilter(parentRemove, "remove");
        if (parentAdd.children) {
          const addingChildren = parentAdd.children.filter(
            (n) => n.count > 0 && n.key !== parentRemove.key
          );
          addingChildren.forEach((n) => {
            addNodeInFilter(n, "add");
          });
          const node = getSelectNode(r, "add");
          if (node) {
            deleteRecordsFromFilter(r, "add");
          }
        }
      });
      Object.values(this.nodeFilter.add).forEach((n) => {
        const parentAdd = getSelectParent(n, "add");
        const parentRemove = getSelectParent(n, "remove");
        if (!parentAdd || !parentRemove) {
          return;
        }
        deleteNodeFromFilter(parentAdd, "add");
        deleteNodeFromFilter(parentRemove, "remove");
        if (parentAdd.children) {
          const addingChildren = parentAdd.children.filter(
            (n) => n.count > 0 && n.key !== parentRemove.key
          );
          addingChildren.forEach((n) => {
            addNodeInFilter(n, "add");
          });
        }
      });
    },
    updateCheckedNodes() {
      // обновляются значения checked и partialChecked у всех узлов
      // по nodeFilter(add/remove) и gridFilter(add/remove)

      const updateCheck = (key, checked, partialChecked) => {
        if (!checked && !partialChecked) {
          delete listTreeNodes[key];
          return;
        }
        if (!listTreeNodes[key]) {
          const node = this.$refs.tree.list[key];
          listTreeNodes[key] = {
            key: node.key,
            parentKey: node.parentId,
          };
        }
        listTreeNodes[key].checked = checked;
        listTreeNodes[key].partialChecked = partialChecked;
      };

      const listTreeNodes = {
        all: {
          checked: false,
          partialChecked: true,
        },
      };

      const initListTreeNodes = (item) => {
        if (!item.fullPath && !item.path) {
          return;
        }
        const keys = item.fullPath
          ? item.fullPath.split("|")
          : item.path.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              key: key,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      };

      Object.values(this.gridFilter.add).forEach(initListTreeNodes);
      Object.values(this.gridFilter.remove).forEach(initListTreeNodes);
      Object.values(this.nodeFilter.add).forEach(initListTreeNodes);
      Object.values(this.nodeFilter.remove).forEach(initListTreeNodes);

      // init fns
      const updateChildren = (parentKey, mode = true) => {
        const parent = this.$refs.tree.list[parentKey];
        if (!parent) {
          return;
        }
        const children = Object.values(this.$refs.tree.list)
          .filter((n) => this.filterParentNode(parent, n) && n.count > 0)
          .map((n) => n.key);
        if (children.length === 0) {
          return;
        }
        children.forEach((key) => {
          updateCheck(key, mode, false);
          updateChildren(key, mode);
        });
      };

      const updateParent = (childKey) => {
        const child = this.$refs.tree.list[childKey];
        if (!child) {
          return;
        }
        const parents = Object.values(this.$refs.tree.list)
          .filter((n) => this.filterParentNode(n, child))
          .map((n) => n.key);
        if (parents.length === 0) {
          return;
        }
        parents.forEach((key) => {
          updateCheck(key, false, true);
          updateParent(key, false, true);
        });
      };

      const getNodeFromRecord = (record) => {
        const keys = record.fullPath.split("|").reverse();
        const parentKey = keys.find(
          (k) => listTreeNodes[k] && this.$refs.tree.list[k]
        );
        return parentKey;
      };

      // checked parent node and children
      Object.keys(this.nodeFilter.add).forEach((key) => {
        updateCheck(key, true, false);
        updateChildren(key);
      });

      // uncheck nodes
      Object.keys(this.nodeFilter.remove).forEach((key) => {
        updateCheck(key, false, false);
        updateChildren(key, false, false);
        updateParent(key);
      });

      // partialChecked nodes
      Object.values(this.gridFilter.remove).forEach((r) => {
        const nodeKey = getNodeFromRecord(r);
        updateCheck(nodeKey, false, true);
        updateParent(nodeKey);
      });

      this.$refs.tree.checkedNodes = listTreeNodes;
    },
    getKeyRecord(record) {
      return `${record.id}|${record.recordType}`;
    },
    getStatus(record) {
      let type = "default";
      return {
        text: this.$t(record.recordType),
        type,
      };
    },
    setCheckedRows() {
      const selected = [];
      // select nodes
      const nodeRemove = Object.values(this.nodeFilter.remove);
      const nodeAdd = Object.values(this.nodeFilter.add);
      const gridIdsRemove = Object.keys(this.gridFilter.remove);
      const gridIdsAdd = Object.keys(this.gridFilter.add);
      const filterGridRecord = (r, add, remove) => {
        return (
          add.some((n) => this.filterGridRecord(r, n)) &&
          !remove.some((n) => this.filterGridRecord(r, n))
        );
      };
      let filteredRecords = this.$refs.grid.records.filter(
        (r) =>
          (gridIdsAdd.includes(`${r.id}|${r.recordType}`) ||
            filterGridRecord(r, nodeAdd, nodeRemove)) &&
          !gridIdsRemove.includes(`${r.id}|${r.recordType}`)
      );
      selected.push(...filteredRecords);
      this.$refs.grid.selectedModel = selected;
    },
    nodeUnselect(value) {
      const type =
        value.type === "RealDistributor" ? "Distributor" : value.type;
      value.realType = type;
      if (
        this.selectedNode.key === "all" ||
        (this.selectedNode.realType === type &&
          (this.selectedNode.distributorId === null ||
            this.selectedNode.distributorId === value.distributorId))
      ) {
        this.$refs.grid.selectedModel = this.$refs.grid.selectedModel.filter(
          (e) => !this.filterGridRecord(e, value)
        );
        if (this.$refs.grid.selectedModel.length === 0) {
          this.$refs.grid.records
            .map((r) => `${r.id}|${r.recordType}`)
            .forEach((k) => {
              delete this.gridFilter.add[k];
              delete this.gridFilter.remove[k];
            });
        }
      }

      delete this.$refs.tree.checkedNodes[value.key];
    },
    filterGridRecord(record, node) {
      return record.fullPath.startsWith(node.path) || node.key === "all";
    },
    filterParentNode(parent, child) {
      return (
        (child.path?.startsWith(parent.path) || parent.key === "all") &&
        child.path !== parent.path
      );
    },
    handlerCheck({ node }) {
      if (this.nodeFilter.remove[node.key]) {
        delete this.nodeFilter.remove[node.key];
      } else {
        this.nodeFilter.add[node.key] = node;
        this.checkNodeFilterAdd(node);
      }
      this.setCheckedRows();
      this.updateFilterStateAndCheckedNodes();
    },
    checkNodeFilterAdd(node) {
      const getSelectParent = (child, mode) => {
        return Object.values(this.nodeFilter[mode]).find((n) =>
          this.filterParentNode(n, child)
        );
      };
      const getAntiMode = (mode) => {
        return mode === "add" ? "remove" : "add";
      };
      const deleteNodeFromFilter = (node, mode = "add") => {
        delete this.nodeFilter[mode][node.key];
      };
      const addNodeInFilter = (node, mode = "add") => {
        const antiMode = getAntiMode(mode);
        if (this.nodeFilter[antiMode][node.key]) {
          deleteNodeFromFilter(node, antiMode);
        }
        this.nodeFilter[mode][node.key] = node;
      };

      const parentAdd = getSelectParent(node, "add");
      const parentRemove = getSelectParent(node, "remove");
      if (!parentAdd || !parentRemove) {
        return;
      }
      deleteNodeFromFilter(parentAdd, "add");
      deleteNodeFromFilter(parentRemove, "remove");
      if (parentAdd.children) {
        const removesKeys = Object.values(this.nodeFilter.remove).map(
          (n) => n.key
        );
        const addingChildren = parentAdd.children.filter(
          (n) =>
            n.count > 0 &&
            n.key !== parentRemove.key &&
            !removesKeys.includes(n.key)
        );
        addingChildren.forEach((n) => {
          addNodeInFilter(n, "add");
        });
      }
    },
    handlerUncheck({ node }) {
      if (Object.keys(this.nodeFilter.add).includes(node.key)) {
        delete this.nodeFilter.add[node.key];
      } else {
        this.nodeFilter.remove[node.key] = node;
        Object.values(this.nodeFilter.add)
          .filter((n) => this.filterParentNode(node, n))
          .forEach((n) => delete this.nodeFilter.add[n.key]);
        Object.values(this.gridFilter.add)
          .filter((r) => this.filterGridRecord(r, node))
          .forEach((r) => delete this.gridFilter.add[this.getKeyRecord(r)]);
      }
      //this.nodeUnselect(node);
      this.setCheckedRows();
      this.updateFilterStateAndCheckedNodes();
    },
    handlerNodeSelect(value) {
      if (this.selectedNode && this.selectedNode.key === value.key) return;

      if (value.key === "all") {
        this.selectedNode = value;
        // Reset positon filter
        delete this.$refs["filter-panel"].filter.position;
        delete this.$refs["grid"].filter.position;
        this.positionFilterKey++;
        this.$refs.grid.sumbitMergeFilter({
          orgId: { and: [null] },
          type: { and: [] },
          distributorId: { and: [] },
        });
        return;
      }
      let filter = {};
      const orgId =
        value.parentId && value.type !== "RealDistributor" ? value.id : null;
      filter.orgId = { and: [orgId] };
      filter.type = { and: [value.realType] };
      if (value.realType === "Distributor" && value.parentId)
        filter.distributorId = { and: [value.distributorId] };
      else filter.distributorId = { and: [] };
      if (
        this.selectedNode.realType !== value.realType &&
        this.selectedNode.realType !== undefined
      ) {
        // Reset positon filter
        delete this.$refs["filter-panel"].filter.position;
        delete this.$refs["grid"].filter.position;
        this.positionFilterKey++;
      }
      this.selectedNode = value;
      this.$refs.grid.sumbitMergeFilter(filter);
    },
    handlerDeleteItem(item) {
      const mode = this.addItems.map((i) => i.key).includes(item.key)
        ? "add"
        : "remove";
      const nameFilter = Object.keys(this.nodeFilter[mode]).includes(item.key)
        ? "nodeFilter"
        : "gridFilter";

      if (nameFilter === "nodeFilter") {
        const node = this.nodeFilter[mode][item.key];
        this.$refs.tree.handlerCheckbox(node);
      } else {
        delete this.gridFilter[mode][item.key];
        this.updateFilterStateAndCheckedNodes();
      }
    },
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    getPositionService() {
      if (Object.keys(this.selectedNode).length === 0)
        return this.marsOrgService;

      return this.selectedNode.realType === "Mars"
        ? this.marsOrgService
        : this.distributorOrgService;
    },
    addItems() {
      const nodes = Object.values(this.nodeFilter.add).map((i) => {
        return {
          id: i.id,
          type: this.$t("node"),
          recipient: i.name,
          key: i.key,
        };
      });
      const emails = Object.values(this.gridFilter.add).map((i) => {
        return {
          id: i.id,
          type: this.$t("email"),
          recipient: i.email,
          key: `${i.id}|${i.recordType}`,
        };
      });
      return [...nodes, ...emails];
    },
    removeItems() {
      const nodes = Object.values(this.nodeFilter.remove).map((i) => {
        return {
          id: i.id,
          type: this.$t("node"),
          recipient: i.name,
          key: i.key,
        };
      });
      const emails = Object.values(this.gridFilter.remove).map((i) => {
        return {
          id: i.id,
          type: this.$t("email"),
          recipient: i.email,
          key: `${i.id}|${i.recordType}`,
        };
      });
      return [...nodes, ...emails];
    },
    customServices() {
      return [
        {
          type: "chips",
          model: "firstName",
          label: this.$t("firstName"),
          selected: {},
        },
        {
          type: "chips",
          model: "email",
          label:  this.$t("email"),
          selected: {},
        },
      ];
    }
  },
};
</script>
