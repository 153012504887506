<template>
  <DashboardModalLayout
    ref="dashboardLayoutSettingsLayout"
    :class="['dashboard-layout-settings', `dashboard-layout-settings--${selectedLeftTreeNode?.element}`]"
    :panels="panels"
    isHideCenter
    :fullWidth="layoutView === 'grid'"
    scrollableRight
    @clickAction="handleAddDataset"
  >
    <template #center>
      <div class="dashboard-layout-settings-grid-element__wrapper">
        <DashboardLayoutSettingsGridElement
          ref="grid"
          :active-container="selectedLeftTreeNode?.systemName"
          :hover-key="hoverLayoutViewKey"
          :filter="model.filterPanel"
          :validationFilter="v$.selectedLeftTreeNode"
          :element="model.rootContainer"
          :isPaste="tempCopingObj !== null"
          :validation="v$.model"
          :dotOptions="listDotOptions"
          :invalidKeys="invalidKeys"
          @updateActiveContainer="setActiveContainerGridView"
          @updateActiveHoverContainer="setHoverContainerGridView"
          @clearHoverKey="setHoverContainerGridView(null)"
          @addElement="gridElementWrapActions($event, 'add')"
          @editElement="gridElementWrapActions($event, 'edit')"
          @copyElement="gridElementWrapActions($event, 'copy')"
          @pasteElement="gridElementWrapActions($event, 'paste')"
          @deleteElement="gridElementWrapActions($event, 'delete')"
          @moveUpElement="gridElementWrapActions($event, 'moveUp')"
          @moveDownElement="gridElementWrapActions($event, 'moveDown')"
          @moveRightElement="gridElementWrapActions($event, 'moveRight')"
          @moveLeftElement="gridElementWrapActions($event, 'moveLeft')"
        />
      </div>
    </template>
    <template #left>
      <ProSpaceTree
        ref="leftTree"
        propKey="systemName"
        propLabel="blockName"
        getNodeMethod="getTree"
        noMountedSelectNode
        :service="leftTreeService"
        :needAdd="handleLeftTreeNeedAdd"
        :needDelete="handleLeftTreeNeedDelete"
        :localization="$t"
        @select="handleNodeSelect"
        @loaded="handleTreeLoaded"
      >
        <template #action="{ node }">
          <ProSpaceHLayout :gap="6" justify-content="flex-end" class="dashboard-layout-settings__tree-actions">
            <div v-if="node.needWarning" class="tree-item-action-container">
              <Fa
                :icon="icons.faExclamationTriangle"
                :style="{ color: '#EE6D62' }"
                size="10px"
              />
            </div>
            <div v-if="node.needAdd" class="tree-item-action-container">
              <Fa
                :icon="icons.faPlus"
                :style="{ color: 'var(--prospace-ui-main)' }"
                size="10px"
                @click="handlerShowModalContainer(node, false)"
              />
            </div>
            <div
              v-if="node.systemName !== 'filterPanel' && node.systemName !== 'mainContainer' && node.systemName !== 'key_0:1'"
              class="tree-item-action-container"
            >
              <ProSpaceDots
                v-if="node"
                ref="dots"
                class="prospace-icon-button--primary"
                :options="dotsOptions(node)"
                @action="(action) => handleHeaderDots(action, node)"
              />
            </div>
            <div
              v-if="node.systemName === 'mainContainer' && node.systemName === 'key_0:1' && tempCopingObj !== null"
              class="tree-item-action-container"
            >
              <ProSpaceDots
                v-if="node"
                ref="dots"
                class="prospace-icon-button--primary"
                :options="dotsOptions(node)"
                @action="(action) => handleHeaderDots(action, node)"
              />
            </div>
          </ProSpaceHLayout>
        </template>
      </ProSpaceTree>
    </template>
    <template #right >
      <component
        v-if="selectedLeftTreeNode"
        :is="componentName"
        :key="`${componentName}`"
        v-model:selectedNode="selectedLeftTreeNode"
        v-model:modelTabChart="modelTabChart"
        :validation="v$.selectedLeftTreeNode"
        v-bind="getPropsList"
        @combinedEvents="(typeEvent, value, ...args) => routeLayoutSettingEvents(typeEvent, value, ...args)"
      />
    </template>
  </DashboardModalLayout>
  <DashboardModalColorSelection
    v-if="showModal"
    v-model="showModal"
    fieldType="field"
    :color="editingColor"
    @update:color="updateColor"
  />
  <DashboardModalNewContainer
    v-model="showModalContainer"
    @create:container="setNewContainer"
  />
  <ProSpaceFlashMessage
    v-if="deleteAlertModal"
    v-model="deleteAlertModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingElement')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('cancelInf')"
    @apply="removeNode"
  >
    <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('alertTextDeletingElement', { name:  deletableNode.name })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
  <ProSpaceFlashMessage
    v-if="changingComponentTypeAlertModal"
    v-model="changingComponentTypeAlertModal"
    type="default"
    icon="flash-warning"
    :title="$t('changingElementTypeAlertTitle')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('cancelInf')"
    @cancel="cancelChangingComponentType"
    @apply="applyChangingComponentType"
  >
    <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
      >
        {{ $t('changingElementTypeAlertTxt') }}
      </div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
  <ProSpaceModal
    v-if="showPickListColor"
    v-model="showPickListColor"
    modalClass="modal-pick-list"
    :style="getModalStyle"
    :width="900"
    :height="536"
    :smallPadding="true"
    :iconWidth="20"
    :iconHeight="20"
    icon="list-check"
    :title="$t('colorSelectionPickList')"
    :applyTxt="$t('applyTxt')"
    :cancelTxt="$t('cancelInf')"
    @apply="(val) => setDatasetColor(val, 0)"
    @cancel="handlePickListModal(false)"
    @resize="(e) => setModalSize(e, 'rows')"
  >
    <ProSpacePickList
      v-if="showPickListColor"
      v-model="selectedPieColors"
      prop-label="value"
      prop-key="label"
      :options="pickListColorOptions"
      mode="color"
    />
  </ProSpaceModal>
  <ProSpaceModal
    v-if="showGridLayoutSettings"
    v-model="showGridLayoutSettings"
    :width="520"
    :height="590"
    :iconWidth="20"
    :iconHeight="20"
    icon="gears"
    :title="$t('settingsTxt')"
    :applyTxt="$t('applyTxt')"
    :cancelTxt="$t('cancelInf')"
    @apply="handleApplyModalGridLayoutSetting"
    @cancel="cancelGridLayoutSettings"
  >
    <div
      :class="['modal-grid-layout-settings', `modal-grid-layout-settings--${selectedLeftTreeNode?.element}`]"
    >
      <component
        :is="componentName"
        :key="`${componentName}-1`"
        v-model:selectedNode="selectedLeftTreeNode"
        v-model:modelTabChart="modelTabChart"
        :validation="v$.selectedLeftTreeNode"
        v-bind="getPropsList"
        @combinedEvents="(typeEvent, value, ...args) => routeLayoutSettingEvents(typeEvent, value, ...args)"
      />
    </div>
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceGeoLayout,
  ProSpaceIconButton,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceIcon,
  ProSpaceDropdown,
  ProSpacePanelBlock,
  ProSpaceTag,
  ProSpaceTree,
  ProSpacePanelBlockItem,
  ProSpaceInputSwitch,
  ProSpaceLeftPanel,
  ProSpaceAction,
  ProSpaceInputNumber,
  ProSpaceInputText,
  ProSpaceInputChips,
  ProSpaceTabs,
  ProSpaceCheckbox,
  ProSpaceFlashMessage,
  ProSpaceModal,
  ProSpaceDots,
  Hint,
} from "@prospace/prospace-components-library";
import DashboardModalLayout from "./DashboardModalLayout.vue";
import DashboardModalColorSelection from "./DashboardModalColorSelection.vue";
import DashboardModalNewContainer from "./DashboardModalNewContainer.vue";
import DashboardColorPicker from "./DashboardColorPicker.vue";
import DashboardLayoutElementSettings from "./DashboardLayoutElementSettings.vue";
import { faTrashAlt, faPlus, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, maxLength, minValue } from "@vuelidate/validators";
import PivotTableSettings from "./PivotTableSettings.vue";
import DashboardLayoutSettingsTable from "./DashboardLayoutSettingsTable.vue";
import ProSpacePickList from "./ProSpacePickList.vue";
import { DashboardColorService } from "@dashboard/services/DashboardColorService.js";
import DashboardLayoutSettingsGridElement from "./DashboardLayoutSettingsGridElement.functional.vue";
import DashboardLayoutSettingsContainer from "./DashboardLayoutSettingsContainer.vue";
import DashboardLayoutSettingsGridChart from "./DashboardLayoutSettingsGridChart.vue";
import DashboardLayoutSettingsGridText from "./DashboardLayoutSettingsGridText.vue";
import DashboardLayoutSettingsGridPanel from "./DashboardLayoutSettingsGridPanel.vue";
import DashboardLayoutSettingsGridIterPanel from "./DashboardLayoutSettingsGridIterPanel.vue";
import DashboardLayoutSettingsGridFilterPanel
  from "./DashboardLayoutSettingsGridFilterPanel.vue";
import DashboardLayoutSettingsGridRootContainer
  from "./DashboardLayoutSettingsGridRootContainer.vue";
import useSelectElementType from "./composes/useSelectElementType";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
export default {
  name: "DashboardLayoutSettings",
  components: {
    ProSpaceDots,
    DashboardLayoutSettingsGridRootContainer,
    DashboardLayoutSettingsGridFilterPanel,
    DashboardLayoutSettingsGridIterPanel,
    DashboardLayoutSettingsGridPanel,
    DashboardLayoutSettingsGridText,
    DashboardLayoutSettingsContainer,
    DashboardLayoutSettingsGridElement,
    DashboardLayoutSettingsGridChart,
    ProSpacePickList,
    ProSpaceGeoLayout,
    ProSpaceTree,
    ProSpaceTag,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceIconButton,
    ProSpaceIcon,
    ProSpacePanelBlockItem,
    ProSpaceDropdown,
    ProSpaceInputSwitch,
    ProSpaceLeftPanel,
    ProSpacePanelBlock,
    ProSpaceAction,
    DashboardModalLayout,
    DashboardModalColorSelection,
    DashboardModalNewContainer,
    ProSpaceFlashMessage,
    ProSpaceInputNumber,
    ProSpaceInputText,
    Fa: FontAwesomeIcon,
    ProSpaceInputChips,
    ProSpaceTabs,
    ProSpaceCheckbox,
    DashboardColorPicker,
    DashboardLayoutElementSettings,
    PivotTableSettings,
    DashboardLayoutSettingsTable,
    ProSpaceModal,
  },
  props: {
    layoutSettings: {
      type: [Array, Object],
      required: true,
    },
    validateWhenSelect: {
      type: [Array, Boolean],
      default: false
    },
    selectedSourcesService: {
      type: Object,
      required: true,
    },
    createDefaultLayoutColorSettings: {
      type: Function,
      required: true,
    },
    createLayoutSettingsRootContainerChild: {
      type: Function,
      required: true,
    },
    createGraphLayout: {
      type: Function,
      required: true,
    },
    createPivotValueField: {
      type: Function,
      required: true,
    },
    createTableRowField: {
      type: Function,
      required: true,
    },
    layoutView: {
      type: String,
      default: 'tree'
    }
  },
  emits: ["update:model"],
  directives: {
    hint: Hint
  },
  provide() {
    return {
      changingComponentType: computed(() => {
        return {
          oldType: this.oldChangingElementType,
          newType: this.tempChangingComponentType,
          cancel: this.cancelingComponentType
        }
      })
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      editingColor: null,
      selectedLeftTreeNode: null,
      leftTreeService: {
        getTree: this.getLeftTreeData,
      },
      dashboardColorService: new DashboardColorService(),
      filterPanelSourceDropdownOptions: [],
      showModal: false,
      showModalContainer: false,
      activeContainer: null,
      deleteAlertModal: false,
      deletableNode: null,
      modelTabChart: {
        name: this.$t("dataset") + " 1",
        isActive: true,
        systemName: "dataset1",
        index: 1
      },
      model: {},
      filterPanelValuesOptions: [],
      childPanelValuesOptions: [],
      childGraphParametersOptions: [],
      childGraphValuesOptions: [],
      firstLoad: true,
      totalLevels: 0,
      showPickListColor: false,
      pickListColorOptions: null,
      selectedPieColors: [],
      modalSize: { width: '900px', height: '536px' },
      chartTypes: ['line', 'bar', 'pie', 'doughnut', 'polarArea', 'radar'],
      multipleDataSetsChartTypes: ['line', 'bar', 'radar'],
      hoverLayoutViewKey: null,
      fromGridMode: false,
      tempCopingObj: null,
      showGridLayoutSettings: false,
      graphType: null,
      initializedBlockNames: false,
      tempChangingComponentType: null,
      changingComponentTypeAlertModal: false,
      canChangeComponentType: false,
      cancelingComponentType: false,
      oldChangingElementType: null,
      invalidKeys: [],
      existNames: [],
      startScrollPosition: 0,
      oldSelectedModel: null,
    };
  },
  validations() {
    const elementValidation = (elementPropName) => {
      return {
        type: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required)
        },
        value: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"),
            () => this.elementValueValidation(elementPropName)),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", { maxLength: 255 }),
            maxLength(255)
          ),
        }
      }
    }

    const containerValidation = (container) => ({
      value: {
        minValue: helpers.withMessage(
          this.$t("minValueErrorMessage") + 1,
          minValue(1))
      },
    })

    const graphValidation = (graph) => ({
      xAxis: {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
      },
      datasets: graph.datasets.map(dataset => ({
        valueField: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
        }
      }))
    })

    const textPanelValidation = (textPanel) => ({
      header: elementValidation("header"),
      label: elementValidation("label"),
      value: elementValidation("value")
    })

    const valuePanelValidation = (valuePanel) => ({
      label: elementValidation("label"),
      value: elementValidation("value")
    })

    const iterPanelValidation = () => ({
      value: {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
      },
      label: {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
      },
    })

    const filterPanelSourceValidation = (panel) => helpers.withParams(
      { type: 'filterPanelSourceValidation', value: panel },
      (value) => {
        if (panel.header.type === 'field' || panel.label.type === 'field' || panel.value.type === 'field')
          return !!value;
        return true;
      }
    )

    const filterPanelValidation = (filterPanel) => ({
      source: {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), filterPanelSourceValidation(filterPanel))
      },
      header: elementValidation("header"),
      label: elementValidation("label"),
      value: elementValidation("value")
    })

    const pivotValidation = (pivot) => ({
      columnField: {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
      },
      rows: {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"),
          () => pivot.rows?.length > 0 && pivot.rows.every(row => row))
      },
      values: {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"),
          () => pivot.values?.length > 0 && pivot.values.every(val => val?.field))
      },
      sort: {
        field: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required)
        },
        direction: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
          checkValues: () => pivot.sort.direction === 'asc' || pivot.sort.direction === 'desc'
        }
      }
    })

    const tableValidation = (table) => ({
      rows: {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"),
          () => table.rows?.length > 0 && table.rows.every(row => row))
      },
      sort: {
        field: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required)
        },
        direction: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
          checkValues: () => table.sort.direction === 'asc' || table.sort.direction === 'desc'
        }
      }
    })

    const selectedLeftTreeNodeValidation = () => {
      if (this.selectedLeftTreeNode.systemName === 'filterPanel')
        return filterPanelValidation(this.selectedLeftTreeNode)
      if (this.selectedLeftTreeNode.element === 'container')
        return containerValidation(this.selectedLeftTreeNode)
      if (this.selectedLeftTreeNode.element === 'graph')
        return graphValidation(this.selectedLeftTreeNode)
      if (this.selectedLeftTreeNode.element === 'text')
        return textPanelValidation(this.selectedLeftTreeNode)
      if (this.selectedLeftTreeNode.element === 'panel')
        return valuePanelValidation(this.selectedLeftTreeNode)
      if (this.selectedLeftTreeNode.element === 'iterpanel')
        return iterPanelValidation()
      if (this.selectedLeftTreeNode.element === 'pivot')
        return pivotValidation(this.selectedLeftTreeNode)
      if (this.selectedLeftTreeNode.element === 'table') {
        return tableValidation(this.selectedLeftTreeNode);
      }
      // console.error("Couldn't build validation for element:", this.selectedLeftTreeNode)
    }

    const getValidationForElement = (element) => {
      if (element.systemName === 'filterPanel') return filterPanelValidation(element);
      if (element.element === 'container') return containerValidation(element);
      if (element.element === 'graph') return graphValidation(element);
      if (element.element === 'text') return textPanelValidation(element);
      if (element.element === 'panel') return valuePanelValidation(element);
      if (element.element === 'iterpanel') return iterPanelValidation();
      if (element.element === 'pivot') return pivotValidation(element);
      if (element.element === 'table') return tableValidation(element);
      // console.error("Couldn't build validation for element:", element);
      return {};
    };


    const buildRecursiveValidation = (element) => {
      const validation = {};

      validation[element.systemName] = getValidationForElement(element);

      if (element.children && element.children.length > 0) {
        element.children.forEach(child => {
          const childValidation = buildRecursiveValidation(child);
          Object.assign(validation, childValidation);
        });
      }
      return validation;
    };

    const result = {};

    if (this.selectedLeftTreeNode &&
      this.selectedLeftTreeNode.systemName !== 'mainContainer' &&
      this.selectedLeftTreeNode.systemName !== 'key_0:1')
      result.selectedLeftTreeNode = selectedLeftTreeNodeValidation();
      result.model = buildRecursiveValidation(this.model.rootContainer);

    return result
  },
  watch: {
    "selectedLeftTreeNode.type": {
      handler: function (val, oldVal) {
        if (val && val !== oldVal) {
          const root = this.model.rootContainer;
          const path = this.findPathByKey(
            root,
            this.selectedLeftTreeNode.systemName
          );
          const obj = path ? this.getObjectByPath(root, path) : null;
          if (obj && obj.element === "container") {
            obj.type = val;
            obj.icon =
              val === "vertical" ? "node-container" : "node-hcontainer";
          } else if (obj) {
            obj.icon = this.getNodeIcon(obj.element);
          }
        }
      },
    },
    "selectedLeftTreeNode.element": {
      handler: function(val) {
        if ((val === 'pivot' || val === 'table') && !this.selectedLeftTreeNode.tagRows) {
          this.selectedLeftTreeNode.tagRows = {};
        }
        if (val === 'graph') {
          this.graphType = this.selectedLeftTreeNode.datasets[0].graphType;
          if (this.checkMultipleDataSetsChartTypes(this.graphType)) {
            const backgrounds = this.selectedLeftTreeNode.datasets.map(d => d.color.background);
            this.selectedLeftTreeNode.datasets.forEach((d, i) => {
              d.color.background = backgrounds[i];
            });
          } else {
            this.selectedPieColors = this.pickListColorOptions;
            this.selectedLeftTreeNode.datasets[0].color.background = this.selectedPieColors.map(c => c.value);
          }
        }
      }
    },
    layoutSettings: {
      handler: function (val) {
        const { filterPanel, rootContainer } = val;
        if (filterPanel) {
          filterPanel.systemName = 'filterPanel';
          filterPanel.blockName = this.$t('filterPanel');
        }
        if (rootContainer) {
          rootContainer.systemName = 'key_0:1';
          rootContainer.key = 'key_0:1';
          rootContainer.blockName = this.$t('mainContainer');
        }
        this.model = val;
        if (!this.initializedBlockNames) {
          this.setDefaultBlockName(this.model.rootContainer);
          this.initializedBlockNames = true;
        }
      },
      immediate: true,
      deep: true,
    },
    validateWhenSelect: {
      handler: function (val) {
        if (val && this.v$.selectedLeftTreeNode)
          this.v$.selectedLeftTreeNode.$touch();
      },
    },
    layoutView(val, oldVal) {
      if (val && val === 'tree' && val !== oldVal && oldVal === 'grid') {
        if (this.selectedLeftTreeNode) {
          this.firstLoad = true;
          this.$nextTick(() => {
            this.handleTreeLoaded();
          });
        }
      } else if (val && val === 'grid' && val !== oldVal && oldVal === 'tree') {
        // console.log(this.v$.selectedLeftTreeNode);
        // this.$nextTick(() => {
        //   this.selectedLeftTreeNode = this.model.rootContainer;
        // });
      }
    },
    showGridLayoutSettings(val) {
      if (val && val === true && this.selectedLeftTreeNode) {
        this.oldSelectedModel = _.cloneDeep(this.selectedLeftTreeNode);
        this.$nextTick(() => {
          if (this.v$ && this.v$.selectedLeftTreeNode && this.validateWhenSelect) {
            this.v$.selectedLeftTreeNode.$touch();
          } else {
            this.v$.$reset();
          }
        });
      }
    }
  },
  async created() {
    this.filterPanelSourceDropdownOptions = (
      await this.selectedSourcesService.getSelectedSources()
    ).data;
    this.childPanelValuesOptions = (
      await this.selectedSourcesService.getMainSource()
    ).data[0].children;
    this.childGraphParametersOptions = (
      await this.selectedSourcesService.getMainSource()
    ).data[0].children.filter(x => x.isParameter);
    this.childGraphValuesOptions = (
      await this.selectedSourcesService.getMainSource()
    ).data[0].children.filter(x => x.isValue);
  },
  mounted() {
    this.$refs["leftTree"] ? this.$refs["leftTree"].handlerDoubleClick = function () {
      return false;
    } : null;
    this.totalLevels = this.calcTotalLevels(this.model.rootContainer);
    this.getColorOptions();
    // this.$nextTick(() => {
    //   this.setWidthTree(this.totalLevels);
    // });
  },
  methods: {
    async setActiveContainerGridView(key) {
      const service = await this.getLeftTreeData();
      if (key === 'filterPanel') {
        this.selectedLeftTreeNode = service.data[0];
        return;
      }
      if (key === 'mainContainer') {
        this.selectedLeftTreeNode = service.data[1];
        this.selectedLeftTreeNode.systemName = 'key_0:1';
        this.selectedLeftTreeNode.key = 'key_0:1';
        return;
      }
      const root = service.data[1];
      const path = this.findPathByKey(root, key, "");
      const obj = path ? this.getObjectByPath(root, path) : null;
      this.selectedLeftTreeNode = obj ?? root;
      this.setHoverContainerGridView(null);
    },
    setHoverContainerGridView(key) {
      this.hoverLayoutViewKey = key;
    },
    async getColorOptions() {
      let res = null;
      try {
        res = await this.dashboardColorService.getGraphColors();
        this.pickListColorOptions = res.data.records;
      } catch(e) {}
    },
    async getLeftTreeData() {
      if (this.model) {
        const { filterPanel, rootContainer } = this.model;
        const existingKeys = new Set();
        this.initializeUniqueKeys(rootContainer, existingKeys);
        return {
          data: [
            {
              ...filterPanel,
              systemName: "filterPanel",
              key: "filterPanel",
              blockName: this.$t("filterPanel"),
              element: this.$t("filterPanel"),
              name: this.$t("filterPanel"),
              icon: "folder",
            },
            {
              ...rootContainer,
              index: 0,
              systemName: "key_0:1",
              key: "key_0:1",
              blockName: this.$t("mainContainer"),
              element: this.$t("mainContainer"),
              name: this.$t("mainContainer"),
              icon: "folder",
              needAdd: true,
              level: 0,
              root: "rootContainer",
            },
          ],
        };
      } else {
        return {
          data: [
            {
              systemName: "filterPanel",
              name: this.$t("filterPanel"),
              icon: "folder",
              needWarning: false,
            },
            {
              index: 0,
              systemName: "mainContainer",
              name: this.$t("mainContainer"),
              icon: "folder",
              needAdd: true,
              needWarning: false,
              level: 0,
              children: [],
              root: "rootContainer",
            },
          ],
        };
      }
    },
    handleLeftTreeNeedAdd: (node) => node.needAdd,
    handleLeftTreeNeedDelete: (node) => node.needDelete,
    setDefaultModelTabChart() {
      this.modelTabChart = {
        name: this.$t("dataset") + " 1",
        isActive: true,
        systemName: "dataset1",
        index: 1
      };
    },
    handleNodeSelect(node) {
      this.$nextTick(async () => {
        this.selectedLeftTreeNode = node;

        const selectedSources = (
          await this.selectedSourcesService.getSelectedSources()
        ).data;
        const selectedFilterPanelSourceOption = this.model.filterPanel.source
          ? selectedSources.filter(
              (o) => o.systemName === this.model.filterPanel.source
            )[0]
          : null;

        if (node.systemName === "filterPanel") {
          if (selectedFilterPanelSourceOption) {
            this.handleFilterPanelSourceDropdownChange(selectedFilterPanelSourceOption, false);
          }
        }
        if (this.childPanelValuesOptions) {
          if (node.element === "graph") {
            this.setDefaultModelTabChart();
          } else if (node.element === "iterpanel") {
          }
        }
        this.$emit("update:model", this.model);
        if (this.v$ && this.v$.selectedLeftTreeNode && this.validateWhenSelect)
          this.v$.selectedLeftTreeNode.$touch();
        else
          this.v$.$reset();
      })
    },
    handleFilterPanelSourceDropdownChange(item, noClearElements = true) {
      if (!item) return;
      this.filterPanelValuesOptions = item.children;
      this.model.filterPanel.source = item.systemName;
      if(noClearElements) {
        if (this.model.filterPanel.header.type === "field")
          this.model.filterPanel.header.value = null
        if (this.model.filterPanel.label.type === "field")
          this.model.filterPanel.label.value = null
        if (this.model.filterPanel.value.type === "field")
          this.model.filterPanel.value.value = null
      }
    },
    handleAddColorSelection() {
      this.editingColor = this.selectedLeftTreeNode["color"];
      this.showModal = true;
    },
    updateElementProp(district, element, prop, newVal) {
      if (district) {
        this.model[district][element][prop] = newVal
      }
      else {
        this.selectedLeftTreeNode[element][prop] = newVal;
      }
    },
    handlerShowModalContainer(e, fromGridMode = false) {
      this.fromGridMode = fromGridMode;
      this.showModalContainer = true;
      this.activeContainer = e;
    },
    calcTotalLevels(rootObject) {
      if (Array.isArray(rootObject.children) && rootObject.children.length > 0) {
        for (let i = 0; i < rootObject.children.length; i++) {
          const children = this.calcTotalLevels(
            rootObject.children[i],
          );
          if (children) {
            return children;
          }
        }
      } else if (rootObject.level) {
        return rootObject.level;
      }
    },
    findPathByKey(rootObject, targetKey, path = "") {
      if (rootObject.systemName === targetKey) {
        return path;
      }

      if (Array.isArray(rootObject.children)) {
        for (let i = 0; i < rootObject.children.length; i++) {
          const childPath = this.findPathByKey(
            rootObject.children[i],
            targetKey,
            `${path}.children[${i}]`
          );
          if (childPath) {
            return childPath;
          }
        }
      }

      return null;
    },
    generateUniqueKey(existingKeys, level, index) {
      let uniqueKey;

      do {
        uniqueKey = `key_${level}:${index}`;
        if (!existingKeys.has(uniqueKey)) {
          return uniqueKey;
        }
        index++;
      } while (true);
    },
    getObjectByPath(root, path) {
      if (path.startsWith(".")) {
        path = path.slice(1);
      }

      const keys = path.split(/[\.\[\]']+/).filter(Boolean);

      return keys.reduce((acc, key) => {
        return acc[key];
      }, root);
    },
    getNextUniqueBlockName(type, node) {
      const count = this.calcBlockNamesTypes(type, node);
      const getTempName = (t, c) => `${this.$t(t)} ${c + 1}`;
      const validName = (t, c) => {
        if (!t || (!c && c !== 0)) {
          console.error('Can`t create a valid blockName');
          return;
        }
        const tempName = getTempName(t, c);
        if (!this.existNames || this.existNames.length === 0 || !this.existNames.includes(tempName)) {
          return tempName;
        } else {
          let trueCount = c;
          trueCount += 1;
          return validName(t, trueCount);
        }
      }
      return validName(type, count);
    },

    calcBlockNamesTypes(type, node) {
      let blockTypesCounter = 0;

      if (node.element && node.element === type) {
        blockTypesCounter += 1;
        if (node.blockName && !this.existNames.includes(node.blockName)) this.existNames.push(node.blockName);
      }

      if (node.children && Array.isArray(node.children)) {
        node.children.forEach(child => {
          blockTypesCounter += this.calcBlockNamesTypes(type, child);
        });
      }

      return blockTypesCounter;
    },

    collectExistingKeys(node) {
      let existingKeys = new Set();

      if ('systemName' in node) {
        existingKeys.add(node.systemName);
      }

      if (node.children && Array.isArray(node.children)) {
        node.children.forEach(child => {
          this.collectExistingKeys(child);
        });
      }

      return existingKeys;
    },

    addNewObject(parentNode, root, existingKeys, obj) {
      const path =
        parentNode && typeof parentNode === "string"
          ? this.getObjectByPath(root, parentNode)
          : root;
      const index = path.children.length + 1;
      const key = this.generateUniqueKey(existingKeys, path.level + 1, index);
      const blockName = this.getNextUniqueBlockName(obj.element, root);
      const newObject = {
        ...obj,
        blockName: blockName,
        systemName: key,
        key: key,
        children: [],
        level: path.level + 1,
        parent: "",
      };
      const parent = { ...this.activeContainer };
      delete parent["parent"];
      delete parent["children"];
      newObject.parent = parent;
      path.children.push(newObject);
      existingKeys.add(newObject.systemName);
      return newObject;
    },
    initializeUniqueKeys(node, existingKeys) {
      let index = 1;
      if (!node.name && node.element) {
        node.name = node.element;
      }
      if (!node.level) {
        node.level = 0;
      }
      node.systemName = this.generateUniqueKey(existingKeys, node.level, index);
      node.key = node.systemName;
      existingKeys.add(node.systemName);

      if (Array.isArray(node.children)) {
        for (const child of node.children) {
          child.level = node.level + 1;
          index++;
          child.systemName = this.generateUniqueKey(
            existingKeys,
            child.level,
            index
          );
          child.key = child.systemName;
          child.parent.key = node.systemName;
          child.parent.systemName = node.systemName;
          existingKeys.add(child.systemName);
          this.initializeUniqueKeys(child, existingKeys);
        }
      }
    },
    getNodeIcon(type) {
      const types = {
        container: "container",
        hcontainer: "hcontainer",
        graph: "graph",
        text: "text",
        panel: "iterpanel",
        iterpanel: "iterpanel",
        pivot: "table",
        table: "table",
      };
      return `node-${types[type]}`;
    },
    copyContainer(target, fromGridMode = false) {
      const { systemName } = target;
      const rootObject = this.model.rootContainer;
      const childPath = this.findPathByKey(rootObject, systemName, "");
      const path = this.getObjectByPath(rootObject, childPath);
      const index = path.children.length + 1;
      const existingKeys = new Set();
      const expandedKeys = !fromGridMode ? this.$refs.leftTree.expandedKeys : null;

      this.initializeUniqueKeys(rootObject, existingKeys);
      const key = this.generateUniqueKey(existingKeys, path.level + 1, index);
      const parent = { ...path };

      delete parent["parent"];
      delete parent["children"];

      const clonedObject = {
        ...this.tempCopingObj,
        systemName: key,
        key: key,
        parent: parent,
        level: path.level + 1,
      };

      path.children.push(clonedObject);
      if(!fromGridMode) {
        this.expandNodes(expandedKeys);
      }
      this.tempCopingObj = null;
      this.startScrollPosition = this.$refs.dashboardLayoutSettingsLayout.$el.children[0].scrollTop;
      this.setActiveContainerGridView(key);
      this.$nextTick(() => {
        this.selectedLeftTreeNode = clonedObject;
        this.$refs.dashboardLayoutSettingsLayout.$el.children[0].scrollTop = this.startScrollPosition;
      })
    },

    setNewContainer(type, setActive = true) {
      const { label, value } = type;
      const { level, systemName } = this.activeContainer;
      const existingKeys = new Set();
      const root = this.model.rootContainer;
      const rootObject = root;
      const rootPath = "";
      const childPath = this.findPathByKey(rootObject, systemName, rootPath);
      const icon = this.getNodeIcon(value);
      const model = this.createLayoutSettingsRootContainerChild(value);

      const newElement = {
        ...model,
        systemName: "",
        key: "",
        name: label,
        icon: icon || "folder",
        needAdd: value === "container",
        needDelete: true,
        needWarning: false,
        level: level,
        children: [],
      };
      this.initializeUniqueKeys(rootObject, existingKeys);
      const newObject = this.addNewObject(childPath, rootObject, existingKeys, newElement);

      this.showModalContainer = false;
      if (!this.fromGridMode) {
        this.$refs.leftTree.expandNode(this.activeContainer, false);
        this.$refs.leftTree.selectNode(newObject);
      }
      if (setActive) {
        this.$nextTick(() => {
          this.setActiveContainerGridView(newObject.systemName);
        });
      }
      return newObject;
      // this.setWidthTree(level);
    },
    setWidthTree(level) {
      const tree = this.$refs.leftTree;
      if (!tree) return;
      const treeWrapper = tree.$el.querySelector('.p-tree-wrapper');
      const currentWidthTree = treeWrapper.offsetWidth || 377;
      const stepLevelWidth = 20;
      const threshold = 5;
      const widthTree = level && level >= threshold
        ? currentWidthTree + (stepLevelWidth * (level % threshold))
        : currentWidthTree;
      treeWrapper.style.width = `${widthTree}px`;
    },
    removeNode(childNode, lastStep = true) {
      const node = childNode || this.model.rootContainer;
      const { key } = this.deletableNode;
      if (Array.isArray(node.children)) {
        const index = node.children.findIndex((child) => child.key === key);
        if (index != -1)
          node.children.splice(index, 1);
        else
          node.children.forEach((child) => {
            if (child.element === "container")
              this.removeNode(child, false)
          });
      }

      if (lastStep) {
        this.deleteAlertModal = false;
        !this.fromGridMode ? this.$refs.leftTree.getData() : null;
      }
    },
    changeContainerChildrenValues(val) {
      let children = this.selectedLeftTreeNode?.children
      if(this.selectedLeftTreeNode?.systemName === 'mainContainer' || this.selectedLeftTreeNode?.systemName === 'key_0:1') {
        this.selectedLeftTreeNode.type = val.systemName;
      }
      if (!children || children.length == 0)
        return;

      children.forEach(elem => {
        elem.parent.type = val.systemName
      });
    },
    compareModels(actualModel, oldModel) {
      for (const key in actualModel) {
        if (actualModel.hasOwnProperty(key)) {
          const actualValueString = JSON.stringify(actualModel[key]);
          const oldValueString = JSON.stringify(oldModel[key]);

          if (actualValueString !== oldValueString) {
            return false;
          }
        }
      }
      return true;
    },

    checkChangedComponentModel(node) {
      const oldType = node.element;
      const defaultModel = this.createLayoutSettingsRootContainerChild(oldType);
      const excludedFields = ['blockName', 'parent', 'children'];
      const filteredDefaultModel = Object.fromEntries(
        Object.entries(defaultModel)
          .filter(([key]) => !excludedFields.includes(key))
      );
      const actualModel = Object.entries(_.cloneDeep(this.selectedLeftTreeNode)).reduce((acc, [key, value]) => {
        if (key in defaultModel && !excludedFields.includes(key)) {
          acc[key] = value;
        }
        return acc;
      }, {});

      return !this.compareModels(filteredDefaultModel, actualModel);
    },
    checkSelectedElementType(type) {
      return this.selectedLeftTreeNode?.element === type;
    },
    checkGraphType(index = 0) {
      return this.selectedLeftTreeNode.datasets[index]?.graphType;
    },
    showDeleteAlertModal(node, fromGridMode = false) {
      this.fromGridMode = fromGridMode
      this.deletableNode = node;
      this.deleteAlertModal = true;
    },
    handleAddDataset() {
      const newDataset = this.createGraphLayout();
      if (this.checkGraphType(0) === 'radar') {
        newDataset.graphType = 'radar';
      }
      this.selectedLeftTreeNode.datasets.push(newDataset);
    },
    updateColor(color) {
      this.selectedLeftTreeNode["color"] = color;
    },
    handleTreeLoaded() {
      this.expandAllNodes();
      this.$refs.leftTree.selectNode(this.selectedLeftTreeNode || 0);
    },
    expandAllNodes(force = false) {
      if (force || this.firstLoad) {
        const tree = this.$refs.leftTree;
        tree?.expandAll();
        this.firstLoad = false;
      }
    },
    handleDeleteGraphDatasetClick(index, exceptOne = false) {
      this.selectedLeftTreeNode.datasets = exceptOne
        ? this.selectedLeftTreeNode.datasets.filter((d, i) => i === index)
        : this.selectedLeftTreeNode.datasets.filter((d, i) => i !== index);
      this.setDefaultModelTabChart();
    },
    setDatasetColor(color, index) {
      if (typeof color === 'string') {
        this.selectedLeftTreeNode.datasets[index].color.background = color;
      } else {
        this.selectedLeftTreeNode.datasets[index].color.background = this.selectedPieColors.map(c => c.value);
      }
    },
    setTagsRow(tags) {
      this.selectedLeftTreeNode.tagRows = tags;
    },
    elementValueValidation(elementPropName) {
      const elementModel = this.selectedLeftTreeNode[elementPropName]
      if (elementModel.type !== 'none')
        return !!elementModel.value;
      return true;
    },
    handlePickListModal(state) {
      if (state) {
          this.selectedPieColors = this.pickListColorOptions
            .filter(o => this.selectedLeftTreeNode.datasets[0].color.background.includes(o.value));
      }
      this.showPickListColor = state;
    },
    checkMultipleDataSetsChartTypes(type) {
      if (typeof type === "function") {
        const localType = type();
        return this.multipleDataSetsChartTypes.includes(localType);
      } else {
        return this.multipleDataSetsChartTypes.includes(type);
      }
    },
    resetGraphSettings(e, index) {
      const { systemName: type } = e;

      if (index === 0 && !this.checkMultipleDataSetsChartTypes(type)) {
        this.handleDeleteGraphDatasetClick(index, true);
      }
      if (this.checkMultipleDataSetsChartTypes(type)) {
        const background = this.selectedLeftTreeNode.datasets[index].color.background
        if (!background || Array.isArray(background)) {
          this.selectedLeftTreeNode.datasets[index].color.background = "--prospace-dashboard-graph-light-blue";
        }
      } else if (type === 'pie' || type === 'doughnut' || 'polarArea') {
        this.selectedPieColors = this.pickListColorOptions;
        this.selectedLeftTreeNode.datasets[0].color.background = this.selectedPieColors.map(c => c.value);
      }
    },
    getModalStyle() {
      const { width, height } = this.modalSize;
      return `min-width: ${width}; width: ${width}; height: ${height}; min-height: ${height};`
    },
    setModalSize(size) {
      let { width, height } = size;
      this.modalSize = {
        width: `${(width).toFixed()}px`,
        height: `${(height).toFixed()}px`,
      };
    },
    gridElementWrapActions(node, type, fromGridMode = true) {
      this.fromGridMode = fromGridMode;

      const actions = {
        add: () => this.handlerShowModalContainer(node, true),
        delete: () => this.showDeleteAlertModal(node, true),
        copy: () => { this.tempCopingObj = _.cloneDeep(node); },
        paste: () => this.copyContainer(node, fromGridMode),
        edit: () => this.handleModalGridLayoutSetting(true, node),
        moveUp: () => this.moveChildNode('up', node),
        moveDown: () => this.moveChildNode('down', node),
        moveLeft: () => this.moveChildNode('left', node),
        moveRight: () => this.moveChildNode('right', node),
      };

      if (actions[type]) {
        actions[type]();
      } else {
        console.warn(`Unsupported action type: ${type}`);
      }
    },
    findNode(key) {
      if (!key) return;
      const root = this.model.rootContainer;
      const path = this.findPathByKey(root, key);
      return this.getObjectByPath(root, path || "");
    },
    moveChildNode(direction, node) {
      const parentKey = node.parent?.key;
      const parentNode = parentKey === 'key_0:1' ?  this.model.rootContainer : this.findNode(parentKey);
      if (!parentNode || !parentNode.children || parentNode.children.length === 0) return;

      const children = parentNode.children;
      const total = children.length;
      const index = children.findIndex(n => n.key === node.key);
      const expandedKeys = this.layoutView === 'tree'
        ? this.$refs.leftTree.expandedKeys
        : null;

      if (index === -1) return;

      let newIndex = index;

      const isHorizontal = parentNode.type === 'horizontal';
      const isVertical = parentNode.type === 'vertical';

      if (isHorizontal) {
        if ((direction === 'right' && index + 1 < total) ||
          (direction === 'left' && index > 0)) {
          newIndex += direction === 'right' ? 1 : -1;
        }
      }

      else if (isVertical) {
        if ((direction === 'down' && index + 1 < total) ||
          (direction === 'up' && index > 0)) {
          newIndex += direction === 'down' ? 1 : -1;
        }
      }

      if (newIndex !== index) {
        this.moveNode(node, newIndex);

        this.selectedLeftTreeNode = parentNode.children[newIndex];
        this.updateChildrenIndices(parentNode);
      }

      this.expandNodes(expandedKeys);
    },
    expandNodes(expandedKeys) {
      if (!expandedKeys) return;

      for (let keyNode in expandedKeys) {
        const node = this.findNode(keyNode);
        node ? this.$refs.leftTree.expandNode(node) : null;
      }

      this.expandedKeys = { ...expandedKeys };
    },
    moveNode(node, newIndex) {
      if (!node || !node.parent) return;

      const parentKey = node.parent.key;
      const parentNode = parentKey === 'key_0:1' ?  this.model.rootContainer : this.findNode(parentKey);

      if (!parentNode || !parentNode.children) return;

      const children = parentNode.children;
      const currentIndex = children.findIndex(n => n.key === node.key);

      if (currentIndex === -1 || newIndex < 0 || newIndex >= children.length) return;

      const [movedNode] = children.splice(currentIndex, 1);

      children.splice(newIndex, 0, movedNode);

      this.initializeUniqueKeys(this.model.rootContainer, new Set());
      this.updateChildrenIndices(parentNode);
    },

    updateChildrenIndices(parentNode) {
      if (!parentNode.children) return;

      parentNode.children.forEach((child, index) => {
        child.index = index;
      });
    },

    handleApplyModalGridLayoutSetting() {
      const systemName = this.selectedLeftTreeNode.systemName;

      if (systemName === 'filterPanel' || systemName === 'mainContainer' || systemName === 'key_0:1') {
        if (systemName === 'filterPanel') {
          this.model.filterPanel = this.selectedLeftTreeNode;
        }
        if (systemName === 'mainContainer' || systemName === 'key_0:1') {
          this.model.rootContainer = this.selectedLeftTreeNode;
        }
      } else {
        const path = this.findPathByKey(this.model.rootContainer, systemName);
        let target = this.getObjectByPath(this.model.rootContainer, path);

        target = { ...this.selectedLeftTreeNode, ...target };
      }

      this.$emit('update:model', this.model);

      if (this.v$ && this.v$.selectedLeftTreeNode) {
        this.v$.selectedLeftTreeNode.$touch();
        this.setInvalidsElements(this.selectedLeftTreeNode.systemName, this.v$.selectedLeftTreeNode.$invalid);
      }
      if (this.selectedLeftTreeNode.systemName !== 'filterPanel') {
        if (this.v$ && this.v$.model) {
          this.invalidKeys.forEach(k => {
            this.v$.model[k]?.$touch();
          });
        }
      }

      this.handleModalGridLayoutSetting(false);
    },
    handleModalGridLayoutSetting(state, node) {
      if (node) {
        this.selectedLeftTreeNode = node;
        this.activeContainer = node.parent;
      }
      this.showGridLayoutSettings = state;
    },
    routeLayoutSettingEvents(eventType, value, ...args) {
      let componentName = eventType === 'change:component' ? value.value : this.selectedLeftTreeNode.systemName;
      if (componentName !== 'mainContainer' && componentName !== 'key_0:1' && componentName !== 'filterPanel') {
        componentName = this.selectedLeftTreeNode.element;
      }
      console.log('Event received:', eventType);

      const componentConfig = this.getComponentEvents(componentName);

      if (componentConfig) {
        const eventHandler = componentConfig.events.find(event => event.hasOwnProperty(eventType));

        if (eventHandler) {
          const handlerFunction = eventHandler[eventType];

          if (typeof handlerFunction === 'function') {
            handlerFunction(value, ...args);
          } else {
            console.error(`Handler for ${eventType} is not a function.`);
          }
        } else {
          console.warn(`No handler found for event type: ${eventType} in component: ${componentName}`);
        }
      } else {
        console.warn(`No configuration found for component: ${componentName}`);
      }
    },

    getComponentEvents(componentName) {
      const componentsEvents = [
        {
          name: 'filterPanel',
          events: [
            { 'update:source': this.handleFilterPanelSourceDropdownChange }
          ]
        },
        {
          name: 'mainContainer',
          events: [
            { 'update:type': this.changeContainerChildrenValues }
          ]
        },
        {
          name: 'container',
          events: [
            { 'change:container': this.changeContainerChildrenValues },
            { 'change:component': this.changeComponentType }
          ]
        },
        {
          name: 'graph',
          events: [
            { 'update:pickListModal': this.handlePickListModal },
            { 'reset:graphSettings': this.resetGraphSettings },
            { 'set:datasetColor': this.setDatasetColor }, // {value, index}
            { 'delete:dataset': this.handleDeleteGraphDatasetClick },
            { 'add:dataset': this.handleAddDataset },
            { 'change:component': this.changeComponentType }
          ]
        },
        {
          name: 'pivot',
          events: [
            { 'update:tagsRow': this.setTagsRow },
            { 'change:component': this.changeComponentType }
          ]
        },
        {
          name: 'panel',
          events: [
            { 'add:colorSelection': this.handleAddColorSelection },
            { 'change:component': this.changeComponentType }
          ]
        },
        {
          name: 'iterpanel',
          events: [
            { 'add:colorSelection': this.handleAddColorSelection },
            { 'change:component': this.changeComponentType }
          ]
        },
        {
          name: 'text',
          events: [
            { 'change:component': this.changeComponentType }
          ]
        },
        {
          name: 'table',
          events: [
            { 'update:tagsRow': this.setTagsRow },
            { 'change:component': this.changeComponentType }
          ]
        },
      ];

      return componentsEvents.find(component => component.name === componentName);
    },
    setDefaultBlockName(element) {
      if (!element) return;

      if (element?.element !== 'root-container' && !element.blockName) {
        element.blockName = element.element;
      }

      if (!element?.children && !Array.isArray(element.children) && element.children.length === 0) {
        return;
      } else {
        element.children.forEach(el => {
          this.setDefaultBlockName(el);
        })
      }
    },
    findNodeIndexByKey(key) {
      const node = this.findNode(key);

      if (!node) return -1;

      const parentKey = node.parent?.key;

      if (!parentKey) return -1;

      const parentNode = this.findNode(parentKey);
      const children = parentNode.children;

      return children?.length > 0 ? children.findIndex(el => el.key === key) : -1;
    },
    findTotalChildrenByKey(parentKey) {
      if (!parentKey) return null;

      const parentNode = this.findNode(parentKey);

      if (!parentNode) return null;

      return parentNode.children?.length;
    },
    dotsOptions(node) {
      const options = this.listDotOptions;
      const isMainContainer = node.systemName === 'mainContainer' || node.systemName === 'key_0:1';
      const isFilterPanel = node.systemName === 'filterPanel';
      const isAllChildren = !isMainContainer && !isFilterPanel;
      const nodeIndex = isAllChildren
        ? this.findNodeIndexByKey(node.systemName)
        : null;
      const isDisabledDecrease = nodeIndex === 0;
      const isDisabledIncrease = isAllChildren ? nodeIndex + 1 === this.findTotalChildrenByKey(node.parent.systemName) : false;
      const isContainer = node.element === 'container';
      const isElement = !isMainContainer && !isContainer;
      const typeContainer = node.parent && !isMainContainer ? node.parent.type : node.type;
      const canMoveHorizontal = typeContainer === 'horizontal';
      const canPaste = this.tempCopingObj !== null && !isElement;

      let availableOptions = [];

      if (!isMainContainer) {
        availableOptions.push(options.find(o => o.name === "copy"));
      }

      if (canPaste) {
        availableOptions.push(options.find(o => o.name === "paste"));
      }

      if (typeContainer === 'horizontal' && !isMainContainer) {
        availableOptions.push(options.find(o => o.name === "moveRight"));
        availableOptions.push(options.find(o => o.name === "moveLeft"));
        if (isDisabledDecrease) {
          availableOptions = availableOptions.filter(o => o.name !== "moveLeft");
        }
        if (isDisabledIncrease) {
          availableOptions = availableOptions.filter(o => o.name !== "moveRight");
        }
      } else if (typeContainer === 'vertical' && !isMainContainer) {
        availableOptions.push(options.find(o => o.name === "moveUp"));
        availableOptions.push(options.find(o => o.name === "moveDown"));
        if (isDisabledDecrease) {
          availableOptions = availableOptions.filter(o => o.name !== "moveUp");
        }
        if (isDisabledIncrease) {
          availableOptions = availableOptions.filter(o => o.name !== "moveDown");
        }
      }

      if (!isMainContainer && node.needDelete) {
        availableOptions.push(options.find(o => o.name === "delete"));
      }

      return availableOptions.filter(Boolean);
    },
    handleHeaderDots(action, node, fromGridMode = false) {
      this.gridElementWrapActions(node, action, fromGridMode)
    },
    async changeComponentType(selectedNode, type) {
      if (type) this.tempChangingComponentType = type
      if (selectedNode) {
        this.oldChangingElementType = selectedNode.element;
      }

      const isChanged = this.checkChangedComponentModel(selectedNode);

      if (isChanged && !this.canChangeComponentType) {
        this.changingComponentTypeAlertModal = true;
        this.cancelingComponentType = false;
        return;
      }
      if (!isChanged && this.canChangeComponentType) {
        this.cancelingComponentType = false;
      }

      this.activeContainer = selectedNode.parent;

      const parentNodeKey = selectedNode.parent.key;
      const parentNode = this.findNode(parentNodeKey);
      const children = parentNode.children;
      const oldChildren = _.cloneDeep(children);
      const oldIndex = children.findIndex(ch => ch.key === selectedNode.key);
      const oldName = selectedNode.blockName;
      const newNode = this.setNewContainer(type, false);

      !isChanged && this.canChangeComponentType
        ? newNode.blockName = oldName
        : null;
      this.canChangeComponentType = false; // reset flag
      oldChildren.splice(oldIndex, 1, newNode);
      parentNode.children = oldChildren;

      this.layoutView === 'tree'
        ? await this.$refs.leftTree.getData()
        : null;
      this.$nextTick(() => {
        this.initializeUniqueKeys(this.model.rootContainer, new Set());
        this.setActiveContainerGridView(newNode.systemName);
        this.layoutView === 'tree'
          ? this.dotsOptions(newNode)
          : null;
      });
      this.changingComponentTypeAlertModal = false;
    },
    cancelChangingComponentType() {
      this.cancelingComponentType = true;
      this.selectedLeftTreeNode.element = this.oldChangingElementType;
      this.changingComponentTypeAlertModal = false;
      this.canChangeComponentType = false;
    },
    applyChangingComponentType() {
      this.changingComponentTypeAlertModal = false;
      this.canChangeComponentType = true;
      this.changeComponentType(this.selectedLeftTreeNode, this.tempChangingComponentType);
    },
    cancelGridLayoutSettings() {
      if (!this.oldChangingElementType) {
        // TODO method replaceNode
        const parentNodeKey = this.selectedLeftTreeNode.parent.key;
        const parentNode = this.findNode(parentNodeKey);
        const children = parentNode.children;
        const oldChildren = _.cloneDeep(children);
        const oldIndex = children.findIndex(ch => ch.key === this.oldSelectedModel.systemName);
        oldChildren.splice(oldIndex, 1, this.oldSelectedModel);
        parentNode.children = oldChildren;

        this.handleModalGridLayoutSetting(false);
        this.canChangeComponentType = false;
        return;
      }
      this.selectedLeftTreeNode.element = this.oldChangingElementType;
      this.oldChangingElementType = null;
      this.canChangeComponentType = false;
      this.handleModalGridLayoutSetting(false);
    },
    setInvalidsElements(e, state) {
      if (!this.invalidKeys.includes(e) && state) {
        this.invalidKeys.push(e);
      } else if (this.invalidKeys.includes(e) && !state) {
        const indexKey = this.invalidKeys.findIndex(k => k === e);
        if (indexKey > -1) {
          this.invalidKeys.splice(indexKey, 1);
        }
      }
    }
  },
  computed: {
    getContainerTypesOptions() {
      return [
        { label: this.$t('containerElementType'), value: 'container' },
        { label: this.$t('chartElementType'), value: 'graph' },
        { label: this.$t('textValueElementType'), value: 'text' },
        { label: this.$t('digitValueElementType'), value: 'panel' },
        { label: this.$t('iterPanelElementType'), value: 'iterpanel' },
        { label: this.$t('pivotElementType'), value: 'pivot' },
        { label: this.$t('tableElementType'), value: 'table' },
      ]
    },
    getPropsList() {
      const propsMap = {
        filterPanel: {
          filterPanelSourceDropdownOptions: this.filterPanelSourceDropdownOptions,
          filterPanelValuesOptions: this.filterPanelValuesOptions,
          filterPanelFilterHintText: this.filterPanelFilterHintText,
          createDefaultLayoutColorSettings: this.createDefaultLayoutColorSettings
        },
        mainContainer: {
          rootContainerTypeDropdownOptions: this.rootContainerTypeDropdownOptions
        },
        container: {
          rootContainerTypeDropdownOptions: this.rootContainerTypeDropdownOptions,
          isParentContainerVertical: this.isParentContainerVertical,
          getComponentTypeOptions: this.getContainerTypesOptions,
          gridView: this.layoutView === 'grid',
        },
        graph: {
          filterHintText: this.filterHintText,
          chartTabs: this.chartTabs,
          showAddDataset: false,
          chartTypeDropdownOptions: this.chartTypeDropdownOptions,
          childGraphValuesOptions: this.childGraphValuesOptions,
          childGraphParametersOptions: this.childGraphParametersOptions,
          getComponentTypeOptions: this.getContainerTypesOptions,
          gridView: this.layoutView === 'grid',
        },
        pivot: {
          pivotTableModel: this.selectedLeftTreeNode,
          createPivotValueField: this.createPivotValueField,
          fieldOptions: this.childPanelValuesOptions,
          filterHintText: this.filterHintText,
          validation: this.v$.selectedLeftTreeNode,
          gridView: this.layoutView === 'grid',
          getComponentTypeOptions: this.getContainerTypesOptions,
        },
        text: {
          childPanelValuesOptions: this.childPanelValuesOptions,
          filterHintText: this.filterHintText,
          createDefaultLayoutColorSettings: this.createDefaultLayoutColorSettings,
          getComponentTypeOptions: this.getContainerTypesOptions,
          gridView: this.layoutView === 'grid',
        },
        panel: {
          childPanelValuesOptions: this.childPanelValuesOptions,
          filterHintText: this.filterHintText,
          createDefaultLayoutColorSettings: this.createDefaultLayoutColorSettings,
          getComponentTypeOptions: this.getContainerTypesOptions,
          gridView: this.layoutView === 'grid',
        },
        iterpanel: {
          childPanelValuesOptions: this.childPanelValuesOptions,
          filterHintText: this.filterHintText,
          getComponentTypeOptions: this.getContainerTypesOptions,
          gridView: this.layoutView === 'grid',
        },
        table: {
          tableModel: this.selectedLeftTreeNode,
          createTableRowField: this.createTableRowField,
          fieldOptions: this.childPanelValuesOptions,
          filterHintText: this.filterHintText,
          validation: this.v$.selectedLeftTreeNode,
          gridView: this.layoutView === 'grid',
          getComponentTypeOptions: this.getContainerTypesOptions,
        },
      };

      if (this.getActiveElement === 'graph') {
        propsMap[this.getActiveElement].showAddDataset =
          this.checkMultipleDataSetsChartTypes(this.graphType) && this.layoutView === 'grid';
      }
      if (this.getActiveElement === 'key_0:1') {
        return propsMap['mainContainer'] || {};
      }
      return propsMap[this.getActiveElement] || {};
    },
    getActiveElement() {
      return this.selectedLeftTreeNode.systemName === 'filterPanel' ||
      this.selectedLeftTreeNode.systemName === 'mainContainer' ||
      this.selectedLeftTreeNode.systemName === 'key_0:1'
        ? this.selectedLeftTreeNode.systemName === 'key_0:1'
          ? 'mainContainer'
          : this.selectedLeftTreeNode.systemName
        : this.selectedLeftTreeNode.element
    },
    componentName() {
      const componentMap = {
        filterPanel: 'DashboardLayoutSettingsGridFilterPanel',
        mainContainer: 'DashboardLayoutSettingsGridRootContainer',
        container: 'DashboardLayoutSettingsContainer',
        graph: 'DashboardLayoutSettingsGridChart',
        text: 'DashboardLayoutSettingsGridText',
        panel: 'DashboardLayoutSettingsGridPanel',
        iterpanel: 'DashboardLayoutSettingsGridIterPanel',
        pivot: 'PivotTableSettings',
        table: 'DashboardLayoutSettingsTable'
      };
      return componentMap[this.getActiveElement] || null;
    },
    panels() {
      let rightPanelTitle = "";
      let rightPanelAction = "";

      if (this.selectedLeftTreeNode?.systemName === "filterPanel")
        rightPanelTitle = this.$t("filterPanelSettings");
      else if (this.selectedLeftTreeNode?.root === "rootContainer")
        rightPanelTitle = this.$t("rootContainerSettings");
      else if (this.checkSelectedElementType("container"))
        rightPanelTitle = this.$t("containerSettings");
      else if (this.checkSelectedElementType("graph")) {
        rightPanelTitle = this.$t("chartSettings");
        rightPanelAction = this.checkMultipleDataSetsChartTypes(this.checkGraphType())
          ? this.$t('addDataset')
          : null;
      }
      else if (this.checkSelectedElementType("text"))
        rightPanelTitle = this.$t("textPanelSettings");
      else if (this.checkSelectedElementType("panel"))
        rightPanelTitle = this.$t("digitPanelSettings");
      else if (this.checkSelectedElementType("iterpanel"))
        rightPanelTitle = this.$t("iteratorSettings");
      else if (this.checkSelectedElementType("pivot"))
        rightPanelTitle = "";
      else if (this.checkSelectedElementType("table"))
        rightPanelTitle = ""; //this.$t("simpleTableSettings");

      return [
        {
          title: "",
          search: false,
        },
        {},
        {
          title: rightPanelTitle,
          action: rightPanelAction
        },
      ];
    },
    icons() {
      return { faTrashAlt, faPlus, faExclamationTriangle };
    },
    rootContainerTypeDropdownOptions() {
      return [
        {
          systemName: "vertical",
          name: this.$t("vertical"),
        },
        {
          systemName: "horizontal",
          name: this.$t("horizontal"),
        },
      ];
    },
    chartTypeDropdownOptions() {
      const baseOptions = [
        {
          systemName: "bar",
          name: this.$t("barChart"),
        },
        {
          systemName: "line",
          name: this.$t("lineChart"),
        },
        {
          systemName: "radar",
          name: this.$t("radarChart"),
        },
      ];
      const addOptions =  [
        {
          systemName: "pie",
          name: this.$t("pieChart"),
        },
        {
          systemName: "doughnut",
          name: this.$t("doughnutChart"),
        },
        {
          systemName: "polarArea",
          name: this.$t("polarChart"),
        },
      ];
      if (this.modelTabChart.index === 1) {
        return [...baseOptions, ...addOptions];
      } else {
        return [...baseOptions].filter(t => t.systemName !== 'radar');
      }
    },
    chartTabs() {
      const chartDatasets = this.selectedLeftTreeNode?.datasets;
      if (!chartDatasets) return [];

      return chartDatasets.map((dataset, index) => {
        const datasetNumber = index + 1;
        const datasetName = "dataset" + datasetNumber;

        return {
          name: this.$t("dataset") + " " + datasetNumber,
          systemName: datasetName,
          isActive: this.modelTabChart.systemName === datasetName,
          index: datasetNumber
        };
      });
    },
    isParentContainerVertical() {
      return this.selectedLeftTreeNode?.parent?.type === "vertical";
    },
    filterPanelFilterHintText() {
      let useNextNamesToFillFilterField = this.$t("exampleToFillFilterField") + "\n\n" + this.$t("useNextNamesToFillFilterField");
      for (const option of this.filterPanelValuesOptions) {
        useNextNamesToFillFilterField+= `\n${option.systemName} - ${option.name}`
      }
      return useNextNamesToFillFilterField;
    },
    filterHintText() {
      let useNextNamesToFillFilterField = this.$t("exampleToFillFilterField") + "\n\n" + this.$t("useNextNamesToFillFilterField");
      for (const option of this.childPanelValuesOptions) {
        useNextNamesToFillFilterField+= `\n${option.systemName} - ${option.name}`
      }
      return useNextNamesToFillFilterField;
    },
    listDotOptions() {
      return [
        { name: "copy", text: this.$t("copyAction"), icon: 'document-copy' },
        { name: "paste", text: this.$t("pasteTxt"), icon: 'paste' },
        { name: "moveUp", text: this.$t("moveUpTxt"), icon: 'move-up' },
        { name: "moveDown", text: this.$t("moveDownTxt"), icon: 'move-down' },
        { name: "moveRight", text: this.$t("moveRightTxt"), icon: 'move-right' },
        { name: "moveLeft", text: this.$t("moveLeftTxt"), icon: 'move-left' },
        { name: "delete", text: this.$t("delete"), icon: 'delete' }
      ];
    }
  },
};
</script>

<style lang="scss" >
@mixin simple-grid-styles {
  .prospace-list-items-table {
    .p-datatable-thead {
      & > tr {
        display: flex !important;
        th {
          &:first-child {
            flex: 1 0 220px !important;
          }
          &:last-child {
            .p-column-header-content {
              justify-content: flex-end;
              padding-right: 10px;
            }
          }
        }
      }
    }
    .p-datatable-tbody {
      & > tr {
        &:not(.p-datatable-row-expansion) {
          padding-top: 0px !important;
          padding-bottom: 0px !important;
          display: flex !important;
          & > td {
            &:first-child {
              flex: 1 0 220px !important;
            }
            &:nth-child(2) {
              display: none !important;
            }
            &:last-child {
              width: 70% !important;
              padding: 0;
              display: flex !important;
              height: 34px;
              justify-content: space-between;
              align-items: center;
              .prospace-list-items__additional-value {
                width: 100%;
                font-size: 13px;
                color: var(--prospace-ui-main);
                cursor: pointer;
                .prospace-input-switch__wrapper {
                  width: 140px;
                  white-space: nowrap;
                  label {
                    display: none;
                    //font-size: 11px;
                  }
                  .p-inputswitch {
                    margin-left: 5px;
                  }
                }
              }
              .prospace-default-text-column {
                height: auto;
                & > div {
                  display: flex;
                }
              }
            }
          }
        }
      }
    }
  }
}
@mixin simple-grid-styles-pivot {
  &--pivot {
    .pivot-setting {
      &--rows {
        .prospace-list-items-table {
          .p-datatable-tbody {
            & > tr {
              &:not(.p-datatable-row-expansion) {
                td {
                  &:first-child {
                    flex: 1 0 79% !important;
                  }
                  &:last-child {
                    flex: 1 0 21% !important;
                  }
                }
              }
            }
          }
        }
      }
      &--values {
        .prospace-list-items-table {
          .p-datatable-tbody {
            & > tr {
              &:not(.p-datatable-row-expansion) {
                td {
                  &:first-child {
                    flex: 1 0 84% !important;
                  }
                  &:last-child {
                    flex: 1 0 16% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.dashboard-layout-settings {
  .prospace-left-panel {
    & > .prospace-geo-layout__center {
      padding-right: 3px !important;
      padding-bottom: 3px !important;
      .p-tree-wrapper {
        padding-right: 3px !important;
      }
    }
  }
  .prospace-tree-item {
    .prospace-label-with-counter {
      width: calc(100% - 16px - 42px - 5px);
    }
    .prospace-icon {
      width: 16px !important;
      height: 16px !important;
    }
  }
  &__tree-actions {
    position: absolute;
    right: 0;
  }
  &.dashboard-grid-layout {
    &.prospace-geo-layout {
      &--ishideCenter {
        grid-template-rows: 1fr !important;
      }
    }
  }
  .tree-item-action-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    font-size: 10px;
    border-radius: 5px;
    background: var(--prospace-ui-select-light);

    .prospace-dots-wrapper {
      border-radius: 5px;
      width: 18px;
      height: 18px;
      .prospace-icon {
        width: 18px !important;
        height: 18px !important;
      }
    }
  }
  .prospace-tree-item .prospace-icon {
    min-width: 16px;
  }
  .dashboard-grid-layout__right {
    padding-left: 0;
  }
  .p-tree .p-tree-wrapper .p-tree-container .p-treenode .p-treenode-content {
    margin: 0;
    padding-right: 10px;
    padding-left: 5px;
  }
  .p-tree {
    overflow: auto !important;
    .p-tree-wrapper {
      width: 100%;
      .p-tree-container {
        .p-treenode {
          .p-treenode-children {
            margin-right: 0px;
            padding-left: 26px;
            & > .p-treenode {
              margin-right: 0px;
            }
            .p-treenode-content {
              padding-left: 0px;
              padding-right: 10px;
            }
            .p-treenode-children {
              padding-left: 22px;
            }
          }
          .p-treenode-content {
            &.p-treenode-selectable {
              &.p-highlight {
                position: relative;
                background-color: transparent;
                &:before {
                  content: '';
                  position: absolute;
                  left: -300%;
                  top: 0;
                  width: 600%;
                  height: 30px;
                  background-color: var(--prospace-ui-left-panel-selected);
                  z-index: 0;
                }
              }
            }
            .p-treenode-label {
              position: relative;
            }
          }
        }
      }
    }
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    .prospace-icon {
      background-color: var(--prospace-ui-main);
    }
  }
  .prospace-panel-block-list__row {
    .prospace-action {
      margin-top: 20px;
    }
    &--withoutMarginAction {
      .prospace-action {
        margin-top: 0;
      }
    }
  }
  .dashboard-grid-layout {
    &__panel {
      .prospace-geo-layout__center {
        padding: 10px;
      }
    }
  }
  &__tabs {
    padding-right: 3px;
    .prospace-tabs__content {
      padding: 20px 3px 20px 0 !important;
    }
    .prospace-tabs__header {
      &.prospace-h-layout {
        width: 100% !important;
        margin: 0;
      }
    }
  }
  &--graph {
    .dashboard-grid-layout__right {
      &.prospace-panel-block {
        .prospace-panel-block__body {
          margin-right: -5px;
          padding-right: 0 !important;
        }
      }
    }
  }
  &--pivot, &--table {
    .dashboard-grid-layout__right {
      &.prospace-panel-block {
        .prospace-panel-block__body {
          height: 100%;
          margin-right: 0px;
          padding-right: 0px !important;
          padding-left: 0 !important;
        }
        .prospace-panel-block {
          &__body {
            padding-left: 10px !important;
          }
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
        .prospace-panel-block-item {
          &__header {
            font-size: 12px;
            font-weight: 400;
            color: var(--prospace-text-gray);
          }
        }
      }
      @include simple-grid-styles;
    }
  }
  &--table {
    .prospace-list-items-table {
      .p-datatable-tbody {
        & > tr {
          &:not(.p-datatable-row-expansion) {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
          }
        }
      }
    }
  }
  @include simple-grid-styles-pivot;
}
.modal-grid-layout-settings {
  .prospace-panel-block-list__row {
    margin-bottom: 20px;
    border-bottom: none;
  }
  .prospace-panel-block {
    .prospace-warning {
      padding-top: 0;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  @include simple-grid-styles;
  @include simple-grid-styles-pivot;
}
</style>
