import { DashboardDatasetService } from "@dashboard/services/DashboardDatasetService.js";
import { DashboardService } from "@dashboard/services/DashboardService.js";
import { DashboardColorService } from "@dashboard/services/DashboardColorService.js";
import { YesNoService } from "@base/services/YesNoService.js";
import DashboardChartItem from "@dashboard/views/Dashboards/components/DashboardViews/DashboardChartItem";
import DashboardPlateIterator from "@dashboard/views/Dashboards/components/DashboardViews/DashboardPlateIterator";
import DashboardPlateValue from "@dashboard/views/Dashboards/components/DashboardViews/DashboardPlateValue";
import DashboardFilterPanel from "@dashboard/views/Dashboards/components/DashboardViews/DashboardFilterPanel";
import DashboardContainer from "@dashboard/views/Dashboards/components/DashboardViews/DashboardContainer";
import DashboardChart from "@dashboard/views/Dashboards/components/DashboardViews/DashboardChart";
import DashboardPivot from "@dashboard/views/Dashboards/components/DashboardViews/DashboardPivot";
import DashboardTable from "@dashboard/views/Dashboards/components/DashboardViews/DashboardTable/DashboardTable";
import {
    ProSpaceFilterSearchfield,
    ProSpaceFilterContainsChips,
    ProSpaceFilterNumber,
    ProSpaceFilterNumberRange,
    ProSpaceMultiSelect,
    ProSpaceFilterDatesRange,
    ProSpaceFilterDateTimeRange,
    ProSpaceFilterDatePicker,
    Filters
} from "@prospace/prospace-components-library";
import { checkValue } from "@dashboard/utils/dashboardUtils.js";
import { getAMorPM } from "@prospace/prospace-components-library/src/lib-assets/mixins/date";
import moment from "moment";
import _ from "lodash";

// панель фильтров - добавить модель, подставление значений
// панель фильтров - валидация
// график на всю ширину контейнера
// панель фильтров - кнопка - удалить все фильтры ???
export default class DashboardBroker {
    constructor() {
        this.userinfo = null;
        this.dashboard = null;
        this.dashboardView = null;
        this.datasets = null;
        this.filterPanelDatasets = null;
        this.mainDataset = null;
        this.mainDatasetFields = [];
        this.layout = null;
        this.filters = {};
        this.filterPanel = null;
        this.rootContainer = null;
        this.charts = [];
        this.ro = null;
        this.datasetService = new DashboardDatasetService();
        this.dashboardService = new DashboardService();
        this.dashboardColorService = new DashboardColorService();
        this.yesNoService = new YesNoService();
    }
    async init(id) {
        this.dashboard = await this.getDashboard(id);
        this.dashboardView = this.dashboard.id;
        this.layout = JSON.parse(this.dashboard.layouts[0].layoutSettings);
        this.mainDataset = _.find(this.dashboard.datasets, i => i.isMain) || this.dashboard.datasets[0];
        this.mainDatasetFields = await this.getDatasetFields(this.mainDataset.datasetName);
    }
    onMounted() {
        this.bindResizeObserver();
    }
    onUnmounted() {
        this.unbindResizeObserver();
    }
    bindResizeObserver() {
        if (!document.getElementById("dashboard-body")) {
            return;
        }
        this.ro = new ResizeObserver(_.debounce(() => {
            this.setChartsSize();
        }, 100));
        this.ro.observe(document.getElementById("dashboard-body"));
    }
    unbindResizeObserver() {
        if (!this.ro) {
            return;
        }
        this.ro.disconnect();
    }
    async update() {
        this.rootContainer = null;
        const query = Filters.getQuery(this.filters);
        try {
            this.datasets = await this.getDatasets(this.mainDataset.datasetName, query) ?? [];
            if (this.layout.filterPanel.source && this.mainDataset.datasetName !== this.layout.filterPanel.source)
                this.filterPanelDatasets = await this.getDatasets(this.layout.filterPanel.source) ?? [];
        } catch(e) {}
        this.filterPanel = await this.getFilterPanelComponentsStructure();
        this.rootContainer = this.getRootContainerComponentsStructure();
    }
    reset() {
        this.userinfo = null;
        this.dashboard = null;
        this.dashboardView = null;
        this.datasets = null;
        this.filterPanelDatasets = null;
        this.mainDataset = null;
        this.mainDatasetFields = [];
        this.layout = null;
        this.filters = {};
        this.filterPanel = null;
        this.rootContainer = null;
        this.charts = [];
        this.datasetService = new DashboardDatasetService();
        this.dashboardService = new DashboardService();
        this.dashboardColorService = new DashboardColorService();
        this.yesNoService = new YesNoService();
    }
    async getDatasets(datasetName, filter="") {
        const data = [{
            datasetName,
            filter
        }];
        let res = null
        try {
            res = await this.datasetService.getDatasetData(data);
        } catch(e) {}
        return res ? res[0].data : null;
    }
    async getDashboard(id) {
        const res = await this.dashboardService.get(id);
        return res ? res.data : null;
    }
    async getDatasetFields (name) {
        const res = await this.datasetService.getDatasetFields(name);
        return res ? res.data : null;
    }
    setUserinfo(userinfo) {
        this.userinfo = userinfo;
    }
    setFilterModel(filter) {
        const arrayTypes = ["multiselect", "select", "checkbox"]
        const dateTypes = [ "dateselect", "datetimeselect", "daterangeselect", "datetimerangeselect"]
        this.filters = filter ?? this.filters;
        this.dashboard.filters.forEach(f => {
            let filterVal = this.filters[f.sourceField ?? f.name];
            if (filterVal == undefined)
                this.filters[f.sourceField ?? f.name] = arrayTypes.includes(f.type) ? [] : {};

            if(filterVal && filterVal.and && dateTypes.includes(f.type)) {
                filterVal.and[0] = {
                    ge: moment(filterVal.and[0].ge).toDate(),
                    le: moment(filterVal.and[0].le).toDate()
                }
            }
        })
    }
    async getFilterPanelComponentsStructure() {
        const filterPanel = this.layout.filterPanel;
        const promiseFilters = _.map(this.dashboard.filters, async item => {
            const onApply = () => {
                const filterName = btoa("dashbord-filter_" + this.dashboard.id + this.dashboard.lastUpdated);
                localStorage.setItem(
                    filterName,
                    JSON.stringify(this.filters)
                );
                this.update();
            };
            return {
                onApply,
                name: item.sourceField ?? item.name,
                getMethod: 'get',
                localization: JSON.parse(item.localization),
                clearable: !item.isRequired,
                ...this.mapComponentFilter(item.type, item)
            }
        });
        const filters = await Promise.all(promiseFilters);
        const filterPanelData = this.filterValues(this.filterPanelDatasets ?? this.datasets, filterPanel.filter);
        let title = this.getValueByType(filterPanel.header, filterPanel, filterPanelData);
        let label = this.getValueByType(filterPanel.label, filterPanel, filterPanelData);
        let value = this.getValueByType(filterPanel.value, filterPanel, filterPanelData);
        return checkValue(title) || checkValue(label) || checkValue(value) || checkValue(filters)
        ? {
            layout: 'horizontal',
            bg: 'dashboard-bg-white',
            children: [
                {
                    component: DashboardFilterPanel,
                    props: {
                        model: this.filters,
                        title: title,
                        text: label,
                        rate: value,
                        titleColor: this.getColorByType(filterPanel.header, filterPanel),
                        textColor: this.getColorByType(filterPanel.label, filterPanel),
                        rateColor: this.getColorByType(filterPanel.value, filterPanel),
                        filters
                    }
                }
            ]
        }
        : null
    }
    getRootContainerComponentsStructure() {
        const {element, type, children} = this.layout.rootContainer;
        const layout = {
            element, type
        }
        if (_.size(children) === 0) {
            return layout;
        }
        const setChildren = (item) => { // переписать на стек
            const layoutItem = this.mapComponentProps(item);
            if (item.children) {
                layoutItem.props.children = _.map(item.children, setChildren);
            }
            return layoutItem
        };
        layout.children = _.map(children, setChildren);
        return layout;
    }
    mapComponentProps(item) {
        switch(item.element) {
            case 'container':
                return {
                    component: DashboardContainer,
                    props: {
                        layout: item.type,
                        layoutParent: item.parent.type,
                        with: item.value
                    }
                }
            case 'text':
                return {
                    component: DashboardChartItem,
                    props: {
                        title: this.getValueByType(item.header, item),
                        text: this.getValueByType(item.label, item),
                        link: this.getValueByType(item.label, item),
                        rate: this.getValueByType(item.value, item),
                        titleColor: this.getColorByType(item.header, item),
                        textColor: this.getColorByType(item.label, item),
                        rateColor: this.getColorByType(item.value, item)
                    }
                }

            case 'iterpanel':
                let iterData = _.map(this.sortValues(this.filterValues(this.datasets, item.filter), item.label), v => {
                    return {
                        title: this.escapeValue(v[item.value]),
                        text: this.escapeValue(v[item.label])
                    }
                })
                iterData = [...new Map(iterData.map(item => [item.title + item.text, item])).values()]
                return {
                    component: DashboardPlateIterator,
                    props: {
                        data: iterData,
                        color: item.color
                    }
                }
            case 'panel':
                item.type = "field"
                return {
                    component: DashboardPlateValue,
                    props: {
                        title: this.getValueByType(item.value, item),
                        text: this.getValueByType(item.label, item),
                        color: this.getColorByType(item.value, item, item)
                    }
                }
            case 'graph':
                const styles = getComputedStyle(document.documentElement);
                let sortedData = this.sortValues(this.filterValues(this.datasets, item.filter), item.xAxis);
                return {
                    component: DashboardChart,
                    props: {
                        type: this.getChartType(item.datasets, item.element),
                        data: {
                            labels: this.mapValues(sortedData, item.xAxis),
                            datasets: _.map(item.datasets, i => ({
                                label: this.localizeField(i.valueField),
                                type: i.graphType,
                                data: this.mapValues(sortedData, i.valueField),
                                backgroundColor: this.getBackgroundColorDataset(styles, i),
                                borderColor: this.getBorderColorDataset(styles, i),
                                borderRadius: 5,
                            }))
                        },

                        options: this.getGraphOptions(styles, item),
                        onLoaded: (chart) => {
                            this.resizeChart(chart);
                            this.charts.push(chart);
                        }
                    }
                }
            case 'pivot':
                let data = this.sortValues(this.filterValues(this.datasets, item.filter), item.sort.field, item.sort.direction);
                let rows = this.getPivotRows(data, item);
                let tagRows = this.getTagRows(item);
                let columns = this.getPivotColumns(data, item);
                let values = this.getPivotData(data, rows, item);
                return {
                    component: DashboardPivot,
                    props: {
                        rowData: rows,
                        tagRows: tagRows,
                        columns: columns,
                        data: values,
                        datasetFields: this.mainDatasetFields,
                        broker: this
                    }
                }
            case 'table':
                let tableData = this.sortValues(this.filterValues(this.datasets, item.filter), item.sort.field, item.sort.direction);
                let tableColumns = this.getTableColumns(item);
                let tableRows = this.getTableRows(tableData, item, tableColumns);
                let backgroundColors = this.getBackgroundColorsTable(tableData, item);
                let tableTagRows = this.getTagRows(item);

                return {
                  component: DashboardTable,
                  props: {
                    rowData: tableRows,
                    columns: tableColumns,
                    backgroundColors: backgroundColors,
                    tagRows: tableTagRows,
                    sort: { field: item.sort.field, direction: item.sort.direction },
                    broker: this
                  }
                };
            default:
                return null;
        }
    }
    mapComponentFilter(type, item) {
        // 'String': ['chips', 'checkbox', 'select', 'multiselect'],
        // 'Int32': ['number', 'range', 'select', 'multiselect'],
        // 'Int64': ['number', 'range', 'select', 'multiselect'],
        // 'Decimal': ['number', 'range', 'select', 'multiselect'],
        // 'Boolean': ['checkboxyesno'],
        // 'DateTime': ['dateselect', 'datetimeselect', 'daterangeselect','datetimerangeselect', 'select', 'multiselect']
        const me = this;
        const service = {
            async get(filter = "") {
                return {
                    data: {
                        records: await me.getDatasets(item.sourceDatasetName, filter)
                    }
                }
            }
        }
        const optionValue = item.sourceField ? 'Id' : item.sourceDatasetField;
        const optionLabel = item.sourceDatasetField;
        switch(type) {
            case 'select':
                return {
                    selectionMode: "single",
                    dataKey: item.sourceDatasetField,
                    component: ProSpaceFilterSearchfield,
                    service,
                    optionValue,
                    optionLabel
                };
            case 'multiselect':
                return {
                    selectionMode: "multiple",
                    dataKey: item.sourceDatasetField,
                    component: ProSpaceFilterSearchfield,
                    service,
                    optionValue,
                    optionLabel
                };
            case 'chips':
                return {
                    component: ProSpaceFilterContainsChips
                };
            case 'checkbox':
                return {
                    dataKey: item.sourceDatasetField,
                    component: ProSpaceMultiSelect,
                    service,
                    optionValue,
                    optionLabel
                };
            case 'number':
                return {
                    component: ProSpaceFilterNumber
                };
            case 'range':
                return {
                    min: 0,
                    max: Number.MAX_SAFE_INTEGER,
                    component: ProSpaceFilterNumberRange
                };
            case 'yesno':
                return {
                    dataKey: "value",
                    component: ProSpaceMultiSelect,
                    service: this.yesNoService,
                    optionValue: "value",
                    optionLabel: "label"
                };
            case 'dateselect':
                return {
                    component: ProSpaceFilterDatePicker
                };
            case 'datetimeselect':
                return {
                    component: ProSpaceFilterDatePicker,
                    showTime: true,
                    showSeconds: true
                };
            case 'daterangeselect':
                return {
                    component: ProSpaceFilterDatesRange
                };
            case 'datetimerangeselect':
                return {
                    component: ProSpaceFilterDateTimeRange
                };
            default:
                return null
        }
    }
    getColorByType(item, obj, colorModel = null) {
        if (!item || !(item.color || (colorModel && colorModel.color)))
            return null;

        let { color } = colorModel ?? item;
        let resultColor = null;

        if (color.type === 'default') {
            resultColor = color
            return resultColor
        }
        if (color.type === 'lights') {
            const { range } = color;
            const value = this.getValueByType(item, obj);
            if (value < range.left) {
                resultColor = color.before
            } else if (value > range.left && value < range.right) {
                resultColor = color.center
            } else if (value > range.right) {
                resultColor = color.after
            } else {
                resultColor = color.after
            }
        }
        return resultColor
    }
    filterValues(values, filter) {
        const checkFn = this.getCheckFnFromFilterStr(filter);
        return _.filter(values, checkFn);
    }
    mapValues(values, field) {
        return _.map(values, i => this.escapeValue(i[field], false));
    }
    sortValues(values, sortField, direction = "asc") {
        let sortArray = _.cloneDeep(values);
        return sortArray.sort((a, b) => direction === "asc" ? this.naturalCompareObj(a, b, sortField) : this.naturalCompareObj(b, a, sortField));
    }
    getValueByType(item, obj, data = null) {
        const dataset = data ?? this.datasets;
        if (!item || item.type === 'none') {
            return null;
        }
        if (item.type === 'label' && !item.link) {
            return item.value;
        }
        if (item.type === 'label' && item.link) {
          return {
            text: item.value,
            href: item.link
          };
        }
        if (item.type === 'field') {
            const checkFn = this.getCheckFnFromFilterStr(obj.filter);
            const values = _.map(_.filter(dataset, checkFn), i => i[item.value]);
            if (!item.link) {
              return this.escapeValue(values[0]);
            } else {
              return {
                text: this.escapeValue(values[0]),
                href: item.link
              };
            }

        }
    }
    getCheckFnFromFilterStr(filterStr) {
        if (!filterStr) {
            return () => true;
        }
        const arrSplit = _.map(filterStr, i => i.split('='));
        const arrReduce = _.reduce(arrSplit, (acc, i) => {
            const key = i[0];
            const value = i[1];
            if (acc[key]) {
                acc[key].add(value);
            } else {
                acc[key] = new Set([value]);
            }
            return acc;
        }, {});
        return (value) => _.every(_.entries(arrReduce), i => {
            const key = i[0];
            const valueSet = i[1];
            return valueSet.has(value[key]?.toString());
        });
    }
    localizeField(name) {
        const field = this.mainDatasetFields.find(f => f.systemName === name);
        return field ? field.name : name;
    }
    escapeValue(value, useLocaleString = true) {
        if (value === null || value === undefined) {
            return "";
        }
        else if (typeof value === "boolean") {
          return value;
        }
        else if (this.isFloat(value)) {
            let val = Number(Number(value).toFixed(2));
            if (useLocaleString) val = val.toLocaleString("ru-RU").replace(',', '.');
            return val;
        }
        else if (this.isInt(value)){
            let val = Number(value);
            if (useLocaleString) val = val.toLocaleString("ru-RU").replace(',', '.');
            return val;
        }
        else if (this.isDate(value) && this.isValidDate(value))
            return Filters.dateFormat(
              value,
              this.userinfo.dateFormat + " "
              + this.userinfo.timeFormat) + " "
              + getAMorPM(value, this.userinfo.timeFormat)

        return value;
    }
    isInt(n){
        return Number(n) === n && n % 1 === 0;
    }
    isFloat(n){
        return Number(n) === n && n % 1 !== 0;
    }
    getFactor(num) {
      const numStr = num.toString();
      const decimalIndex = numStr.indexOf('.');

      if (decimalIndex === -1) return 1;
      return Math.pow(10, numStr.length - decimalIndex - 1);
    }
    addFloats(a, b) {
      if (!a) return b;
      if (!b) return a;
      if (typeof a === 'string') {
        a = parseFloat(a);
      }
      if (typeof b === 'string') {
        b = parseFloat(b);
      }
      if (isNaN(a) || isNaN(b)) {
        throw new Error("Both arguments must be numbers or strings representing numbers.");
      }

      const factorA = this.getFactor(a);
      const factorB = this.getFactor(b);
      const factor = Math.max(factorA, factorB);

      return Math.round((a * factor + b * factor)) / factor;
  }

    isDate(date){
          return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
      }
    isValidDate(dateString) {
      const formats = [
        'YYYY-MM-DD', // ISO 8601
        'YYYY-MM',
        'YYYYMMDD',
        'hhmm',
        'hh:mm',
        'hhmmss',
        'hh:mm:ss',
        '±hh',
        '±hhmm',
        '±hh:mm',
        'YYYY-MM-DDThh:mm:ssZ',
        'YYYYMMDDThhmmss±hhmm',
        'DD.MM.YYYY', // ru
        'DD.MM.YY', //
        'DD-MM-YY', //
        'DD-MM-YYYY', //
        'DD/MM/YYYY', // UK
        'MM-DD-YYYY', // US
      ];

      return formats.some(format => moment(dateString, format, true).isValid());
    }

    setChartsSize() {
          this.charts.forEach(this.resizeChart)
      }
    resizeChart(chart) {
        const parent = chart?.canvas?.parentNode?.parentNode;
        if (!parent) {
            return
        }
        const otherChildren = Array.from(parent.children).filter(el => !el.classList.contains('p-chart') && !el.classList.contains('dashboard-block'));
        const containerChildren = Array.from(parent.children).filter(el => el.classList.contains('dashboard-block'));
        const chartChildren = Array.from(parent.children).filter(el => el.classList.contains('p-chart'));
        const padding = 20;
        const gap = 10;
        let height = 0;
        let width = 0;
        if (parent.classList.contains('prospace-v-layout')) {
            const heightContainerChildren = containerChildren.reduce((acc,i) => acc += i.offsetHeight, 0);
            height = (parent.offsetHeight - padding - heightContainerChildren - gap) / (chartChildren.length + otherChildren.length);
            width = parent.offsetWidth - padding;
        }
        else {
            const widthtContainerChildren = containerChildren.reduce((acc,i) => acc += i.offsetWidth, 0);
            height = parent.offsetHeight - padding;
            width = (parent.offsetWidth - padding - widthtContainerChildren - gap) / (chartChildren.length + otherChildren.length);
        }

        chart.resize(width, height);
    }

    getPivotRows(data, item) {
        let tempRows = data
            .map((x) => {
                let temp = Object.entries(x)
                    .filter(([key]) => item.rows.includes(key) || item.rows.find(r => r.field === key))
                    .sort(([aKey], [bKey]) => item.rows.indexOf(aKey) - item.rows.indexOf(bKey))
                    .map(([key, value]) => { return { [key]: value }});

                return Object.assign(...temp);
            });
      return [
            ...new Map(tempRows.map(item => [Object.values(item).join('-'), item]))
                .values()
                .map((x, index) => { return { key: index, rows: {...x} } })
        ];
    }

    getTableRows(data, item, columns) {
        const tableData = data.map((row, i) => {
            const rowData = {};
            rowData['id'] = i;
            columns.forEach(column => {
                rowData[column.key] = row[column.key];
            });
            return rowData;
        });
        return tableData;
    }

    getTableColumns(item) {
        return item.rows.map(x => {
            return {
                label: this.localizeField(x.field),
                key: x.field
            };
        }).sort(this.naturalCompare);
    }

    getBackgroundColorsTable(data, item) {
        const tableColors = data.map((row, i) => {
            const rowData = {};

            rowData['id'] = i;

            item.rows.forEach(column => {
                const value = row[column.field];

                rowData[column.field] = this.getColorByType({ type: "label", value: row[column.field] }, item, column);

            });

            return rowData;
        });

        return tableColors;
    }

    getTagRows(item) {
        return item.tagRows || {};
      }
    getPivotColumns(data, item) {
        return [...new Set(data.map(x => x[item.columnField]))].sort(this.naturalCompare);
    }

    getPivotData(data, rowsData, item) {
        let tempValueData = data
            .map((x) => {
                let temp = Object.entries(x)
                    .filter(([key]) => item.values.map(v => v.field).includes(key))
                    .sort(([aKey], [bKey]) => this.naturalCompareByArray(aKey, bKey, item.values.map(v => v.field)))
                    .map(([key, value]) => {
                        let valObj = item.values.find(v => v.field === key);
                        return {
                            key: this.localizeField(key),
                            value: this.escapeValue(value),
                            color: this.getColorByType({ type: "label", value: value }, null, valObj)
                        }
                    });

                return {
                    row: rowsData.find(r => Object.keys(r.rows).every(rk => r.rows[rk] === x[rk]))?.key,
                    column: x[item.columnField],
                    values: temp
                }
            });

        return tempValueData.sort((a, b) => this.naturalCompareObj(a, b, item.columnField));
    }
    naturalCompareObj(a, b, sortField) {
        return this.naturalCompare(a[sortField], b[sortField])
    }
    naturalCompareByArray(a, b, sortingArr) {
        return sortingArr.indexOf(a) - sortingArr.indexOf(b)
    }
    naturalCompare(a, b) {
        if (!(a && b))
            return 0;
        var ax = [], bx = [];
        a.toString().replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
        b.toString().replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });

        while(ax.length && bx.length) {
            var an = ax.shift();
            var bn = bx.shift();
            var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
            if(nn) return nn;
        }
        return ax.length - bx.length;
    }
    getChartType(datasets, element) {
      if (element !== 'graph') return;

      const types = ['line', 'bar', 'pie', 'doughnut', 'polarArea', 'radar'];
      const defaultType = types[0];

      if (!datasets || !Array.isArray(datasets) || datasets?.length === 0) {
        return defaultType;
      }
      return datasets[0].graphType || defaultType;
    }
    isChartPieDoughnut(item, type) {
      const chartType = type || this.getChartType(item.datasets, item.element);
      return chartType && (chartType === 'doughnut' || chartType === 'pie' || chartType === 'polarArea');
    }
    isRadarChart(item, type) {
      const chartType = type || this.getChartType(item.datasets, item.element);
      return chartType && (chartType === 'radar');
    }
    isPolarChart(item, type) {
      const chartType = type || this.getChartType(item.datasets, item.element);
      return chartType && (chartType === 'polarArea');
    }
    getGraphOptions(styles, item) {
      let scales = {
        x: {
          ticks: {
            color: styles.getPropertyValue('--prospace-text-gray'),
            font: {
              size: 9,
              weight: 400,
              style: 'normal'
            },
            padding: 0
          },
          border: {
            display: false,
          },
          grid: {
            display: false,
          }
        },
        y: {
          ticks: {
            color: styles.getPropertyValue('--prospace-text-gray'),
            font: {
              size: 9,
              weight: 400,
              style: 'normal'
            },
            padding: 0
          },
          grid: {
            color: styles.getPropertyValue('--prospace-ui-border-color'),
            borderDash: [2,2],
            offset: true
          },
        },
      };
      let legend = {
        //this.isChartPieDoughnut(item) ? false :
          display: item.showLegend,
          labels: {
            color: styles.getPropertyValue('--prospace-text-gray'),
            font: {
              size: 10,
              weight: 400,
              style: 'normal'
            },
            usePointStyle: true,
            pointStyle: 'rectRounded',
          },
          position: 'bottom'
        };
      if (this.isChartPieDoughnut(item)) {
        scales = {};
      }
      if (this.isRadarChart(item) || this.isPolarChart(item)) {
        const font = {
          size: 9,
          weight: 400,
          style: 'normal'
        }
        scales = {
          r: {
            pointLabels: {
              font: {},
              color: styles.getPropertyValue('--prospace-text-gray'),
            },
            ticks: {
              font: {},
              color: styles.getPropertyValue('--prospace-text-gray'),
            }
          },
        }
        scales.r.ticks.font = font;
        scales.r.pointLabels.font = font;
      }
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const { type, label } = tooltipItem.dataset;
                if (this.isChartPieDoughnut(null, type)) {
                  return `${label}: ${tooltipItem.formattedValue}`
                } else {
                  return tooltipItem.formattedValue;
                }

              }
            }
          },
          legend: legend,
        },
        scales: scales,
        elements: {
          line: this.getBorderWidthLineOptions(item),
          arc: {
            borderWidth: 3,
            hoverBorderColor: styles.getPropertyValue('--prospace-dashboard-ui-card-white'),
          },
          // point: {
          //   backgroundColor: ''
          // }
        },

      };
    }
    hexToRgba(hex, alpha = 1, raw = false) {
      hex = hex.replace(/^#/, '');

      if (hex.length === 3) {
        hex = hex.split('').map(char => char + char).join('');
      }

      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      return !raw ? `rgba(${r}, ${g}, ${b}, ${alpha})` : { r, g, b };
    }
    adjustColorForContrast(hexColor, factor = 0.5, alpha = 1) {
      const rgb = this.hexToRgba(hexColor, alpha, true);

      let newR = Math.max(0, Math.floor(rgb.r * factor));
      let newG = Math.max(0, Math.floor(rgb.g * factor));
      let newB = Math.max(0, Math.floor(rgb.b * factor));

      if (newR === 255 && newG === 255 && newB === 255) {
        newR -= (10 * factor);
        newG -= (10 * factor);
        newB -= (10 * factor);
      }

      return `rgba(${newR}, ${newG}, ${newB}, ${alpha})`;
    }
    getBorderWidthLineOptions(item) {
      const chartType = this.getChartType(item.datasets, item.element);
      if (chartType === 'radar') {
        return {
          borderWidth: 2,
          fill: true
        }
      } else if (chartType === 'line') {
        return {
          borderWidth: 1,
        }
      } else if (chartType === 'bar') {
        return {
          borderWidth: 0,
        }
      } else {
        return {
          borderWidth: 2,
        }
      }
    }
    getBorderColorDataset(styles, i) {
      const borderColor = i.color.background;
      const type = i.graphType;
      if (type === 'radar') {
        if (typeof borderColor === 'string') {
          return styles.getPropertyValue(borderColor);
        }
      } else if (type === 'line') {
        return styles.getPropertyValue(borderColor);
      } else {
        return styles.getPropertyValue('--prospace-ui-bg');
      }
    }
    getBackgroundColorDataset(styles, i) {
        const background = i.color.background;
        if (typeof background === 'string') {
          if (i.graphType === 'radar') {
            return this.hexToRgba(styles.getPropertyValue(background), 0.2);
          } else {
            return styles.getPropertyValue(background);
          }
        }
        let baseColors = background;
        const maxColors = baseColors.length;
        let colors = [];
        let derivativeAlphaColors = [];
        let derivativeColors = [];
        const stepColor = 20;
        const stepFactor = 10;
        let alpha = 100;
        let factor = 50;
        const sectors = this.datasets?.length || 0;

        for (let j = 0; j < sectors; j++) {
          const colorIndex = j % maxColors;
          const hexColor = styles.getPropertyValue(background[colorIndex]);
          let rgbaColor;

          if (alpha === 100 && derivativeAlphaColors.length === 0) {
            rgbaColor = this.hexToRgba(hexColor, 100);
            colors.push(rgbaColor);
          } else if (alpha > 0 && alpha < 100 && derivativeAlphaColors.length !== 100 / stepColor * maxColors - maxColors) {
            rgbaColor = this.hexToRgba(hexColor, alpha / 100);
            derivativeAlphaColors.push(rgbaColor)
          } else {
            rgbaColor = this.adjustColorForContrast(hexColor, factor / 100, alpha / 100);
            derivativeColors.push(rgbaColor);
          }
          if (colorIndex === maxColors - 1) {
            alpha -= stepColor;
            if (alpha <= 0) alpha = 100;
          }
          if (derivativeAlphaColors.length === 100 / stepColor * maxColors - maxColors) {
            factor += stepFactor;
          }
        }
        return [...colors, ...derivativeAlphaColors, ...derivativeColors];
      }
}
