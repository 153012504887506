<template>
  <ProSpaceGeoLayout class="reserve-creation">
    <template #top>
      <ProSpaceTopPanel :header="$t('reserveCreateReserve')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            @click="actionUpdate"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
          />
          <IconButtonSettingsColumns @click="showModalSettingsColumns"/>
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #left>
      <ProSpaceLeftPanel
        searchable
        :panelName="$t('reserveShipTo')"
        :searchPlaceholder="$t('searchTxt')"
        @search="handlerSearch"
      >
        <template #center>
          <ProSpaceTree
            ref="tree"
            getNodeMethod="getShipToTree"
            propKey="id"
            propLabel="name"
            defaultSortField="name"
            isNested
            needLocalSearch
            :service="reserveService"
            :prop-parent-id="null"
            :searchFilterModel="['name', 'code']"
            :blocked="selectedItems.length > 0"
            @select="nodeSelect"
            @block="handlerBlockTree"
            @loaded="onLoadedTree"
          >
            <template #prefix="{ node }">
              <ProSpaceTag :text="node.code" type="gray-light" bordered />
            </template>
            <template #empty>
              <div></div>
            </template>
          </ProSpaceTree>
        </template>
      </ProSpaceLeftPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            noMountedRequest
            :searchPlaceholder="$t('searchTxt')"
            :closeTxt="$t('closeTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :unicKeyStorage="reserveService.constructor.name + 'grid'"
            @apply="submitFilter"
            ref="filter-panel"
            :filter-model="['description', 'code', 'grd']"
            :ignore-clear-keys="['shipToId', 'shipFrom']"
          >
            <template #hint> {{ $t("filterBy") }}</template>
            <template #filters="{ filter }">
              <ProSpaceFilterDatesRange
                v-model="filter.bbd"
                :placeholder-value="$t('bbdProductBBD')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterNumberRange
                v-model="filter.discount"
                :placeholder-value="placeholderDiscount"
                :placeholder-filter="$t('bbdProductDiscount')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                @apply="emitFilter"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="reserveService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
              <ProSpaceFilterCheckbox
                v-model="filter.count"
                :label="$t('added')"
                @apply="emitFilter"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            selectionMode="single"
            local-sort
            defaultSortField="bbd"
            :defaultSortOrder="1"
            :clean-records="visibleRecords"
            defaultColumnWidths="300"
            v-bind="gridPropsSettingsColumns"
            @selected-change="selectedHandler"
            @update:localSort="updateSortingRecords"
            @loaded="setDefaultSort"
          >
            <template #columns>
              <Column field="grd" :header="$t('bbdProductGRD')" sortable>
                <template #body="field">
                  <ProSpaceTag
                    :text="field.data.grd"
                    type="gray-light"
                    bordered
                  />
                </template>
              </Column>
              <Column
                field="description"
                :header="$t('bbdProductDescription')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.description">
                    <span class="text-bolder">{{
                      field.data.description
                    }}</span>
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="bbd" :header="$t('bbdProductBBD')" sortable>
                <template #body="field">
                  <ProSpaceDateColumn
                    :date="field.data.bbd"
                    :format="userinfo.dateFormat"
                  />
                </template>
              </Column>
              <Column
                field="discount"
                :header="$t('bbdProductDiscount')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.discount">
                    <span style="font-size: 14px" class="text-bolder">{{ field.data.discount }}%</span>
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column
                field="availableQuantity"
                :header="$t('bbdProductAvailableQuantity')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn style="min-height: 20px">
                    <ProSpaceTag
                      :text="field.data.availableQuantity"
                      type="secondary"
                      style="font-size: 14px; height: 20px"
                    />
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="count" :header="$t('reserveCount')" sortable>
                <template #body="field">
                  <ProSpaceInputNumber
                    v-model="field.data.count"
                    like-text
                    :invalid="field.data.count > field.data.availableQuantity"
                    :min="0"
                    :max="max_value_available"
                    style="width: 80px"
                    @click.stop
                    @update:modelValue="handlerInputCount(field.data)"
                  />
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                v-if="!loading"
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
    </template>
    <template #right>
      <ReserveCreationRightPanel
        :items="getFixSortedSelectedItems"
        :selected-product="selectedProduct"
        :loading="loading"
        @clear="clear"
        @create="actionCreateReserve"
      />
      <ProSpaceFlashMessage
        class="reserve-creation__dialog"
        v-model="showConfirmModel"
        type="default"
        :icon="flashMessage.icon"
        :title="flashMessage.title"
        :applyTxt="flashMessage.apply"
        :cancelTxt="flashMessage.cancel"
        @apply="handlerApplyFlashMessage"
        @cancel="handlerCancelFlashMessage"
      >
        <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
          <div
            class="text-color-gray text-bold"
            style="padding: 0 5px"
            v-html="flashMessage.body"
          />
        </ProSpaceVLayout>
      </ProSpaceFlashMessage>
    </template>
  </ProSpaceGeoLayout>
</template>

<script>
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceRightPanel,
  ProSpaceEmptyGrid,
  ProSpaceDefaultTextColumn,
  ProSpaceIconButton,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpaceTag,
  ProSpaceDateColumn,
  ProSpaceInputNumber,
  ProSpaceFilterDatesRange,
  ProSpaceFilterNumberRange,
  ProSpaceFilterCheckbox,
  ProSpaceFlashMessage,
} from "@prospace/prospace-components-library";
import Column from "primevue/column";
import { BBDProductService } from "../../services/BBDProductService.js";
import { ReserveService } from "../../services/ReserveService.js";
import { mapGetters } from "vuex";
import ReserveCreationRightPanel from "./components/ReserveCreationRightPanel";
import { useLocalFilter } from "@composes";
import _ from "lodash";
import IconButtonSettingsColumns from "@components/settingsColumns/IconButtonSettingsColumns.vue";
import useSettingsColumns from "@composes/useSettingsColumns.js";
export default {
  name: "ReserveCreation",
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMainGrid,
    ProSpaceDots,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceRightPanel,
    ProSpaceEmptyGrid,
    ProSpaceDefaultTextColumn,
    ProSpaceIconButton,
    Column,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpaceTag,
    ProSpaceDateColumn,
    ProSpaceInputNumber,
    ReserveCreationRightPanel,
    ProSpaceFilterDatesRange,
    ProSpaceFilterNumberRange,
    ProSpaceFilterCheckbox,
    ProSpaceFlashMessage,
    IconButtonSettingsColumns
  },
  setup() {
    const { getLocalFilteredItems } = useLocalFilter();
     const {
      showModalSettingsColumns,
      gridPropsSettingsColumns
    } = useSettingsColumns(grid)
    return {
      getLocalFilteredItems,
      showModalSettingsColumns,
      gridPropsSettingsColumns
    };
  },
  data() {
    return {
      BBDProductService: new BBDProductService(),
      reserveService: new ReserveService(),
      records: [],
      visibleRecords: [],
      showConfirmModel: false,
      flashMessage: {
        title: "",
        body: "",
        apply: "",
        cancel: "",
      },
      reserveModel: {
        status: "reserveCreated",
        distributorId: null,
        shipFrom: null,
        shipToCode: null,
        shipToName: null,
        reserveProducts: [],
      },
      selectedProduct: null,
      selectedNode: null,
      loading: false,
      max_value_available: 1000000,
      handlerCancelFlashMessage: () => {},
      sorted: false
    };
  },
  mounted() {
    this.setLoadingGrid(true);
    window.addEventListener("beforeunload", this.beforeUnload);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeUnload);
  },
  beforeRouteLeave(to, from, next) {
    if (this.selectedItems?.length > 0)
      this.handlerBlockLeave(next);
    else next();
  },
  watch: {
    visibleRecords() {
      const grid = this.$refs.grid;
      if (grid) {
        this.$nextTick(() => {
          grid.getCleanRecords();
          grid.checkColumnSize();
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    placeholderDiscount() {
      return `${this.$t('bbdProductDiscount')}, %`
    },
    selectedItems() {
      return this.records.filter(
        (i) => i.count > 0
      );
    },
    customServices() {
      return [
        {
          type: "chips",
          model: "grd",
          label: this.$t("bbdProductGRD"),
          selected: {},
        },
        {
          type: "chips",
          model: "description",
          label: this.$t("bbdProductDescription"),
          selected: {},
        },
        {
          type: "range",
          model: "availableQuantity",
          label: this.$t("bbdProductAvailableReserveQuantity"),
          min: 0,
          max: 99999999,
          selected: {},
        }
      ];
    },
    getFixSortedSelectedItems() {
      return this.selectedItems.sort((a, b) => a['stamp'].localeCompare(b['stamp']));
    },
    mapRecords() {
      const mapRecords = new Map()
      this.records.forEach((i) => {
        mapRecords.set(i.id, i)
      });
      return mapRecords
    }
  },
  methods: {
    beforeUnload(event) {
      if (this.selectedItems?.length > 0) {
        event.preventDefault();
        event.returnValue = true;
      }
    },
    updateTreeGrid() {
      this.$refs.tree.getData();
    },
    async sentReserveCreation() {
      this.reserveModel.reserveProducts = this.selectedItems.map((x) => {
        return {
          grd: x.grd,
          description: x.description,
          bbd: x.bbd,
          discount: x.discount,
          reserveQuantity: x.count,
          bbdProductId: x.id,
        };
      });
      if (this.reserveModel.reserveProducts.length > 0) {
        const create = await this.reserveService["create"](this.reserveModel);
        return create.data;
      }
    },
    handlerInputCount() {
      this.$refs.grid.selectedModel = this.selectedItems.length > 0 ? this.selectedItems : [this.selectedProduct];
    },
    async actionCreateReserve() {
      this.setLoadingGrid(true);
      let saveValues = {};
      this.sentReserveCreation()
        .then((result) => {
          if (!result.success) {
            this.records.forEach((i) => (saveValues[i.id] = i.count));
            this.showErrorFlashMessage();
          } else {
            this.selectedProduct = null;
            this.reserveModel.reserveProducts = [];
            this.showSussessFlashMessage();
            this.clear()
          }
          this.getData(saveValues, result.updatedBBDProducts);
        })
        .catch(() => {
          this.setLoadingGrid(false);
        });
    },
    showErrorFlashMessage() {
      this.showConfirmModel = true;
      this.setFlashMessageText("error");
    },
    showSussessFlashMessage() {
      this.showConfirmModel = true;
      this.setFlashMessageText("success");
    },
    handlerBlockLeave(next) {
      this.showConfirmModel = true;
      this.setFlashMessageText("leave");
      this.handlerApplyFlashMessage = () => {
        this.clear();
        this.$nextTick(() => {
          next();
        });
      };
      this.handlerCancelFlashMessage = () => {
        this.$nextTick(() => {
          next(false);
        });
      };
    },
    handlerBlockTree({ nextNode }) {
      this.showConfirmModel = true;
      this.setFlashMessageText("tree");
      this.handlerApplyFlashMessage = () => {
        this.clear();
        this.$nextTick(() => {
          this.$refs.tree.selectNode(nextNode);
        });
      };
    },
    setFlashMessageText(key) {
      const dist = {
        leave: {
          icon: "flash-warning",
          title: this.$t("reserveCreateLeaveTitle"),
          body: this.$t("reserveCreateLeaveBody"),
          apply: this.$t("accept"),
          cancel: this.$t("reject"),
        },
        tree: {
          icon: "flash-warning",
          title: this.$t("reserveCreateChoiceShipToTitle"),
          body: this.$t("reserveCreateChoiceShipToBody"),
          apply: this.$t("accept"),
          cancel: this.$t("reject"),
        },
        success: {
          icon: "flash-success",
          title: this.$t("reserveCreateSuccessTitle"),
          body: this.$t("reserveCreateSuccessBody"),
          apply: "",
          cancel: this.$t("closeTxt"),
        },
        error: {
          icon: "flash-warning",
          title: this.$t("reserveCreateChangeCountTitle"),
          body: this.$t("reserveCreateChangeCountBody"),
          apply: "",
          cancel: this.$t("closeTxt"),
        },
      };
      const newFlashMessage = dist[key];
      if (!newFlashMessage) {
        throw new Error("Wrong argument");
      }
      this.flashMessage = newFlashMessage;
    },
    handlerSearch(value) {
      this.$refs.tree.handlerSearch(value);
    },
    nodeSelect(node) {
      if (!node || (this.selectedNode && this.selectedNode.key === node.key))
        return;

      this.selectedNode = _.cloneDeep(node);

      this.reserveModel.distributorId = node.distributorId;
      this.reserveModel.distributorGRD = node.soldToName;
      this.reserveModel.distributorGRDCode = node.soldToCode;
      this.reserveModel.shipFrom = node.shipFrom;
      this.reserveModel.shipToCode = node.code;
      this.reserveModel.shipToName = node.name;

      this.selectedProduct = null;

      const filter = { and: [node.shipFrom] };
      this.$refs["filter-panel"].filter["shipFrom"] = filter;
      this.$refs["filter-panel"].submitFilter();
    },
    submitFilter({ filter, search }) {
      if (filter.count && Object.keys(filter.count).length === 0) {
        delete filter.count;
      }
      if (filter.count) {
        filter.count = {
          and: [{ ge: 1 }],
        };
      }
      this.visibleRecords = this.getLocalFilteredItems(this.records, {
        filter,
        search,
      });
      this.$nextTick(() => {
        this.sorted = false
        this.setDefaultSort()
      })
    },
    emitFilter() {
      this.$refs["filter-panel"].submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.$refs["filter-panel"].submitFilter();
    },
    actionUpdate() {
      const saveValues = {};
      this.records.forEach((i) => (saveValues[i.id] = i.count));
      this.getData(saveValues);
    },
    async getData(saveValues, newRecords = null, emitFilter = true) {
      this.setLoadingGrid(true);
      const res = newRecords
        ? { data: { records: newRecords } }
        : await this.BBDProductService.grid();
      const records = res.data.records;
      this.records = records.map((x) => Object.assign(x, { count: 0 }));
      this.visibleRecords = records;
      if (saveValues) {
        records.forEach((i) => {
          if (saveValues[i.id]) i.count = saveValues[i.id];
        });
        emitFilter ? this.emitFilter() : '';
      }
      this.$nextTick(() => {
        this.setLoadingGrid(false);
      });
    },
    clear() {
      this.records.forEach((i) => (i.count = 0));
      const filterPanel = this.$refs["filter-panel"];
      if (filterPanel) {
        filterPanel.filter.count = {};
        filterPanel.submitFilter();
      }
      this.selectedProduct = null;
      this.reserveModel.reserveProducts = [];
      this.$refs.grid.selectedModel = [];
    },
    selectedHandler({data}) {
      let selected = [];
      if (this.selectedItems?.length === 0) {
        selected = [data]
      } else {
        selected = this.selectedItems;
      }
      if (this.selectedProduct?.id === data.id && this.selectedItems.length === 0) {
        this.selectedProduct = null
        selected = selected.filter(i => i.id !== data.id)
      } else {
        this.selectedProduct = data
      }
      this.$refs.grid.selectedModel = selected;
    },
    onLoadedTree() {
      this.actionUpdate();
    },
    setLoadingGrid(state = false) {
      this.loading = state;
      const grid = this.$refs.grid;
      if (grid) {
        grid.loading = state;
      }
    },
    setDefaultSort() {
      this.$nextTick(() => {
        if (!this.sorted && this.visibleRecords?.length > 0) {
          const grid = this.$refs.grid;
          grid ? grid.localSortHandler() : '';
          this.sorted = true;
        }
      });
    },
    updateSortingRecords(sortedRecords) {
      if (sortedRecords && sortedRecords.length > 0) {
        const saveValues = {};
        this.records.forEach((i) => saveValues[i.id] = i.count);
        const visibleRecords = sortedRecords.map(i => this.mapRecords.get(i.id))
        this.visibleRecords = visibleRecords
      }
    }
  },
};
</script>

<style lang="scss">
.reserve-creation {
  &__dialog {
    .prospace-flash-message__title {
      max-width: 70%;
    }
  }
  .main-grid .p-datatable .p-datatable-thead .p-sortable-column {
    padding-left: 10px;
  }
}
</style>
