<template>
  <ProSpaceGeoLayout :routeName="$route.name" isParent>
    <template #top>
      <ProSpaceTopPanel :header="this.$t('newsItems')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <IconButtonSettingsColumns @click="showModalSettingsColumns"/>
          <ProSpaceDots
            :options="wrapperCheckRoles(dotsHeaderOptions)"
            :isSquare="true"
            @action="(action) => handleHeaderDots(action)"
          />
          <ProSpaceButton
            v-if="checkAccess('add-button')"
            type="primary"
            icon="plus"
            :label="`${$t('createItemTxt')} ${$t('newsItemN')}`"
            @click="
              $router.push({
                path: `/newsservice/newsItem`,
                query: {
                  code: this.selectedNode ? this.selectedNode.code : null,
                },
              })
            "
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #left>
      <ProSpaceLeftPanel
        :panelName="$t('topics')"
        searchable
        @search="handlerSearch"
        :searchPlaceholder="$t('searchTxt')"
      >
        <template #center>
          <ProSpaceTree
            ref="tree"
            searchName="name"
            getNodeMethod="treeWithAll"
            propKey="id"
            propLabel="name"
            counterField="unreadCounter"
            defaultSortField="name"
            :service="baseTypeService"
            :prop-parent-id="null"
            :defaultFilter="{
              systemDirectoryCode: { and: ['News Type'] },
              disabled: { and: [false] },
            }"
            @select="nodeSelect"
            @loaded="updateUnreaded"
          >
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
              />
            </template>
          </ProSpaceTree>
        </template>
      </ProSpaceLeftPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            :unicKeyStorage="newsItemService.constructor.name + 'grid'"
            :noMountedRequest="true"
            @apply="submitFilter"
            ref="filterPanel"
            filter-model="name"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
                selectedMultipleClear();
              }
            "
            :ignore-clear-keys="['type']"
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceMultiSelect
                v-if="userinfo.orgLinkType !== 'Mars' && checkAccess('assign-ProSpaceMultiSelect')"
                :localization="$t"
                v-model="filter.assign"
                :service="newsItemService"
                getMethod="getAssignOptions"
                optionLabel="label"
                optionValue="value"
                :placeholderValue="$t('assign')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />

              <ProSpaceFilterDistributor
                v-if="checkAccess('distributors-ProSpaceFilterSearchfield')"
                v-model="filter.newsItemDistributorId"
                :hint="$t('hintFilterDistrNews')"
                @apply="emitFilter"
              />
              <ProSpaceFilterContainsChips
                v-model="filter.name"
                :placeholder-value="$t('newsItemName')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />

              <ProSpaceFilterDateTimeRange
                v-model="filter.publishDate"
                :placeholder-value="$t('newsItemPublishDate')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterSearchfield
                v-if="checkAccess('distributorStructureId-ProSpaceFilterSearchfield')"
                v-model="filter.distributorStructureId"
                :closeTxt="$t('closeTxt')"
                :placeholderValue="$t('branch')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :service="distributorStructureService"
                :txtTitleModal="$t('distrsAndBranches')"
                :txtNameColumn="$t('distributor')"
                get-method="getStructure"
                optionLabel="name"
                optionValue="id"
                mode="tree"
                :hint="$t('hintFilterBranchNews')"
                @apply="emitFilter"
              >
                <template #modal="{model, setModel, applyFn, selectedArray, isWatchMode}">
                  <DistributorHierarchySelectModal
                    :modelValue="model"
                    :service="distributorStructureService"
                    :checkedRows="selectedArray"
                    :title-modal="$t('distrsAndBranches')"
                    get-method="allTree"
                    expandAll
                    :read-only="isWatchMode"
                    @update:modelValue="setModel"
                    @click.stop
                    @apply="applyFn"
                  />
                </template>
              </ProSpaceFilterSearchfield>
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="newsItemService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :service="newsItemService"
            :noMountedRequest="true"
            defaultColumnWidths="500"
            v-bind="gridPropsSettingsColumns"
            @selected-change="selectedHandler"
            @db-click="toItem"
            @loaded="gridLoaded"
          >
            <template #columns>
              <Column
                field="name"
                :header="this.$t('newsItem')"
                sortable
                headerClass="move-sort-head"
                frozen
                class="column-table-header-cell"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.typeName"
                    :status="getStatus(field.data)"
                    :title="field.data.name"
                    :description="`${field.data.message || ''}`"
                    :isRemoveHTMLtags="false"
                    :isHtml="true"
                    @click-on-title="toItem(field.data)"
                  />
                </template>
              </Column>

              <Column
                v-if="checkAccess('distributors-Column')"
                field="distributorName"
                sortable
                :header="$t('distributors')"
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn
                    :title="
                      !isDistrList(field.data)
                        ? field.data.distributorName
                        : $t('distrList')
                    "
                  >
                    <template v-if="!(field.data.isAllDistributors || isDistrList(field.data))">
                      {{ field.data.distributorName }}
                    </template>
                    <template v-else>
                      <ProSpaceAction
                        :text="field.data.isAllDistributors ? `${$t('allTxt')} ${$t('distributors')}` : $t('distrList')"
                        @click="distrListShow(field.data)"
                      />
                    </template>
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="publishDate"
                :header="$t('newsItemPublishDate')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.publishDate"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>

              <Column
                field="createdAt"
                sortable
                :header="$t('createdDate')"
              >
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>

              <Column
                field="tags"
                :header="$t('newsItemTags')"
                sortable
              >
                <template #body="field">
                  <ProSpaceTagsColumn
                    :tags="field.data.tags.split('#').filter((t) => !!t)"
                    :limit="2"
                  />
                </template>
              </Column>

              <Column
                field="actions"
                class="column-end column-end-120"
                frozen alignFrozen="right"
              >
                <template #body="field">
                  <ProSpaceHLayout justify-content="flex-end" :gap="10">
                    <ProSpaceIconsGrid
                      isBookmarkExist
                      :repeatHint="$t('repeatHint')"
                      :clipHint="$t('clipHint')"
                      :exclamationHint="$t('exclamationHint')"
                      :bookmarkHint="$t('bookmarkHint')"
                      :isExclamationExist="field.data.highImportant"
                      :isClipExist="field.data.isAnyAttachment"
                      :isBookmarkActive="
                        isImportantInMount
                          ? field.data.isImportant
                          : checkImportantInMount(field.data.id)
                      "
                      isExclamationActive
                      @bookmarkEvt="setImportant(field.data)"
                    />
                    <ProSpaceDots
                      :options="localWrapperCheckRoles(dotsOptions, field.data)"
                      @action="(action) => handleDots(action, field.data)"
                    ></ProSpaceDots>
                  </ProSpaceHLayout>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
          <DistributorSelectModal
            v-model="showDistributorModal"
            selectionMode="single"
            readOnly
            :modalTitle="$t('listDistributors')"
            :service="distributorGridService"
            :getMethod="distributorGetMethod"
            :defaultFilter="distributorGridFilter"
            :filterModel="distributorFilterModel"
            :filterPrefix="distributorFilterPrefix"
          />
        </template>
      </ProSpaceGeoLayout>
      <ProSpaceHistoryModal
        ref="history"
        v-model="showHistoryModal"
        modelName="NewsItemApiModel"
        @close="closeHistory"
      />
    </template>
    <template #right>
      <NewsItemPanel
        v-if="hasSelect"
        ref="right-panel"
        :service="newsItemService"
        :options="dotsOptions"
        :filterAccessDots="localWrapperCheckRoles"
        :checkAccess="checkAccess"
        @clear="selectedMultipleClear"
        @edit="editItem"
        @delete="deleteItem"
        @history="showHistory"
        @refresh="actionUpdate"
      />
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingConfirmation')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteNewsItem', { name:  deletingModel.name })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import statusAccessMixin from "../../../../../Frontends/Common/utils/elementAccess/statusAccessMixin";
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceModal,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceTagsColumn,
  ProSpaceDefaultTextColumn,
  ProSpaceEmptyGrid,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpaceVLayout,
  ProSpaceFilterContainsChips,
  ProSpaceFilterDatesRange,
  ProSpaceFilterDateTimeRange,
  ProSpaceDropdown,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceInputSwitch,
  ProSpaceInputText,
  ProSpaceIconsGrid,
  ProSpaceHLayout,
  ProSpaceDateTimeColumn,
  ProSpaceAction,
  ProSpaceSimpleGrid,
  ProSpaceFilterSearchfield,
  ProSpaceMultiSelect,
  ProSpaceFlashMessage
} from "@prospace/prospace-components-library";
import { NewsItemService } from "../../services/NewsItemService.js";
import { NewsItemDistributorService } from "../../services/NewsItemDistributorService.js";
import { NewsItemUserImportantService } from "../../services/NewsItemUserImportantService.js";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import { DistributorService } from "@masterdata/services/DistributorService.js";
import Column from "primevue/column";
import NewsItemPanel from "./components/NewsItemPanel";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import NewsItemModalImport from "./components/NewsItemModalImport";
import DistributorSelectModal from "@modals/DistributorSelectModal";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
import DistributorHierarchySelectModal from "@modals/DistributorHierarchySelectModal.vue";
const _ = require("lodash");
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
import ProSpaceFilterDistributor from "@filters/ProSpaceFilterDistributor.vue";
import { YesNoService } from "@base/services/YesNoService.js";
import IconButtonSettingsColumns from "@components/settingsColumns/IconButtonSettingsColumns.vue";
import useSettingsColumns from "@composes/useSettingsColumns.js";
export default {
  mixins: [accessMixin, statusAccessMixin],
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMainGrid,
    ProSpaceDots,
    ProSpaceModal,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceTagsColumn,
    ProSpaceDefaultTextColumn,
    ProSpaceEmptyGrid,
    NewsItemPanel,
    ProSpaceHistoryModal,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpaceVLayout,
    ProSpaceFilterContainsChips,
    ProSpaceFilterDatesRange,
    ProSpaceFilterDateTimeRange,
    ProSpaceDropdown,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceInputSwitch,
    ProSpaceInputText,
    ProSpaceIconsGrid,
    NewsItemModalImport,
    ProSpaceHLayout,
    ProSpaceDateTimeColumn,
    ProSpaceAction,
    ProSpaceSimpleGrid,
    ProSpaceFilterSearchfield,
    DistributorSelectModal,
    DistributorHierarchySelectModal,
    ProSpaceFilterDistributor,
    ProSpaceMultiSelect,
    ProSpaceFlashMessage,
    IconButtonSettingsColumns
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic()
     const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    const {
      showModalSettingsColumns,
      gridPropsSettingsColumns
    } = useSettingsColumns(grid)
    return {
      grid, filterPanel,
      setStatistic, distActions, selectedMultipleGridHandler,
      hasSelect, selectedMultipleClear,
      showModalSettingsColumns,
      gridPropsSettingsColumns
    }
  },
  data() {
    let statusAccessConfig = {
      "Content manager": {
        newsDraft: "CREATOR_OR_NONE",
        other: "NONE",
      },
      "Content manager plus": {
        newsDraft: "ALIVE",
        newsModer: "ALIVE",
        other: "NONE",
      },
    };

    return {
      showModal: false,
      showImportModal: false,
      showHistoryModal: false,
      // Grid Panel
      selectedNode: {},

      gridDefaultFilter: {},

      // Service
      newsItemService: new NewsItemService(),
      NewsItemDistributorService: new NewsItemDistributorService(),
      newsItemUserImportantService: new NewsItemUserImportantService(),
      baseTypeService: new BaseTypeService(),
      distributorService: new DistributorService(),
      distributorStructureService: new DistributorStructureService(),

      //InlinePanel
      masterId: null,
      isEdit: false,
      isCreate: false,
      modalModel: {},

      // Multi filter
      modelFastFilter: {
        label: this.$t("allTxt"),
        field: "disabled",
        value: false,
      },

      // Important
      importantSaver: null,
      isImportantSaveRun: false,
      isImportantInMount: false,
      isImportantLocalState: [],

      //Distr modal
      showDistributorModal: false,
      distributorGridFilter: null,
      statusAccessConfig: {
        "delete-option": statusAccessConfig,
        "edit-option": statusAccessConfig,
      },
      distributorGridService: null,
      distributorFilterModel: null,
      distributorFilterPrefix: "",
      distributorGetMethod: "grid",

      deletingModel: null,
      showDeleteModal: false,
    };
  },
  watch: {
    lastChangedNotification: {
      handler(val) {
        if (val && val.messageData && val.messageData.Title !== "News") return;
        this.updateUnreaded();
      },
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
      lastChangedNotification: "notifications/lastChanged",
      notificationMessages: "notifications/notificationMessages",
      getCounter: "notifications/getCounter",
    }),
    newsNotifications() {
      return this.notificationMessages.filter(
        (n) => n.messageData.Title === "News"
      );
    },
    unreadNewsNotifications() {
      return this.newsNotifications.filter((n) => n.readed === false);
    },
    deletedNewsNotifications() {
      return this.newsNotifications.filter(
        (n) =>
          n.messageData.NotificationType === "newsPublishDeleted" &&
          n.readed === false
      );
    },
    customServices() {
      let filters = [
        {
          service: new NewsItemService(),
          getMethod: "getStatuses",
          optionValue: "value",
          optionLabel: "text",
          type: "checkboxes",
          model: "status",
          label: this.$t("newsItemStatus"),
          selected: {},
          needSelectAll: true,
        },
        {
          type: "chips",
          model: "tags",
          label: this.$t("newsItemTags"),
          selected: {},
        },
        {
          type: "chips",
          model: "message",
          label: this.$t("newsItemMessage"),
          selected: {},
        },
        {
          service: new YesNoService(),
          getMethod: "get",
          optionValue: "value",
          optionLabel: "label",
          type: "checkboxes",
          model: "highImportant",
          label: this.$t("newsItemHighImportant"),
          selected: {},
          needSelectAll: true,
        },
        {
          service: new NewsItemService(),
          getMethod: "getStatuses",
          optionValue: "value",
          optionLabel: "text",
          type: "checkboxes",
          model: "status",
          label: this.$t("newsItemStatus"),
          selected: {},
          needSelectAll: true,
        },
        {
          service: new YesNoService(),
          getMethod: "get",
          optionValue: "value",
          optionLabel: "label",
          type: "checkboxes",
          model: "readConfirmationRequired",
          label: this.$t("newsItemReadConfirmation"),
          selected: {},
          needSelectAll: true,
        },
        {
          service: new YesNoService(),
          getMethod: "get",
          optionValue: "value",
          optionLabel: "label",
          type: "checkboxes",
          model: "isAllDistributors",
          label: `${this.$t('allTxt')} ${this.$t('distributors')}`,
          selected: {},
          needSelectAll: true,
        },
        {
          service: new YesNoService(),
          getMethod: "get",
          optionValue: "value",
          optionLabel: "label",
          type: "checkboxes",
          model: "IsPersonal",
          label: this.$t('newsItemIsPersonal'),
          selected: {},
          needSelectAll: true,
        },
        {
          service: new YesNoService(),
          getMethod: "get",
          optionValue: "value",
          optionLabel: "label",
          type: "checkboxes",
          model: "isAnyAttachment",
          label: this.$t("documentIsAnyAttachment"),
          selected: {},
          needSelectAll: true,
        },
        {
          type: "dates-range",
          model: "dueDate",
          label: this.$t("newsItemDueDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];

      if (this.checkAccess("distributorStructureCode-customServices"))
        filters.push({
          type: "chips",
          model: "distributorStructureCode",
          label: this.$t("distributorStructureCodeFilial"),
          selected: {},
        });

      if (this.checkAccess("metainfo-by-block"))
        filters.push(
          {
            type: "chips",
            model: "createdBy",
            label: this.$t("createdByTxt"),
            selected: {},
          },
          {
            type: "chips",
            model: "updatedBy",
            label: this.$t("updatedByTxt"),
            selected: {},
          }
        );

      filters.push(
        {
          type: "dates-range-time",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range-time",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        }
      );

      return filters;
    },
    dotsHeaderOptions() {
      return [
        {
          name: "export_csv",
          text: this.$t("export_csv"),
          icon: "upload",
          liteRef: "export-csv-option",
        },
        {
          name: "export_xlsx",
          text: this.$t("export_xlsx"),
          icon: "upload",
          liteRef: "export-xlsx-option",
        },
      ];
    },
    dotsOptions() {
      let res = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
          liteRef: "edit-option",
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
          liteRef: "delete-option",
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
          liteRef: "history-option",
        },
      ];
      if (this.modelFastFilter.label === this.$t("deleted")) {
        return res.filter(
          (option) => option.name !== "edit" && option.name !== "delete"
        );
      }
      return res;
    },
    fastFilters() {
      return [
        { label: this.$t("allTxt"), field: "disabled", value: false },
        {
          label: this.$t("highImportants"),
          value: [
            { field: "disabled", value: false },
            { field: "highImportant", value: true },
          ],
        },
        {
          label: this.$t("newsModerTxt"),
          value: [
            { field: "disabled", value: false },
            { field: "status", value: "newsModer" },
          ],
          isHidden: !this.checkAccess("newsModer-FastFilters"),
        },
        {
          label: this.$t("checkeds"),
          value: [
            { field: "disabled", value: false },
            { field: "isImportant", value: true },
          ],
        },
        {
          label: this.$t("drafts"),
          value: [
            { field: "disabled", value: false },
            { field: "status", value: "newsDraft" },
          ],
          isHidden: !this.checkAccess("newsDraft-FastFilters"),
        },
        {
          label: this.$t("deleted"),
          field: "disabled",
          value: true,
          isHidden: !this.checkDeleteAccess(),
        },
      ];
    },
  },
  mounted() {
    let ff = this.trySetRouteFastFilter();
    let lt = this.trySetRouteLeftTree();
    if (ff || lt) {
      this.filterPanel.submitFilter(true);
    }
    this.loadRoleAccess();
    window.addEventListener("beforeunload", this.beforeUnload);
    this.saveIsImportant(true);
    this.importantSaver = setInterval(() => this.saveIsImportant(true), 10000);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeUnload);
    clearInterval(this.importantSaver);
  },
  beforeRouteLeave() {
    this.saveIsImportant();
  },
  methods: {
    trySetRouteFastFilter() {
      let params = this.$route.params;
      if (params.fastFilter) {
        let fastFilter = this.fastFilters.find(
          (x) => x.label === params.fastFilter
        );
        if (fastFilter) {
          const filterPanel = this.filterPanel;
          this.modelFastFilter = fastFilter;
          filterPanel.fastFilter = fastFilter;
          return true;
        }
      }
    },
    trySetRouteLeftTree() {
      let params = this.$route.params;
      if (params.leftTree) {
        const node = JSON.parse(params.leftTree);
        this.$refs.tree.setLocalStorage(node);
        this.nodeSelect(node, true);
        return true;
      }
    },
    gridLoaded(event) {
      this.isImportantInMount = true;
      this.isImportantLocalState = [...this.grid.records];
      this.updateGridUnreaded();
    },
    checkImportantInMount(id) {
      const test = this.isImportantLocalState.find((elem) => elem.id === id);
      return test.isImportant;
    },
    beforeUnload(event) {
      this.saveIsImportant();
    },
    handlerSearch(value) {
      this.$refs.tree.handlerSearch(value);
    },
    searchTree(search) {
      this.$refs.tree.search = search;
    },
    getDefaultFilter() {
      return {
        disabled: { and: [false] },
      };
    },
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.code;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    toItem(data) {
      const url = `/newsservice/newsItem/${data.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    submitFilter({ filter, search }) {
      this.saveIsImportant().then(() => {
        const distributorFilterExists = filter &&
          filter.newsItemDistributorId &&
          filter.newsItemDistributorId.or &&
          !!filter.newsItemDistributorId.or.length

        const distributorStructureFilterExists = filter &&
          filter.distributorStructureCode &&
          filter.distributorStructureCode.or &&
          !!filter.distributorStructureCode.or.length;

        const distributorStructureIdFilterExists = filter &&
          filter.distributorStructureId &&
          filter.distributorStructureId.or &&
          !!filter.distributorStructureId.or.length;

        if (distributorFilterExists && !distributorStructureFilterExists && !distributorStructureIdFilterExists) {
          filter.or = {
            or: [
              {
                and: [
                  { or: filter.newsItemDistributorId.or.map(d => { return { newsItemDistributorId: d }}) },
                  { or: [{ newsItemDistributorDisabled: false }] },
                ]
              },
              { isAllDistributors: true }
            ]
          };

          delete filter.newsItemDistributorId;
        }
        else if (!distributorFilterExists && distributorStructureFilterExists && !distributorStructureIdFilterExists) {
          filter.or = {
            or: [
              {
                and: [
                  { or: filter.distributorStructureCode.or.map(ds => { return { distributorStructureCode: ds }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              }
            ]
          };

          delete filter.distributorStructureCode;
        }
        else if (distributorFilterExists && distributorStructureFilterExists && !distributorStructureIdFilterExists) {
          filter.and = {
            or: [
              {
                and: [
                  { or: filter.newsItemDistributorId.or.map(d => { return { newsItemDistributorId: d }}) },
                  { or: [{ newsItemDistributorDisabled: false }] },
                ]
              },
              { isAllDistributors: true }
            ],
            and: [
              {
                and: [
                  { or: filter.distributorStructureCode.or.map(ds => { return { distributorStructureCode: ds }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              }
            ]
          };

          delete filter.newsItemDistributorId;
          delete filter.distributorStructureCode;
        }
        else if (distributorFilterExists && !distributorStructureFilterExists && distributorStructureIdFilterExists) {
          filter.and = {
            or: [
              {
                and: [
                  { or: filter.newsItemDistributorId.or.map(d => { return { newsItemDistributorId: d }}) },
                  { or: [{ newsItemDistributorDisabled: false }] },
                ]
              },
              { isAllDistributors: true }
            ],
            and: [
              {
                and: [
                  { or: filter.distributorStructureId.or.map(ds => { return { distributorStructureId: ds }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              }
            ]
          };

          delete filter.newsItemDistributorId;
          delete filter.distributorStructureId;
        }
        else if (!distributorFilterExists && distributorStructureFilterExists && distributorStructureIdFilterExists) {
          filter.and = {
            or: [
              {
                and: [
                  { or: filter.distributorStructureId.or.map(d => { return { distributorStructureId: d }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              },
              { isAllDistributors: true }
            ],
            and: [
              {
                and: [
                  { or: filter.distributorStructureCode.or.map(ds => { return { distributorStructureCode: ds }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              }
            ]
          };

          delete filter.distributorStructureId;
          delete filter.distributorStructureCode;
        }
        else if (distributorFilterExists && distributorStructureFilterExists && distributorStructureIdFilterExists) {
          filter.and = {
            or: [
              {
                and: [
                  { or: filter.newsItemDistributorId.or.map(d => { return { newsItemDistributorId: d }}) },
                  { or: [{ newsItemDistributorDisabled: false }] },
                ]
              },
              { isAllDistributors: true }
            ],
            and: [
              {
                and: [
                  { or: filter.distributorStructureCode.or.map(ds => { return { distributorStructureCode: ds }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              }
            ],
            and: [
              {
                and: [
                  { or: filter.distributorStructureId.or.map(ds => { return { distributorStructureId: ds }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              }
            ]
          };

          delete filter.newsItemDistributorId;
          delete filter.distributorStructureCode;
          delete filter.distributorStructureId;
        }
        else if (!distributorFilterExists && !distributorStructureFilterExists && distributorStructureIdFilterExists) {
          filter.or = {
            or: [
              {
                and: [
                  { or: filter.distributorStructureId.or.map(ds => { return { distributorStructureId: ds }}) },
                  { or: [{ distributorStructureDisabled: false }] },
                ]
              }
            ]
          };

          delete filter.distributorStructureId;
        }
        const assignFilterExists = filter &&
          filter.assign &&
          filter.assign.or &&
          !!filter.assign.or.length;

        if (assignFilterExists) {
          let assignOptions = filter.assign.or;
          this.newsItemService.setAssignOptions(assignOptions)

          delete filter.assign;
        } else {
          this.newsItemService.clearAssignOption();
        }

        this.grid.submitFilter(filter, search);
      });
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    actionUpdate() {
      this.saveIsImportant().then(() => {
        this.grid.getRecords(true);
        if (this.$refs["right-panel"]) {
          this.$refs["right-panel"].update();
        }
      });
    },
    async actionExport(format) {
      this.loadingHandler(true);
      await this.newsItemService.export(
        this.grid.getRecords(false, true),
        format,
        this.newsItemService.assignOptions
      );
      this.loadingHandler(false);
    },
    actionImport() {
      this.showImportModal = true;
    },
    loadingHandler(val) {
      this.grid.loading = val;
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    editItem(model) {
      if (!model) return;
      this.toItem(model);
    },
    changeItem() {
      let method;
      if (this.isCreate) {
        method = "create";
      }
      if (this.isEdit) {
        method = "update";
      }
      this.newsItemService[method](this.modalModel).then(() => {
        this.actionUpdate();
      });
    },
    deleteItem(model) {
      if (!model) return;
      this.deletingModel = model;
      this.showDeleteModal = true;
    },
    applyDelete() {
      this.newsItemService.delete(this.deletingModel).then(() => {
        this.actionUpdate();
        this.selectedMultipleClear();
        this.deletingModel = null;
      });
    },
    getStatus(data) {
      if (!data) {
        return { type: "default", text: "none" };
      }
      return this.newsItemService.getStatusByValue(data.status);
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    handleHeaderDots(action) {
      if (action === "export_csv") {
        this.actionExport("CSV");
      } else if (action === "export_xlsx") {
        this.actionExport("XLSX");
      }
    },
    nodeSelect(value, skipFilter = false) {
      if (this.selectedNode && this.selectedNode.key === value.key) return;
      var filter = value.id === 0 ? { and: [] } : { and: [value.code] };
      this.filterPanel.filter["type"] = filter;
      if (!skipFilter) this.filterPanel.submitFilter();
      this.selectedNode = _.cloneDeep(value);
    },
    updateDeleted() {
      let deletedIds = this.deletedNewsNotifications
        .filter((un) => !un.messageData.DeletedInfo)
        .map((un) => un.messageData.Record.Id);
      let extraIds = this.unreadNewsNotifications
        .filter((un) => un.messageData.DeletedInfo)
        .flatMap((un) => un.messageData.DeletedInfo.flatMap((x) => x.Ids));
      deletedIds = deletedIds.concat(extraIds);
      let toRead = this.unreadNewsNotifications.map((un) => {
        if (deletedIds.includes(un.messageData.Record.Id))
          return un.messageData.Record;
        else if (un.messageData.UnreadInfo) {
          let r = un.messageData.UnreadInfo.map((x) => {
            let ids = x.Ids.filter((x) => deletedIds.includes(x));
            if (ids.length > 0) {
              return {
                Type: x.Type,
                Ids: ids,
              };
            }
            return null;
          }).filter((x) => x);
          if (r.length > 0) return r;
        }
        return null;
      });
      toRead.forEach((n) => {
        if (Array.isArray(n))
          n.forEach((x) => x.Ids.forEach((id) => this.read(id, x.Type)));
        else if (n) this.read(n.Id, n.Type);
      });
    },
    read(id, type) {
      this.$store.dispatch("notifications/updateReadedRecord", {
        title: "News",
        id: Number(id),
      });
      this.$store.dispatch("notifications/updateReadedRecord", {
        title: "News",
        id: "newsPublishStaticId",
        realId: Number(id),
        type: type,
        groupUpdate: true,
      });
      this.$store.dispatch("notifications/updateReadedRecord", {
        title: "News",
        id: "newsNewStaticId",
        realId: Number(id),
        type: type,
        groupUpdate: true,
      });
    },
    updateUnreaded(updateGrid = true) {
      this.updateDeleted();
      let allNode = this.getAllNode();
      if (allNode)
        allNode.unreadCounter = this.getCounter(this.unreadNewsNotifications);
      let nodes = this.getRealNodes();
      if (nodes)
        nodes.forEach((n) => {
          var c = this.unreadNewsNotifications.filter(
            (un) => un.messageData.Record.Type === n.code
          ).length;
          this.unreadNewsNotifications
            .filter((un) => un.messageData.UnreadInfo)
            .forEach((un) => {
              let nui = un.messageData.UnreadInfo.find(
                (ui) => ui.Type === n.code
              );
              if (nui) c += nui.Ids.length;
            });
          n.unreadCounter = c;
        });
      if (updateGrid) this.updateGridUnreaded(false);
    },
    updateGridUnreaded(updateTree = true) {
      if (updateTree) this.updateUnreaded(false);
      let unreaded =
        !this.selectedNode.id || this.selectedNode.id === 0
          ? this.unreadNewsNotifications.filter(
              (x) => !x.messageData.UnreadCount
            )
          : this.unreadNewsNotifications.filter(
              (u) => u.messageData.Record.Type === this.selectedNode.code
            );
      let ids = unreaded.map((u) => u.messageData.Record.Id);
      if (!Array.isArray(ids)) ids = [];

      this.unreadNewsNotifications
        .filter((un) => un.messageData.UnreadInfo)
        .forEach((un) => {
          if (this.selectedNode.code !== "all") {
            let nui = un.messageData.UnreadInfo.find(
              (ui) => ui.Type === this.selectedNode.code
            );
            if (nui) ids = ids.concat(nui.Ids);
          } else {
            un.messageData.UnreadInfo.forEach((x) => (ids = ids.concat(x.Ids)));
          }
        });
      if (this.grid && this.grid.records) {
        this.grid.records.forEach((r) => (r.unread = ids.includes(r.id)));
      }
    },
    getAllNode() {
      if (this.$refs.tree && this.$refs.tree.nodes)
        return this.$refs.tree.nodes.find((n) => n.id === 0);
    },
    getRealNodes() {
      if (this.$refs.tree && this.$refs.tree.nodes)
        return this.$refs.tree.nodes.filter((n) => n.id !== 0);
    },
    setImportant(news) {
      this.isImportantInMount = false;

      this.isImportantLocalState.forEach((el) => {
        if (el.id === news.id) {
          el.isImportant = !el.isImportant;
        }
      });
      let importantUpdates = this.getUpdatedImportantNews();

      if (this.checkNewImportantSet(news, importantUpdates)) {
        importantUpdates.push({
          newsItemId: news.id,
          securityUserCode: this.userinfo.code,
          isImportantSet: news.isImportant,
        });
      } else {
        importantUpdates = importantUpdates.filter(
          (x) => x.newsItemId != news.id
        );
      }

      localStorage["important news"] = JSON.stringify(importantUpdates);
    },
    checkNewImportantSet(news, importantUpdates) {
      return !importantUpdates.some((x) => x.newsItemId == news.id);
    },
    saveIsImportant(isBackground) {
      if (this.isImportantSaveRun)
        return new Promise((resolve, reject) => {
          resolve();
        });

      if (!isBackground) this.loadingHandler(true);

      let importantUpdates = this.getUpdatedImportantNews();
      if (importantUpdates.length === 0)
        return new Promise((resolve, reject) => {
          resolve();
        });

      this.isImportantSaveRun = true;

      const toCreate = importantUpdates.filter((x) => x.isImportantSet);
      const toDelete = importantUpdates.filter((x) => !x.isImportantSet);

      let promises = [];

      if (toCreate.length > 0)
        promises.push(this.newsItemUserImportantService.createMany(toCreate));
      if (toDelete.length > 0)
        promises.push(this.newsItemUserImportantService.deleteMany(toDelete));

      return Promise.all(promises)
        .then(() => {
          let importantUpdatesAfter = this.getUpdatedImportantNews();
          importantUpdatesAfter = importantUpdatesAfter.filter(
            (x) =>
              !importantUpdates.some(
                (u) =>
                  u.newsItemId == x.newsItemId &&
                  u.securityUserCode == x.securityUserCode
              )
          );
          localStorage["important news"] = JSON.stringify(
            importantUpdatesAfter
          );

          if (!isBackground) this.loadingHandler(false);

          this.isImportantSaveRun = false;
        })
        .catch(() => {
          if (!isBackground) this.loadingHandler(false);

          this.isImportantSaveRun = false;
        });
    },
    getUpdatedImportantNews() {
      let importantNews = localStorage["important news"];
      return importantNews ? JSON.parse(localStorage["important news"]) : [];
    },
    isDistrList(item) {
      return item.distributorName === "-1";
    },
    distrListShow(item) {
      if(item.isAllDistributors) {
        this.distributorGridFilter = { disabled: { and: [false] } };
        this.distributorGridService = this.distributorService;
        this.distributorGetMethod = "grid";
        this.distributorFilterModel = ['code', 'name'];
        this.distributorFilterPrefix = "";
      } else {
        this.distributorGridFilter = {
          newsItemId: { and: [item.id] },
          disabled: { and: [false] },
        };
        this.distributorGridService = this.newsItemService;
        this.distributorGetMethod = "getDistributors";
        this.distributorFilterModel = ['distributorCode', 'distributorName'];
        this.distributorFilterPrefix = "distributor";
      }
      this.$nextTick(() => {
        this.showDistributorModal = true;
      });
    },
    localWrapperCheckRoles(dotsOptions, data) {
      return this.wrapperStatusCheckRoles(
        this.wrapperCheckRoles(dotsOptions, data),
        data
      );
    },
  },
};
</script>
<style lang="scss">
.-tree {
  .radio-tabs {
    margin-top: 1px;
    .radio-tab:nth-child(2) {
      display: none !important;
    }
    &-body {
      margin: 10px;
    }
  }

  &__left-panel {
    border: 1px solid var(--prospace-ui-border-color);
  }
}
</style>
