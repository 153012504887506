<template>
  <div>
    <ProSpaceGeoLayout>
      <template #top>
        <ProSpaceTopPanel :header="layoutName" back @back="backHandler" />
      </template>
      <template #center>
        <ProSpaceGeoLayout>
          <template #center>
            <ProSpaceRadioButtonSecondary
              v-if="model"
              :tabs="tabs"
              v-model="modelTab"
              @select="handlerSelectTab"
            >
              <ProSpaceAction
                icon="double-check"
                :text="$t('activateWorkflow')"
                @click="saveAll"
                :disabled="v$.model.$invalid"
              />
              <template #Overview>
                <ProSpaceGeoLayout is-template :template-center="true">
                  <template #center>
                    <ProSpaceTabs
                      :tabs="overviewTabs"
                      :loading="loading"
                      :withoutMarginBottom="true"
                      @select-tab="handlerSelectTab"
                    >
                      <ProSpaceAction
                        icon="check"
                        :text="$t('save')"
                        @click="savePart(saveFields.Overview)"
                        :disabled="v$.model.$invalid"
                      />
                      <template v-slot:tab-1>
                        <ProSpaceVLayout>
                          <ProSpaceGeoLayout class="geo-row">
                            <template v-slot:left>
                              <ProSpaceTextBlock
                                :top="$t('mainInformation')"
                                :bottom="
                                  $t('workflowMainInformationInformation')
                                "
                                :gap="10"
                                type="third"
                                break-word
                              />
                            </template>
                            <template v-slot:center>
                              <ProSpaceVLayout :gap="20">
                                <ProSpaceInputText
                                  :label="$t('name')"
                                  v-model="model.name"
                                  :placeholder="$t('workflowName')"
                                  :invalid="v$.model.name.$error"
                                  :invalid-messages="v$.model.name.$errors"
                                  @blur="v$.model.name.$touch"
                                />
                                <ProSpaceInputText
                                  :label="$t('description')"
                                  v-model="model.description"
                                  :placeholder="$t('workflowDescription')"
                                  :invalid="v$.model.description.$error"
                                  :invalid-messages="v$.model.description.$errors"
                                  @blur="v$.model.description.$touch"
                                />
                              </ProSpaceVLayout>
                            </template>
                            <template v-if="v$.model.name.$error || v$.model.description.$error" v-slot:right>
                              <ProSpaceWarning />
                            </template>
                          </ProSpaceGeoLayout>
                        </ProSpaceVLayout>
                      </template>
                    </ProSpaceTabs>
                  </template>
                </ProSpaceGeoLayout>
              </template>
              <template #Steps>
                <ProSpaceGeoLayout
                  is-template
                  :template-center="true"
                  :template-right="true"
                >
                  <template #center>
                    <ProSpaceTabs :tabs="stepsTabs" :loading="loading" @select-tab="handlerSelectTab">
                      <ProSpaceHLayout :gap="10" :is-inline-w="true">
                        <ProSpaceAction
                          icon="plus"
                          :text="$t('add')"
                          @click="showAddStepModal()"
                        />
                        <ProSpaceAction
                          icon="check"
                          :text="$t('save')"
                          @click="saveSteps"
                        />
                      </ProSpaceHLayout>
                      <template v-slot:tab-1>
                        <ProSpaceMainGrid
                          v-if="masterId"
                          :key="masterId"
                          :id="masterId"
                          :default-filter="getDefaultFilter()"
                          class="in-block-grid"
                          ref="grid"
                          :isInline="true"
                          :service="workflowItemService"
                          @loaded="gridLoaded"
                          getMethod="getMainGridByWorkflowId"
                          style="height: calc(100vh - 180px)"
                          @selected-change="stepSelect"
                          defaultSortField="stepNo"
                          :defaultSortOrder="1"
                        >
                          <template #columns>
                            <Column
                              field="stepNo"
                              :header="$t('step')"
                              :sortable="true"
                            >
                              <template #body="field">
                                <ProSpaceDefaultTextColumn>
                                  {{ field.data.stepNo }}
                                </ProSpaceDefaultTextColumn>
                              </template>
                            </Column>
                            <Column
                              field="name"
                              :header="$t('name')"
                              :sortable="true"
                            >
                              <template #body="field">
                                <ProSpaceDefaultTextColumn>
                                  {{ field.data.name }}
                                </ProSpaceDefaultTextColumn>
                              </template>
                            </Column>
                            <Column
                              field="status"
                              :header="$t('status')"
                              :sortable="true"
                            >
                              <template #body="field">
                                <ProSpaceDefaultTextColumn>
                                  {{ field.data.status }}
                                </ProSpaceDefaultTextColumn>
                              </template>
                            </Column>
                            <Column
                              field="submitStep"
                              :header="$t('onSuccess')"
                              :sortable="true"
                            >
                              <template #body="field">
                                <ProSpaceDefaultTextColumn>
                                  {{ field.data.submitStep }}
                                </ProSpaceDefaultTextColumn>
                              </template>
                            </Column>
                            <Column
                              field="rejectStep"
                              :header="$t('onFailure')"
                              :sortable="true"
                            >
                              <template #body="field">
                                <ProSpaceDefaultTextColumn>
                                  {{ field.data.rejectStep }}
                                </ProSpaceDefaultTextColumn>
                              </template>
                            </Column>
                            <Column
                              field="actions"
                              class="column-end column-end-30"
                            >
                              <template #body="field">
                                <ProSpaceDots
                                  :options="dotsOptions"
                                  :sub-options="subDotsOptions"
                                  @action="
                                    (action) =>
                                      handleDots(
                                        [...dotsOptions, ...subDotsOptions],
                                        action,
                                        field.data
                                      )
                                  "
                                />
                              </template>
                            </Column>
                          </template>
                          <template #empty>
                            <ProSpaceEmptyGrid
                              icon="empty-grid"
                              title="No selected fields"
                            />
                          </template>
                        </ProSpaceMainGrid>
                      </template>
                    </ProSpaceTabs>
                  </template>
                  <template #right>
                    <ProSpaceRightPanel
                      :header="stepHeader"
                      no-border
                      :key="selectedStep.id"
                    >
                      <template v-slot:center>
                        <ProSpacePanelBlock
                          :header="{ title: $t('stepOptions') }"
                        >
                          <template v-slot>
                            <ProSpacePanelBlockList
                              :list="stepPanelContent"
                              :itemsPerPage="stepPanelContent.length"
                            />
                          </template>
                        </ProSpacePanelBlock>
                      </template>
                    </ProSpaceRightPanel>
                  </template>
                </ProSpaceGeoLayout>
              </template>
            </ProSpaceRadioButtonSecondary>
          </template>
        </ProSpaceGeoLayout>
      </template>
    </ProSpaceGeoLayout>
    <ProSpaceModal
      v-model="showModalAddStep"
      width="410"
      :icon="isEdit ? 'edit' : 'plus'"
      :disabledApply="disabledStepModalRule"
      @apply="applyStepModal"
      @cancel="onStepModalCancel"
      :cancelTxt="$t('cancelInf')"
      :applyTxt="$t('applyTxt')"
      class="modal-steps-edit"
    >
      <template #title>
        {{ isEdit ? $t("editItemTxt") : "" }}
        {{ isCreate ? $t("createItemTxt") : "" }} {{ $t("step") }}
      </template>
      <ProSpaceTabs :tabs="stepTabs" ref="step-tabs">
        <template v-slot:tab-1>
          <ProSpaceVLayout :gap="20">
            <ProSpaceInputText
              :label="$t('stepNumber')"
              v-model="modalStepModel.stepNo"
              :placeholder="$t('stepNumber')"
              :disabled="true"
            ></ProSpaceInputText>
            <ProSpaceInputText
              :label="$t('name')"
              v-model="modalStepModel.name"
              :placeholder="$t('stepName')"
            ></ProSpaceInputText>
            <ProSpaceInputText
              :label="$t('status')"
              v-model="modalStepModel.status"
              :placeholder="$t('stepStatus')"
            ></ProSpaceInputText>
            <ProSpaceDropdown
              :label="$t('stepType')"
              v-model="modalStepModel.stepType"
              getMethod="getStepTypes"
              :placeholder="$t('selectStepType')"
              :service="workflowItemTypeService"
              @change="stepTypeChange"
            ></ProSpaceDropdown>
            <ProSpaceInputText
              :label="$t('parameters')"
              v-model="modalStepModel.parameters"
              :placeholder="$t('parametersStep')"
            ></ProSpaceInputText>
          </ProSpaceVLayout>
        </template>
        <template v-slot:tab-2>
          <ProSpaceVLayout :gap="20" isInlineH>
            <ProSpaceInputText
              v-if="modalStepModel.stepType == 10"
              :label="$t('condition')"
              v-model="modalStepModel.filter"
              :placeholder="$t('filter')"
            ></ProSpaceInputText>
            <RecipientTree
              v-if="modalStepModel.stepType == 20"
              :recipientFilter="approverFilter"
              :recipientTreeService="recipientTreeService"
              @updated="updateApproverFilter"
            />
            <ProSpaceDivider type="dashed" />
            <ProSpaceHLayout :gap="10">
              <ProSpaceDropdown
                showClear
                :label="$t('onSuccessF')"
                v-model="modalStepModel.submitStep"
                :placeholder="$t('selectStep')"
                :options="steps.filter((s) => s.value != modalStepModel.stepNo)"
                style="height: 52px; width: calc(50% - 5px)"
              />
              <ProSpaceDropdown
                showClear
                :label="$t('onFailureF')"
                v-model="modalStepModel.rejectStep"
                optionLabel="label"
                optionValue="value"
                :placeholder="$t('selectStep')"
                :options="steps.filter((s) => s.value != modalStepModel.stepNo)"
                style="height: 52px; width: calc(50% - 5px)"
              />
            </ProSpaceHLayout>
          </ProSpaceVLayout>
        </template>
      </ProSpaceTabs>
    </ProSpaceModal>
  </div>
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingConfirmation')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteWorkflowItem', { name: deletingModel.name })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import {
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceRadioButtonSecondary,
  ProSpaceModal,
  ProSpaceTextBlock,
  ProSpaceMainGrid,
  ProSpaceDropdown,
  ProSpaceHLayout,
  ProSpaceTabs,
  ProSpaceRightPanel,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceVLayout,
  ProSpaceEmptyGrid,
  ProSpaceDots,
  ProSpaceDivider,
  ProSpaceDefaultTextColumn,
  ProSpaceWarning,
  ProSpaceFlashMessage
} from "@prospace/prospace-components-library";
import { WorkflowService } from "../../services/WorkflowService.js";
import { WorkflowItemService } from "../../services/WorkflowItemService.js";
import { WorkflowItemTypeService } from "../../services/WorkflowItemTypeService.js";
import { RecipientTreeService } from "../../services/RecipientTreeService.js";
import RecipientTree from "./RecipientTree.vue";
import Column from "primevue/column";
import { faPlus, faPen } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  useStatistic
} from "@composes"
export default {
  components: {
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceRadioButtonSecondary,
    ProSpaceModal,
    ProSpaceTextBlock,
    ProSpaceMainGrid,
    Column,
    ProSpaceDropdown,
    RecipientTree,
    ProSpaceEmptyGrid,
    ProSpaceHLayout,
    ProSpaceTabs,
    ProSpaceRightPanel,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceVLayout,
    ProSpaceDots,
    ProSpaceDivider,
    ProSpaceDefaultTextColumn,
    ProSpaceWarning,
    ProSpaceFlashMessage
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  validations() {
    return {
      model: {
        name: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        description: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
      },
    };
  },
  data() {
    return {
      showModalAddStep: false,
      // Tabs
      modelTab: null, // Перовое предустановленное значение

      // Common
      layoutName: "",
      loayoutNameProp: "name",
      model: null,

      // Service
      workflowService: new WorkflowService(),
      workflowItemService: new WorkflowItemService(),
      workflowItemTypeService: new WorkflowItemTypeService(),
      recipientTreeService: new RecipientTreeService(),
      getMethod: "get",
      masterId: null,
      recordCounted: null,

      // Modal
      modalModel: { name: "", description: "" },
      modalStepModel: {
        name: "",
        status: "",
        stepType: 10,
        submitStep: null,
        rejectStep: null,
        filter: "",
        approverFilter: "",
        workflowSettingId: 0,
        workflowSettingName: "",
      },
      isEdit: false,
      isCreate: false,

      // SaveFields
      saveFields: {
        Overview: ["name", "description"],
        Claims: ["claims"],
      },
      selectedStep: {},
      steps: [],
      approverFilter: JSON.parse('{"To":{"Custom":[],"Roles":[],"Users":[]}}'),
      loading: false,

      deletingModel: null,
      showDeleteModal: false
    };
  },
  computed: {
    dotsOptions() {
      return [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
        },
      ];
    },
    subDotsOptions() {
      return [
        {
          name: "up",
          text: this.$t("up"),
          icon: "arrow-up",
          method: this.moveUp,
        },
        {
          name: "down",
          text: this.$t("down"),
          icon: "arrow-down",
          method: this.moveDown,
        },
      ];
    },
    tabs() {
      return [
        { label: this.$t("overview"), value: "Overview", systemName: "overview" },
        { label: this.$t("steps"), value: "Steps", systemName: "steps" },
      ];
    },
    stepTabs() {
      return [{ name: "Overview", isActive: true }, { name: "Detail" }];
    },
    overviewTabs() {
      return [{ name: this.$t("workflow"), systemName: "workflow" }];
    },
    stepsTabs() {
      return [{ name: this.$t("steps"), counter: this.recordCounted, systemName: "steps" }];
    },
    icon() {
      return { faPlus, faPen };
    },
    stepHeader() {
      return {
        title: this.model.name,
        id: this.model.id,
        status: this.getStatus(this.model.status),
        showCounter: false,
      };
    },
    stepPanelContent() {
      return [
        [
          {
            title: this.$t("stepNumber"),
            text: this.selectedStep.stepNo,
          },
        ],
        [
          {
            title: this.$t("name"),
            text: this.selectedStep.name,
          },
        ],
        [
          {
            title: this.$t("status"),
            text: this.selectedStep.status,
          },
        ],
        [
          {
            title: this.$t("stepType"),
            text: this.getStepTypeAlias(this.selectedStep.stepType),
          },
        ],
        [
          {
            title: this.$t("condition"),
            text: this.selectedStep.filter,
          },
        ],
        [
          {
            title: this.$t("onSuccess"),
            text: this.selectedStep.submitStep,
          },
        ],
        [
          {
            title: this.$t("onFailure"),
            text: this.selectedStep.rejectStep,
          },
        ],
        [
          {
            title: this.$t("parameters"),
            text: this.selectedStep.parameters,
          },
        ],
      ];
    },
    // check step model
    disabledStepModalRule() {
      let validateModel = this.modalStepModel;
      return (
        !validateModel.name ||
        validateModel.stepType === null ||
        // validateModel.stepType == 10 ||
        // validateModel.stepType == 20 ||
        (validateModel.stepType == 10 && validateModel.filter.length == 0)
      );
    },
  },
  mounted() {
    this.modelTab = this.tabs[0];
    this.getData();
  },
  methods: {
    handlerSelectTab(value) {
      const tab = value.selectedTab || value
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: tab.systemName
      })
    },
    getStepTypeAlias(stepType) {
      if (stepType) {
        return this.workflowItemTypeService.workflowItemTypes.find(
          (x) => x.value == stepType
        ).label;
      }
    },
    handleDots(allDotOptions, action, item) {
      var option = allDotOptions.find((o) => o.name === action);
      option.method(item);
    },

    getDefaultFilter() {
      return {
        workflowSettingId: { and: [this.masterId] },
        disabled: { and: [false] },
      };
    },

    getStatus(status) {
      if (!status) return;
      return {
        type: "success",
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
      };
    },
    stepSelect(val) {
      if (val) {
        this.selectedStep = val;
      } else {
        this.selectedStep = {};
      }
    },

    gridLoaded() {
      let me = this;
      me.recordCounted = me.$refs.grid.records.length;
      if (me.recordCounted > 0) {
        me.$refs.grid.selectedModel = me.$refs.grid.records[0];
        me.steps = me.$refs.grid.records
          .map((s) => {
            return { value: s.stepNo, label: `Step ${s.stepNo}. ${s.name}` };
          })
          .sort((a, b) => a.value - b.value);
        me.selectedStep = me.$refs.grid.records[0];
      }
    },
    async getData() {
      this.masterId = parseInt(this.$route.params.id);
      if (this.masterId === undefined || this.masterId === NaN) {
        // redirect to 404
        return;
      }
      const result = await this.workflowService[this.getMethod](this.masterId);
      this.model = result.data;
      this.layoutName = this.model[this.loayoutNameProp];
    },
    saveAll() {
      this.loading = true;
      this.workflowService.setActive(this.model).then((result) => {
        this.getData();
        this.loading = false;
      });
    },
    savePart(fields) {
      this.loading = true;
      this.workflowService.setPart(fields).then(() => {
        this.getData();
        this.loading = false;
      });
    },
    createItem() {
      this.workflowService.create(this.modalModel);
    },
    deleteRecord(id) {
      // this.model.claims = this.model.claims.filter((claim) => claim.id !== id);
    },
    sendGridModal(model) {
      console.log(model);
    },
    backHandler() {
      this.$router.go(-1);
    },
    editItem(model) {
      if (!model) return;
      this.isEdit = true;
      this.isCreate = false;
      this.modalStepModel = _.cloneDeep(model);
      this.approverFilter = model.approverFilter
        ? JSON.parse(model.approverFilter)
        : this.approverFilter;
      this.recipientTreeService.set(this.approverFilter);
      this.showModalAddStep = true;
    },
    // Move step up. Save step references with respect to step orders
    moveUp(model) {
      let me = this;
      let prevRec = me.$refs.grid.records.filter(
        (r) => r.stepNo == model.stepNo - 1
      )[0];
      if (prevRec) {
        let submitToDecrement = me.$refs.grid.records.filter(
          (r) => r.submitStep == model.stepNo
        );
        let rejectToDecrement = me.$refs.grid.records.filter(
          (r) => r.rejectStep == model.stepNo
        );
        let submitToIncrement = me.$refs.grid.records.filter(
          (r) => r.submitStep == prevRec.stepNo
        );
        let rejectToIncrement = me.$refs.grid.records.filter(
          (r) => r.rejectStep == prevRec.stepNo
        );

        model.stepNo -= 1;
        prevRec.stepNo += 1;

        submitToIncrement.forEach((element) => {
          element.submitStep += 1;
        });
        submitToDecrement.forEach((element) => {
          element.submitStep -= 1;
        });
        rejectToIncrement.forEach((element) => {
          element.rejectStep += 1;
        });
        rejectToDecrement.forEach((element) => {
          element.rejectStep -= 1;
        });
        me.$refs.grid.records = me.$refs.grid.records.sort(
          (a, b) => a.stepNo - b.stepNo
        );
        me.steps = me.$refs.grid.records
          .map((s) => {
            return { value: s.stepNo, label: `Step ${s.stepNo}. ${s.name}` };
          })
          .sort((a, b) => a.value - b.value);
      }
    },
    // Move step down. Save step references with respect to step orders
    moveDown(model) {
      let me = this;
      var nextRec = me.$refs.grid.records.filter(
        (r) => r.stepNo == model.stepNo + 1
      )[0];
      if (nextRec) {
        let submitToDecrement = me.$refs.grid.records.filter(
          (r) => r.submitStep == nextRec.stepNo
        );
        let rejectToDecrement = me.$refs.grid.records.filter(
          (r) => r.rejectStep == nextRec.stepNo
        );
        let submitToIncrement = me.$refs.grid.records.filter(
          (r) => r.submitStep == model.stepNo
        );
        let rejectToIncrement = me.$refs.grid.records.filter(
          (r) => r.rejectStep == model.stepNo
        );

        model.stepNo += 1;
        nextRec.stepNo -= 1;

        submitToIncrement.forEach((element) => {
          element.submitStep += 1;
        });
        submitToDecrement.forEach((element) => {
          element.submitStep -= 1;
        });
        rejectToIncrement.forEach((element) => {
          element.rejectStep += 1;
        });
        rejectToDecrement.forEach((element) => {
          element.rejectStep -= 1;
        });
        me.$refs.grid.records = me.$refs.grid.records.sort(
          (a, b) => a.stepNo - b.stepNo
        );
        me.steps = me.$refs.grid.records
          .map((s) => {
            return { value: s.stepNo, label: `Step ${s.stepNo}. ${s.name}` };
          })
          .sort((a, b) => a.value - b.value);
      }
    },
    showAddStepModal() {
      this.isEdit = false;
      this.isCreate = true;
      //get max stepNo value from workflow steps
      let val =
        this.$refs.grid.records.length > 0
          ? Math.max(...this.$refs.grid.records.map((o) => o.stepNo))
          : 0;
      this.modalStepModel = {
        name: "",
        status: "",
        stepType: 10,
        submitStep: null,
        rejectStep: null,
        filter: "",
        stepNo: ++val,
        workflowSettingId: this.model.id,
        workflowSettingName: this.model.name,
      };
      this.approverFilter = JSON.parse(
        '{"To":{"Custom":[],"Roles":[],"Users":[]}}'
      );
      this.recipientTreeService.set(this.approverFilter);
      this.showModalAddStep = true;
    },
    // save step
    applyStepModal() {
      this.modalStepModel.approverFilter = JSON.stringify(this.approverFilter);
      //if current workflow not draft, create new draft workflow
      if (this.model.status != "DRAFT") {
        this.createDraftModel();
      } else if (this.isCreate) {
        const item = _.cloneDeep(this.modalStepModel);
        item.localId = new Date().getTime();
        this.$refs.grid.records.push(item);
      } else {
        const findId = this.modalStepModel.id ? "id" : "localId";
        let index = this.$refs.grid.records.findIndex(
          (i) => i[findId] === this.modalStepModel[findId]
        );

        this.$refs.grid.records[index] = this.modalStepModel;
      }
      this.stepSelect(this.modalStepModel);
      this.onStepModalCancel();
    },
    editStepRequest() {
      if (this.modalStepModel.id) {
        this.workflowItemService["edit"](this.modalStepModel).then((res) => {
          const model = res.data.data[0];
          let gridRec = this.$refs.grid.records.find((r) => r.id == model.id);
          gridRec = model;
          this.gridLoaded();
        });
      } else {
        let item = this.$refs.grid.records.find(
          (i) => i.localId === this.modalStepModel
        );
        item = this.modalStepModel;
      }
    },
    createDraftModel() {
      const model = this.modalStepModel;
      let draftModel = this.model;
      draftModel.status = "DRAFT";
      draftModel.id = 0;
      //create draft copy of workflow
      this.workflowService["createDraft"](draftModel).then(
        (draftWorkflowResult) => {
          //create copy of steps
          let steps = this.$refs.grid.records;
          steps.push(model);
          steps.forEach((s) => {
            s.id = 0;
            s.workflowSettingId = draftWorkflowResult.data.id;
            s.workflowSettingName = draftWorkflowResult.data.name;
          });
          this.workflowItemService["create"](steps).then((draftStepsResult) => {
            //reload steps grid
            this.$router.push(
              `/workflow/workflowsetting/${draftWorkflowResult.data.id}`
            );
            this.model = draftWorkflowResult.data;
            this.masterId = draftWorkflowResult.data.id;
          });
        }
      );
    },
    onStepModalCancel() {
      this.modalStepModel = {
        name: "",
        status: "",
        stepType: 10,
        submitStep: 1,
        rejectStep: 1,
        filter: "",
        approverFilter: "",
        workflowSettingId: 0,
        workflowSettingName: "",
      };
      if (this.$refs["step-tabs"]) {
        this.$refs["step-tabs"].setTabs(0);
      }
    },
    // save all steps
    async saveSteps() {
      this.loading = true;
      //if current workflow not draft, create new draft workflow
      if (this.model.status != "DRAFT") {
        let draftModel = this.model;
        draftModel.status = "DRAFT";
        draftModel.id = 0;
        draftModel.code = null;
        //create draft copy of workflow
        this.workflowService["createDraft"](draftModel)
          .then((draftWorkflowResult) => {
            //create copy of steps
            let steps = this.$refs.grid.records;
            steps.forEach((s) => {
              s.id = 0;
              s.code = null;
              s.workflowSettingId = draftWorkflowResult.data.id;
              s.workflowSettingName = draftWorkflowResult.data.name;
            });
            this.workflowItemService["create"](steps).then(
              (draftStepsResult) => {
                //reload steps grid
                this.$router.push(
                  `/workflow/workflowsetting/${draftWorkflowResult.data.id}`
                );
                this.model = draftWorkflowResult.data;
                this.masterId = draftWorkflowResult.data.id;
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.workflowItemService
          .edit(this.$refs.grid.records)
          .then((result) => {
            this.$refs.grid.records = result.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    // delete steps
    deleteItem(model) {
      if (!model) return;
      this.deletingModel = model;
      this.showDeleteModal = true;
    },
    applyDelete() {
      //if current workflow not draft, create new draft workflow
      if (this.model.status != "DRAFT") {
        let draftModel = this.model;
        draftModel.status = "DRAFT";
        draftModel.id = 0;
        //create draft copy of workflow
        this.workflowService["createDraft"](draftModel).then(
          (draftWorkflowResult) => {
            //create copy of steps
            let steps = this.$refs.grid.records.filter((s) => s.id != this.deletingModel.id);
            steps.forEach((s) => {
              s.id = 0;
              s.workflowSettingId = draftWorkflowResult.data.id;
              s.workflowSettingName = draftWorkflowResult.data.name;
              s.stepNo = s.stepNo > this.deletingModel.stepNo ? --s.stepNo : s.stepNo;
            });
            //reload steps grid
            this.workflowItemService["create"](steps).then(
              (draftStepsResult) => {
                this.$router.push(
                  `/workflow/workflowsetting/${draftWorkflowResult.data.id}`
                );
                this.model = draftWorkflowResult.data;
                this.masterId = draftWorkflowResult.data.id;
              }
            );
          }
        );
      } else {
        const filterId = this.deletingModel.id ? "id" : "localId";
        this.$refs.grid.records = this.$refs.grid.records.filter(
          (i) => i[filterId] !== this.deletingModel[filterId]
        );
        this.$refs.grid.records.forEach((i) => {
          if (i.stepNo > this.deletingModel.stepNo) {
            --i.stepNo;
          }
        });
      }
    },
    updateApproverFilter(data) {
      this.approverFilter = data;
    },
    // no next steps in terminal steps
    stepTypeChange() {
      if (
        this.modalStepModel.stepType == 30 ||
        this.modalStepModel.stepType == 40
      ) {
        this.modalStepModel.submitStep = null;
        this.modalStepModel.rejectStep = null;
      }
    },
  },
};
</script>
<style lang="scss">
.modal-steps-edit {
  .prospace-tree {
    overflow: auto;
     max-height: 250px;
    .p-tree {
      overflow-y: hidden !important;
    }
  }
}
.radio-tabs-body {
  .main-grid.in-block-grid {
    .p-datatable.p-datatable-scrollable {
      background: none;
      height: 100%;

      .p-datatable-table {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        .p-datatable-thead {
          margin-left: 5px;
          border-radius: 5px;
          box-shadow: none;

          tr {
            box-shadow: none;
          }
        }

        .p-datatable-tbody {
          tr {
            box-shadow: none;
            position: relative;
            box-shadow: 0px 0px 6px rgba(51, 51, 51, 0.07);
          }
        }
      }
    }
  }
}
</style>
