<template>
  <ProSpaceGeoLayout :routeName="$route.name">
    <template #top>
      <ProSpaceTopPanel :header="this.$t('integrationLogs')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <IconButtonSettingsColumns @click="showModalSettingsColumns"/>
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #left>
      <ProSpaceLeftPanel
        class="basetype-tree__left-panel"
        :panelName="$t('topics')"
        searchable
        :searchPlaceholder="$t('searchTxt')"
        @search="handlerSearch"
      >
        <template #center>
          <ProSpaceTree
            ref="tree"
            :searchPlaceholder="$t('searchTxt')"
            :service="baseTypeService"
            searchName="name"
            getNodeMethod="treeWithAll"
            propKey="id"
            propLabel="name"
            :default-filter="getDefaultFilter()"
            :prop-parent-id="null"
            defaultSortField="name"
            @select="nodeSelect"
          />
        </template>
      </ProSpaceLeftPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :searchPlaceholder="$t('searchTxt')"
            :closeTxt="$t('closeTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :noMountedRequest="true"
            :fastFilters="fastFilters"
            :unicKeyStorage="integrationLogService.constructor.name + 'grid'"
            @apply="submitFilter"
            ref="filterPanel"
            :filter-model="[
              'fileName',
              'code',
            ]"
            :ignore-clear-keys="['interfaceId']"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
                selectedMultipleClear()
              }
            "
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceMultiSelect
                :localization="$t"
                v-model="filter.typeName"
                :service="baseTypeService"
                getMethod="grid"
                optionLabel="name"
                optionValue="name"
                :default-filter="{
                  disabled: false,
                  systemDirectoryName: 'Interface type',
                }"
                :placeholderValue="$t('integrationLogTypeName')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :searchPlaceholder="$t('searchTxt')"
                @apply="emitFilter"
              />
              <ProSpaceMultiSelect
                :localization="$t"
                v-model="filter.Direction"
                :service="directionService"
                getMethod="get"
                optionLabel="text"
                optionValue="direction"
                :placeholderValue="$t('integrationLogDirection')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :searchPlaceholder="$t('searchTxt')"
                @apply="emitFilter"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="integrationLogService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            selectionMode="single"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :service="integrationLogService"
            :noMountedRequest="true"
            v-bind="gridPropsSettingsColumns"
            @selected-change="selectedHandler"
          >
            <template #columns>
              <Column
                field="gFID"
                :header="this.$t('integrationLogCode')"
                sortable
                headerClass="move-sort-head"
                style="min-width: 200px; margin-right: 5px"
                frozen
                class="column-table-header-cell"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :status="getStatus(field.data.status)"
                    :title="field.data.code"
                    :description="`${field.data.fileName || ''}`"
                    :no-hover="true"
                  />
                </template>
              </Column>

              <Column
                field="interfaceName"
                :header="$t('integrationLogInterfaceName')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.interfaceName }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="typeName"
                :header="$t('integrationLogTypeName')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.typeName }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="direction"
                :header="$t('integrationLogDirection')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ directionService.find(field.data.direction) }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="createdAt" sortable :header="$t('createdDate')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column
                field="startProcess"
                :header="$t('integrationLogStartProcess')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.startProcess"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>

              <Column
                field="endProcess"
                :header="$t('integrationLogEndProcess')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.endProcess"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>

              <Column
                field="errorCode"
                :header="$t('integrationLogErrorCode')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.errorCode }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="tryCount"
                :header="$t('integrationLogTryCount')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.tryCount }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="actions"
                class="column-end column-end-30"
                frozen alignFrozen="right"
              >
                <template #body="field">
                  <ProSpaceDots
                    :options="dotsOptions"
                    @action="(action) => handleDots(action, field.data)"
                  ></ProSpaceDots>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
      <ProSpaceModal
        v-model="showModal"
        width="410"
        :disabledApply="false"
        :title="isEdit ? $t('editItemTxt') : $t('createItemTxt')"
        :icon="isEdit ? 'edit' : 'plus'"
        @apply="changeItem"
        :cancelTxt="$t('cancelInf')"
        :applyTxt="$t('applyTxt')"
      >
        <ProSpaceVLayout :gap="20"> </ProSpaceVLayout>
      </ProSpaceModal>
      <ProSpaceHistoryModal
        ref="history"
        v-model="showHistoryModal"
        modelName="IntegrationLogApiModel"
        @close="closeHistory"
      />
    </template>
    <template #right>
      <IntegrationLogPanel
        v-if="hasSelect"
        ref="right-panel"
        :service="integrationLogService"
        :options="dotsOptions"
        @clear="selectedMultipleClear"
        @skip="skip"
        @restart="restart"
        @downloadLog="downloadLog"
        @downloadFile="downloadFile"
      />
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceModal,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceDateTimeColumn,
  ProSpaceDefaultTextColumn,
  ProSpaceEmptyGrid,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpaceVLayout,
  ProSpaceFilterContainsChips,
  ProSpaceFilterDatesRange,
} from "@prospace/prospace-components-library";
import { IntegrationLogService } from "../../services/IntegrationLogService.js";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService.js";
import { StatusService } from "../../services/StatusService.js";
import { DirectionService } from "../../services/DirectionService.js";
import { FileService } from "../../../../../Frontends/ProSpace/services/FileService.js";
import Column from "primevue/column";
import IntegrationLogPanel from "./components/IntegrationLogPanel";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
const _ = require("lodash");
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue";
import IconButtonSettingsColumns from "@components/settingsColumns/IconButtonSettingsColumns.vue";
import useSettingsColumns from "@composes/useSettingsColumns.js";
export default {
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    ProSpaceModal,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceDateTimeColumn,
    ProSpaceDefaultTextColumn,
    ProSpaceEmptyGrid,
    IntegrationLogPanel,
    ProSpaceHistoryModal,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpaceVLayout,
    ProSpaceFilterContainsChips,
    ProSpaceFilterDatesRange,
    IconButtonSettingsColumns
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic()
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      selectedData,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    const {
      showModalSettingsColumns,
      gridPropsSettingsColumns
    } = useSettingsColumns(grid)
    return {
      grid, filterPanel,
      setStatistic, distActions,
      selectedMultipleGridHandler, selectedData,
      hasSelect, selectedMultipleClear,
      showModalSettingsColumns,
      gridPropsSettingsColumns
    }
  },
  data() {
    return {
      showModal: false,
      showHistoryModal: false,

      gridDefaultFilter: {},

      // Service
      integrationLogService: new IntegrationLogService(),
      baseTypeService: new BaseTypeService(),
      directionService: new DirectionService(),
      statusService: new StatusService(),
      fileService: new FileService(),

      //InlinePanel
      isEdit: false,
      isCreate: false,
      modalModel: {},

      // Multi filter
      modelFastFilter: {
        label: this.$t("allTxt"),
        field: "disabled",
        value: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    customServices() {
      return [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        },
        {
          type: "checkboxes",
          service: this.statusService,
          getMethod: "get",
          model: "status",
          label: this.$t("status"),
          optionValue: "status",
          optionLabel: "text",
          selected: {},
        },
        {
          type: "chips",
          model: "code",
          label: this.$t("integrationLogCode"),
          selected: {},
        },
        {
          type: "chips",
          model: "messageId",
          label: this.$t("integrationLogMessageId"),
          selected: {},
        },
        {
          type: "chips",
          model: "errorCode",
          label: this.$t("integrationLogErrorCode"),
          selected: {},
        },
        {
          optionValue: "value",
          type: "range",
          model: "tryCount",
          label: this.$t("integrationLogTryCount"),
          selected: {},
        },
        {
          type: "chips",
          model: "createdBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "chips",
          model: "updatedBy",
          label: this.$t("updatedByTxt"),
          selected: {},
        },
        {
          type: "dates-range-time",
          model: "createdAt",
          label: this.$t("createdAtTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range-time",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
    dotsOptions() {
      let res = [
        {
          name: "skip",
          text: this.$t("skipItemTxt"),
          icon: "ban",
          method: this.skip,
        },
        {
          name: "restart",
          text: this.$t("restartItemTxt"),
          icon: "history",
          method: this.restart,
        },
        {
          name: "downloadLog",
          text: this.$t("downloadLogTxt"),
          icon: "download",
          method: this.downloadLog,
        },
        {
          name: "downloadFile",
          text: this.$t("downloadFileTxt"),
          icon: "download",
          method: this.downloadFile,
        },
      ];
      return res;
    },
    fastFilters() {
      return [
        {
          label: this.$t("allTxt"),
          field: "disabled",
          value: false,
        },
        {
          label: this.$t("integrationLogNewTxt"),
          value: [
            { field: "status", value: "NEW" },
            { field: "disabled", value: false },
          ],
        },
        {
          label: this.$t("integrationLogCompleteTxt"),
          value: [
            { field: "status", value: "COMPLETE" },
            { field: "disabled", value: false },
          ],
        },
        {
          label: this.$t("integrationLogSkipTxt"),
          value: [
            { field: "status", value: "SKIP" },
            { field: "disabled", value: false },
          ],
        },
        {
          label: this.$t("integrationLogSuspendedTxt"),
          value: [
            { field: "status", value: "SUSPENDED" },
            { field: "disabled", value: false },
          ],
        },
        {
          label: this.$t("integrationLogErrorTxt"),
          value: [
            { field: "status", value: "ERROR" },
            { field: "disabled", value: false },
          ],
        },
      ];
    },
  },
  methods: {
    handlerSearch(value) {
      this.$refs.tree.handlerSearch(value);
    },
    getDefaultFilter() {
      return {
        disabled: { and: [false] },
        systemDirectoryName: { and: ["Interface name"] },
      };
    },
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.name;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    toItem(data) {
      const url = `/integration/integrationlog/${data.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.emitFilter();
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    actionUpdate() {
      this.$refs.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    actionUpload() {
      this.integrationLogService.export(
        this.grid.getRecords(false, true, true)
      );
      this.grid.loading = false;
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    skip(model) {
      if (!model) return;
      this.modalModel = _.cloneDeep(model);
      this.modalModel.status = "SKIP";
      this.integrationLogService["update"](this.modalModel).then(() => {
        this.actionUpdate();
      });
    },
    restart(model) {
      if (!model) return;
      this.modalModel = _.cloneDeep(model);
      this.modalModel.status = "NEW";
      this.integrationLogService["update"](this.modalModel).then(() => {
        this.actionUpdate();
      });
    },
    downloadLog(model) {
      this.fileService.getFileToken(model.logFileGFID).then((response) => {
        if (response != null) {
          //второй запрос скачивает файл
          this.fileService.downloadFile(response.data).then((fileResponse) => {
            //TODO: check error
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(fileResponse.data);
            let name = fileResponse.fileName;
            link.download = decodeURI(name).replace("%2b", "+");
            link.click();
          });
        } else {
          //TODO: ERROR
        }
      });
    },
    downloadFile(model) {
      this.fileService.getFileToken(model.gfid).then((response) => {
        if (response != null) {
          //второй запрос скачивает файл
          this.fileService.downloadFile(response.data).then((fileResponse) => {
              if(!fileResponse)
                return;

            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(fileResponse.data);
              let name = fileResponse.fileName;
            link.download = decodeURI(name).replace("%2b", "+");
            link.click();
          });
        } else {
          //TODO: ERROR
        }
      });
    },
    getStatus(status) {
      if (!status) return;
      let s = this.statusService.search(status);
      let result =
        JSON.parse(JSON.stringify(s)) &&
        JSON.parse(JSON.stringify(s)).length > 0
          ? JSON.parse(JSON.stringify(s))[0]
          : {
              text: status[0].toUpperCase() + status.toLowerCase().slice(1),
              type: "default",
            };
      return result;
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    nodeSelect(value) {
      if (this.selectedNode && this.selectedNode.key === value.key) return;
      var filter = value.id === 0 ? { and: [] } : { and: [value.id] };
      this.selectedMultipleClear()
      this.filterPanel.filter["interfaceId"] = filter;
      this.filterPanel.submitFilter();
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/main.scss";
.basetype-tree {
  .radio-tabs {
    margin-top: 1px;
    .radio-tab:nth-child(2) {
      display: none !important;
    }
    &-body {
      margin: 10px;
    }
  }

  &__left-panel {
    border: 1px solid var(--prospace-ui-border-color);
  }
}
</style>
