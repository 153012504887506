<template>
  <Chart style="overflow:hidden; padding-top: 10px;"
    :type="type"
    :data="data"
    :options="options"
    :width="0"
    :height="0"
  />
</template>

<script>
import Chart from "primevue/chart";
export default {
  name: "DashboardChart",
  components: {
    Chart,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss">
</style>
