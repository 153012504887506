<template>
  <ProSpaceGeoLayout class="competence" v-if="model">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler">
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout
        :is-templated="true"
        :template-center="true"
        :template-right="true"
        :routeName="$route.name"
      >
        <template #center>
          <ProSpaceTabs
            v-model="modelTab"
            :tabs="overviewTabs"
            :loading="loading"
            :refLoading="refLoading"
            without-margin-bottom
            @select-tab="handlerSelectTab"
          >
            <ProSpaceHLayout :gap="10">
              <ProSpaceAction
                v-if="isCreation || isDraft"
                ref="saveDraft-ProSpaceAction"
                :disabled="disabledDraftSave"
                :text="$t('saveDraft')"
                @click="saveDraft"
              />
              <ProSpaceAction
                v-if="isCreation || isDraft"
                ref="publish-ProSpaceAction"
                :disabled="disabledPublish"
                icon="check"
                :text="$t('publish')"
                @click="publish"
              />
              <ProSpaceAction
                v-if="!(isCreation || isDraft)"
                ref="save-ProSpaceAction"
                :disabled="disabledSave"
                icon="check"
                :text="$t('save')"
                @click="save"
              />
            </ProSpaceHLayout>
            <template v-slot:tab-1>
              <ProSpaceVLayout>
                <!-- Дистрибьютор -->
                <ProSpaceGeoLayout
                  v-if="checkAccess('distributorBlock-ProSpaceGeoLayout')"
                  class="geo-row"
                >
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('distributorNameTxt')"
                      :bottom="$t('distributorInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="20"
                    >
                      <ProSpaceSearchfield
                        style="width: calc(50% - 5px)"
                        ref="distributor-ProSpaceSearchfield"
                        v-model="model.distributorId"
                        optionLabel="name"
                        optionValue="id"
                        getMethod="grid"
                        filterModel="name"
                        icon="list-check"
                        :label="$t('distributor')"
                        :inputPlaceholder="$t('distributorEditPlaceholder')"
                        :searchPlaceholder="$t('searchTxt')"
                        :cancelTxt="$t('cancelInf')"
                        :applyTxt="$t('select')"
                        :txtTitleModal="$t('selectDistributor')"
                        :displayValue="model.distributorName"
                        :service="distributorService"
                        :columns="columns"
                        :fastFilters="[]"
                        :defaultFilter="{ disabled: { and: [false] } }"
                        :invalid="v$.model.distributorId.$error"
                        :invalid-messages="v$.model.distributorId.$errors"
                        :txtFilterHint="$t('filterBy')"
                        @blur="v$.model.distributorId.$touch"
                        @apply="handlerApplySearchfield($event, 'distributor')"
                      >
                        <template #filters="{ filter }">
                          <ProSpaceCustomFilterPanel
                            :customTxt="$t('custom')"
                            :customFilterTxt="$t('customFilterTxt')"
                            :clearFilterTxt="$t('clearFilterTxt')"
                            :selectAllTxt="$t('selectAllTxt')"
                            :closeTxt="$t('closeTxt')"
                            :applyTxt="$t('applyTxt')"
                            :fromTxt="$t('fromTxt')"
                            :toTxt="$t('toTxt')"
                            :services="distributorFilterService"
                            @apply="
                              (model) =>
                                customFilterApply(
                                  filter,
                                  model,
                                  'distributor-ProSpaceSearchfield'
                                )
                            "
                          />
                        </template>
                        <template #columns>
                          <Column
                            field="name"
                            sortable
                            headerClass="move-sort-head"
                            :header="$t('distributor')"
                            style="
                              min-width: 450px;
                              max-width: 450px;
                              margin-right: 5px;
                            "
                          >
                            <template #body="field">
                              <ProSpaceTableHeaderCell
                                v-if="field.data"
                                show-image
                                :id="field.data.code"
                                :status="{
                                  text: $t('distributor'),
                                  type: 'blue',
                                }"
                                :img-src="field.data.logoFileContent"
                                :title="field.data.name"
                                :description="`${field.data.mailAddress || ''}`"
                              />
                            </template>
                          </Column>
                          <Column
                            field="phoneNumber"
                            sortable
                            :header="$t('distributorPhoneNumber')"
                          >
                            <template #body="field">
                              <ProSpaceDefaultTextColumn>
                                {{ field.data.phoneNumber }}
                              </ProSpaceDefaultTextColumn>
                            </template>
                          </Column>
                          <Column
                            field="faxNumber"
                            :header="$t('distributorFaxNumber')"
                            sortable
                          >
                            <template #body="field">
                              <ProSpaceDefaultTextColumn>
                                {{ field.data.faxNumber }}
                              </ProSpaceDefaultTextColumn>
                            </template>
                          </Column>
                          <Column
                            field="emailAddress"
                            :header="$t('emailAddress')"
                            sortable
                          >
                            <template #body="field">
                              <ProSpaceDefaultTextColumn>
                                {{ field.data.emailAddress }}
                              </ProSpaceDefaultTextColumn>
                            </template>
                          </Column>
                        </template>
                        <template #panel="{ item }">
                          <ProSpaceRightPanel :header="headerDistributor(item)">
                            <template #center>
                              <ProSpaceTabs
                                :tabs="tabsDistributor"
                                @select-tab="handlerSelectTab"
                              >
                                <template v-slot:tab-1>
                                  <ProSpacePanelBlock
                                    :header="{}"
                                    withPaddingTop
                                  >
                                    <template v-slot:header> </template>
                                    <template v-slot>
                                      <ProSpacePanelBlockList
                                        :itemsPerPage="5"
                                        :list="contentDistributor(item)"
                                      />
                                    </template>
                                  </ProSpacePanelBlock>
                                </template>
                                <template v-slot:tab-2>
                                  <ProSpacePanelBlock
                                    :header="{}"
                                    withPaddingTop
                                  >
                                    <template v-slot:header> </template>
                                    <template v-slot>
                                      <ProSpacePanelBlockList
                                        :itemsPerPage="10"
                                        :list="contentRequisites(item)"
                                      />
                                    </template>
                                  </ProSpacePanelBlock>
                                </template>
                              </ProSpaceTabs>
                            </template>
                          </ProSpaceRightPanel>
                        </template>
                      </ProSpaceSearchfield>
                    </ProSpaceVLayout>
                  </template>
                  <template v-if="v$.model.distributorId.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>

                <!-- Общая информация -->
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('competenceDirectoryInfo')"
                      :bottom="$t('competenceDirectoryInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="20"
                    >
                      <ProSpaceHLayout
                        v-if="!isCreation && !isDraft"
                        align-items="flex-start"
                        :gap="10"
                        isInlineH
                      >
                        <ProSpaceDropdown
                          v-if="!(isCreation || isDraft)"
                          ref="status-ProSpaceDropdown"
                          v-model="model.newStatus"
                          :label="$t('competenceStatusTxt')"
                          :placeholder="$t('competenceStatusTxt')"
                          :invalid="v$.model.newStatus.$error"
                          :invalid-messages="v$.model.newStatus.$errors"
                          @blur="v$.model.newStatus.$touch"
                          :service="baseTypeService"
                          getMethod="grid"
                          propValue="code"
                          propLabel="name"
                          :argMethod="statusDropdownArgMethod"
                          style="width: calc(50% - 5px)"
                          @change="statusChange"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout
                        align-items="flex-start"
                        :gap="10"
                        isInlineH
                      >
                        <ProSpaceSearchfield
                          ref="competenceDirectories-ProSpaceSearchfield"
                          v-model="model.competenceDirectoryId"
                          optionLabel="name"
                          optionValue="id"
                          getMethod="grid"
                          filterModel="name"
                          icon="list-check"
                          defaultSortField="createdAt"
                          :defaultSortOrder="-1"
                          :label="$t('competenceDirectoryName')"
                          :inputPlaceholder="
                            $t('competenceDirectoryNamePlaceholder')
                          "
                          :searchPlaceholder="$t('searchTxt')"
                          :cancelTxt="$t('cancelInf')"
                          :applyTxt="$t('select')"
                          :txtTitleModal="$t('competenceSelect')"
                          :displayValue="model.competenceDirectoryName"
                          :service="competenceDirectoryService"
                          :columns="columns"
                          :fastFilters="[]"
                          :defaultFilter="{ disabled: { and: [false] } }"
                          :invalid="v$.model.competenceDirectoryId.$error"
                          :invalid-messages="
                            v$.model.competenceDirectoryId.$errors
                          "
                          :txtFilterHint="$t('filterBy')"
                          @blur="v$.model.competenceDirectoryId.$touch"
                          @apply="
                            handlerApplySearchfield(
                              $event,
                              'competenceDirectories'
                            )
                          "
                        >
                          <template #filters="{ filter }">
                            <ProSpaceMultiSelect
                              :localization="$t"
                              v-model="filter.block"
                              :service="baseTypeService"
                              getMethod="grid"
                              optionLabel="name"
                              optionValue="code"
                              :default-filter="{
                                disabled: false,
                                systemDirectoryCode: 'BlockType',
                              }"
                              :placeholderValue="$t('competenceDirectoryBlock')"
                              :selectAllTxt="$t('selectAllTxt')"
                              :closeTxt="$t('closeTxt')"
                              :applyTxt="$t('applyTxt')"
                              :clearFilterTxt="$t('clearFilterTxt')"
                              @apply="
                                (model) =>
                                  customFilterApply(
                                    filter,
                                    model,
                                    'competenceDirectories-ProSpaceSearchfield'
                                  )
                              "
                            />

                            <ProSpaceMultiSelect
                              :localization="$t"
                              v-model="filter.type"
                              :service="baseTypeService"
                              getMethod="grid"
                              optionLabel="name"
                              optionValue="code"
                              :default-filter="{
                                disabled: false,
                                systemDirectoryCode: 'CompetenceType',
                              }"
                              :placeholderValue="$t('competenceDirectoryType')"
                              :selectAllTxt="$t('selectAllTxt')"
                              :closeTxt="$t('closeTxt')"
                              :applyTxt="$t('applyTxt')"
                              :clearFilterTxt="$t('clearFilterTxt')"
                              @apply="
                                (model) =>
                                  customFilterApply(
                                    filter,
                                    model,
                                    'competenceDirectories-ProSpaceSearchfield'
                                  )
                              "
                            />
                            <ProSpaceCustomFilterPanel
                              :customTxt="$t('custom')"
                              :customFilterTxt="$t('customFilterTxt')"
                              :clearFilterTxt="$t('clearFilterTxt')"
                              :selectAllTxt="$t('selectAllTxt')"
                              :closeTxt="$t('closeTxt')"
                              :applyTxt="$t('applyTxt')"
                              :fromTxt="$t('fromTxt')"
                              :toTxt="$t('toTxt')"
                              :services="competenceDirectoryFilterService"
                              @apply="
                                (model) =>
                                  customFilterApply(
                                    filter,
                                    model,
                                    'competenceDirectories-ProSpaceSearchfield'
                                  )
                              "
                            />
                          </template>
                          <template #columns>
                            <Column
                              field="name"
                              :header="this.$t('competenceDirectoryName')"
                              sortable
                              headerClass="move-sort-head"
                            >
                              <template #body="field">
                                <ProSpaceTableHeaderCell
                                  v-if="field.data"
                                  :title="field.data.name"
                                />
                              </template>
                            </Column>

                            <Column
                              field="block"
                              :header="this.$t('competenceDirectoryBlock')"
                              sortable
                              headerClass="move-sort-head"
                              style="min-width: 95px"
                            >
                              <template #body="field">
                                <ProSpaceDefaultTextColumn
                                  :title="field.data.blockName"
                                >
                                  {{ field.data.blockName }}
                                </ProSpaceDefaultTextColumn>
                              </template>
                            </Column>

                            <Column
                              field="level"
                              :header="this.$t('competenceDirectoryLevel')"
                              sortable
                              headerClass="move-sort-head"
                              style="min-width: 95px"
                            >
                              <template #body="field">
                                <ProSpaceDefaultTextColumn
                                  :title="field.data.levelName"
                                >
                                  {{ field.data.levelName }}
                                </ProSpaceDefaultTextColumn>
                              </template>
                            </Column>

                            <Column
                              field="type"
                              :header="this.$t('competenceDirectoryType')"
                              sortable
                              headerClass="move-sort-head"
                              style="min-width: 95px"
                            >
                              <template #body="field">
                                <ProSpaceDefaultTextColumn
                                  :title="$t(field.data.typeName)"
                                >
                                  {{ $t(field.data.typeName) }}
                                </ProSpaceDefaultTextColumn>
                              </template>
                            </Column>
                          </template>
                          <template #panel="{ item }">
                            <ProSpaceRightPanel
                              :header="headerPanelCompetenceDirectories(item)"
                            >
                              <template #center>
                                <ProSpacePanelBlock
                                  :header="headerBlockCompetenceDirectories"
                                >
                                  <template v-slot>
                                    <ProSpacePanelBlockList
                                      :list="
                                        contentBlockCompetenceDirectories(item)
                                      "
                                      :itemsPerPage="7"
                                    />
                                  </template>
                                </ProSpacePanelBlock>
                              </template>
                            </ProSpaceRightPanel>
                          </template>
                        </ProSpaceSearchfield>
                        <ProSpaceDropdown
                          ref="levelDevelopment-ProSpaceDropdown"
                          v-model="model.levelDevelopment"
                          :label="$t('competenceLevelDevelopment')"
                          :placeholder="$t('competenceLevelDevelopment')"
                          :service="baseTypeService"
                          :invalid="v$.model.levelDevelopment.$error"
                          :invalid-messages="v$.model.levelDevelopment.$errors"
                          @blur="v$.model.levelDevelopment.$touch"
                          getMethod="grid"
                          propValue="code"
                          propLabel="name"
                          argMethod="$filter=systemDirectoryCode eq 'AdvancementLevelType' and disabled eq false"
                          @change="levelDevelopmentChange"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout
                        align-items="flex-start"
                        :gap="10"
                        isInlineH
                      >
                        <ProSpaceInputText
                          ref="competenceDirectoryBlock-ProSpaceInputText"
                          v-model="model.competenceDirectoryBlockName"
                          :label="$t('competenceDirectoryBlock')"
                          :placeholder="$t('competenceDirectoryBlock')"
                          :hint="$t('competenceAutoCompletePlaceholder')"
                        />
                        <ProSpaceInputText
                          ref="competenceDirectoryLevel-ProSpaceInputText"
                          v-model="model.competenceDirectoryLevelName"
                          :label="$t('competenceDirectoryLevel')"
                          :placeholder="$t('competenceDirectoryLevel')"
                          :hint="$t('competenceAutoCompletePlaceholder')"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceInputText
                        ref="competenceDirectoryType-ProSpaceInputText"
                        v-model="model.competenceDirectoryTypeName"
                        :label="$t('competenceDirectoryType')"
                        :placeholder="$t('competenceDirectoryType')"
                        :hint="$t('competenceAutoCompletePlaceholder')"
                        style="width: calc(50% - 5px)"
                      />
                      <ProSpaceExtendedTextArea
                        ref="competenceDirectoryDescription-ProSpaceExtendedTextArea"
                        v-model="model.competenceDirectoryDescription"
                        :placeholder="$t('competenceDirectoryDescription')"
                        :label="$t('competenceDirectoryDescription')"
                        :hint="$t('competenceAutoCompletePlaceholder')"
                        :t="$t"
                        :locale="$i18n.locale"
                      />
                      <ProSpaceExtendedTextArea
                        ref="competenceDirectoryAdvancementRecommendation-ProSpaceExtendedTextArea"
                        v-model="
                          model.competenceDirectoryAdvancementRecommendation
                        "
                        :placeholder="
                          $t('competenceDirectoryAdvancementRecommendations')
                        "
                        :label="
                          $t('competenceDirectoryAdvancementRecommendations')
                        "
                        :hint="$t('competenceAutoCompletePlaceholder')"
                        :t="$t"
                        :locale="$i18n.locale"
                      />
                      <ProSpaceHLayout
                        align-items="flex-start"
                        :gap="10"
                        isInlineH
                      >
                        <ProSpaceInputCalendar
                          ref="startAt-ProSpaceInputCalendar"
                          v-model="model.startAt"
                          :label="$t('competenceStartAt')"
                          :placeholder="$t('competenceStartAt')"
                          :invalid="v$.model.startAt.$error"
                          :invalid-messages="v$.model.startAt.$errors"
                          @blur="v$.model.startAt.$touch"
                        />
                        <ProSpaceInputCalendar
                          ref="endAt-ProSpaceInputCalendar"
                          v-model="model.endAt"
                          :label="$t('competenceEndAt')"
                          :placeholder="$t('competenceEndAt')"
                          :invalid="v$.model.endAt.$error"
                          :invalid-messages="v$.model.endAt.$errors"
                          @blur="v$.model.endAt.$touch"
                        />
                      </ProSpaceHLayout>
                    </ProSpaceVLayout>
                  </template>
                  <template
                    v-if="
                      v$.model.newStatus.$error ||
                      v$.model.competenceDirectoryId.$error ||
                      v$.model.levelDevelopment.$error ||
                      v$.model.startAt.$error ||
                      v$.model.endAt.$error
                    "
                    v-slot:right
                  >
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>

                <!-- Развитие компетенции -->
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('competenceDevelopmentInfo')"
                      :bottom="$t('competenceDevelopmentInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout
                      justify-content="flex-start"
                      align-items="flex-start"
                      :gap="20"
                    >
                      <ProSpaceInputNumber
                        ref="developmentPercentage-ProSpaceInputNumber"
                        v-model="model.developmentPercentage"
                        style="width: calc(50% - 5px)"
                        likeText
                        :integeronly="false"
                        :maxFractionDigits="0"
                        :min="0"
                        :max="100"
                        :formatter="formatDigit"
                        :label="$t('competenceDevelopmentPercentage')"
                        :placeholder="$t('competenceDevelopmentPercentage')"
                        :invalid="v$.model.developmentPercentage.$error"
                        :invalid-messages="
                          v$.model.developmentPercentage.$errors
                        "
                        @blur="v$.model.developmentPercentage.$touch"
                      />
                      <ProSpaceExtendedTextArea
                        ref="businessGoal-ProSpaceExtendedTextArea"
                        v-model="model.businessGoal"
                        :placeholder="$t('competenceBusinessGoalPlaceholder')"
                        :label="$t('competenceBusinessGoal')"
                        :t="$t"
                        :locale="$i18n.locale"
                        :invalid="v$.model.businessGoal.$error"
                        :invalid-messages="v$.model.businessGoal.$errors"
                        @blur="v$.model.businessGoal.$touch"
                      />
                    </ProSpaceVLayout>
                  </template>
                  <template
                    v-if="
                      v$.model.developmentPercentage.$error ||
                      v$.model.businessGoal.$error
                    "
                    v-slot:right
                  >
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>

                <!-- Результат и метрики -->
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('competenceResultsAndMetricsInfo')"
                      :bottom="$t('competenceResultsAndMetricsInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="20"
                    >
                      <ProSpaceExtendedTextArea
                        ref="expectedResult-ProSpaceExtendedTextArea"
                        v-model="model.expectedResult"
                        :placeholder="
                          $t('competenceExpectedResultTxtPlaceholder')
                        "
                        :label="$t('competenceExpectedResult')"
                        :t="$t"
                        :locale="$i18n.locale"
                        :invalid="v$.model.expectedResult.$error"
                        :invalid-messages="v$.model.expectedResult.$errors"
                        @blur="v$.model.expectedResult.$touch"
                      />
                      <ProSpaceExtendedTextArea
                        ref="metrics-ProSpaceExtendedTextArea"
                        v-model="model.metrics"
                        :placeholder="$t('competenceMetricsTxtPlaceholder')"
                        :label="$t('competenceMetrics')"
                        :t="$t"
                        :locale="$i18n.locale"
                        :invalid="v$.model.metrics.$error"
                        :invalid-messages="v$.model.metrics.$errors"
                        @blur="v$.model.metrics.$touch"
                      />
                    </ProSpaceVLayout>
                  </template>
                  <template
                    v-if="
                      v$.model.expectedResult.$error || v$.model.metrics.$error
                    "
                    v-slot:right
                  >
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceVLayout>
            </template>
            <template v-slot:tab-2>
              <DistributorHierarchySelectForm
                ref="distributorStructures-DistributorHierarchySelectForm"
                v-model="distributor.structures"
                get-method="allFilial"
                expandAll
                searchable
                hideRightPanel
                is-one-filial
                isFilial
                :title-modal="$t('distributorStructureViewModalTitle')"
                :service="distributorStructureService"
                :afterEdit="afterDistributorStructureEdit"
                :titleTxt="$t('distributorStructureViewInfo')"
                :descriptionTxt="$t('distributorStructureViewInfoAdditional')"
                @apply="applyDistributorStructure"
              />
            </template>
            <template v-slot:tab-3>
              <!-- Задачи -->
              <ProSpaceGeoLayout class="geo-row geo-row--no-border">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('competenceTasks')"
                    :bottom="$t('competenceAdvancementTasks')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceVLayout
                    justify-content="space-between"
                    align-items="flex-start"
                    :gap="20"
                  >
                    <ProSpaceButton
                      v-if="!(isCreation || isDraft)"
                      ref="showCreateTaskModal-ProSpaceButton"
                      type="secondary"
                      icon="plus"
                      class="margin-bottom-30"
                      :label="$t('taskCreate')"
                      @click="showCreateTaskModal"
                    />
                  </ProSpaceVLayout>
                </template>
              </ProSpaceGeoLayout>
              <ProSpaceGeoLayout style="height: calc(100% - 70px)">
                <template #center>
                  <!-- Общая информация -->
                  <ProSpaceMainGrid
                    v-if="!(mixinLoadingAccess || statusMixinLoadingAccess)"
                    ref="competenceTaskGrid"
                    getMethod="grid"
                    defaultSortField="id"
                    isHeadHide
                    removeBgOnly
                    :defaultSortOrder="-1"
                    :service="competenceTaskService"
                    :defaultFilter="{
                      competenceId: { and: [+masterId] },
                      disabled: { and: [false] },
                    }"
                    :isInForm="true"
                    @selected-change="competenceTaskSelectedHandler"
                    @loaded="tryOpenTaskFromNotification"
                  >
                    <template #columns>
                      <Column style="max-width: 39%">
                        <template #body="field">
                          <ProSpaceVLayout :gap="5" justify-content="center">
                            <div
                              class="text-color-main text-bold text-ellipsis w-100"
                              style="line-height: 14px"
                            >
                              {{ field.data.name }}
                            </div>
                            <ProSpaceTag
                              is-border-style
                              v-bind="
                                mapExpandStatuses.get(field.data.status) || {
                                  type: 'draft',
                                  text: field.data.status,
                                }
                              "
                            />
                          </ProSpaceVLayout>
                        </template>
                      </Column>
                      <Column style="max-width: 18%">
                        <template #body="field">
                          <ProSpaceTextBlock
                            type="five"
                            :top="$t('competenceTaskAssignmentTo')"
                            :bottom="$t(field.data.assignmentTo)"
                            :gap="3"
                            justify-content="center"
                          />
                        </template>
                      </Column>
                      <Column style="max-width: 14%">
                        <template #body="field">
                          <ProSpaceTextBlock
                            type="five"
                            :top="$t('competenceStartAt')"
                            :bottom="formatMomentDate(field.data.startAt)"
                            :gap="3"
                            justify-content="center"
                          />
                        </template>
                      </Column>
                      <Column style="max-width: 17%">
                        <template #body="field">
                          <ProSpaceTextBlock
                            type="five"
                            :top="$t('competenceEndAt')"
                            :bottom="formatMomentDate(field.data.endAt)"
                            :gap="3"
                            justify-content="center"
                          />
                        </template>
                      </Column>
                      <Column style="max-width: 12%">
                        <template #body="field">
                          <ProSpaceTextBlock
                            type="five"
                            :top="$t('competenceTaskPriority')"
                            :bottom="
                              field.data.priority !== null &&
                              field.data.priority !== undefined
                                ? String(field.data.priority)
                                : null
                            "
                            :gap="3"
                            justify-content="center"
                          />
                        </template>
                      </Column>
                      <Column field="actions" class="column-end column-end-90">
                        <template #body="field">
                          <ProSpaceHLayout :gap="10" justify-content="flex-end">
                            <ProSpaceIcon
                              v-if="field.data.isAnyAttachment"
                              icon="paperclip"
                            />
                            <ProSpaceTag
                              v-if="field.data.daysExpired"
                              :text="field.data.daysExpired"
                              :description="$t('overdueDayHint')"
                              type="red-secondary"
                            />
                            <ProSpaceDots
                              :options="
                                localWrapperCheckRoles(
                                  competenceTaskOptions,
                                  field.data
                                )
                              "
                              @action="
                                (action) =>
                                  handleCompetenceTaskDots(action, field.data)
                              "
                            />
                          </ProSpaceHLayout>
                        </template>
                      </Column>
                    </template>
                    <template #empty>
                      <div></div>
                    </template>
                  </ProSpaceMainGrid>
                </template>
              </ProSpaceGeoLayout>
              <CompetenceTaskModalForm
                v-model="showTaskModal"
                :id="taskModalId"
                :competence="taskModalCompetence"
                :minStartAt="taskModalCompetenceStartAt"
                :maxEndAt="taskModalCompetenceEndAt"
                :afterSaveHandler="handleCompetenceTaskModalFormSavedData"
              />
              <ProSpaceHistoryModal
                ref="competenceTaskHistory"
                v-model="showCompetenceTaskHistoryModal"
                modelName="CompetenceTaskApiModel"
                :optionsPanel="optionsPanel"
                @close="closeHistory"
              />
            </template>
            <template v-slot:tab-4>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('competenceRecipientsMars')"
                    :bottom="$t('competenceRecipientsInfo')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceGeoLayout>
                    <template v-slot:top>
                      <ProSpaceButton
                        ref="addRecipient-button"
                        type="secondary"
                        icon="edit"
                        class="margin-bottom-30"
                        :label="$t('editItemTxt')"
                        @click="showOrgStructureSelect"
                      />
                    </template>
                    <template v-slot:center>
                      <ProSpaceSimpleGrid
                        ref="recipientGrid"
                        getMethod="simple"
                        :headers="recipientGridHeaders"
                        :service="recipientService"
                        :limit="50"
                        :btnText="$t('viewMore')"
                        :deletable="
                          checkStatusAccess(
                            'action-delete-ProSpaceSimpleGrid',
                            model
                          )
                        "
                        deletingFieldName="email"
                        :confirmTitle="$t('deleting')"
                        :confirmText="$t('questionDeleting')"
                        :confirmApplyText="$t('applyTxt')"
                        :confirmCancelText="$t('cancelInf')"
                        :afterConfirmText="$t('questionDeletingList')"
                        @delete="deleteFromFilter"
                      />
                    </template>
                  </ProSpaceGeoLayout>
                </template>
              </ProSpaceGeoLayout>
              <OrgStructureSelectModal
                ref="filterModal"
                v-model="showOrgStructureSelectModal"
                node-method="onlyMars"
                shortGrid
                @close="closeOrgStructureSelectModal"
              />
            </template>
          </ProSpaceTabs>
        </template>
        <template #right>
          <ProSpaceRightPanel
            v-if="masterId && model.status && !isCompetenceTaskSelected"
            no-border
            :header="panelBlockHeader"
          >
            <template #center>
              <ProSpaceTimeLineCondition
                :elems="model.comments"
                :commentTile="$t('comment')"
                :title="$t('comments')"
                :titleAction="$t('addComment')"
                :dateTimeFormatter="formatTimelineDate"
                :showMarker="false"
                @add="showReasonModal(addComment, $t('addComment'))"
              >
                <template #empty>
                  <ProSpaceEmptyGrid
                    v-if="!model.comments || model.comments.length == 0"
                    style="padding-top: 100px"
                    icon="empty-grid"
                    :title="$t('noDataTitle')"
                    :text="$t('noDataTxt')"
                  />
                </template>
              </ProSpaceTimeLineCondition>
              <ProSpaceModal
                v-model="modalShow"
                icon="plus"
                :title="modalTitle"
                :width="530"
                :cancelTxt="$t('cancelInf')"
                :applyTxt="$t('send')"
                :validate-local="$t"
                :validate-model="modalModel"
                :validate-config="{
                  comment: {
                    required,
                  },
                }"
                @apply="saveComment"
                @cancel="cancelComment"
              >
                <template #default="{ v }">
                  <ProSpaceVLayout :gap="20">
                    <ProSpaceInputText
                      class="prospace-competence-input-layout"
                      v-model="modalModel.comment"
                      :label="$t('comment')"
                      :placeholder="$t('comment')"
                      :isTextarea="true"
                      :invalid="v.comment.$error"
                      :invalid-messages="v.comment.$errors"
                      @blur="v.comment.$touch"
                    />
                  </ProSpaceVLayout>
                </template>
              </ProSpaceModal>
            </template>
          </ProSpaceRightPanel>
          <CompetenceTaskPanel
            v-if="hasSelect && isCompetenceTaskSelected"
            ref="right-panel"
            :service="competenceTaskService"
            :options="competenceTaskOptions"
            :checkAccess="checkAccess"
            :checkStatusAccess="checkStatusAccess"
            :filterAccessDots="localWrapperCheckRoles"
            @edit="showEditTaskModal"
          />
        </template>
      </ProSpaceGeoLayout>
      <ProSpaceFlashMessage
        v-if="isStatusNotify"
        v-model="isStatusNotify"
        type="default"
        icon="flash-warning"
        :title="
          newStatus === 'Completed'
            ? $t('competenceStatusCompletedNotifyTitle')
            : $t('competenceStatusCanceledNotifyTitle')
        "
        :applyTxt="$t('accept')"
        :cancelTxt="$t('cancelInf')"
        @apply="applyStatusChange"
        @cancel="cancelStatusChange"
      >
        {{
          newStatus === "Completed"
            ? $t("competenceStatusCompletedNotifyAdditional")
            : $t("competenceStatusCanceledNotifyAdditional")
        }}
      </ProSpaceFlashMessage>
      <ProSpaceFlashMessage
        v-if="showCopyCompetenceTaskMessage"
        v-model="showCopyCompetenceTaskMessage"
        :title="$t('copyCreation')"
        :applyTxt="$t('confirmTxt')"
        :cancelTxt="$t('cancelInf')"
        @apply="() => save(true)"
      >
        <div
          v-html="
            $t('competenceCopyMsg', { distributorName: model.distributorName })
          "
        />
        <br />
        <ProSpaceRadioButton
          v-model="copyCompetenceRadioButtonModel"
          :values="copyCompetenceTaskOptions"
        />
      </ProSpaceFlashMessage>
      <ProSpaceFlashMessage
        v-if="showCopiedTaskDatesChanged"
        icon="flash-success"
        v-model="showCopiedTaskDatesChanged"
        :title="$t('copiedTaskDatesChanged')"
        :cancelTxt="$t('closeTxt')"
      >
        <div v-html="$t('inProcessOfCopyingTaskDatesChanged')" />
      </ProSpaceFlashMessage>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import statusAccessMixin from "../../../../../Frontends/Common/utils/elementAccess/statusAccessMixin";
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceRadioButton,
  ProSpaceGeoLayout,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceRightPanel,
  ProSpaceTableHeaderCell,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceFile,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceExtendedTextArea,
  ProSpaceDefaultTextColumn,
  ProSpaceSearchfield,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceTimeLineCondition,
  ProSpaceModal,
  ProSpaceIcon,
  ProSpaceButton,
  ProSpaceWarning,
  ProSpaceSimpleGrid,
  ProSpaceEmptyGrid,
  ProSpaceDivider,
  ProSpaceMainGrid,
  ProSpaceTag,
  ProSpaceDots,
  ProSpaceFlashMessage,
} from "@prospace/prospace-components-library";
import moment from "moment";
import CompetenceTaskPanel from "./components/CompetenceTaskPanel";
import CompetenceTaskModalForm from "./../CompetenceTasks/components/CompetenceTaskModalForm";
import ProSpaceHistoryModal from "../../../../../Frontends/ProSpace/components/modals/ProSpaceHistoryModal";
import OrgStructureSelectModal from "@modals/OrgStructureSelectModal";
import { CompetenceService } from "../../services/CompetenceService.js";
import { CompetenceDirectoryService } from "../../services/CompetenceDirectoryService.js";
import { CompetenceCommentService } from "../../services/CompetenceCommentService.js";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService.js";
import { DistributorService } from "../../../../MasterData/MasterData.Frontend/services/DistributorService.js";
import { DistributorStructureService } from "../../../../MasterData/MasterData.Frontend/services/DistributorStructureService.js";
import { CompetenceRecipientService } from "../../services/CompetenceRecipientService";
import { CompetenceTaskService } from "../../services/CompetenceTaskService";
import DistributorHierarchySelectForm from "@forms/DistributorHierarchySelectForm";
import Column from "primevue/column";
import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useStatistic } from "@composes";
import { useMultiSelectGrid } from "@composes/multiselect";
import { mapGetters } from "vuex";
import { ref } from "vue";
import { formatBytes, downloadFile } from "@common/utils/fileHelpers.js";
import _ from "lodash";
export default {
  mixins: [accessMixin, statusAccessMixin],
  components: {
    OrgStructureSelectModal,
    Column,
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceRadioButton,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceRightPanel,
    ProSpaceTableHeaderCell,
    ProSpaceTabs,
    ProSpaceTextBlock,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceFile,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceExtendedTextArea,
    ProSpaceDefaultTextColumn,
    ProSpaceSearchfield,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceTimeLineCondition,
    ProSpaceModal,
    ProSpaceIcon,
    ProSpaceButton,
    ProSpaceWarning,
    ProSpaceSimpleGrid,
    ProSpaceEmptyGrid,
    ProSpaceDivider,
    DistributorHierarchySelectForm,
    ProSpaceMainGrid,
    ProSpaceTag,
    ProSpaceDots,
    CompetenceTaskPanel,
    CompetenceTaskModalForm,
    ProSpaceFlashMessage,
    ProSpaceHistoryModal,
  },
  setup() {
    const { setStatistic, distActions } = useStatistic();
    const competenceTaskGrid = ref(null);
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      selectedData,
      hasSelect,
      selectedMultipleBlockFilterHandler,
    } = useMultiSelectGrid(competenceTaskGrid);
    return {
      v$: useVuelidate(),
      setStatistic,
      distActions,
      competenceTaskGrid,
      selectedMultipleGridHandler,
      selectedData,
      hasSelect,
      selectedMultipleClear,
      selectedMultipleBlockFilterHandler,
    };
  },
  data() {
    let statusConfig = {
      "Distributor user": "READONLY",
      "Distributor manager": "READONLY",
      "Mars user": {
        other: "ALIVE",
      },
      "Mars manager": {
        other: "ALIVE",
      },
      "Content manager": {
        other: "ALIVE",
      },
      "Support administrator": {
        other: "ALIVE",
      },
      "Technical Support": {
        other: "ALIVE",
      },
      "Content manager plus": {
        other: "ALIVE",
      },
    };
    let statusDraftConfig = {
      "Distributor user": "READONLY",
      "Distributor manager": "READONLY",
      "Mars user": {
        none: "ALIVE",
        Draft: "ALIVE",
        other: "READONLY",
      },
      "Mars manager": {
        none: "ALIVE",
        Draft: "ALIVE",
        other: "READONLY",
      },
      "Content manager": {
        none: "ALIVE",
        Draft: "ALIVE",
        other: "READONLY",
      },
      "Support administrator": {
        none: "ALIVE",
        Draft: "ALIVE",
        other: "READONLY",
      },
      "Technical Support": {
        none: "ALIVE",
        Draft: "ALIVE",
        other: "READONLY",
      },
      "Content manager plus": {
        none: "ALIVE",
        Draft: "ALIVE",
        other: "READONLY",
      },
    };
    let statusButtonsConfig = {
      "Distributor user": "NONE",
      "Distributor manager": "NONE",
      "Mars user": {
        other: "ALIVE",
      },
      "Mars manager": {
        other: "ALIVE",
      },
      "Content manager": {
        other: "ALIVE",
      },
      "Support administrator": {
        other: "ALIVE",
      },
      "Technical Support": {
        other: "ALIVE",
      },
      "Content manager plus": {
        other: "ALIVE",
      },
    };
    let statusButtonsTaskConfig = {
      "Distributor user": {
        Draft: "NONE",
        other: "ALIVE",
      },
      "Distributor manager": {
        Draft: "NONE",
        other: "ALIVE",
      },
      "Mars user": {
        Draft: "NONE",
        other: "ALIVE",
      },
      "Mars manager": {
        Draft: "NONE",
        other: "ALIVE",
      },
      "Content manager": {
        Draft: "NONE",
        other: "ALIVE",
      },
      "Support administrator": {
        Draft: "NONE",
        other: "ALIVE",
      },
      "Technical Support": {
        Draft: "NONE",
        other: "ALIVE",
      },
      "Content manager plus": {
        Draft: "NONE",
        other: "ALIVE",
      },
    };
    let statusCDConfig = {
      "Distributor user": "READONLY",
      "Distributor manager": "READONLY",
      "Mars user": {
        none: "DISABLE",
        Draft: "DISABLE",
        other: "READONLY",
      },
      "Mars manager": {
        none: "DISABLE",
        Draft: "DISABLE",
        other: "READONLY",
      },
      "Content manager": {
        none: "DISABLE",
        Draft: "DISABLE",
        other: "READONLY",
      },
      "Support administrator": {
        none: "DISABLE",
        Draft: "DISABLE",
        other: "READONLY",
      },
      "Technical Support": {
        none: "DISABLE",
        Draft: "DISABLE",
        other: "READONLY",
      },
      "Content manager plus": {
        none: "DISABLE",
        Draft: "DISABLE",
        other: "READONLY",
      },
    };
    return {
      // Tabs
      modelTab: {
        name: this.$t("parameters"),
        isActive: true,
        systemName: "parameters",
      },

      // Common
      model: null,
      initModel: null,
      loading: false,

      // Service
      competenceService: new CompetenceService(),
      competenceDirectoryService: new CompetenceDirectoryService(),
      competenceCommentService: new CompetenceCommentService(),
      distributorService: new DistributorService(),
      baseTypeService: new BaseTypeService(),
      distributorStructureService: new DistributorStructureService(),
      recipientService: new CompetenceRecipientService(),
      competenceTaskService: new CompetenceTaskService(),

      getMethod: "get",
      masterId: null,

      persistRecipients: true,
      showOrgStructureSelectModal: false,
      nodeFilter: {
        add: {},
        remove: {},
      },
      gridFilter: {
        add: {},
        remove: {},
      },
      oDataStringFilter: "",
      selectedTreeNodes: {},
      recipientGridHeaders: [
        { name: this.$t("type"), value: "recordTypeLocale" },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
        {
          name: this.$t("email"),
          value: "email",
          f: (item) => item,
        },
      ],

      modalTitle: "",
      modalCallback: null,
      modalShow: false,
      modalModel: {
        comment: "",
      },

      distributor: {
        structures: [],
      },
      afterDistributorStructureEdit: false,
      isCompetenceTaskSelected: false,

      //task modal
      competenceTaskIdFromNotification: null,
      taskModalId: null,
      taskModalCompetence: null,
      taskModalCompetenceStartAt: null,
      taskModalCompetenceEndAt: null,
      showTaskModal: false,
      showCompetenceTaskHistoryModal: false,

      isStatusNotify: false,
      newStatus: "",
      oldStatus: "",

      //status access
      statusAccessConfig: {
        "competenceDirectories-ProSpaceSearchfield": statusDraftConfig,
        "levelDevelopment-ProSpaceDropdown": statusDraftConfig,
        "competenceDirectoryBlock-ProSpaceInputText": statusCDConfig,
        "competenceDirectoryLevel-ProSpaceInputText": statusCDConfig,
        "competenceDirectoryType-ProSpaceInputText": statusCDConfig,
        "competenceDirectoryDescription-ProSpaceExtendedTextArea":
          statusCDConfig,
        "competenceDirectoryAdvancementRecommendation-ProSpaceExtendedTextArea":
          statusCDConfig,
        "startAt-ProSpaceInputCalendar": statusConfig,
        "endAt-ProSpaceInputCalendar": statusConfig,
        "developmentPercentage-ProSpaceInputNumber": statusConfig,
        "businessGoal-ProSpaceExtendedTextArea": statusConfig,
        "expectedResult-ProSpaceExtendedTextArea": statusConfig,
        "metrics-ProSpaceExtendedTextArea": statusConfig,
        "distributor-ProSpaceSearchfield": statusDraftConfig,
        "distributorStructures-DistributorHierarchySelectForm":
          statusDraftConfig,
        "editTask-option": statusButtonsTaskConfig,
        "subtaskCreate-action": statusButtonsTaskConfig,
        "addRecipient-button": statusButtonsConfig,
        "action-delete-ProSpaceSimpleGrid": statusButtonsConfig,
      },

      //competence copy
      copyCompetenceTaskOptions: [
        {
          value: "true",
          label: this.$t("createTaskCopies"),
        },
        {
          value: "false",
          label: this.$t("doNotCreateTaskCopies"),
        },
      ],
      copyCompetenceRadioButtonModel: "false",
      showCopyCompetenceTaskMessage: false,
      copySourceDistributorId: null,
      copySourceId: null,
      showCopiedTaskDatesChanged: false,
      parentTasksExist: false,
    };
  },
  validations() {
    const isFilialValid = (v) => {
      if (
        this.model &&
        this.model.levelDevelopment === "Filial" &&
        this.model.distributorStructureViewId == null
      )
        return false;

      return true;
    };
    return {
      model: {
        distributorId: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        newStatus: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage"),
            maxLength(100)
          ),
        },
        competenceDirectoryId: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        levelDevelopment: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        developmentPercentage: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          minValue: helpers.withMessage(
            this.$t("minValueErrorMessage"),
            minValue(0)
          ),
          maxValue: helpers.withMessage(
            this.$t("maxValueErrorMessage"),
            maxValue(100)
          ),
        },
        businessGoal: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        expectedResult: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        metrics: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        startAt: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          mustBeLessThanEnd: helpers.withMessage(
            this.$t("dateMustBeLessThanEnd"),
            this.checkStartDate
          ),
        },
        endAt: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          mustBeGreaterThanStart: helpers.withMessage(
            this.$t("dateMustBeGreaterThanStart"),
            this.checkEndDate
          ),
        },
        distributorStructureViewId: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            isFilialValid
          ),
        },
      },
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.CompetenceTaskId) {
      this.competenceTaskIdFromNotification =
        this.$route.query.CompetenceTaskId;
      this.modelTab = {
        name: this.$t("tasks"),
        systemName: "tasks",
        isActive: true,
      };
    }

    this.loadRoleAccess();
    this.masterId = this.$route.params.id;
    this.copySourceId = this.$route.query.copySourceId;
    this.getData().then(() => {
      this.setAllAccess();
    });
    this.recipientService.filter = this.recipientGridFilter;
  },
  methods: {
    formatTimelineDate(date) {
      return this.getFormatDateTime(date);
    },
    tryOpenTaskFromNotification() {
      if (!this.competenceTaskIdFromNotification) return;

      this.showEditTaskModal({ id: this.competenceTaskIdFromNotification });
      this.competenceTaskIdFromNotification = null;
    },
    emptyComment() {
      return {
        comment: "",
      };
    },
    checkStartDate(value) {
      return (
        moment(value).format("YYYYMMDD") <=
        moment(this.model.endAt).format("YYYYMMDD")
      );
    },
    checkEndDate(value) {
      return (
        moment(value).format("YYYYMMDD") >=
        moment(this.model.startAt).format("YYYYMMDD")
      );
    },
    setAllAccess(clear = false) {
      this.$nextTick(() => {
        if (clear) this.clearAllCondition();
        this.mountAccess();
        this.mountStatusAccess();
      });
    },
    levelDevelopmentChange(item) {
      this.model.levelDevelopment = item && item.code ? item.code : null;
    },
    statusChange(item) {
      let newStatus = item && item.code ? item.code : null;
      if (newStatus === "Canceled" || newStatus === "Completed") {
        this.isStatusNotify = true;
        this.newStatus = newStatus;
      } else {
        this.model.newStatus = newStatus;
        this.oldStatus = newStatus;
      }
    },
    applyStatusChange() {
      this.isStatusNotify = false;
      this.model.newStatus = this.newStatus;
    },
    cancelStatusChange() {
      this.isStatusNotify = false;
      this.newStatus = null;
      this.model.newStatus = this.oldStatus;
    },
    applyDistributorStructure(selectedUnits) {
      if (selectedUnits && selectedUnits.length > 0) {
        this.model.distributorStructureViewId =
          selectedUnits[0].distributorStructureViewId;
        this.model.distributorStructureViewName =
          selectedUnits[0].distributorStructureViewName;
        this.distributor.structures.push({
          distributorStructureViewId: this.model.distributorStructureViewId,
        });
      } else {
        this.model.distributorStructureViewId = null;
        this.model.distributorStructureViewName = null;
        this.distributor.structures = [];
      }
      this.afterDistributorStructureEdit = true;
    },
    handlerApplySearchfield(data, field) {
      if (!data && field === "distributor") {
        this.model.distributorName = null;
        this.model.distributorStructureViewId = null;
        this.model.distributorStructureViewName = null;
        this.distributor.structures = [];
        return;
      } else if (!data && field === "competenceDirectories") {
        this.model.competenceDirectoryName = null;
        this.model.competenceDirectoryBlock = null;
        this.model.competenceDirectoryBlockName = null;
        this.model.competenceDirectoryLevel = null;
        this.model.competenceDirectoryLevelName = null;
        this.model.competenceDirectoryType = null;
        this.model.competenceDirectoryTypeName = null;
        this.model.competenceDirectoryDescription = null;
        this.model.competenceDirectoryAdvancementRecommendation = null;
      } else if (field === "distributor") {
        this.model.distributorName = data.name;
        this.distributorStructureService.setDistributorId(data.id);
        this.model.distributorStructureViewId = null;
        this.model.distributorStructureViewName = null;
        this.distributor.structures = [];
      } else if (field === "competenceDirectories") {
        this.model.competenceDirectoryName = data.name;
        this.model.competenceDirectoryBlock = data.block;
        this.model.competenceDirectoryBlockName = data.blockName;
        this.model.competenceDirectoryLevel = data.level;
        this.model.competenceDirectoryLevelName = data.levelName;
        this.model.competenceDirectoryType = data.type;
        this.model.competenceDirectoryTypeName = data.typeName;
        this.model.competenceDirectoryDescription = data.description;
        this.model.competenceDirectoryAdvancementRecommendation =
          data.advancementRecommendation;
      }
    },
    customFilterApply(filter, model, refName) {
      filter = Object.assign(filter, model);
      this.$refs[refName].$refs["filter-panel"].submitFilter();
    },
    headerPanelCompetenceDirectories(competenceDirectory) {
      return {
        title: competenceDirectory.name,
        id: competenceDirectory.id,
        showCounter: false,
      };
    },
    contentBlockCompetenceDirectories(competenceDirectory) {
      return [
        [
          {
            title: this.$t("competenceDirectoryBlock"),
            text: competenceDirectory.blockName,
          },
        ],
        [
          {
            title: this.$t("competenceDirectoryLevel"),
            text: competenceDirectory.levelName,
          },
        ],
        [
          {
            title: this.$t("competenceDirectoryType"),
            text: competenceDirectory.typeName,
          },
        ],
        [
          {
            title: this.$t("competenceDirectoryDescription"),
            text: competenceDirectory.description,
            isHtml: true,
          },
        ],
        [
          {
            title: this.$t("competenceDirectoryAdvancementRecommendation"),
            text: competenceDirectory.advancementRecommendation,
            isHtml: true,
          },
        ],
      ];
    },
    contentDistributor(distributor) {
      if (distributor) {
        return [
          [
            {
              title: this.$t("distributorLegalAddress"),
              text: distributor.legalAddress,
            },
          ],
          [
            {
              title: this.$t("distributorMailAddress"),
              text: distributor.mailAddress,
            },
          ],
          [
            {
              title: this.$t("distributorPhoneNumber"),
              text: distributor.phoneNumber,
            },
          ],
          [
            {
              title: this.$t("distributorFaxNumber"),
              text: distributor.faxNumber,
            },
          ],
          [
            {
              title: this.$t("emailAddress"),
              text: distributor.emailAddress,
            },
          ],
        ];
      }
      return [];
    },
    contentRequisites(distributor) {
      if (distributor) {
        return [
          [
            {
              title: this.$t("nameOfBank"),
              text: distributor.bankName,
            },
          ],
          [
            {
              title: this.$t("bankPaymentAccount"),
              text: distributor.bankPaymentAccount,
            },
          ],
          [
            {
              title: this.$t("bankCorrespondentAccount"),
              text: distributor.bankCorrespondentAccount,
            },
          ],
          [
            {
              title: this.$t("bankBIC"),
              text: distributor.bankBIC,
            },
          ],
          [
            {
              title: this.$t("bankINN"),
              text: distributor.bankINN,
            },
          ],
          [
            {
              title: this.$t("bankKPP"),
              text: distributor.bankKPP,
            },
          ],
          [
            {
              title: this.$t("bankOGRN"),
              text: distributor.bankOGRN,
            },
          ],
        ];
      }
      return [];
    },
    headerDistributor(distributor) {
      if (distributor) {
        return {
          title: distributor.name,
          id: distributor.id,
          status: { text: this.$t("distributor"), type: "blue" },
          showCounter: false,
        };
      }
    },
    gridLoaded() {},
    handlerSelectTab({ selectedTab }) {
      if (selectedTab.systemName !== "tasks") {
        this.selectedMultipleClear();
        this.isCompetenceTaskSelected = false;
      }
      this.setAllAccess();
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName,
      });
    },
    async getData() {
      if (this.isCopy) {
        const source = await this.competenceService[this.getMethod](
          this.copySourceId
        );
        const copyData = Object.fromEntries(
          Object.entries(source.data).filter(([key]) =>
            this.copyingProperties.includes(key)
          )
        );

        this.model = {
          status: "none",
          newStatus: null,
          createdBy: this.userinfo.code,
          developmentPercentage: null,
          ...copyData,
        };

        this.parentTasksExist = source.data.taskTotalCount > 0;
        this.copySourceDistributorId = source.data.distributorId;
        this.distributorStructureService.setDistributorId(
          source.data.distributorId
        );

        return;
      }
      if (this.masterId === undefined) {
        this.model = {
          status: "none",
          newStatus: null,
          createdBy: this.userinfo.code,
          developmentPercentage: null,
        };
        return;
      }
      const result = await this.competenceService[this.getMethod](
        this.masterId
      );
      this.model = result.data;
      if (this.model) {
        this.model.needFilter = false;
        try {
          let filter = JSON.parse(this.model.filter);
          this.nodeFilter = filter.nodeFilter;
          this.gridFilter = filter.gridFilter;
          this.feelNeedFilter();
        } catch {}
        try {
          this.selectedTreeNodes = JSON.parse(this.model.nodeSelection);
        } catch {}

        this.model.comments = this.model.comments.map((value) => {
          return {
            comment: value.comment,
            confirmDate: value.createdAt,
            securityUserFirstName: value.securityUserFullName,
            securityUserType: this.$t(value.securityUserType),
          };
        });
        this.oldStatus = this.model.status;
        this.model.newStatus = this.model.status;
        this.distributorStructureService.setDistributorId(
          this.model.distributorId
        );
        this.distributorStructureService.setNodeViewIds([
          this.model.distributorStructureViewId,
        ]);
        if (this.model.distributorStructureViewId) {
          this.distributor.structures.push({
            distributorStructureViewId: this.model.distributorStructureViewId,
          });
          this.afterDistributorStructureEdit = true;
        }
        this.initModel = _.cloneDeep(this.model);
      }
    },
    saveDraft() {
      this.setModelAndSave("Draft");
    },
    publish() {
      this.setModelAndSave("New");
    },
    setModelAndSave(statusCode) {
      this.model.newStatus = statusCode;
      this.save();
    },
    save(dontShowCopyModal = false) {
      if (
        this.parentTasksExist &&
        !dontShowCopyModal &&
        !this.masterId &&
        this.copySourceDistributorId &&
        this.copySourceDistributorId === this.model.distributorId
      ) {
        this.showCopyCompetenceTaskMessage = true;
        return;
      }

      this.loading = true;
      const isCreate = !this.masterId;
      let saveMethod = isCreate ? "create" : "update";

      this.model.status = this.model.newStatus;

      if (this.model && this.model.levelDevelopment != "Filial") {
        this.model.distributorStructureViewId = null;
        this.model.distributorStructureViewName = null;
      }

      const copyTaskCompetenceId =
        this.copyCompetenceRadioButtonModel === "true" &&
        !this.masterId &&
        this.copySourceDistributorId === this.model.distributorId
          ? this.copySourceId
          : null;

      if (copyTaskCompetenceId && !this.masterId)
        saveMethod = "createcompetencewithtaskcopies";

      this.startDateUpdate();
      this.endDateUpdate();

      this.competenceService[saveMethod](this.model, copyTaskCompetenceId)
        .then(async (result) => {
          if (
            copyTaskCompetenceId &&
            !this.masterId &&
            result.data.isTasksDatesNotEqualToOriginal
          ) {
            this.showCopiedTaskDatesChanged = true;
          }
          this.afterSave(
            result,
            isCreate,
            copyTaskCompetenceId && !this.masterId ? true : false
          );
        })
        .catch(() => {
          this.loading = false;
        });
    },
    afterSave(result, isCreate, isCopied) {
      if (isCreate) {
        this.masterId = !isCopied
          ? result.data[0].id
          : result.data.competences[0].id;
      }
      this.getData().then(() => {
        this.setAllAccess(true);
      });

      if (this.modelTab.systemName === "tasks")
        this.competenceTaskGrid.getRecords(true);
      this.persistRecipients = true;
      this.recipientService.fromView = false;
      this.recipientService.filter = this.recipientGridFilter;
      this.loading = false;

      if (this.model.status != "New") {
        this.$refs["status-ProSpaceDropdown"].localOptions = this.$refs[
          "status-ProSpaceDropdown"
        ].localOptions.filter((object) => {
          return object.code !== "New";
        });
      }
    },
    submitFilter(nameRef = "grid") {
      if (!this.$refs[nameRef]) return;
      this.$refs[nameRef].getRecords(true);
    },
    backHandler() {
      this.$router.go(-1);
    },
    showReasonModal(callback, title) {
      this.modalCallback = async () => {
        await callback();
        this.modalCallback = null;
      };
      this.modalTitle = title;
      this.modalShow = true;
    },
    async addComment() {
      await this.competenceCommentService.create(this.commentItem);
      this.modalModel = this.emptyComment();
      this.getData();
      this.mountAccess();
    },
    saveComment() {
      this.modalCallback();
    },
    cancelComment() {
      this.modalModel = this.emptyComment();
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "draft":
          type = "default";
          break;
        case "new":
          type = "light-blue";
          break;
        case "inprogress":
          type = "warning";
          break;
        case "completed":
          type = "success";
          break;
        case "canceled":
          type = "default";
          break;
        default:
          type = "default";
      }
      return {
        text: this.$t(("competenceStatus" + status).toLowerCase()),
        type,
      };
    },
    showOrgStructureSelect() {
      this.updateSelectedTreeNodes();
      this.$refs.filterModal.nodeFilter = { ...this.nodeFilter };
      this.$refs.filterModal.gridFilter = { ...this.gridFilter };
      this.$refs.filterModal.selectedTreeNodes = { ...this.selectedTreeNodes };
      this.$nextTick(() => {
        this.showOrgStructureSelectModal = true;
      });
    },
    deleteFromFilter(item) {
      this.$refs.recipientGrid.records =
        this.$refs.recipientGrid.records.filter(
          (r) => !(r.id === item.id && r.recordType === item.recordType)
        );
      if (this.$refs.recipientGrid.records.length === 0) {
        this.nodeFilter = {
          add: {},
          remove: {},
        };
        this.gridFilter = {
          add: {},
          remove: {},
        };
      } else {
        let key = `${item.id}|${item.recordType}`;
        if (Object.keys(this.gridFilter.add).includes(key)) {
          delete this.gridFilter.add[key];
        } else {
          this.gridFilter.remove[key] = item;
        }
      }
      let nodeSelection = JSON.parse(this.model.nodeSelection);
      nodeSelection = this.updateSelectedTreeNodes(nodeSelection);
      this.oDataStringFilter = this.convertFilterToOdata();

      this.model.nodeSelection = JSON.stringify(nodeSelection);
      this.model.filter = JSON.stringify({
        nodeFilter: this.nodeFilter,
        gridFilter: this.gridFilter,
      });
      this.model.oDataFilter = this.oDataStringFilter;
      this.persistRecipients = false;
      this.recipientService.fromView = true;
      this.recipientService.filter = this.recipientGridFilter;
      this.feelNeedFilter();
    },
    closeOrgStructureSelectModal() {
      // баг с применением
      this.nodeFilter = { ...this.$refs.filterModal.nodeFilter };
      this.gridFilter = { ...this.$refs.filterModal.gridFilter };
      this.selectedTreeNodes = {
        ...this.$refs.filterModal.$refs.tree.checkedNodes,
      };
      this.oDataStringFilter = this.convertFilterToOdata();
      Object.values(this.nodeFilter).forEach((o) => delete o.children);
      this.model.filter = JSON.stringify({
        nodeFilter: this.nodeFilter,
        gridFilter: this.gridFilter,
      });
      this.model.nodeSelection = JSON.stringify(this.selectedTreeNodes);
      this.model.oDataFilter = this.oDataStringFilter;
      this.persistRecipients = false;
      this.recipientService.fromView = true;
      this.recipientService.filter = this.recipientGridFilter;
      this.$refs.recipientGrid.records = [];
      this.feelNeedFilter();
      if (this.model.needFilter) this.$refs.recipientGrid.getData();
      this.showOrgStructureSelectModal = false;
    },
    convertFilterToOdata() {
      const nodeAdd = Object.values(this.nodeFilter.add);
      const usersAdd = Object.values(this.gridFilter.add);
      const nodeRemove = Object.values(this.nodeFilter.remove);
      const usersRemove = Object.values(this.gridFilter.remove);
      let nodeAddStrings = "";
      let usersAddStrings = usersAdd
        .map((e) => `(recordTypeId eq '${e.recordType + "-" + e.id}')`)
        .join(" or ");
      let usersRemoveStrings = usersRemove
        .map((e) => `(recordTypeId ne '${e.recordType + "-" + e.id}')`)
        .join(" and ");
      let getIdFilter = (n, o) => {
        if (n.type === "RealDistributor")
          return `and distributorId ${o} ${n.id}`;
        if ([9223372036854775000, -9223372036854775000].includes(n.id))
          return `and orgId ${o} null`;
        return `and orgId ${o} ${n.id}`;
      };
      let nodeRemoveStrings = nodeRemove
        .map((e) => `(type eq '${e.type}' ${getIdFilter(e, "ne")})`)
        .join(" and ");
      if (!nodeAdd.find((e) => e.key === "all"))
        nodeAddStrings = nodeAdd
          .map((e) => `(type eq '${e.realType}' ${getIdFilter(e, "eq")})`)
          .join(" or ");

      let filter = "";
      if (nodeAddStrings || nodeRemoveStrings)
        filter =
          "(" +
          [nodeAddStrings, nodeRemoveStrings]
            .filter((e) => e)
            .map((e) => `(${e})`)
            .join(" and ") +
          ")";

      if (usersAddStrings) {
        if (filter) filter = `${filter} or `;
        filter = `${filter} (${usersAddStrings})`;
      }

      if (usersRemoveStrings) {
        if (filter) filter = `(${filter}) and `;
        filter = `${filter} (${usersRemoveStrings})`;
      }
      if (filter) filter = "$filter=" + filter;
      return filter;
    },
    updateSelectedTreeNodes(nodeSelection) {
      let selectedTreeNodes = nodeSelection || this.selectedTreeNodes;
      if (
        Object.keys(this.nodeFilter.add).length === 0 &&
        Object.keys(this.gridFilter.add).length === 0
      ) {
        if (selectedTreeNodes) {
          selectedTreeNodes = {};
        }
        return;
      }
      const fullRecords = this.$refs.recipientGrid.records;
      const listTreeNodes = {
        all: {
          checked: false,
          partialChecked: true,
        },
      };

      fullRecords.forEach((r) => {
        const keys = r.fullPath.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      });
      Object.values(this.nodeFilter.add).forEach((n) => {
        const keys = n.path.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      });
      // check=true all nodeFilter.add and children
      // баг если дочерние check, parent uncheck, см свой чат
      // listTreeNodes добавлять из через this.nodeFilter.add (нет некоторых узлов)
      const checkChildren = (parentKey) => {
        const children = Object.entries(listTreeNodes)
          .filter(([k, v]) => v.parentKey === parentKey)
          .map(([k]) => k);
        if (children.length === 0) {
          return;
        }
        children.forEach((k) => {
          checkChildren(k);
          listTreeNodes[k] = {
            checked: true,
            partialChecked: false,
            parentKey: parentKey,
          };
        });
        return;
      };
      Object.entries(this.nodeFilter.add).forEach(([key, value]) => {
        listTreeNodes[key] = {
          checked: true,
          partialChecked: false,
          parentKey: value.parentId,
        };
        checkChildren(key);
      });

      // partialChecked=true if parent has removed child
      const removesParentKeys = [];
      Object.values(this.gridFilter.remove).forEach((val) => {
        const arr = val.fullPath.split("|");
        const parentKey = arr[arr.length - 2];
        if (!removesParentKeys.includes(parentKey)) {
          removesParentKeys.push(parentKey);
        }
      });
      const uncheckParent = (childKey) => {
        const child = listTreeNodes[childKey];
        if (!child) {
          return;
        }
        if (child.checked) {
          child.checked = false;
          child.partialChecked = true;
        }
        const parent = listTreeNodes[child.parentKey];
        if (!parent) {
          return;
        }
        if (parent.checked) {
          parent.checked = false;
          parent.partialChecked = true;
          uncheckParent(parent.parentKey);
          return;
        }
        return;
      };
      removesParentKeys.forEach(uncheckParent);

      selectedTreeNodes = listTreeNodes;
    },
    feelNeedFilter() {
      this.model.needFilter =
        Object.keys(this.nodeFilter.add).length > 0 ||
        Object.keys(this.nodeFilter.remove).length > 0 ||
        Object.keys(this.gridFilter.add).length > 0 ||
        Object.keys(this.gridFilter.remove).length > 0;
      this.recipientService.needFilter = this.model.needFilter;
    },
    competenceTaskSelectedHandler(val) {
      this.selectionChanged(val, true);
    },
    selectionChanged(val, isTaskSelected) {
      if (!val.hasSelect) {
        this.selectedMultipleGridHandler({});
        this.isCompetenceTaskSelected = false;
      } else {
        this.selectedMultipleGridHandler(val);
        this.isCompetenceTaskSelected = isTaskSelected;
      }
    },
    handleCompetenceTaskDots(action, item) {
      var option = this.competenceTaskOptions.find((o) => o.name === action);
      option.method(item);
    },
    showCreateTaskModal() {
      this.taskModalId = null;
      this.taskModalCompetence = {
        Id: this.model.id,
        CompetenceDirectoryName: this.model.competenceDirectoryName,
        Status: this.model.status,
      };
      this.taskModalCompetenceStartAt = new Date(this.model.startAt);
      this.taskModalCompetenceEndAt = new Date(this.model.endAt);
      this.showTaskModal = true;
    },
    showEditTaskModal(competenceTask) {
      this.taskModalId = competenceTask.id;
      this.taskModalCompetence = null;
      this.taskModalCompetenceStartAt = new Date(this.model.startAt);
      this.taskModalCompetenceEndAt = new Date(this.model.endAt);
      this.showTaskModal = true;
    },
    showCompetenceTaskHistory(record) {
      this.$refs.competenceTaskHistory.tabs[0].masterId = record.id;
      this.$refs.competenceTaskHistory.masterName = record.name;
      this.$nextTick(() => {
        this.showCompetenceTaskHistoryModal = true;
      });
    },
    closeHistory() {
      this.showCompetenceTaskHistoryModal = false;
    },
    localWrapperCheckRoles(dotsOptions, data) {
      return this.wrapperStatusCheckRoles(dotsOptions, data);
    },
    handleCompetenceTaskModalFormSavedData() {
      this.getData().then(() => {
        this.competenceTaskGrid.getRecords(true);
        if (this.$refs["right-panel"]) this.$refs["right-panel"].update();
      });
    },
    formatMomentDate(dateString) {
      return moment(dateString).format(`${this.userinfo.dateFormat}`);
    },
    startDateUpdate() {
      if (this.model.startAt !== null) {
        let momentDate = moment(this.model.startAt);
        if (
          momentDate.hours() == 0 &&
          momentDate.minutes() == 0 &&
          momentDate.seconds() == 0
        ) {
          this.model.startAt = momentDate.format("YYYY-MM-DDTHH:mm:ss");
          return;
        }

        let startMomentDate = momentDate.startOf("day");
        this.model.startAt = startMomentDate.format("YYYY-MM-DDTHH:mm:ss");
      }
    },
    endDateUpdate() {
      if (this.model.endAt !== null) {
        let momentDate = moment(this.model.endAt);
        if (
          momentDate.hours() == 23 &&
          momentDate.minutes() == 59 &&
          momentDate.seconds() == 59
        ) {
          this.model.endAt = momentDate.format("YYYY-MM-DDTHH:mm:ss");
          return;
        }

        let endMomentDate = momentDate.endOf("day");
        this.model.endAt = endMomentDate.format("YYYY-MM-DDTHH:mm:ss");
      }
    },
  },
  computed: {
    optionsPanel() {
      return {
        fields: {
          Subtasks: {
            type: "modal",
            modalType: "checkbox",
            value: (value) => (value ? this.$t("openList") : ""),
          },
          Attachments: {
            type: "tab-actual",
            value: this.$t("openList"),
            modelName: "CompetenceTaskAttachmentApiModel",
            filterField: "CompetenceTaskId",
            filterValueField: "Id",
          },
          FileLength: {
            value: formatBytes,
          },
          FileName: {
            type: "action",
            fnAction: (value) => {
              const file = {
                name: value.FileName,
                gfid: value.GFID,
              };
              downloadFile(file);
            },
          },
          StartAt: {
            type: "date",
          },
          EndAt: {
            type: "date",
          },
          CompetenceTaskAttachmentApiModel: {
            type: "tab",
            value: this.$t("openList"),
          },
          CompetenceRecipientApiModel: {
            type: "tab",
            value: this.$t("openList"),
          },
        },
      };
    },
    copyTaskFlashMessageRadioValue() {
      return [
        {
          value: true,
          label: this.$t("createTaskCopies"),
        },
        {
          value: false,
          label: this.$t("doNotCreateTaskCopies"),
        },
      ];
    },
    headerBlockCompetenceDirectories() {
      return {
        title: this.$t("commonInformation"),
        isExpand: false,
      };
    },
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDate: "userinfoStore/getFormatDate",
      access: "securityRoleAccess/access",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    tabsDistributor() {
      return [
        {
          name: this.$t("mainInformation"),
          isActive: true,
          systemName: "mainInformation",
        },
        { name: this.$t("requisites"), systemName: "requisites" },
      ];
    },
    refLoading() {
      return this.mixinLoadingAccess || this.statusMixinLoadingAccess;
    },
    refsForCheckInMixin() {
      let refs = [];

      if (this.modelTab.systemName === "parameters") {
        if (
          !(
            this.isCreation ||
            this.isDraft ||
            this.isComplete ||
            this.isCanceled
          )
        )
          refs.push("status-ProSpaceDropdown");

        refs.push(
          "competenceDirectories-ProSpaceSearchfield",
          "levelDevelopment-ProSpaceDropdown",
          "competenceDirectoryBlock-ProSpaceInputText",
          "competenceDirectoryLevel-ProSpaceInputText",
          "competenceDirectoryType-ProSpaceInputText",
          "competenceDirectoryDescription-ProSpaceExtendedTextArea",
          "competenceDirectoryAdvancementRecommendation-ProSpaceExtendedTextArea",
          "startAt-ProSpaceInputCalendar",
          "endAt-ProSpaceInputCalendar",
          "developmentPercentage-ProSpaceInputNumber",
          "businessGoal-ProSpaceExtendedTextArea",
          "expectedResult-ProSpaceExtendedTextArea",
          "metrics-ProSpaceExtendedTextArea"
        );
      }

      if (this.modelTab.systemName === "filial") {
        refs.push("distributorStructures-DistributorHierarchySelectForm");
      }

      if (
        this.modelTab.systemName === "tasks" &&
        !(this.isCreation || this.isDraft)
      ) {
        refs.push("showCreateTaskModal-ProSpaceButton");
      }

      if (this.modelTab.systemName === "subscribes") {
        refs.push("addRecipient-button");
      }

      return refs;
    },
    layoutName() {
      return this.masterId
        ? this.model.competenceDirectoryName
        : this.$t("competenceNew");
    },
    overviewTabs() {
      return [
        {
          name: this.$t("parameters"),
          systemName: "parameters",
          isActive: this.modelTab.systemName === "parameters",
        },
        {
          name: this.$t("branch"),
          systemName: "filial",
          isActive: this.modelTab.systemName === "filial",
          isHidden:
            !this.model.distributorId ||
            this.model.levelDevelopment != "Filial",
        },
        {
          name: this.$t("tasks"),
          systemName: "tasks",
          isDisabled: this.isCreation || this.isDraft || this.isCopy,
          isActive: this.modelTab.systemName === "tasks",
        },
        {
          name: this.$t("competenceSubscribeTabTxt"),
          systemName: "subscribes",
          isDisabled: this.isCreation,
          isHidden: !this.checkAccess("tab-subscribes-ProSpaceTabs"),
          isActive: this.modelTab.systemName === "subscribes",
        },
      ];
    },
    panelBlockHeader() {
      return {
        title: this.model.competenceDirectoryName,
        status: this.getStatus(this.model.status),
      };
    },
    distributorFilterService() {
      return [
        {
          type: "chips",
          model: "Code",
          label: this.$t("distributorCode"),
          selected: {},
        },
        {
          type: "chips",
          model: "LegalAddress",
          label: this.$t("distributorLegalAddress"),
          selected: {},
        },
        {
          type: "chips",
          model: "MailAddress",
          label: this.$t("distributorMailAddress"),
          selected: {},
        },
        {
          type: "chips",
          model: "PhoneNumber",
          label: this.$t("distributorPhoneNumber"),
          selected: {},
        },
        {
          type: "chips",
          model: "FaxNumber",
          label: this.$t("distributorFaxNumber"),
          selected: {},
        },
        {
          type: "chips",
          model: "emailAddress",
          label: this.$t("email"),
          selected: {},
        },
        {
          type: "checkboxes",
          service: new DistributorService(),
          getMethod: "getEDOs",
          model: "EDOProviderName",
          label: this.$t("distributorEDO"),
          optionValue: "value",
          optionLabel: "label",
          selected: {},
        },
        {
          type: "chips",
          model: "BankName",
          label: this.$t("distributorBankName"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankPaymentAccount",
          label: this.$t("distributorBankPaymentAccount"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankCorrespondentAccount",
          label: this.$t("distributorBankCorrespondentAccount"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankBIC",
          label: this.$t("distributorBankBIC"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankINN",
          label: this.$t("distributorBankINN"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankKPP",
          label: this.$t("distributorBankKPP"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankOGRN",
          label: this.$t("distributorBankOGRN"),
          selected: {},
        },
        {
          type: "chips",
          model: "createdBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "chips",
          model: "updatedBy",
          label: this.$t("updatedByTxt"),
          selected: {},
        },
        {
          type: "dates-range",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
    competenceDirectoryFilterService() {
      const filters = [
        {
          type: "chips",
          model: "description",
          label: this.$t("competenceDirectoryDescription"),
          selected: {},
        },
        {
          type: "chips",
          model: "advancementRecommendation",
          label: this.$t("competenceDirectoryAdvancementRecommendation"),
          selected: {},
        },
      ];
      filters.push(
        {
          type: "chips",
          model: "createdBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "chips",
          model: "updatedBy",
          label: this.$t("updatedByTxt"),
          selected: {},
        }
      );

      filters.push(
        {
          type: "dates-range-time",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range-time",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        }
      );
      return filters;
    },
    recipientGridFilter() {
      if (this.persistRecipients)
        return `$filter=CompetenceId eq ${Number(
          this.masterId
        )} and disabled eq false`;

      return this.oDataStringFilter;
    },
    isCreation() {
      return !(this.model && this.model.id);
    },
    isCopy() {
      return this.copySourceId && !this.masterId;
    },
    isDraft() {
      return this.model && this.model.id && this.model.status === "Draft";
    },
    isComplete() {
      return this.model && this.model.id && this.model.status === "Completed";
    },
    isCanceled() {
      return this.model && this.model.id && this.model.status === "Canceled";
    },
    disabledDraftSave() {
      return (
        this.v$.model.distributorId.$invalid ||
        this.v$.model.competenceDirectoryId.$invalid ||
        this.v$.model.developmentPercentage.$invalid ||
        this.v$.model.startAt.$invalid ||
        this.v$.model.levelDevelopment.$invalid ||
        this.v$.model.endAt.$invalid
      );
    },
    disabledPublish() {
      return this.v$.model.$invalid;
    },
    disabledSave() {
      return this.v$.model.$invalid || _.isEqual(this.model, this.initModel);
    },
    statusDropdownArgMethod() {
      const userTypeFilter =
        this.model.status == "New"
          ? "code ne 'Draft'"
          : "code ne 'Draft' and code ne 'New'";
      return `$filter=systemDirectoryCode eq 'CompetenceStatus' and ${userTypeFilter} and disabled eq false`;
    },
    commentItem() {
      return {
        comment: this.modalModel.comment || "",
        competenceId: this.model.id,
        createdAt: new Date(),
      };
    },
    mapExpandStatuses() {
      return new Map([
        ["New", { type: "blue", text: this.$t("competencetaskstatusnew") }],
        [
          "Approving",
          { type: "teal", text: this.$t("competencetaskstatusapproving") },
        ],
        [
          "RejectedByDistributor",
          {
            type: "error",
            text: this.$t("competencetaskstatusrejectedbydistributor"),
          },
        ],
        [
          "Planning",
          { type: "purple", text: this.$t("competencetaskstatusplanning") },
        ],
        [
          "InProgress",
          { type: "warning", text: this.$t("competencetaskstatusinprogress") },
        ],
        [
          "CompleteApproving",
          {
            type: "teal",
            text: this.$t("competencetaskstatuscompleteapproving"),
          },
        ],
        [
          "RejectedByMars",
          {
            type: "error",
            text: this.$t("competencetaskstatusrejectedbymars"),
          },
        ],
        [
          "Completed",
          { type: "success", text: this.$t("competencetaskstatuscompleted") },
        ],
        [
          "Canceled",
          { type: "draft", text: this.$t("competencetaskstatuscanceled") },
        ],
      ]);
    },
    competenceTaskOptions() {
      return [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.showEditTaskModal,
          liteRef: "editTask-option",
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showCompetenceTaskHistory,
        },
      ];
    },
    copyingProperties() {
      return [
        "competenceDirectoryId",
        "competenceDirectoryName",
        "competenceDirectoryLevel",
        "competenceDirectoryType",
        "competenceDirectoryBlock",
        "competenceDirectoryDescription",
        "competenceDirectoryAdvancementRecommendation",
        "businessGoal",
        "expectedResult",
        "metrics",
        "distributorId",
        "distributorName",
      ];
    },
  },
};
</script>
<style lang="scss">
.prospace-competence-input-layout {
  .prospace-input-layout__label {
    font-weight: 400;
  }
}
</style>
