<template>
  <div class="prospace-scrollbar" :style="`width: 100%; height: 100%; overflow: auto;`">
    <table
      v-if="tableData"
      :class="['dashboard-table dashboard-table--simple', { 'dashboard-table--empty': !tableData || tableData.length === 0 }]" >
      <thead>
      <tr>
        <th v-for="th in tableColumns" :key="th">
          <div
            class="dashboard-table__th dashboard-table__th--sorting"
            @click="handleSortClick(th.key)"
          >
            <span v-hint="th.label">{{ th.label }}</span>
            <div class="dashboard-table__sorting">
              <ProSpaceIcon
                v-show="isVisibleSortIcon('asc', th)"
                class="dashboard-table__sort-icon"
                icon="sort-up"
                iconWidth="10px"
                iconHeight="10px"
                customIconColor="var(--prospace-ui-main)"
              />
              <ProSpaceIcon
                v-show="isVisibleSortIcon('desc', th)"
                class="dashboard-table__sort-icon"
                icon="sort-down"
                iconWidth="10px"
                iconHeight="10px"
                customIconColor="var(--prospace-ui-main)"
              />
            </div>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
        <DashboardTableRow
          v-for="row in tableData"
          :key="row.id"
          :row="row"
          :columns="tableColumns"
          :tagRows="tableTagRows"
          :isSelected="selectedRow === row.id"
          :backgroundColor="tableBackgroundColors[row.id]"
          :broker="broker"
        />
      </tbody>
    </table>
    <ProSpaceEmptyGrid
      v-if="!tableData || tableData.length === 0"
      icon="error-message"
      :title="$t('noDataTitle')"
      :text="$t('noDataTxt')"
    />
  </div>
</template>

<script>
import DashboardTableRow from "./DashboardTableRow.functional.js";
import {
  ProSpaceIcon,
  ProSpaceEmptyGrid,
  Hint,
} from "@prospace/prospace-components-library";
import { ref, onMounted, watch, computed } from 'vue';
import { useCompareData } from "./composes/useCompareData";

export default {
  name: "DashboardTable",
  components: {
    ProSpaceIcon,
    ProSpaceEmptyGrid,
    DashboardTableRow
  },
  directives: {
    hint: Hint
  },
  props: {
    rowData: {
      type: Array,
      default: null,
    },
    columns: {
      type: Array,
      default: null,
    },
    backgroundColors: {
      type: Object,
      default: null
    },
    tagRows: {
      type: Object,
      default: null,
    },
    sort: {
      type: Object,
      default: null
    },
    broker: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const tableData = ref([]);
    const tableColumns = ref([]);
    const selectedRow = ref(null);
    const tableBackgroundColors = ref({});
    const tableTagRows = ref({});
    const count = ref(null);
    const sortState = ref({
      column: null,
      order: null
    });
    const naturalCompare = props.broker.naturalCompare;
    const isValidDate = props.broker.isValidDate;
    const { sortData } = useCompareData(naturalCompare, isValidDate);


    watch(props.rowData, (val) => {
        tableData.value = val;
      },
      { immediate: true }
    )

    watch(props.columns, (val) => {
        tableColumns.value = val;
        count.value = val.length;
      },
      { immediate: true }
    )

    watch(props.backgroundColors, (val) => {
        tableBackgroundColors.value = val;
      },
      { immediate: true }
    )

    watch(props.tagRows, (val) => {
        tableTagRows.value = val;
      },
      { immediate: true }
    )

    watch(props.sort, (val) => {
      const { field, direction } = val;
      sortState.value.column = field;
      sortState.value.order = direction;
    },
      { immediate: true }
    )

    const columnStyle = computed(() => {
      const tableWidth = count.value * 100;
      const columnWidth = tableWidth / count.value;
      return `max-width: ${columnWidth}px; min-width: ${columnWidth}px;`;
    });

    const isVisibleSortIcon = (state, th) => {
      return (sortState.value.column === th.key && sortState.value.order === state);
    }

    const handleSortClick = (column) => {
      if (sortState.value.column === column) {
        if (sortState.value.order === 'asc') {
          sortState.value.order = 'desc';
        } else {
          sortState.value.order = 'asc';
        }
      } else {
        sortState.value.column = column;
        sortState.value.order = 'asc';
      }

      tableData.value = sortData(tableData.value, column, sortState.value.order);
    };

    return {
      tableData,
      selectedRow,
      tableBackgroundColors,
      tableColumns,
      columnStyle,
      tableTagRows,
      count,
      handleSortClick,
      sortState,
      isVisibleSortIcon
    }
  },
  data() {
    return {
      tableWidth: null,
      maxTableWidth: null
    }
  },
  computed: {
    calcColWidth() {
      const maxColWidth = 300;
      const columnCount = this.tableColumns?.length || 1;

      return this.tableWidth % maxColWidth > 0
        ? this.tableWidth
        : maxColWidth * columnCount;
    },
    getTableStyle() {
      return `max-width: ${this.calcColWidth}px;`;
    }
  },
  methods: {

  },
  mounted() {
    this.tableWidth = this.$el.getBoundingClientRect().width;
  }
}
</script>

<style lang="scss">
.dashboard-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: separate;
  background: var(--prospace-ui-bg);
  thead {
    position: sticky;
    top: 0px;
    background-color: var(--prospace-ui-bg);
    z-index: 11;
    tr {
      &:first-child {
        th {
          &:before {
            border-top: 0;
          }
          &:after {
            border-bottom: 0;
          }
        }
      }
      th {
        padding: 5px;
        font-size: 11px;
        line-height: 16px;
        color: var(--prospace-dashboard-text-black);
        span {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  th,
  td {
    line-height: 12.89px;
    text-align: center;
    min-width: 100px;
    max-width: 300px;
    white-space: nowrap;
    padding: 10px 5px;
    font-size: 11px;

    span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
  th {
    position: relative;
    color: var(--prospace-text-main);
    font-style: normal;
    font-weight: 400;
    border: 0.5px solid var(--prospace-ui-border-color);
  }
  td {
    font-size: 11px;
    font-weight: 400;
    border-top: 0.5px solid var(--prospace-ui-border-color);
    border-bottom: 0.5px solid var(--prospace-ui-border-color);
    border-left: 0.5px solid var(--prospace-ui-border-color);
    border-right: 0.5px solid var(--prospace-ui-border-color);
    color: var(--prospace-dashboard-text-grey);

    .prospace-tag {
      max-width: 80px;
    }
  }

  &__sorting {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-left: 5px;
  }

  &__sort-icon {
    &:first-child {
      margin-bottom: -3px;
    }
  }

  &__th {
    &--sorting {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      span {
        width: auto !important;
      }
    }
  }
  &--simple {
    table-layout: auto;
  }
  &--empty {
    & + .prospace-empty-grid {
      height: calc(100% - 40px);
    }
  }
}
</style>
