<template>
  <DistributorContactPersonGrid class="disrtributor-another-contact-person"
    masterField="distributorId"
    cpAbbreviation="ocp"
    :model="distributor"
    :service="anotherContactPersonService"
    :mountAccess="mountAccess"
    :checkAccess="checkAccess"
    :validationProps="validations"
    :fields="fields"
    :modalFields="modalFields"
    :modalHeader="$t('anotherPerson')"
    :mainTxt="$t('anotherContactPersonCreateInfo')"
  />
</template>
<script>
import DistributorContactPersonGrid from "./DistributorContactPersonGrid";
import { AnotherContactPersonService } from "../../../services/AnotherContactPersonService.js";
import {
  required,
  maxLength,
  minLength,
  helpers,
  email,
  minValue,
  maxValue,
} from "@vuelidate/validators";

export default {
  components: {
    DistributorContactPersonGrid,
  },
  props: {
    distributor: {
      type: Object,
      required: true,
    },
    mountAccess: {
      type: Function,
      required: true,
    },
    checkAccess: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      anotherContactPersonService: new AnotherContactPersonService(),
    };
  },
  computed: {
    validations() {
      return {
        distributorId: {},
        fio: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
        },
        position: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
        },
        phone: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          )
        },
        phoneAdditional: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
        },
        email: {
          email: helpers.withMessage(
            this.$t("notValidEmail"),
            email
          ),
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
        },
      };
    },
    modalFields() {
      return [
        [
          {
            name: "position",
            displayName: this.$t("positionFull"),
            placeholder: 'Руководитель отдела продаж'
          },
        ],
        [
          {
            name: "fio",
            displayName: this.$t("fioFull"),
            placeholder: 'Иванов Иван Иванович'
          },
        ],
        [
          {
            name: "phone",
            displayName: this.$t("phone") + " 1",
            placeholder: '(000) 123-45-67 доб. 123'
          },
        ],
        [
          {
            name: "phoneAdditional",
            displayName: this.$t("phone") + " 2",
            placeholder: '(000) 123-45-67 доб. 123'
          },
        ],
        [
          {
            name: "email",
            displayName: this.$t("email"),
            placeholder: 'ivan.ivanov@company.com'
          },
        ],
      ];
    },
    fields() {
      return [
        {
          value: "position",
          name: this.$t("positionFull"),
          f: (item) => item,
        },
        {
          value: "fio",
          name: this.$t("fioFull"),
          f: (item) => item,
          class: 'text-bold'
        },
        {
          value: "phone",
          name: this.$t("phone"),
          additionalValue: 'phoneAdditional',
          f: (item) => item,
        },
        {
          value: "email",
          name: this.$t("email"),
        },
      ];
    },
  },
};
</script>
<style lang="scss">
.disrtributor-another-contact-person {
  .prospace-default-text-column {
    height: 50%;
  }
}
</style>
