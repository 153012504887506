<template>
  <ProSpaceGeoLayout :routeName="$route.name" isParent>
    <template #top>
      <ProSpaceTopPanel :header="$t('users')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            @click="actionUpdate"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
          />
          <IconButtonSettingsColumns @click="showModalSettingsColumns"/>
          <ProSpaceDots
            :options="dotsHeaderOptions"
            :isSquare="true"
            @action="(action) => handleHeaderDots(action)"
          />
          <ProSpaceButton
            type="primary"
            icon="plus"
            :label="$t('newTxt')"
            @click="showChangeModal"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :searchPlaceholder="$t('searchTxt')"
            :closeTxt="$t('closeTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            :unicKeyStorage="userService.constructor.name + 'grid'"
            @apply="submitFilter"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
              }
            "
            ref="filterPanel"
            :filter-model="[
              'firstName',
              'secondName',
              'lastName',
              'fullName',
              'login',
              'email',
            ]"
            :blocked-filters="needBlock"
            @block="selectedMultipleBlockFilterHandler"
          >
            <template #hint> {{ $t("filterBy") }}</template>
            <template #filters="{ filter }">
              <ProSpaceFilterSearchfield
                v-model="filter.restrictionFieldValue"
                :closeTxt="$t('closeTxt')"
                :cancelTxt="$t('cancelInf')"
                :placeholderValue="$t('distributor')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :service="distributorService"
                :txtTitleModal="$t('selectDistributors')"
                :txtNameColumn="$t('distributor')"
                get-method="grid"
                optionLabel="name"
                optionValue="code"
                :hint="$t('hintFilterDistrUsers')"
                @apply="emitFilter"
              />
              <ProSpaceMultiSelect
                :localization="$t"
                v-model="filter.status"
                :service="statusService"
                getMethod="get"
                optionLabel="text"
                optionValue="status"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :placeholderValue="$t('status')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :searchPlaceholder="$t('searchTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterSearchfield
                v-model="filter.distributorStructureId"
                :closeTxt="$t('closeTxt')"
                :placeholderValue="$t('branch')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :service="distributorStructureService"
                :txtTitleModal="$t('distrsAndBranches')"
                :txtNameColumn="$t('distributor')"
                get-method="getStructure"
                optionLabel="name"
                optionValue="id"
                mode="tree"
                :hint="$t('hintFilterBranchUsers')"
                @apply="emitFilter"
              >
                <template #modal="{model, setModel, applyFn, selectedArray, isWatchMode}">
                  <DistributorHierarchySelectModal
                    :modelValue="model"
                    :service="distributorStructureService"
                    :checkedRows="selectedArray"
                    :title-modal="$t('distrsAndBranches')"
                    get-method="allTree"
                    expandAll
                    :read-only="isWatchMode"
                    @update:modelValue="setModel"
                    @click.stop
                    @apply="applyFn"
                  />
                </template>
              </ProSpaceFilterSearchfield>
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="userService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :service="userService"
            :noMountedRequest="true"
            :selectionMode="isDeletedTab ? 'single' : 'multiple'"
            defaultColumnWidths="500"
            v-bind="gridPropsSettingsColumns"
            @selected-change="selectedHandler"
            @db-click="toItem"
          >
            <template #columns>
              <Column
                field="fullName"
                :header="$t('user')"
                sortable
                headerClass="move-sort-head"
                frozen
                class="column-table-header-cell"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    @click-on-title="toItem(field.data)"
                    :status="getStatus(field.data.status)"
                    :title="
                      field.data.secondName
                        ? `${field.data.lastName} ${field.data.firstName} ${field.data.secondName}`
                        : `${field.data.lastName} ${field.data.firstName}`
                    "
                    :description="`${getPosition(field.data)}`"
                  />
                </template>
              </Column>
              <Column field="email" :header="$t('holderEmail')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.email">
                    {{ field.data.email }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="login" :header="$t('login')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.login">
                    {{ field.data.login }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column
                field="securityLoginAuthenticateProvider"
                :header="$t('loginType')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.securityLoginAuthenticateProvider }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="orgLinkType" :header="$t('userType')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="getTranslatedOrgLinkType(field.data.orgLinkType)">
                    {{ getTranslatedOrgLinkType(field.data.orgLinkType) }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="createdAt" sortable :header="$t('createdDate')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column field="actions" class="column-end column-end-30" frozen alignFrozen="right">
                <template #body="field">
                  <ProSpaceDots
                    :options="getRawDotsOptions([field.data])"
                    @action="(action) => handleDots(action, field.data)"
                  ></ProSpaceDots>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
      <ProSpaceGridModalForm
        v-model="showRoleModal"
        width="410"
        @apply="(mappingModel) => sendGridModal(mappingModel)"
        @apply:delete="toDeletingRoles($event)"
        :service="roleService"
        :preService="securityUserRole"
        :id="selectedData?.id"
        :key="selectedData?.id"
        preGetMethod="get"
        :mappingField="modalFormMappingField"
        getMethod="grid"
        colName="systemName"
        colDesc="Name"
        :header="getTitleModalRole"
        :applyTxt="$t('applyTxt')"
        :cancelTxt="$t('cancelInf')"
        :searchTxt="$t('searchTxt')"
        :showAll="true"
        :defaultFilter="{ disabled: { and: [false] } }"
      >
      </ProSpaceGridModalForm>
      <ModalUser ref="modal-user" @close="closeModalUser" />
      <ModalBlockFilters />
    </template>
    <template #right>
      <UserPanel
        v-if="hasSelect"
        ref="right-panel"
        :service="userService"
        :options="rawDotsOptions"
        :counter-roles="getCounterRoles"
        @addRole="showModalAddRole($event)"
        @edit="editItem"
        @clear="selectedMultipleReset"
        @reset-password-single="resetPasswordSingle"
        @reset-password-multiple="resetPasswordMultiple"
        @delete-item="deleteItem"
        @history-item="showHistory"
        @delete="onDeleteOneItem($event)"
        @apply:add="toAddingRoles($event)"
        @apply:delete="toDeletingRoles($event)"
      />
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceHistoryModal
    ref="history"
    v-model="showHistoryModal"
    modelName="SecurityUserApiModel"
    @close="closeHistory"
  />
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingConfirmation')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="deletingRequestModel.records.length == 1?
                  $t('deleteUser', { name:  deletingRequestModel.records[0].fullName})
                  : $t('deleteUsers')"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceFilterSearchfield,
  ProSpaceMultiSelect,
  ProSpaceInputText,
  ProSpaceGridModalForm,
  ProSpaceMainGrid,
  ProSpaceDots,
  ModalForm,
  event_bus,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceRightPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceEmptyGrid,
  ProSpaceDefaultTextColumn,
  ProSpaceDateTimeColumn,
  ProSpaceFlashMessage
} from "@prospace/prospace-components-library";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import { mapGetters } from "vuex";
import { UserService } from "../../services/UserService.js";
import { RoleService } from "../../services/RoleService.js";
import { StatusService } from "../../services/StatusService.js";
import { LoginTypeService } from "../../services/LoginTypeService.js";
import { SecurityUserRole } from "../../services/SecurityUserRole.js";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import { DistributorService } from "@masterdata/services/DistributorService.js";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
import DistributorHierarchySelectModal from "@modals/DistributorHierarchySelectModal.vue";
import ModalUser from "./components/ModalUser.vue";
import PatchedCheckbox from "./PatchedCheckbox.vue";
import UserPanel from "./components/UserPanel";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import {
  useStatistic,
} from "@composes"
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import ModalBlockFilters from "@multiselect/ModalBlockFilters"
import { ref } from "vue"
const _ = require("lodash");
import IconButtonSettingsColumns from "@components/settingsColumns/IconButtonSettingsColumns.vue";
import useSettingsColumns from "@composes/useSettingsColumns.js";
export default {
  components: {
    ProSpaceFilterPanel,
    UserPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceFilterSearchfield,
    ProSpaceMultiSelect,
    ProSpaceInputText,
    PatchedCheckbox,
    ProSpaceGridModalForm,
    ProSpaceMainGrid,
    DataTable,
    ProSpaceDots,
    ModalForm,
    Column,
    ProSpaceEmptyGrid,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceRightPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ModalUser,
    ProSpaceHistoryModal,
    ProSpaceDefaultTextColumn,
    ProSpaceDateTimeColumn,
    DistributorHierarchySelectModal,
    ModalBlockFilters,
    ProSpaceFlashMessage,
    IconButtonSettingsColumns
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic()
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      selectedData,
      selectedCount,
      hasSelect,
      selectedMultipleBlockFilterHandler,
      needBlock,
      getRequestModel,
      selectedMultipleReset
    } = useMultiSelectGrid(grid, filterPanel)
    const {
      showModalSettingsColumns,
      gridPropsSettingsColumns
    } = useSettingsColumns(grid)
    return {
      grid, filterPanel,
      setStatistic, distActions,
      selectedMultipleGridHandler, selectedData,
      hasSelect, selectedMultipleClear,
      selectedMultipleBlockFilterHandler,
      needBlock, selectedCount,
      getRequestModel, selectedMultipleReset,
      showModalSettingsColumns,
      gridPropsSettingsColumns
    }
  },
  data() {
    return {
      showHistoryModal: false,
      showRoleModal: false,
      // Grid Panel
      rawDotsOptions: [],
      spKey: 0,
      spHide: false,
      emitter: event_bus,
      panelModel: {},
      panelModels: [],
      tabCountField: "roles",

      modelTabPanel: { name: this.$t("overview") },

      // Service
      userService: new UserService(),
      roleService: new RoleService(),
      statusService: new StatusService(),
      securityUserRole: new SecurityUserRole(),
      loginTypeService: new LoginTypeService(),
      distributorService: new DistributorService(),
      distributorStructureService: new DistributorStructureService(),

      // Filter Panel Model
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: false,
        field: "disabled",
      },
      currentUserRoles: [],
      modalFormMappingField: "securityRoleId",

      deletingRequestModel: null,
      showDeleteModal: false
    };
  },
  watch: {
    selectedData: {
      handler: function() {
        this.getUserRoles();
      }
    },
    immediate: true
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    customServices() {
      return [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        },
        {
          type: "checkboxes",
          service: new RoleService(),
          getMethod: "grid",
          model: "securityRoleId",
          label: this.$t("role"),
          optionValue: "id",
          optionLabel: "systemName",
          defaultFilter: { disabled: false },
          selected: {},
        },
        {
          type: "checkboxes",
          service: new BaseTypeService(),
          getMethod: "grid",
          model: "orgLinkType",
          label: this.$t("userType"),
          optionValue: "code",
          optionLabel: "name",
          defaultFilter: [
            { disabled: false },
            { systemDirectoryCode: "User type" },
          ],
          selected: {},
        },
        {
          type: "chips",
          model: "distributorStructureCode",
          label: this.$t("distributorStructureCodeFilial"),
          selected: {},
        },
        {
          type: "chips",
          model: "createdBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "chips",
          model: "updatedBy",
          label: this.$t("updatedByTxt"),
          selected: {},
        },
        {
          type: "dates-range-time",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range-time",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
    dotsOptions() {
      let res = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
        },
        {
          name: "resetPassword",
          text: this.$t("resetPasswordB"),
          icon: "lock",
          method: this.resetPasswordSingle,
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
        },
      ];
      if (this.modelFastFilter.label === this.$t("deleted")) {
        return res.filter(
          (option) =>
            option.name !== "edit" &&
            option.name !== "delete" &&
            option.name !== "resetPassword"
        );
      }
      return res;
    },
    dotsHeaderOptions() {
      return [
        { name: "export_csv", text: this.$t("export_csv"), icon: "upload" },
        { name: "export_xlsx", text: this.$t("export_xlsx"), icon: "upload" },
      ];
    },
    fastFilters() {
      return [
        {
          label: this.$t("allTxt"),
          value: false,
          field: "disabled",
        },
        {
          label: this.$t("active"),
          value: [
            { field: "status", value: "Active" },
            { field: "disabled", value: false },
          ],
        },
        { label: this.$t("deleted"), field: "disabled", value: true },
      ];
    },
    disabledModalRule() {
      return (
        this.modalModel.firstName.length == 0 ||
        this.modalModel.lastName.length == 0
      );
    },
    isDeletedTab() {
      return this.modelFastFilter.label === this.$t("deleted");
    },
    spShow() {
      return this.selectedModel.length > 0;
    },
    spVisible() {
      return this.selectedModel.length > 0;
    },
    getTitleModalRole() {
      return this.$t('add') + " " + this.$t('roleLower');
    },
    tabsPanel() {
      return [
        { name: "Overview", displayName: this.$t("overview") },
        {
          name: "Roles",
          displayName: this.$t("roles"),
          count: this.panelModel[this.tabCountField]
            ? this.panelModel[this.tabCountField].length
            : null,
        },
      ];
    },
    getCounterRoles() {
      return this.currentUserRoles?.length > 0 ? this.currentUserRoles.length : null;
    }
  },
  methods: {
    getRawDotsOptions(records) {
      if (!Array.isArray(records)) {
        records = [records]
      }
      if (
        records.every((x) => x.securityLoginAuthenticateProvider === "Cookies")
      )
        return this.dotsOptions;

      return this.dotsOptions.filter((o) => o.name !== "resetPassword");
    },
    getPosition(user) {
      return user.orgLinkDistributorName || user.orgLinkMarsOrgName || "";
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    handleHeaderDots(action) {
      if (action === "export_csv") {
        this.actionExport("CSV");
      } else if (action === "export_xlsx") {
        this.actionExport("XLSX");
      }
    },
    closeHistory() {
      this.showHistoryModal = false;
    },
    closeModalUser() {
      this.$refs["modal-user"].show = false;
      this.actionUpdate();
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.secondName
        ? `${record.lastName} ${record.firstName} ${record.secondName}`
        : `${record.lastName} ${record.firstName}`;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    resetPasswordSingle(item) {
      this.resetPassword(item, false)
    },
    resetPasswordMultiple(item) {
      this.resetPassword(item, true)
    },
    resetPassword(item, isMultipleReset = true) {
      const requestModel = isMultipleReset
        ? this.getRequestModel(item)
        : { filter: "", records: [ item ] };

      if (this.selectedCount > 5) {
        this.userService.resetPasswordBackground(requestModel);
        this.selectedMultipleReset()
        return;
      }
      this.userService.resetPassword(requestModel).then((res) => {
        if (res && res.success) {
          this.selectedMultipleReset()
          this.$store
            .dispatch("notifications/addNotification", {
              logLevel: "Information",
              title: this.$t("passwordChanged"),
              messageData: {
                Title: "passChange",
                Record: { Id: this.selectedData?.id || item?.id },
              },
            })
            .then((mi) => {
              this.$store.dispatch("notifications/updateReadedRecord", {
                title: mi.messageData.Title,
                id: mi.messageData.Record.Id,
              });
            });
        }
      });
    },
    toItem(data) {
      const url = `/securityadmin/user/${data.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    submitFilter({ filter, search }) {
      filter.securityUserRoleDisabled =
        filter &&
        filter.securityRoleId &&
        filter.securityRoleId.or &&
        !!filter.securityRoleId.or.length
          ? { or: [false] }
          : null;

      if (
        filter &&
        filter.restrictionFieldValue &&
        filter.restrictionFieldValue.or &&
        !!filter.restrictionFieldValue.or.length
      ) {
        filter.restrictionModelName = { or: ["DistributorDomainModel"] };
        filter.restrictionFieldName = { or: ["Code"] };
        filter.restrictionDisabled = { or: [false] };
      } else {
        filter.restrictionModelName = null;
        filter.restrictionFieldName = null;
        filter.restrictionDisabled = null;
      }

      if (filter.distributorStructureId && filter.distributorStructureId.or.length > 0) {
        filter.securityUserDistributorStructureDisabled = { and: [false] };
      }

      const distributorStructureFilterExists = filter &&
        filter.distributorStructureCode &&
        filter.distributorStructureCode.or &&
        !!filter.distributorStructureCode.or.length;

      if(distributorStructureFilterExists) {
        filter.or = {
          or: [
            {
              and: [
                { or: filter.distributorStructureCode.or.map(ds => { return { distributorStructureCode: ds }}) },
                { or: [{ securityUserDistributorStructureDisabled: false }] },
              ]
            }
          ]
        };

        delete filter.distributorStructureCode;
      }

      this.$refs.grid.submitFilter(filter, search);
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.emitFilter();
    },
    async actionUpdate() {
      await this.$refs.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    async actionExport(format) {
      this.loadingHandler(true);
      await this.userService.export(
        this.$refs.grid.getRecords(false, true),
        format
      );
      this.loadingHandler(false);
    },
    afterLoadHanlder(model) {
      if (typeof model == "object") {
        this.panelModel = model;
      } else {
        this.panelModels = model;
      }
    },
    selectedHandler(val) {
      const selectedItems = this.$refs.grid.getSelectedModel()
      this.rawDotsOptions = this.getRawDotsOptions(selectedItems);
      this.selectedMultipleGridHandler(val)
    },
    showChangeModal() {
      this.$refs["modal-user"].init();
    },
    deleteItem(model) {
      this.deletingRequestModel = this.getRequestModel(model);
      this.showDeleteModal = true;
    },
    applyDelete() {
      const grid = this.$refs["grid"];
      grid.setLoading(true);

      this.userService.delete(this.deletingRequestModel).then(async () => {
        await this.actionUpdate();
        grid.setLoading(false);
        this.selectedMultipleReset();
        this.deletingRequestModel = null;
      });
    },
    editItem(model) {
      if (!model) return;
      this.$refs["modal-user"].init(model);
    },
    async toDeletingRoles(roles) {
      const rightPanel = this.$refs["right-panel"];
      const me = this;
      const deletingRoles = [];
      const isUserRoles = roles.every(r => r.securityUserId)

      roles.forEach((r) => {
        const deletingRole = me.currentUserRoles.find(o => o[this.modalFormMappingField] === r.id);
        if (deletingRole && !isUserRoles) {
          deletingRoles.push(deletingRole);
        } else {
          deletingRoles.push(r);
        }
      });
      await me.securityUserRole.delete(deletingRoles);
      await this.getUserRoles();
      await rightPanel?.refreshRoleList();
      this.updateUserPanel();
    },
    async toAddingRoles(roles) {
      const rightPanel = this.$refs["right-panel"];
      const user = rightPanel.user;

      await this.securityUserRole.create(user, roles);
      await this.getUserRoles();
      await rightPanel?.refreshRoleList();
      this.updateUserPanel();
    },
    updateUserPanel() {
      this.$refs["right-panel"].update();
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      status = status.toLowerCase()
      switch (status) {
        case "active":
          type = "success";
          break;
        case "new":
          type = "info";
          break;
        default:
          type = "danger";
      }
      const capitalizeStatus = status[0].toUpperCase() + status.toLowerCase().slice(1)
      return {
        text: this.$t(`status${capitalizeStatus}`),
        type,
      };
    },
    async sendGridModal(mappingModel) {
      if (!Array.isArray(mappingModel)) {
        mappingModel = [mappingModel];
      }
      const rightPanel = this.$refs["right-panel"];
      const user = rightPanel.user;

      const currentRoleIds = this.currentUserRoles.map(
        (role) => role[this.modalFormMappingField]
      );
      const roleIds = mappingModel.map((role) => role.id);
      const addIdRoles = _.difference(roleIds, currentRoleIds); // add

      if (addIdRoles.length > 0) {
        const creatingRoles = mappingModel.filter((role) =>
          addIdRoles.includes(role["id"])
        );

        this.securityUserRole.create(user, creatingRoles).then(() => {
          rightPanel.refreshRoleList();
        });
      }

      await this.$refs["right-panel"].update();
      await this.getUserRoles();
    },
    loadingHandler(val) {
      this.$refs.grid.loading = val;
    },
    showModalAddRole() {
      this.showRoleModal = true;
    },
    async getUserRoles() {
      if (!this.selectedData) {
        return
      }
      try {
        const res = await this.securityUserRole.get(this.selectedData.id);
        this.currentUserRoles = res?.data?.length > 0 ? res.data : [];
      } catch (e) {
        console.error(e);
      }
    },
    async onDeleteOneItem(item) {
      await this.getUserRoles();
      this.updateUserPanel();
    },
    getTranslatedOrgLinkType(type) {
      if (type === 'Distributor') {
        return this.$t('distributor');
      } else if (type === 'Mars') {
        return this.$t('mars');
      } else {
        return type;
      }
    }
  }
}
</script>
