<template>
  <DashboardLayoutSettingsGridSelectTypeElement
    :selectedContainer="selectedContainer"
    :typeOptions="getComponentTypeOptions"
    @change:type="changeComponentType"
  />
  <div class="prospace-panel-block-list__row">
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
      :title="$t('elementName')"
    >
      <template #content>
        <ProSpaceInputText
          v-model="model.blockName"
          :placeholder="$t('name')"
        />
      </template>
    </ProSpacePanelBlockItem>
  </div>
  <DashboardLayoutElementSettings
    :needColor="false"
    :elementName="$t('label')"
    :elementType="model.label.type"
    :elementValue="model.label.value"
    :color="model.label.color"
    :createDefaultLayoutColorSettings="createDefaultLayoutColorSettings"
    :valueOptions="childPanelValuesOptions"
    :validation="validation.label"
    @update:elementType="(elementType) => updateElementProp('label', 'type', elementType)"
    @update:elementValue="(elementValue) => updateElementProp('label', 'value', elementValue)"
    @update:color="(color) => updateElementProp('label', 'color', color)"
  />
  <DashboardLayoutElementSettings
    :needColor="false"
    :elementName="$t('value')"
    :elementType="model.value.type"
    :elementValue="model.value.value"
    :color="model.value.color"
    :createDefaultLayoutColorSettings="createDefaultLayoutColorSettings"
    :valueOptions="childPanelValuesOptions"
    :validation="validation.value"
    @update:elementType="(elementType) => updateElementProp('value', 'type', elementType)"
    @update:elementValue="(elementValue) => updateElementProp('value', 'value', elementValue)"
    @update:color="(color) => updateElementProp('value', 'color', color)"
  />
  <div class="prospace-panel-block-list__row">
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
    >
      <template #header>
        <ProSpaceHLayout :gap="5">
          <div>
            {{ $t('filter') }}
          </div>
          <ProSpaceIcon
            v-hint="filterHintText"
            icon="question"
          />
        </ProSpaceHLayout>
      </template>
      <template #content>
        <ProSpaceInputChips
          v-model="model.filter"
          showHintItem
        />
      </template>
    </ProSpacePanelBlockItem>
  </div>
  <div class="prospace-panel-block-list__row prospace-panel-block-list__row--withoutMarginAction">
    <ProSpaceAction
      :text="$t('addColorSelection')"
      @click="handleAddColorSelection"
    />
  </div>
</template>

<script>
import {
  ProSpacePanelBlockItem,
  ProSpaceInputText,
  ProSpaceHLayout,
  ProSpaceIcon,
  ProSpaceInputChips,
  ProSpaceAction
} from "@prospace/prospace-components-library";
import DashboardLayoutElementSettings from "./DashboardLayoutElementSettings.vue";
import DashboardLayoutSettingsGridSelectTypeElement from "./DashboardLayoutSettingsGridSelectTypeElement.vue";
import { ref } from "vue";
import useSelectElementType from "./composes/useSelectElementType";

export default {
  name: "DashboardLayoutSettingsGridPanel",
  components: {
    DashboardLayoutElementSettings,
    DashboardLayoutSettingsGridSelectTypeElement,
    ProSpacePanelBlockItem,
    ProSpaceInputText,
    ProSpaceHLayout,
    ProSpaceIcon,
    ProSpaceInputChips,
    ProSpaceAction
  },
  props: {
    selectedNode: {
      type: Object,
      default: () => {}
    },
    validation: {
      type: Object,
      required: true
    },
    createDefaultLayoutColorSettings: {
      type: Function,
      required: true,
    },
    childPanelValuesOptions: {
      type: Array,
      default: () => []
    },
    filterHintText: {
      type: String,
      default: ''
    },
    getComponentTypeOptions: {
      type: Array,
      default: () => []
    },
    gridView: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const model = ref({
      blockName: '',
      label: {
        type: '',
        value: '',
        color: ''
      },
      value: {
        type: '',
        value: '',
        color: ''
      },
      filter: ''
    });
    const {
      isEmptyModel,
      selectedContainer,
      defaultModel } = useSelectElementType(
      props.selectedNode,
      props.getComponentTypeOptions,
      model
    );

    return {
      isEmptyModel,
      selectedContainer,
      defaultModel,
      model
    }
  },
  data() {
    return {
    }
  },
  watch: {
    selectedNode: {
      handler: function(val) {
        this.model = val;
      },
      immediate: true,
      deep: true
    },
    model: {
      handler: function(val, oldVal) {
        this.updateModel(val);
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    updateElementProp(element, prop, value) {
      this.model[element][prop] = value;
    },
    handleAddColorSelection() {
      this.$emit('combinedEvents', 'add:colorSelection');
    },
    updateModel(value) {
      this.$emit(`update:selectedNode`, value);
    },
    changeComponentType(e) {
      this.$emit('combinedEvents', 'change:component', this.selectedNode, e);
    }
  }
}
</script>

<style scoped>

</style>
