<template>
  <ProSpaceGeoLayout class="user">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler" />
    </template>
    <template #center>
      <ProSpaceRadioButtonSecondary
        v-if="model"
        v-model="modelTab"
        :tabs="tabs"
        @select="handlerSelectRadio"
      >
        <ProSpaceAction ref="action-saveall"
          icon="double-check"
          :disabled="disabledSave || loading || rolesLoading"
          :text="$t('saveAll')"
          @click="saveAll"
        />
        <template #profile>
          <ProSpaceGeoLayout
            is-template
            :template-center="true"
            :template-right="modelTabPanel.systemName === 'distrStructure'"
          >
            <template #center>
              <ProSpaceTabs ref="tabs"
                v-model="modelTabPanel"
                without-margin-bottom
                :tabs="profileTabs"
                :loading="loading"
                :refLoading="mixinLoadingAccess"
                @select-tab="handlerSelectTab"
              >
                <ProSpaceAction ref="action-save"
                  icon="check"
                  :disabled="disabledSave"
                  :text="$t('save')"
                  @click="savePart(saveFields.Overview)"
                />
                <template v-slot:tab-1>
                  <ProSpaceVLayout :gap="10">
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          type="third"
                          break-word
                          :top="$t('orgLinkInfo')"
                          :bottom="$t('orgLinkInfoSub')"
                          :gap="10"
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceVLayout :gap="20">
                          <ProSpaceDropdown
                            ref="userTypeDropdown"
                            v-model="model.orgLinkType"
                            propValue="code"
                            propLabel="name"
                            argMethod="$filter=systemDirectoryCode eq 'User type' and disabled eq false"
                            getMethod="grid"
                            :label="$t('userType')"
                            :placeholder="$t('userType')"
                            :service="baseTypeService"
                            :invalid="v$.model.orgLinkType.$error"
                            :invalid-messages="v$.model.orgLinkType.$errors"
                            @blur="v$.model.orgLinkType.$touch"
                            @change="userTypeChanged"
                          />
                          <ProSpaceSearchfield
                            v-if="model.orgLinkType === 'Distributor'"
                            ref="distributor"
                            v-model="model.orgLinkDistributorId"
                            optionLabel="name"
                            optionValue="id"
                            getMethod="grid"
                            filterModel="name"
                            :label="$t('distributor')"
                            :searchPlaceholder="$t('searchTxt')"
                            :cancelTxt="$t('cancelInf')"
                            :applyTxt="$t('applyTxt')"
                            :txtTitleModal="$t('listDistributors')"
                            :displayValue="model.orgLinkDistributorName"
                            :service="distributorService"
                            :columns="columns"
                            :fastFilters="[]"
                            :defaultFilter="{ disabled: { and: [false] } }"
                            :invalid="v$.model.orgLinkDistributorId.$error"
                            :invalid-messages="
                              v$.model.orgLinkDistributorId.$errors
                            "
                            :txtFilterHint="$t('filterBy')"
                            @blur="v$.model.orgLinkDistributorId.$touch"
                            @update:modelValue="
                              updateModelField($event, 'distributor')
                            "
                          >
                            <template #filters="{ filter }">
                              <ProSpaceCustomFilterPanel
                                :customTxt="$t('custom')"
                                :customFilterTxt="$t('customFilterTxt')"
                                :clearFilterTxt="$t('clearFilterTxt')"
                                :selectAllTxt="$t('selectAllTxt')"
                                :closeTxt="$t('closeTxt')"
                                :applyTxt="$t('applyTxt')"
                                :fromTxt="$t('fromTxt')"
                                :toTxt="$t('toTxt')"
                                :services="customServices"
                                @apply="
                                  (model) => customFilterApply(filter, model)
                                "
                              />
                            </template>
                            <template #columns>
                              <Column
                                field="name"
                                sortable
                                headerClass="move-sort-head"
                                :header="$t('distributor')"
                                style="
                                  min-width: 450px;
                                  max-width: 450px;
                                  margin-right: 5px;
                                "
                              >
                                <template #body="field">
                                  <ProSpaceTableHeaderCell
                                    v-if="field.data"
                                    show-image
                                    :id="field.data.id"
                                    :status="{
                                      text: $t('distributor'),
                                      type: 'blue',
                                    }"
                                    :img-src="field.data.logoFileContent"
                                    :title="field.data.name"
                                    :description="`${
                                      field.data.mailAddress || ''
                                    }`"
                                  />
                                </template>
                              </Column>
                              <Column
                                field="phoneNumber"
                                sortable
                                :header="$t('distributorPhoneNumber')"
                              >
                                <template #body="field">
                                  <ProSpaceDefaultTextColumn>
                                    {{ field.data.phoneNumber }}
                                  </ProSpaceDefaultTextColumn>
                                </template>
                              </Column>
                              <Column
                                field="faxNumber"
                                :header="$t('distributorFaxNumber')"
                                sortable
                              >
                                <template #body="field">
                                  <ProSpaceDefaultTextColumn>
                                    {{ field.data.faxNumber }}
                                  </ProSpaceDefaultTextColumn>
                                </template>
                              </Column>
                              <Column
                                field="emailAddress"
                                :header="$t('email')"
                                sortable
                              >
                                <template #body="field">
                                  <ProSpaceDefaultTextColumn>
                                    {{ field.data.emailAddress }}
                                  </ProSpaceDefaultTextColumn>
                                </template>
                              </Column>
                            </template>
                            <template #panel="{ item }">
                              <ProSpaceRightPanel
                                :header="headerDistributor(item)"
                              >
                                <template #center>
                                  <ProSpaceTabs :tabs="tabsDistributor" @select-tab="handlerSelectTab">
                                    <template v-slot:tab-1>
                                      <ProSpacePanelBlock
                                        :header="{}"
                                        withPaddingTop
                                      >
                                        <template v-slot:header> </template>
                                        <template v-slot>
                                          <ProSpacePanelBlockList
                                            :itemsPerPage="5"
                                            :list="contentDistributor(item)"
                                          />
                                        </template>
                                      </ProSpacePanelBlock>
                                    </template>
                                    <template v-slot:tab-2>
                                      <ProSpacePanelBlock
                                        :header="{}"
                                        withPaddingTop
                                      >
                                        <template v-slot:header> </template>
                                        <template v-slot>
                                          <ProSpacePanelBlockList
                                            :itemsPerPage="10"
                                            :list="contentRequisites(item)"
                                          />
                                        </template>
                                      </ProSpacePanelBlock>
                                    </template>
                                  </ProSpaceTabs>
                                </template>
                              </ProSpaceRightPanel>
                            </template>
                          </ProSpaceSearchfield>
                          <ProSpaceSearchfield
                            v-if="model.orgLinkType === 'Distributor'"
                            ref="distributorOrg"
                            v-model="model.orgLinkDistributorOrgId"
                            classComponent="bg-color-gray-card-bg padding-10"
                            mode="tree"
                            optionLabel="name"
                            optionValue="id"
                            :label="$t('position')"
                            :searchPlaceholder="$t('searchTxt')"
                            :cancelTxt="$t('cancelInf')"
                            :applyTxt="$t('applyTxt')"
                            :txtTitleModal="$t('listDistributorOrgStructure')"
                            :displayValue="model.orgLinkDistributorOrgName"
                            :service="distributorOrgService"
                            :defaultFilter="{ disabled: { and: [false] } }"
                            :invalid="v$.model.orgLinkDistributorOrgId.$error"
                            :invalid-messages="
                              v$.model.orgLinkDistributorOrgId.$errors
                            "
                            @blur="v$.model.orgLinkDistributorOrgId.$touch"
                            @update:modelValue="
                              updateModelField($event, 'distributorOrg')
                            "
                          >
                            <template #panel="{ item }">
                              <ProSpaceRightPanel class="padding-top-10">
                                <template #center>
                                  <ProSpacePanelBlock
                                    :header="{
                                      title: $t('parameters'),
                                      isExpand: false,
                                    }"
                                  >
                                    <ProSpacePanelBlockList
                                      :list="getDistrOrgPanelInfo(item)"
                                    />
                                  </ProSpacePanelBlock>
                                </template>
                              </ProSpaceRightPanel>
                            </template>
                          </ProSpaceSearchfield>
                          <ProSpaceSearchfield
                            v-if="model.orgLinkType === 'Mars'"
                            ref="marsOrg"
                            v-model="model.orgLinkMarsOrgId"
                            mode="tree"
                            optionLabel="name"
                            optionValue="id"
                            :label="$t('position')"
                            :searchPlaceholder="$t('searchTxt')"
                            :cancelTxt="$t('cancelInf')"
                            :applyTxt="$t('applyTxt')"
                            :txtTitleModal="$t('listMarsOrgStructure')"
                            :displayValue="model.orgLinkMarsOrgName"
                            :service="marsOrgService"
                            :defaultFilter="{ disabled: { and: [false] } }"
                            :invalid="v$.model.orgLinkMarsOrgId.$error"
                            :invalid-messages="
                              v$.model.orgLinkMarsOrgId.$errors
                            "
                            @blur="v$.model.orgLinkMarsOrgId.$touch"
                            @update:modelValue="
                              updateModelField($event, 'marsOrg')
                            "
                          >
                            <template #panel="{ item }">
                              <ProSpaceRightPanel>
                                <template #center>
                                  <ProSpacePanelBlock
                                    :header="{
                                      title: $t('parameters'),
                                      isExpand: false,
                                    }"
                                  >
                                    <ProSpacePanelBlockList
                                      :list="getDistrOrgPanelInfo(item)"
                                    />
                                  </ProSpacePanelBlock>
                                </template>
                              </ProSpaceRightPanel>
                            </template>
                          </ProSpaceSearchfield>
                        </ProSpaceVLayout>
                      </template>
                      <template
                        v-if="
                          v$.model.orgLinkType.$error ||
                          v$.model.orgLinkMarsOrgId.$error ||
                          v$.model.orgLinkDistributorId.$error ||
                          v$.model.orgLinkDistributorOrgId.$error
                        "
                        v-slot:right
                      >
                        <ProSpaceWarning />
                      </template>
                    </ProSpaceGeoLayout>
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          type="third"
                          break-word
                          :top="$t('mainInformation')"
                          :bottom="$t('userInformation')"
                          :gap="10"
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceVLayout :gap="20">
                          <ProSpaceHLayout align-items="flex-start" :gap="10">
                            <ProSpaceInputText ref="lastName"
                              v-model="model.lastName"
                              :label="$t('lastName')"
                              :placeholder="$t('lastName')"
                              :invalid="v$.model.lastName.$error"
                              :invalid-messages="v$.model.lastName.$errors"
                              @blur="v$.model.lastName.$touch"
                            ></ProSpaceInputText>
                            <ProSpaceInputText ref="firstName"
                              v-model="model.firstName"
                              :label="$t('firstName')"
                              :placeholder="$t('firstName')"
                              :invalid="v$.model.firstName.$error"
                              :invalid-messages="v$.model.firstName.$errors"
                              @blur="v$.model.firstName.$touch"
                            ></ProSpaceInputText>
                            <ProSpaceInputText ref="secondName"
                              v-model="model.secondName"
                              :label="$t('secondName')"
                              :placeholder="$t('secondName')"
                              :invalid="v$.model.secondName.$error"
                              :invalid-messages="v$.model.secondName.$errors"
                              @blur="v$.model.secondName.$touch"
                            ></ProSpaceInputText>
                          </ProSpaceHLayout>
                          <ProSpaceHLayout align-items="flex-start" :gap="10">
                            <ProSpaceInputText ref="email"
                              v-model="model.email"
                              :label="$t('holderEmail')"
                              :placeholder="$t('holderEmail')"
                              :invalid="v$.model.email.$error"
                              :invalid-messages="v$.model.email.$errors"
                              @blur="v$.model.email.$touch"
                            ></ProSpaceInputText>
                            <ProSpaceInputText ref="login"
                              v-model="model.login"
                              :label="$t('login')"
                              :placeholder="$t('login')"
                              :invalid="v$.model.login.$error"
                              :invalid-messages="v$.model.login.$errors"
                              @blur="v$.model.login.$touch"
                            ></ProSpaceInputText>
                          </ProSpaceHLayout>
                        </ProSpaceVLayout>
                      </template>
                      <template
                        v-if="
                          v$.model.lastName.$error ||
                          v$.model.firstName.$error ||
                          v$.model.secondName.$error ||
                          v$.model.email.$error ||
                          v$.model.login.$error
                        "
                        v-slot:right
                      >
                        <ProSpaceWarning />
                      </template>
                    </ProSpaceGeoLayout>
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          type="third"
                          break-word
                          :top="$t('additionalInformation')"
                          :bottom="$t('additionalInformationInformation')"
                          :gap="10"
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceVLayout :gap="20">
                          <ProSpaceInputText ref="comments"
                            v-model="model.comments"
                            :isTextarea="true"
                            :autoResize="false"
                            :rows="4"
                            :label="$t('comments')"
                            :placeholder="$t('comments')"
                          />
                        </ProSpaceVLayout>
                      </template>
                    </ProSpaceGeoLayout>
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          type="third"
                          break-word
                          :top="$t('date')"
                          :bottom="$t('dateInformation')"
                          :gap="10"
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceHLayout :gap="10">
                          <ProSpaceDropdown ref="dateFormat"
                            v-model="model.dateFormat"
                            getMethod="getDateFormats"
                            :label="$t('dateFormat')"
                            :placeholder="$t('dateFormat')"
                            :service="dateHelperService"
                          />
                          <ProSpaceDropdown ref="timeZoneId"
                            v-model="model.timeZoneId"
                            getMethod="getTimeZone"
                            :label="$t('timeZone')"
                            :placeholder="$t('timeZone')"
                            :service="dateHelperService"
                            @change="handlerChangeTimezone"
                          />
                          <ProSpaceDropdown ref="timeFormat"
                            v-model="model.timeFormat"
                            getMethod="getTimeFormats"
                            :label="$t('timeFormat')"
                            :placeholder="$t('timeFormat')"
                            :service="dateHelperService"
                          />
                        </ProSpaceHLayout>
                      </template>
                    </ProSpaceGeoLayout>
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          type="third"
                          break-word
                          :top="$t('lastLoginDate')"
                          :bottom="$t('lastLoginDateInformation')"
                          :gap="10"
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceVLayout :gap="20">
                          <ProSpaceInputText ref="lastLoginDate"
                            :label="$t('lastLoginDateF')"
                            :modelValue="getFormatDateTime(model.lastLoginDate)"
                            :placeholder="$t('lastLoginDateF')"
                            :disabled="true"
                          />
                        </ProSpaceVLayout>
                      </template>
                    </ProSpaceGeoLayout>
                  </ProSpaceVLayout>
                </template>
                <template v-slot:tab-2>
                  <DistributorHierarchySelectForm ref="distributorStructures"
                    v-model="model.distributorStructures"
                    :title-modal="$t('distrsAndBranches')"
                    viewMethod="getUserViewTree"
                    expandAll
                    searchable
                    needValidate
                    is-one-distr
                    :service="distributorStructureService"
                    :afterEdit="afterDistributorStructureEdit"
                    :titleTxt="$t('securityUserDistributorStructureInfo')"
                    :descriptionTxt="$t('securityUserDistributorStructureInfoAdditional')"
                    @apply="applyDistributorStructure"
                  />
                </template>
              </ProSpaceTabs>
            </template>
            <template #right v-if="modelTabPanel.systemName === 'distrStructure'">
              <ProSpaceRightPanel no-border>
                <template #center>
                  <ProSpacePanelBlock
                    :header="structureInfoBlockHeader"
                  >
                    <ProSpaceVLayout :gap="10" class="text-color-gray text-size-13">
                      <div>
                        <span class="text-bold">{{ $t('distributorStructureInfoBillTo') }}</span>
                        <span v-html="$t('distributorStructureBillToIs')" />
                      </div>
                      <div>
                        <span class="text-bold">{{ $t('distributorStructureSoldTo') }}</span>
                        <span v-html="$t('distributorStructureSoldToIs')" />
                      </div>
                      <div>
                        <span class="text-bold">{{ $t('distributorStructureGRD') }}</span>
                        <span v-html="$t('distributorStructureGRDIs')" />
                      </div>
                      <div>
                        <span class="text-bold">{{ $t('distributorStructureShipTo') }}</span>
                        <span v-html="$t('distributorStructureShipToIs')" />
                      </div>
                      <div>
                        <span class="text-bold">{{ $t('distributorStructureVirtualGRD') }}</span>
                        <span v-html="$t('distributorStructureVirtualGRDIs')" />
                      </div>
                      <div>
                        <span class="text-bold">{{ $t('distributorStructureFilial') }}</span>
                        <span v-html="$t('distributorStructureFilialIs')" />
                      </div>
                      <div>
                        <span class="text-bold">{{ $t('distributorStructureOffice') }}</span>
                        <span v-html="$t('distributorStructureOfficeIs')" />
                      </div>
                    </ProSpaceVLayout>
                  </ProSpacePanelBlock>
                </template>
              </ProSpaceRightPanel>
            </template>
          </ProSpaceGeoLayout>
        </template>
        <template #permissions>
          <ProSpaceGeoLayout
            is-template
            :template-center="true"
            :template-right="isUseCaseSelected || isUserRoleSelected"
          >
            <template #center>
              <ProSpaceTabs
                v-model="modelTabPanel"
                :tabs="tabsPanel"
                :loading="loading"
                :refLoading="mixinLoadingAccess"
                @select-tab="handlerSelectTab"
              >
                <ProSpaceHLayout :is-inline-w="true" :gap="10">
                  <ProSpaceAddInputButton
                    v-if="modelTabPanel.value === 'roles'"
                    ref="action-addrole"
                    searchValue="systemName"
                    getMethod="grid"
                    colName="systemName"
                    :headName="$t('role')"
                    :searchPlaceholder="$t('searchTxt')"
                    filterField="securityRoleId"
                    second-get-method="grid"
                    second-create-method="createWithMap"
                    second-delete-method="delete"
                    :id="masterId"
                    :service="roleService"
                    :second-service="userRoleService"
                    :addTxt="$t('add')"
                    :secondData="userRoleGridData"
                    :defaultFilter="{ disabled: { and: [false] } }"
                    :cancelTxt="$t('cancelInf')"
                    :applyTxt="$t('applyTxt')"
                    :disabled="rolesLoading"
                    @apply="applyRoles"
                  />
                  <ProSpaceAction ref="action-addrestrictions"
                    v-if="modelTabPanel.value === 'restrictions'"
                    icon="plus"
                    :text="$t('add')"
                    @click="showMethadataModal"
                  />
                  <ProSpaceAction ref="action-save"
                    v-if="modelTabPanel.value !== 'menuItems'"
                    icon="check"
                    :text="$t('save')"
                    :disabled="loading || rolesLoading"
                    @click="savePart(saveFields.Claims)"
                  />
                </ProSpaceHLayout>
                <template v-slot:tab-1>
                  <ProSpaceMainGrid v-if="!mixinLoadingAccess"
                    ref="userRoleGrid"
                    getMethod="grid"
                    selectionMode="single"
                    :isHeadHide="true"
                    :isInForm="true"
                    :service="userRoleService"
                    :id="masterId"
                    :key="masterId"
                    class="padding-top-20"
                    @loaded="userRoleGridLoaded()"
                    @selected-change="userRoleSelect"
                    :defaultFilter="{
                      securityUserId: { and: [model.id] },
                      disabled: { and: [false] },
                    }"
                  >
                    <template #columns>
                      <Column>
                        <template #body="{ data }">
                          <ProSpaceTextBlock
                            layout="center"
                            type="primary"
                            :top="data.securityRoleDisplayName"
                            :bottom="`${getFormatDate(
                              data.startDate
                            )} - ${getFormatDate(data.endDate)}`"
                          />
                        </template>
                      </Column>
                      <Column>
                        <template #body="field">
                          <ProSpaceHLayout justify-content="flex-end">
                            <ProSpaceIconButton
                              icon="circle-close"
                              @click="removeUserRole(field.data)"
                            />
                          </ProSpaceHLayout>
                        </template>
                      </Column>
                    </template>
                    <template #empty>
                      <ProSpaceEmptyGrid
                        icon="empty-grid"
                        :title="$t('noDataTitle')"
                        :text="$t('noDataTxt')"
                      />
                    </template>
                  </ProSpaceMainGrid>
                </template>
                <template v-slot:tab-2>
                  <UserUseCases v-if="!mixinLoadingAccess"
                    ref="userUseCase"
                    :userId="model.id"
                    @selected-change="useCaseSelect"
                  />
                </template>
                <template v-slot:tab-3>
                  <ProSpaceGeoLayout v-if="!mixinLoadingAccess">
                    <template #center>
                      <ProSpaceMainGrid
                        dataKey="menuItemId"
                        class="grid-menuitems padding-top-20"
                        type="expand"
                        childFilterField="menuItemParentId"
                        getMethod="grid"
                        :key="masterId"
                        :service="menuItemUserService"
                        :isHeadHide="false"
                        :isInForm="true"
                        :defaultFilter="{
                          securityUserId: { and: [Number(masterId)] },
                        }"
                        :defaultTreeFilter="{
                          securityUserId: { and: [Number(masterId)] },
                        }"
                      >
                        <template #columns>
                          <Column
                            field="menuItemTitle"
                            sortable
                            :header="$t('title')"
                          >
                            <template #body="field">
                              <ProSpaceTableHeaderCell
                                :title="field.data.menuItemTitle"
                              />
                            </template>
                          </Column>
                          <Column
                            field="securityUserLogin"
                            sortable
                            :header="$t('user')"
                          >
                            <template #body="field">
                              <ProSpaceDefaultTextColumn>
                                {{ field.data.securityUserLogin }}
                              </ProSpaceDefaultTextColumn>
                            </template>
                          </Column>
                        </template>
                        <template #expansion>
                          <Column field="menuItemTitle" :header="$t('title')">
                            <template #body="field">
                              <ProSpaceTableHeaderCell
                                :title="field.data.menuItemTitle"
                              />
                            </template>
                          </Column>
                        </template>
                        <template #empty>
                          <ProSpaceEmptyGrid
                            icon="empty-grid"
                            :title="$t('noDataTitle')"
                            :text="$t('noDataTxt')"
                          />
                        </template>
                      </ProSpaceMainGrid>
                    </template>
                  </ProSpaceGeoLayout>
                </template>
                <!-- <template v-slot:tab-4>
                  <UserRestriction
                    ref="user-restriction"
                    :id="masterId"
                    :restrictionService="restrictionService"
                    :methadataService="methadataService"
                  />
                </template> -->
                <template v-slot:tab-4 v-if="model.orgLinkType === 'Mars'">
                  <UserDistributor
                    ref="user-distributor"
                    :id="masterId"
                    :userCode="model.code"
                  />
                </template>
              </ProSpaceTabs>
            </template>
            <template #right>
              <ProSpaceRightPanel v-if="isUserRoleSelected" no-border>
                <template #center>
                  <ProSpacePanelBlock
                    :header="{ title: $t('role'), isExpand: false }"
                  >
                    <ProSpacePanelBlockList :list="contentUserRoles" />
                  </ProSpacePanelBlock>
                </template>
                <template #bottom>
                  <ProSpacePanelBlock :header="headerBottomUserRoles">
                    <template v-slot>
                      <ProSpacePanelBlockList :list="contentBottomUserRoles" />
                    </template>
                  </ProSpacePanelBlock>
                </template>
              </ProSpaceRightPanel>
              <ProSpaceRightPanel v-if="isUseCaseSelected" no-border>
                <template #center>
                  <ProSpacePanelBlock :header="{ title: $t('claim') }">
                    <template v-slot>
                      <ProSpaceSimpleGrid
                        prop-id="securityusecaseId"
                        getMethod="grid"
                        :headers="listHeadersUseCases"
                        :service="useCaseClaimService"
                        :id="selectedUseCaseId"
                        :key="selectedUseCaseId"
                        :btnText="$t('viewMore')"
                      />
                    </template>
                  </ProSpacePanelBlock>
                </template>
                <template v-slot:bottom>
                  <ProSpacePanelBlock :header="userPanelMeta">
                    <template v-slot>
                      <ProSpacePanelBlockList :list="userPanelMetaContent" />
                    </template>
                  </ProSpacePanelBlock>
                </template>
              </ProSpaceRightPanel>
            </template>
          </ProSpaceGeoLayout>
        </template>
      </ProSpaceRadioButtonSecondary>
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceFlashMessage
    v-if="showRoleDeleteModal"
    v-model="showRoleDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingConfirmation')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyUserRoleDelete"
  >
    <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteRole', { name: deletingUserRole.securityRoleDisplayName })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
  <ProSpaceFlashMessage
      v-model="alertTimezoneModal"
      icon="flash-warning"
      :title="$t('signOut')"
      :cancelTxt="$t('cancelTxt')"
      :applyTxt="$t('applyTxt')"
      :needHideApply="false"
      @apply="handlerApplyTimezone"
      @cancel="handlerCancelTimezone"

    >
      {{ $t('alertChangeTimezone') }}
    </ProSpaceFlashMessage>
</template>
<script>
import {
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceDropdown,
  ProSpaceRadioButtonSecondary,
  ProSpaceSimpleGrid,
  ProSpaceMainGrid,
  ProSpaceRightPanel,
  ProSpaceTableHeaderCell,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceAction,
  ProSpaceHLayout,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpaceTextBlock,
  ProSpaceIconButton,
  ProSpacePanelBlockList,
  ProSpacePanelBlockItem,
  ProSpaceVLayout,
  ProSpaceEmptyGrid,
  ProSpaceAddInputButton,
  ProSpaceDefaultTextColumn,
  ProSpaceSearchfield,
  ProSpaceWarning,
  Validations,
  ProSpaceInputLayout,
  ProSpaceFlashMessage,
} from "@prospace/prospace-components-library";
import { getAMorPM } from "@prospace/prospace-components-library/src/lib-assets/mixins/date";
import { mapGetters } from "vuex";
import { UserService } from "../../services/UserService.js";
import { UserInfoService } from "../../services/UserInfoService.js";
import { UserRoleService } from "../../services/UserRoleService.js";
import { RoleService } from "../../services/RoleService.js";
import { MenuItemUserService } from "../../services/MenuItemUserService.js";
import { DateHelperService } from "../../services/DateHelperService.js";
import { UseCaseClaimService } from "../../services/UseCaseClaimService.js";
import { RestrictionService } from "../../services/RestrictionService.js";
import { UserDistributorRestrictionService } from "../../services/UserDistributorRestrictionService.js";
import { MethadataService } from "../../services/MethadataService.js";
import { LoginTypeService } from "../../services/LoginTypeService.js";
import { DistributorService } from "@masterdata/services/DistributorService";
import { DistributorOrgService } from "@masterdata/services/DistributorOrgService";
import { MarsOrgService } from "@masterdata/services/MarsOrgService";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
import UserUseCases from "./components/UserUseCases.vue";
import UserRestriction from "./components/UserRestriction.vue";
import UserDistributor from "./components/UserDistributor.vue";
import DistributorHierarchySelectForm from "@forms/DistributorHierarchySelectForm";
import Column from "primevue/column";
import {
  required,
  maxLength,
  helpers,
  createI18nMessage,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import _ from "lodash";
import {
  useStatistic
} from "@composes"
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import SecUserService from "@security/services/UserService";
import {
  removeToken,
  removeRefreshToken,
  removeRedirectUrl,
} from "@common/utils/auth";
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceInputText,
    ProSpaceDropdown,
    ProSpaceRadioButtonSecondary,
    UserUseCases,
    ProSpaceSimpleGrid,
    ProSpaceMainGrid,
    Column,
    ProSpacePanelBlock,
    ProSpaceRightPanel,
    ProSpaceTableHeaderCell,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceAction,
    ProSpaceHLayout,
    ProSpaceTabs,
    ProSpacePanelBlockList,
    ProSpacePanelBlockItem,
    ProSpaceVLayout,
    ProSpaceTextBlock,
    ProSpaceIconButton,
    UserRestriction,
    UserDistributor,
    ProSpaceEmptyGrid,
    ProSpaceAddInputButton,
    ProSpaceDefaultTextColumn,
    ProSpaceSearchfield,
    ProSpaceWarning,
    DistributorHierarchySelectForm,
    ProSpaceInputLayout,
    ProSpaceFlashMessage
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  data() {
    return {
      // Tabs
      modelTab: null,
      modelTabPanel: null,
      loading: false,
      rolesLoading: false,

      // Common
      layoutName: "",
      model: null,
      startModel: null,
      useCaseSelected: false,
      selectedUseCaseId: null,
      afterDistributorStructureEdit: false,

      // Service
      userService: new UserService(),
      secUserService: new SecUserService(),
      userInfoService: new UserInfoService(),
      roleService: new RoleService(),
      userRoleService: new UserRoleService(),
      menuItemUserService: new MenuItemUserService(),
      dateHelperService: new DateHelperService(),
      useCaseClaimService: new UseCaseClaimService(),
      restrictionService: new RestrictionService(),
      userDistributorRestrictionService:
        new UserDistributorRestrictionService(),
      methadataService: new MethadataService(),
      loginTypeService: new LoginTypeService(),
      baseTypeService: new BaseTypeService(),
      distributorService: new DistributorService(),
      distributorOrgService: new DistributorOrgService(),
      marsOrgService: new MarsOrgService(),
      distributorStructureService: new DistributorStructureService(),

      getMethod: "get",
      masterId: null,

      // Modal
      modalModel: { name: "", description: "", startDate: "", endDate: "" },
      isEdit: false,
      isCreate: false,

      // SaveFields
      saveFields: {
        Overview: [
          "firstName",
          "secondName",
          "lastName",
          "email",
          "login",
          "jobTitle",
          "comments",
          "dateFormat",
          "timeZoneId",
          "timeFormat",
          "orgLinkId",
          "orgLinkType",
          "orgLinkMarsOrgId",
          "orgLinkDistributorId",
          "orgLinkDistributorOrgId",
          "orgLinkDistributorName",
          "orgLinkDistributorCode",
          "orgLinkDistributorOrgName",
          "orgLinkMarsOrgName",
          "distributorStructures"
        ],
        Claims: ["claims"],
      },

      // use case
      listHeadersUseCases: [
        { name: this.$t("type"), value: "securityClaimServiceName" },
        { name: this.$t("value"), value: "securityClaimValue" },
      ],
      userRolesData: [],

      userRoleSelected: false,
      selectedRole: {},
      Validations,
      alertTimezoneModal: false,
      deletingUserRole: null,
      showRoleDeleteModal: false
    };
  },
  validations() {
    const marsValidate = (v) => {
      const model = this.model;
      if (model.orgLinkType === "Distributor") return true;
      return !!v;
    };
    const distrValidate = (v) => {
      const model = this.model;
      if (model.orgLinkType === "Mars") return true;
      return !!v;
    };
    const distrStructureValidate = (v) => {
      const model = this.model;
      if (model.orgLinkType === "Mars") return true;
      return v && v.length > 0;
    };
    const withI18nMessage = createI18nMessage({ t: this.$t });
    const localRequired = withI18nMessage(required);
    const localMaxLength = withI18nMessage(maxLength(255));
    const localEmail = withI18nMessage(Validations.isEmail);
    const localMarsValidate = withI18nMessage(marsValidate);
    const localDistrValidate = withI18nMessage(distrValidate);
    const localdistrStructureValidate = withI18nMessage(distrStructureValidate);
    return {
      model: {
        lastName: {
          localRequired,
          localMaxLength,
        },
        firstName: {
          localRequired,
          localMaxLength,
        },
        secondName: {
          localRequired,
          localMaxLength,
        },
        email: {
          localRequired,
          localMaxLength,
          localEmail,
        },
        login: {
          localRequired,
          localMaxLength,
        },
        orgLinkType: {
          localRequired,
        },
        orgLinkMarsOrgId: {
          localMarsValidate,
        },
        orgLinkDistributorId: {
          localDistrValidate,
        },
        orgLinkDistributorOrgId: {
          localDistrValidate,
        },
        distributorStructures: {
          localdistrStructureValidate
        },
      },
    };
  },
  mounted() {
    this.modelTab = this.tabs[0];
    this.modelTabPanel = this.profileTabs[0];
    this.getData();
    this.mountAccess();
  },
  methods: {
    handlerChangeTimezone({value}) {
      if (this.model.id === this.userinfo.id && value !== this.userinfo.timeZoneId) {
        this.alertTimezoneModal = true;
      }
    },
    async handlerApplyTimezone() {
      this.alertTimezoneModal = false;
      await this.saveAll();
      this.logout();
    },
    handlerCancelTimezone() {
      this.alertTimezoneModal = false;
      this.model.timeZoneId = this.userinfo.timeZoneId;
    },
    logout() {
      this.secUserService.logout().then((res) => {
        removeToken();
        removeRefreshToken();
        removeRedirectUrl();
        if (res.data) window.location.href = res.data;
        else location.href = "/security/login";
      });
    },
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
      this.mountAccess();
    },
    marsValidate(v) {
      const model = this.model;
      if (model.orgLinkType === "Distributor") return true;
      return !!v;
    },
    distrValidate(v) {
      const model = this.model;
      if (model.orgLinkType === "Mars") return true;
      return !!v;
    },
    distrStructureValidate(v) {
      const model = this.model;
      if (model.orgLinkType === "Mars") return true;
      return v && v.length > 0;
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.$refs["distributor"].$refs["filter-panel"].submitFilter();
    },
    contentDistributor(distributor) {
      if (distributor) {
        return [
          [
            {
              title: this.$t("distributorLegalAddress"),
              text: distributor.legalAddress,
            },
          ],
          [
            {
              title: this.$t("distributorMailAddress"),
              text: distributor.mailAddress,
            },
          ],
          [
            {
              title: this.$t("distributorPhoneNumber"),
              text: distributor.phoneNumber,
            },
          ],
          [
            {
              title: this.$t("distributorFaxNumber"),
              text: distributor.faxNumber,
            },
          ],
          [
            {
              title: this.$t("email"),
              text: distributor.emailAddress,
            },
          ],
        ];
      }
      return [];
    },
    contentRequisites(distributor) {
      if (distributor) {
        return [
          [
            {
              title: this.$t("nameOfBank"),
              text: distributor.bankName,
            },
          ],
          [
            {
              title: this.$t("bankPaymentAccount"),
              text: distributor.bankPaymentAccount,
            },
          ],
          [
            {
              title: this.$t("bankCorrespondentAccount"),
              text: distributor.bankCorrespondentAccount,
            },
          ],
          [
            {
              title: this.$t("bankBIC"),
              text: distributor.bankBIC,
            },
          ],
          [
            {
              title: this.$t("bankINN"),
              text: distributor.bankINN,
            },
          ],
          [
            {
              title: this.$t("bankKPP"),
              text: distributor.bankKPP,
            },
          ],
          [
            {
              title: this.$t("bankOGRN"),
              text: distributor.bankOGRN,
            },
          ],
        ];
      }
      return [];
    },
    headerDistributor(distributor) {
      if (distributor) {
        return {
          title: distributor.name,
          id: distributor.id,
          status: { text: this.$t("distributor"), type: "info" },
          showCounter: false,
        };
      }
    },
    userTypeChanged(option) {
      this.model.orgLinkMarsOrgId = null;
      this.model.orgLinkDistributorId = null;
      this.model.orgLinkDistributorOrgId = null;
      this.model.orgLinkDistributorName = "";
      this.model.orgLinkDistributorCode = "";
      this.model.orgLinkDistributorOrgName = "";
      this.model.orgLinkMarsOrgName = "";
    },
    getDistrOrgPanelInfo(record) {
      const pos = [
        {
          title: this.$t("position"),
          text: record.name,
        },
      ];
      const parentPos = [
        {
          title: this.$t("parentPosition"),
          text: record.parentName,
        },
      ];
      return record.parentName ? [parentPos, pos] : [pos];
    },
    updateModelField(data, field) {
      this.model[`orgLink${field[0].toUpperCase() + field.slice(1)}Name`] =
        this.$refs[field].selectedItem.name;
      if (field === "distributor") {
        this.model.orgLinkDistributorCode = this.$refs[field].selectedItem.code;
        this.distributorStructureService.setBillToCodes([this.model.orgLinkDistributorCode]);
        this.distributorStructureService.setNodeViewIds([]);
        this.model.distributorStructures = [];
      }
    },
    handlerSelectRadio(tab) {
      if (tab.value === "profile") {
        this.modelTabPanel = this.profileTabs[0];
      } else if (tab.value === "permissions") {
        const activeTab = this.tabsPanel.find(i => i.isActive)
        this.modelTabPanel = activeTab || this.tabsPanel[0];
      }
      if (tab.systemName) {
        this.setStatistic({
          actionTemp: this.distActions.tab,
          actionStr: tab.systemName
        })
        this.mountAccess();
      }
    },
    userRoleGridLoaded() {
      this.userRolesData = this.$refs["userRoleGrid"].records;
      this.rolesLoading = false;
    },
    selectedHandler(val) {
      let id;

      id = Object.keys(val).map((key) => {
        return val[key].id;
      });

      if (!id[0]) return;

      if (id.length == 0) {
        this.selectedModel = {};
      } else {
        this.selectedModel = id;
        this.spKey = id.join();
        this.masterId = id[0];
      }
    },
    useCaseSelect(id) {
      if (id && this.$refs["userUseCase"]) {
        this.$refs["userUseCase"].userUseCaseService.get(id).then((r) => {
          if (r.data.useCaseId == this.selectedUseCaseId) {
            this.selectedUseCaseId = null;
            this.useCaseSelected = false;
          } else {
            this.selectedUseCaseId = r.data.useCaseId;
            this.useCaseSelected = true;
          }
        });
      } else {
        this.selectedUseCaseId = null;
        this.useCaseSelected = false;
      }
    },
    userRoleSelect(record) {
      if (
        record &&
        record.securityRoleId !== this.selectedRole.securityRoleId
      ) {
        this.selectedRole = record;
        this.userRoleSelected = true;
      } else {
        this.selectedRole = {};
        this.userRoleSelected = false;
        this.$nextTick(() => {
          this.$refs.userRoleGrid.selectedModel = []
        })
      }
    },
    removeUserRole(model) {
      this.deletingUserRole = model;
      this.showRoleDeleteModal = true;
    },
    applyUserRoleDelete() {
      this.rolesLoading = true;
      this.userRoleService.delete([this.deletingUserRole]).then(() => {
        this.$refs["userRoleGrid"].getRecords(true);
        this.rolesLoading = false;
        if (this.selectedRole.securityRoleId === this.deletingUserRole.securityRoleId) {
          this.userRoleSelect(this.deletingUserRole)
        }
        this.deletingUserRole = null;
      });
    },
    formatDate(dateString, format) {
      let date = new Date(dateString);

      let yyyy = date.getFullYear();
      let mm = date.getMonth() + 1;
      let dd = date.getDate();

      let hours = date.getHours();
      let minutes = date.getMinutes();
      let time = { hours, minutes, ampm: "" };

      if (format.includes("hh")) time = this.formatAMPM(date);

      var formated = format
        .replace("dd", dd)
        .replace("MM", mm)
        .replace("yyyy", yyyy)
        .replace("HH", time.hours)
        .replace("hh", time.hours)
        .replace("mm", time.minutes);

      formated += `${time.ampm}`;

      return formated;
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? " pm" : " am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      return { hours, minutes, ampm };
    },
    async getData() {
      this.masterId = this.$route.params.id;
      if (this.masterId === undefined) {
        // redirect to 404
        return;
      }
      const result = await this.userService[this.getMethod](this.masterId);
      this.model = result.data;
      this.startModel = _.cloneDeep(this.model);
      this.userRoleService.setItem(this.model);
      this.restrictionService.setUserId(this.masterId);
      this.restrictionService.setUserCode(this.model.code);
      this.distributorStructureService.setUserId(this.model.id);
      this.distributorStructureService.setBillToCodes([this.model.orgLinkDistributorCode]);
      this.distributorStructureService.setNodeViewIds(this.model.distributorStructures.map(x => x.distributorStructureViewId));
      this.afterDistributorStructureEdit = false;
      this.layoutName = this.model.secondName
        ? `${this.model.lastName} ${this.model.firstName} ${this.model.secondName}`
        : `${this.model.lastName} ${this.model.firstName}`;

      if (!this.model.dateFormat) {
        this.model.dateFormat = localStorage.getItem("dateFormat");
      } else {
        this.model.dateFormat = this.model.dateFormat
          .replaceAll("y", "Y")
          .replaceAll("m", "M")
          .replaceAll("d", "D");
      }

      if (this.model.id === this.userinfo.id) {
        this.$store.dispatch("userinfoStore/setUserinfo", _.cloneDeep(this.model));
      }
    },
    applyRoles() {
      this.rolesLoading = true;
      this.$refs['userRoleGrid'].getRecords(true);
    },
    applyDistributorStructure() {
      this.afterDistributorStructureEdit = true;
    },
    saveAll() {
      if (!this.v$.$dirty) {
        this.v$.$touch();
      }
      this.checkValidProfile()
      if (this.v$.$invalid) {
        return;
      }
      this.loading = true;

      this.userService.set().then(() => {
        this.getData();
        this.loading = false;
      });
    },
    savePart(fields) {
      if (!this.v$.$dirty) {
        this.v$.$touch();
      }
      this.checkValidProfile()
      if (this.v$.$invalid && this.modelTabPanel.value !== "distributors") {
        return;
      }
      this.loading = true;

      // if (this.modelTabPanel.value === "restrictions") {
      //   this.restrictionService.save(this.masterId).then(() => {
      //     this.$refs["user-restriction"].getRecords();
      //     this.loading = false;
      //   });
      // } else {
      if (this.modelTabPanel.value === "distributors") {
        let newModels = this.$refs["user-distributor"].getNewDistributors();
        let deletedModels =
          this.$refs["user-distributor"].getDeletedDistributors();
        this.userDistributorRestrictionService
          .save(newModels, deletedModels)
          .then(() => {
            this.$refs["user-distributor"].getData();
            this.loading = false;
          });
      } else {
        this.userService.setPart(fields).then(async () => {
          await this.getData();
          if (this.model.code === this.userinfo.code) {
            this.userInfoService.get();
          }
          this.loading = false;
        });
      }
    },
    checkValidProfile() {
      const fieldsProfile = new Set(['lastName', 'firstName', 'secondName', 'email',
      'login', 'orgLinkType', 'orgLinkMarsOrgId', 'orgLinkDistributorId', 'orgLinkDistributorOrgId'])
      const fieldsStructure = new Set(['distributorStructures'])
      const hasErrorInProfile = this.v$.$errors.some(i => fieldsProfile.has(i.$property))
      const hasErrorInStructure = this.v$.$errors.some(i => fieldsStructure.has(i.$property))
      if (!hasErrorInProfile && hasErrorInStructure && this.modelTab.systemName === "profile" && this.modelTabPanel.systemName === "userProfile") {
        const index = 1
        const tab = this.profileTabs[index]
        this.$refs.tabs.select(tab, index)
      }
    },
    showModalForm(name) {
      this.$refs[name].show();
    },
    showMethadataModal() {
      this.$refs["user-restriction"].showModal();
    },
    submitFilter(nameRef = "grid") {
      if (!this.$refs[nameRef]) return;
      this.$refs[nameRef].getRecords(true);
    },
    createItem() {},
    deleteRecord(id) {},
    sendGridModal(model) {},
    backHandler() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDate: "userinfoStore/getFormatDate",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    refsForCheckInMixin() {
      const refs = ["action-saveall"];

      if (this.modelTabPanel.systemName !== "menuItems") {
        refs.push("action-save")
      }

      if (this.modelTabPanel.systemName === "roles") {
        refs.push("action-addrole");
      }

      if (this.modelTabPanel.systemName === "restrictions") {
        refs.push("action-addrestrictions");
      }

      return refs;
    },
    structureInfoBlockHeader() {
      return {
        title: this.$t("distributorStructure"),
      };
    },
    disabledSave() {
      return this.v$.model.$error || _.isEqual(this.model, this.startModel);
    },
    userPanelMeta() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    contentUserRoles() {
      if (this.selectedRole) {
        return [
          [
            {
              title: this.$t("name"),
              text: this.selectedRole.securityRoleSystemName,
            },
          ],
        ];
      }
      return [];
    },

    headerBottomUserRoles() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    contentBottomUserRoles() {
      if (this.selectedRole) {
        return [
          [
            {
              title: this.$t("createdByTxt"),
              text: this.selectedRole.createdBy,
            },
          ],
          [
            {
              title: this.$t("createdAtTxt"),
              text: this.getFormatDateTime(this.selectedRole.createdAt) + ' ' + getAMorPM(this.selectedRole.createdAt, this.userinfo.timeFormat),
            },
            {
              title: this.$t("lastUpdatedTxt"),
              text: this.getFormatDateTime(this.selectedRole.lastUpdated) + ' ' + getAMorPM(this.selectedRole.lastUpdated, this.userinfo.timeFormat),
            },
          ],
        ];
      }
    },

    userRoleGridData() {
      return this.userRolesData;
    },
    tabs() {
      return [
        { label: this.$t("profile"), value: "profile", systemName: "profile" },
        { label: this.$t("permissions"), value: "permissions", systemName: "permissions" },
      ];
    },
    tabsDistributor() {
      return [
        { name: this.$t("overview"), isActive: true, systemName: "overview" },
        { name: this.$t("requisites"), systemName: "requisites" },
      ];
    },
    profileTabs() {
      return [
        {
          name: this.$t("userProfile"),
          value: "user",
          systemName: "userProfile",
          isActive: true
        },
        {
          name: this.$t("distributorStructureBindingFull"),
          systemName: "distrStructure",
          isDisabled: this.model && !this.model.orgLinkDistributorId,
          isHidden: this.model && this.model.orgLinkType !== "Distributor"
        }
      ];
    },
    tabsPanel() {
      const roles = {
        name: this.$t("roles"),
        value: "roles",
        isActive: true,
        systemName: "roles"
      };
      const useCases = {
        name: this.$t("useCases"),
        value: "useCases",
        systemName: "useCases"
      };
      const menuItems = {
        name: this.$t("menuItems"),
        value: "menuItems",
        systemName: "menuItems"
      };
      // const restrictions = {
      //   name: this.$t("restrictions"),
      //   value: "restrictions",
      // };
      const distributors = {
        name: this.$t("distributors"),
        value: "distributors",
        systemName: "distributors"
      };
      return this.model.orgLinkType === "Mars"
        ? [roles, useCases, menuItems, distributors]
        : [roles, useCases, menuItems];
    },
    isUseCaseSelected() {
      return this.modelTabPanel.value == "useCases" && this.useCaseSelected;
    },
    isUserRoleSelected() {
      return this.modelTabPanel.value == "roles" && this.userRoleSelected;
    },
    disabledModalRule() {
      return (
        this.modalModel.name.length == 0 ||
        this.modalModel.description.length == 0
      );
    },
    userPanelMetaContent() {
      return [
        [
          {
            title: this.$t("createdByTxt"),
            text: this.model.createdBy,
          },
        ],
        [
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.model.createdAt) + ' ' + getAMorPM(this.model.createdAt, this.userinfo.timeFormat),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.model.lastUpdated) + ' ' + getAMorPM(this.model.lastUpdated, this.userinfo.timeFormat),
          },
        ],
      ];
    },
    customServices() {
      return [
        {
          type: "chips",
          model: "Code",
          label: this.$t("distributorCode"),
          selected: {},
        },
        {
          type: "chips",
          model: "LegalAddress",
          label: this.$t("distributorLegalAddress"),
          selected: {},
        },
        {
          type: "chips",
          model: "MailAddress",
          label: this.$t("distributorMailAddress"),
          selected: {},
        },
        {
          type: "chips",
          model: "PhoneNumber",
          label: this.$t("distributorPhoneNumber"),
          selected: {},
        },
        {
          type: "chips",
          model: "FaxNumber",
          label: this.$t("distributorFaxNumber"),
          selected: {},
        },
        {
          type: "chips",
          model: "emailAddress",
          label: this.$t("email"),
          selected: {},
        },
        {
          type: "checkboxes",
          service: new DistributorService(),
          getMethod: "getEDOs",
          model: "EDOProviderName",
          label: this.$t("distributorEDO"),
          optionValue: "value",
          optionLabel: "label",
          selected: {},
        },
        {
          type: "chips",
          model: "BankName",
          label: this.$t("distributorBankName"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankPaymentAccount",
          label: this.$t("distributorBankPaymentAccount"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankCorrespondentAccount",
          label: this.$t("distributorBankCorrespondentAccount"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankBIC",
          label: this.$t("distributorBankBIC"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankINN",
          label: this.$t("distributorBankINN"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankKPP",
          label: this.$t("distributorBankKPP"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankOGRN",
          label: this.$t("distributorBankOGRN"),
          selected: {},
        },
        {
          type: "chips",
          model: "createdBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "chips",
          model: "updatedBy",
          label: this.$t("updatedByTxt"),
          selected: {},
        },
        {
          type: "dates-range",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
  },
};
</script>
<style lang="scss">
.grid-menuitems {
  tr.p-selectable-row {
    height: 40px;
  }
}
</style>
