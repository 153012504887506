<template>
  <ProSpaceIconButton
    icon="settings"
    iconWidth="20px"
    iconHeight="20px"
    containerSize="20px"
  />
</template>

<script>
import { ProSpaceIconButton } from "@prospace/prospace-components-library";
export default {
  name: "IconButtonSettingsColumns",
  components: {
    ProSpaceIconButton,
  },
};
</script>
