import { h, withDirectives } from 'vue';
import {
  Hint,
  ProSpaceTag,
} from "@prospace/prospace-components-library";

export default (props, { slots, attrs }) => {
  const escapeValue = (value) => {
    return props.broker.escapeValue(value);
  };

  return h(
    'tr',
    { class: { selected: props.isSelected }, },
    props.columns.map((column) => {
      const text = escapeValue(props.row[column.key]);
      const span = h('span', text)
      const labelWithDirective = withDirectives(span, [
        [Hint, text]
      ]);
      const cellContent = props.tagRows[column.key]
        ? h(ProSpaceTag, { text: text, bordered: true })
        : labelWithDirective;

      return h(
        'td',
        { style: [
            { backgroundColor: `var(${props.backgroundColor[column.key].background})` },
            { color: `var(${props.backgroundColor[column.key].text})` }
          ]
        },
        cellContent
      );
    })
  )
}
