<template>
  <ProSpaceGeoLayout>
    <template #top>
      <ProSpaceFilterPanel
        :closeTxt="$t('closeTxt')"
        :searchPlaceholder="$t('searchTxt')"
        :txtResetFilters="$t('txtResetFilters')"
        :unicKeyStorage="menuItemsService.constructor.name + 'grid'"
        @apply="submitFilter"
        @update:modelFastFilter="
          (val) => {
            modelFastFilter = val;
            $emit('clear')
          }
        "
        ref="filter-panel"
        filter-model="title"
        :modelFastFilter="modelFastFilter"
        :fastFilters="fastFilters"
      >
        <template #hint> {{ $t("filterBy") }}</template>
        <template #filters="{ filter }">
          <ProSpaceCustomFilterPanel
            :customTxt="$t('custom')"
            :customFilterTxt="$t('customFilterTxt')"
            :clearFilterTxt="$t('clearFilterTxt')"
            :selectAllTxt="$t('selectAllTxt')"
            :closeTxt="$t('closeTxt')"
            :applyTxt="$t('applyTxt')"
            :fromTxt="$t('fromTxt')"
            :toTxt="$t('toTxt')"
            :services="customServices"
            :unicKeyStorage="menuItemsService.constructor.name + 'grid'"
            @apply="(model) => customFilterApply(filter, model)"
          />
        </template>
      </ProSpaceFilterPanel>
    </template>
    <template #center>
      <ProSpaceMainGrid
        ref="grid"
        type="expand"
        :service="menuItemsService"
        @selected-change="selectedHandler"
        :noMountedRequest="true"
        @expand-change="selectedHandler"
        @db-click="toItem"
        getMethod="grid"
        selection-mode="single"
        v-bind="gridPropsSettingsColumns"
      >
        <template #columns>
          <Column
            :header="$t('title')"
            field="title"
            sortable
            style="width: 33%"
            frozen
            class="column-table-header-cell"
          >
            <template #body="field">
              <ProSpaceTableHeaderCell
                :id="field.data.id"
                @click-on-title="toItem(field.data)"
                :title="field.data.title"
              />
            </template>
          </Column>
          <Column
            :header="$t('path')"
            sortable
            field="path"
            style="width: 35.5%"
          >
            <template #body="field">
              <ProSpaceDefaultTextColumn>
                {{ field.data.path }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column :header="$t('icon')" field="icon" sortable>
            <template #body="slotProps">
              <ProSpaceDefaultTextColumn>
                <ProSpaceHLayout :gap="5">
                  <ProSpaceIcon
                    v-if="slotProps.data.icon"
                    :icon="slotProps.data.icon"
                  />
                  {{ slotProps.data.icon }}
                </ProSpaceHLayout>
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column :header="$t('order')" sortable field="order">
            <template #body="field">
              <ProSpaceDefaultTextColumn>
                {{ field.data.order }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column class="column-end column-end-30" frozen alignFrozen="right">
            <template #body="slotProps">
              <ProSpaceDots
                :options="dotsOptions"
                @action="(action) => handleAction(action, slotProps.data)"
              />
            </template>
          </Column>
        </template>
        <template #expansion>
          <Column>
            <template #body="slotProps">
              <ProSpaceTableHeaderCell
                :id="slotProps.data.id"
                @click-on-title="toItem(slotProps.data)"
                :title="slotProps.data.title"
              />
            </template>
          </Column>
          <Column style="min-width: 25%">
            <template #body="slotProps">
              <ProSpaceDefaultTextColumn>
                <ProSpaceTextBlock
                  :gap="0"
                  type="secondary"
                  top="Route"
                  :bottom="slotProps.data.path"
                />
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <ProSpaceDefaultTextColumn>
                <ProSpaceVLayout>
                  <ProSpaceIcon
                    v-if="slotProps.data.icon"
                    :icon="slotProps.data.icon"
                  />
                  <span style="line-height: 15px">{{
                    slotProps.data.title
                  }}</span>
                </ProSpaceVLayout>
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column :header="$t('order')" sortable field="order">
            <template #body="field">
              <ProSpaceDefaultTextColumn>
                {{ field.data.order }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column class="column-end column-end-30">
            <template #body="slotProps">
              <ProSpaceDots
                :options="dotsOptions"
                @action="(action) => handleAction(action, slotProps.data)"
              />
            </template>
          </Column>
        </template>
      </ProSpaceMainGrid>
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceHistoryModal
    ref="history"
    v-model="showHistoryModal"
    modelName="MenuItemApiModel"
    @close="closeHistory"
  />
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingConfirmation')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10" justify-content="center" align-items="center">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteMenuItem', { name:  deletingModel.title })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>

<script>
import {
  ProSpaceMainGrid,
  ProSpaceIcon,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceTag,
  ProSpaceFilterPanel,
  ProSpaceDots,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceGeoLayout,
  ProSpaceTableHeaderCell,
  ProSpaceTextBlock,
  ProSpaceDefaultTextColumn,
  ProSpaceFlashMessage
} from "@prospace/prospace-components-library";
import Column from "primevue/column";
import { MenuItemsService } from "../../../services/MenuItemsService.js";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes";
import useSettingsColumns from "@composes/useSettingsColumns.js";
import { ref } from "vue"
export default {
  name: "MenuItemsGrid",
  components: {
    ProSpaceMainGrid,
    ProSpaceIcon,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceTag,
    ProSpaceFilterPanel,
    ProSpaceDots,
    Column,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceGeoLayout,
    ProSpaceTableHeaderCell,
    ProSpaceTextBlock,
    ProSpaceHistoryModal,
    ProSpaceDefaultTextColumn,
    ProSpaceFlashMessage
  },
  setup() {
    const grid = ref(null);
    const { setStatistic, distActions } = useStatistic()
    const {
      showModalSettingsColumns,
      gridPropsSettingsColumns
    } = useSettingsColumns(grid)
    return {
      setStatistic, distActions,
      showModalSettingsColumns,
      gridPropsSettingsColumns,
      grid
    }
  },
  data() {
    return {
      showHistoryModal: false,
      activeItem: null,
      menuItemsService: new MenuItemsService(),
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: false,
        field: "disabled",
      },
      deletingModel: null,
      showDeleteModal: false,
    };
  },
  methods: {
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.title;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.$refs["filter-panel"].submitFilter();
      this.$emit("clear");
    },
    emitFilter() {
      this.$refs["filter-panel"].submitFilter();
      this.$emit("clear");
    },
    handleAction(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    getIcon(slotProps) {
      return slotProps.data.icon ? slotProps.data.icon : "dashboard";
    },
    toItem(data) {
      const url = `/securityadmin/menuitem/${data.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    editItem(item) {
      this.$emit("modal-edit", item);
    },
    deleteItem(item) {
      this.deletingModel = item;
      this.showDeleteModal = true;
    },
    applyDelete() {
      const grid = this.grid;
      grid.setLoading(true);
      this.selectedHandler(null);
      this.menuItemsService.deleteItem([this.deletingModel]).finally(() => {
        this.getRecords();
      });
    },
    getRecords() {
      this.grid.getRecords();
    },
    fnSetMenuItemsRecords(response) {
      const records = response.data.records;
      const menuitems = [];
      records.forEach((i) => {
        if (!i.parentId) {
          i.children = [];
          menuitems.push(i);
        } else {
          const item = menuitems.find((mi) => mi.id === i.parentId);
          item?.children?.push(i);
        }
      });
      return menuitems;
    },
    selectedHandler(selectedItem) {
      this.activeItem = selectedItem?.data;
      this.$emit("select", this.activeItem);
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    customServices() {
      return [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        },
        {
          type: "chips",
          model: "createdBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "chips",
          model: "updatedBy",
          label: this.$t("updatedByTxt"),
          selected: {},
        },
        {
          type: "dates-range",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
    dotsOptions() {
      let res = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
        },
      ];
      if (this.modelFastFilter.label === this.$t("deleted")) {
        this.$emit("optionsUpdate", res);
        return res.filter(
          (option) => option.name !== "edit" && option.name !== "delete"
        );
      }
      this.$emit("optionsUpdate", res);
      return res;
    },
    fastFilters() {
      return [
        { label: this.$t("allTxt"), value: false, field: "disabled" },
        { label: this.$t("deleted"), field: "disabled", value: true },
      ];
    },
  },
};
</script>

<style lang="scss"></style>
